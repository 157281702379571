import React from 'react';
import PropTypes from 'prop-types';
import mockDictionary from './mock/dictionary.js';
const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const toValueObject = (obj) => {
    if (typeof obj === 'object') {
        return Object.keys(obj).reduce((result, k) => {
            result[k] = toValueObject(obj[k]);
            return result;
        }, {});
    }

    return { value: obj };
};

const mockT = (key) => mockDictionary[key];

const forStorybook = (WrappedComponent) => {
    const StorybookWrapper = ({
        children,
        placeholders,
        fields,
        renderings,
        rendering,
        params,
        ...props
    }) => {
        const fieldsWithObject = {
            ...toValueObject(props),
            ...fields,
        };
        return (
            <WrappedComponent
                fields={fieldsWithObject}
                params={params}
                {...placeholders}
                rendering={rendering}
                {...renderings}
                t={mockT}
            >
                {children}
            </WrappedComponent>
        );
    };
    StorybookWrapper.displayName = `StorybookWrapper(${getDisplayName(WrappedComponent)})`;
    return StorybookWrapper;
};

forStorybook.propTypes = {
    WrappedComponent: PropTypes.element,
};

export const forStorybookV2 = (WrappedComponent) => {
    const StorybookWrapper = ({
        children,
        placeholders,
        fields,
        customFields,
        renderings,
        rendering,
        ...props
    }) => {
        const fieldsWithObject = {
            ...toValueObject(fields),
            ...customFields,
        };
        return (
            <WrappedComponent
                fields={fieldsWithObject}
                params={props}
                rendering={rendering}
                {...placeholders}
                {...renderings}
                t={mockT}
            >
                {children}
            </WrappedComponent>
        );
    };
    StorybookWrapper.displayName = `StorybookWrapperV2(${getDisplayName(WrappedComponent)})`;
    return StorybookWrapper;
};

forStorybookV2.propTypes = {
    WrappedComponent: PropTypes.element,
};

export default forStorybook;
