import React from 'react';
import Text from '../Text/Text.jsx';
import { Button } from '../Button/Button.js';
import { CloseIcon } from '../../assets/common.js';
import { TagTypeEnum, LinkTargetEnum, SizeEnum, LinkVariantEnum } from '../../utils/enums.js';
import forStorybook from '../../utils/forStorybook.jsx';
import Link from '../Link/Link.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { mapTemplateTypeToDisplay } from '../../utils/searchHelpers.jsx';
import './tag.scss';

export const Tag = ({ fields }) => {
    const {
        Title,
        Identifier,
        as,
        onClose,
        SearchResultsPage,
        ApplyTemplateToSearch,
        templateName,
        HideInArticleTagList,
    } = fields;

    const tagQuery = `${
        SearchResultsPage?.url ? SearchResultsPage.url.split('?')[0] : `/search`
    }?tags=${encodeURIComponent(Identifier?.value)}`;
    const templateQuery = `${
        ApplyTemplateToSearch?.value && templateName
            ? `&contenttype=${mapTemplateTypeToDisplay([templateName])?.[0]}`
            : ''
    }`;
    const searchQuery = { value: tagQuery + templateQuery };

    return (
        !HideInArticleTagList?.value && (
            <span className="zn-tag-bubble">
                {as?.value === TagTypeEnum.button ? (
                    <>
                        <Text type="body" field={Title} useDefaultMargin={false} />
                        <Button as="button" variant="icon" onClick={onClose}>
                            <CloseIcon customColor="#000" size={SizeEnum.small} />
                        </Button>
                    </>
                ) : (
                    <>
                        <Link
                            LinkAltText={Title}
                            LinkTarget={LinkTargetEnum.sameTab}
                            Variant={{ value: LinkVariantEnum.small }}
                            LinkURL={searchQuery}
                        >
                            <Text type="body" field={Title} useDefaultMargin={false} />
                        </Link>
                    </>
                )}
            </span>
        )
    );
};

export default withErrorBoundary([ErrorBoundaryConditions.fields])(Tag);
export const TagStorybook = forStorybook(Tag);
