import React, { useEffect, useState } from 'react';
import './autoload-content-row.scss';
import classnames from 'classnames';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import ContentCard from '../ContentCard/ContentCard.jsx';
import { ArrowRight } from '../../assets/LinkIcons.js';
import {
    HeadingLevelEnum,
    LinkTargetValueEnum,
    SectionHeadingSizeEnum,
    ThemeEnum,
    OverrideAlignmentEnum,
    HighlightColorItemEnum,
} from '../../utils/enums';
import ContentRowPdfList from './ContentRowPdfList.jsx';
import { withTranslation } from 'react-i18next';
import SectionHeading from '../SectionHeading/SectionHeading.jsx';
import { setAnimationInterObserver } from '../../utils/observers.jsx';
import { fetchResults } from '../../actions';
import { formatSearchParams, mapDisplayTypeToTemplate } from '../../utils/searchHelpers.jsx';
import {
    templateNameCondition,
    tagNameCondition,
    teaserDateSortBy,
} from '../../utils/constants.js';
import usePdfView from '../../utils/hooks/usePdfView.jsx';

const autoloadContentRowBlock = 'zn-autoload-content-row';

const allTemplatesConditions = [
    'News',
    'Event',
    'Work Page',
    'Publication Page',
    'Video',
    'Webinar',
    'Podcast',
    'Project Map Work',
];

const AutoloadContentRow = ({ t, params, fields, featuredContent, sitecoreContext, rendering }) => {
    const { DefaultCardColor, TitleAccentColor, ImageHighlightColor, EnableAnimation } = params;
    const {
        NumberOfDesktopRows,
        NumberOfMobileCards,
        ContentTypes,
        Tags,
        SearchResultsPage,
        TitleInput,
        LinkText,
        ApplyTagsFromPage,
    } = fields;
    const isEditing = sitecoreContext.pageEditing;
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const pdfView = usePdfView();
    const defaultNumberOfCards = 3;

    const excludedID = rendering?.placeholders?.featuredContent[0]?.fields?.data?.datasource
        ?.contentURL?.targetItem?.id
        ? rendering?.placeholders?.featuredContent[0]?.fields?.data?.datasource?.contentURL
              ?.targetItem?.id
        : '';

    const animationEnabled = EnableAnimation && !isEditing;
    const ButtonTag = isEditing ? 'div' : 'a';
    let templates = ContentTypes.map((item) => item?.displayName);

    let tags = Tags;

    if (ApplyTagsFromPage.value) {
        const contextFields = sitecoreContext?.route?.fields;
        tags = contextFields?.Tags;
    }

    let tagsID = tags.map((tag) => tag?.id);
    let tagsNames = tags.map((tag) =>
        encodeURIComponent(tag?.fields?.Identifier?.value ? tag?.fields?.Identifier?.value : '')
    );

    let formattedTags =
        tagsID.length > 0 &&
        tagsID.map((item) => formatSearchParams(tagNameCondition, item.replace(/-/g, '')));
    let formattedTemplates =
        templates.length > 0 &&
        mapDisplayTypeToTemplate(templates).map((item) =>
            formatSearchParams(templateNameCondition, item)
        );
    let conditions = [];
    formattedTemplates.length > 0
        ? conditions.push({ group: [...formattedTemplates] })
        : conditions.push({
              group: [
                  ...allTemplatesConditions.map((item) =>
                      formatSearchParams(templateNameCondition, item)
                  ),
              ],
          });
    formattedTags.length > 0 && conditions.push({ group: [...formattedTags] });
    let templateCallParams = templates.join();
    let tagCallParams = tagsNames.join();

    let first =
        NumberOfMobileCards?.value && NumberOfDesktopRows?.value
            ? Math.max(NumberOfMobileCards?.value, NumberOfDesktopRows?.value * 3)
            : defaultNumberOfCards;

    const performSearch = (event) => {
        event.preventDefault();
        let searchString = `${SearchResultsPage?.url.split('?')[0]}`;
        if (templateCallParams) searchString += `?contenttype=${templateCallParams}`;
        if (tagCallParams && !templateCallParams) searchString += `?tags=${tagCallParams}`;
        if (tagCallParams && templateCallParams) searchString += `&tags=${tagCallParams}`;
        window.location.href = searchString;
    };

    const currentItemId = sitecoreContext.itemId.toString();
    useEffect(() => {
        fetchResults(
            conditions,
            [],
            first,
            '0',
            teaserDateSortBy,
            true,
            [currentItemId, excludedID?.toString()],
            '',
            true
        )
            .then((x) => {
                setResults(x.results);
                setIsLoading(false);
                setHasError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
    }, [currentItemId]);

    useEffect(() => {
        if (animationEnabled && results.length > 0 && !isLoading) {
            // Scroll fade up reveal
            const selector = `.${autoloadContentRowBlock} .fadeIn`;
            const newClass = 'fadeInActive';
            // Adds the class that activates the animation whenever the components are in the view
            setAnimationInterObserver(selector, newClass);
        }
    }, [isLoading]);

    if (pdfView)
        return (
            <div>
                {(TitleInput?.value || isEditing) && (
                    <SectionHeading
                        params={{
                            Theme: ThemeEnum.light,
                            SectionHeadingSize: SectionHeadingSizeEnum.large,
                            HeadingLevel: HeadingLevelEnum.h2,
                            AccentColor: TitleAccentColor,
                            OverrideAlignment: OverrideAlignmentEnum.none,
                        }}
                        fields={{
                            TextInput: TitleInput,
                        }}
                    ></SectionHeading>
                )}
                <ContentRowPdfList results={results} />
            </div>
        );
    return (
        (isEditing || results.length > 0) && (
            <div
                className={classnames(`${autoloadContentRowBlock}`, {
                    [`${autoloadContentRowBlock}__animate`]: animationEnabled,
                })}
            >
                <div className={classnames(`${autoloadContentRowBlock}__heading-row`)}>
                    <div className={classnames(`${autoloadContentRowBlock}__desktop-title`)}>
                        {(TitleInput?.value || isEditing) && (
                            <SectionHeading
                                params={{
                                    Theme: ThemeEnum.light,
                                    SectionHeadingSize: SectionHeadingSizeEnum.large,
                                    HeadingLevel: HeadingLevelEnum.h2,
                                    AccentColor: TitleAccentColor,
                                    OverrideAlignment: OverrideAlignmentEnum.none,
                                }}
                                fields={{
                                    TextInput: TitleInput,
                                }}
                            ></SectionHeading>
                        )}
                    </div>
                    {((SearchResultsPage?.url && LinkText?.value) || isEditing) && (
                        <div className={classnames(`${autoloadContentRowBlock}__desktop-button`)}>
                            <ButtonTag
                                onClick={isEditing ? undefined : performSearch}
                                target={LinkTargetValueEnum.self}
                                className={classnames(
                                    `zn-button`,
                                    `zn-button--tertiary`,
                                    `zn-button--light`,
                                    `zn-button--tertiary--light`
                                )}
                                alt={LinkText?.value}
                            >
                                <RichText field={LinkText} />
                                <div
                                    className={classnames(
                                        `zn-button__icon`,
                                        `zn-button--tertiary--light__icon`
                                    )}
                                >
                                    <ArrowRight />
                                </div>
                            </ButtonTag>
                        </div>
                    )}
                </div>
                {(featuredContent || isEditing) && (
                    <div className={classnames(`${autoloadContentRowBlock}__featured-content`)}>
                        {featuredContent}
                    </div>
                )}
                <div className={classnames(`${autoloadContentRowBlock}__grid`)}>
                    {!hasError
                        ? (Array.isArray(results) ? results : []).map((result, index) => (
                              <li
                                  key={index}
                                  className={classnames(`${autoloadContentRowBlock}__item`, {
                                      [`${autoloadContentRowBlock}__item--mobile-display`]:
                                          index < NumberOfMobileCards?.value,
                                      [`${autoloadContentRowBlock}__item--desktop-display`]:
                                          index < NumberOfDesktopRows?.value * 3,
                                      fadeIn: animationEnabled,
                                  })}
                              >
                                  <ContentCard
                                      fields={{
                                          data: {
                                              datasource: {
                                                  pageLink: {
                                                      targetItem: {
                                                          displayName:
                                                              result?.item?.teaserTitle?.value,
                                                          template: {
                                                              name: result?.templateName,
                                                          },
                                                          url: result?.item?.url,

                                                          teaserTitle: {
                                                              value:
                                                                  result?.item?.teaserTitle?.value,
                                                          },

                                                          teaserDescription: {
                                                              value:
                                                                  result?.item?.teaserDescription
                                                                      ?.value,
                                                          },

                                                          teaserDate: {
                                                              value:
                                                                  result?.item?.teaserDate?.value,
                                                          },

                                                          hideCardTeaserDate: {
                                                              value:
                                                                  result?.item?.hideCardTeaserDate
                                                                      ?.value,
                                                          },

                                                          teaserTemplateTypeOverride: {
                                                              value:
                                                                  result?.item
                                                                      ?.teaserTemplateTypeOverride
                                                                      ?.value,
                                                          },

                                                          teaserImage: result?.item?.teaserImage,

                                                          notNavigatable: {
                                                              value:
                                                                  result?.item?.notNavigatable
                                                                      ?.value,
                                                          },
                                                      },
                                                  },
                                              },
                                          },
                                      }}
                                      params={{
                                          IncludeImage: '1',
                                          DefaultCardColor: DefaultCardColor,
                                          ImageHighlightColor: ImageHighlightColor
                                              ? ImageHighlightColor
                                              : HighlightColorItemEnum.none,
                                      }}
                                  />
                              </li>
                          ))
                        : isEditing && (
                              <div className={`${autoloadContentRowBlock}__error`}>
                                  {t('Error')}
                              </div>
                          )}
                </div>
                {!hasError &&
                    !isLoading &&
                    results.length === 0 &&
                    templates.length > 0 &&
                    isEditing && (
                        <div className={`${autoloadContentRowBlock}__no-results`}>
                            No Results, please update content types and tags selection
                        </div>
                    )}
                {SearchResultsPage?.url && LinkText?.value && (
                    <div className={classnames(`${autoloadContentRowBlock}__mobile-button`)}>
                        <a
                            onClick={performSearch}
                            target={LinkTargetValueEnum.self}
                            className={classnames(
                                `zn-button`,
                                `zn-button--secondary`,
                                `zn-button--light`,
                                `zn-button--secondary--light`
                            )}
                        >
                            <RichText field={{ value: LinkText?.value }} />
                        </a>
                    </div>
                )}
            </div>
        )
    );
};

const AutoloadContentRowSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.params])(
        AutoloadContentRow
    )
);
const AutoloadContentRowTranslation = withTranslation()(AutoloadContentRowSitecore);
const AutoloadContentRowWithPlaceholder = withPlaceholder('featuredContent')(
    AutoloadContentRowTranslation
);

export default AutoloadContentRowWithPlaceholder;
export const AutoloadContentRowStorybook = forStorybookV2(AutoloadContentRowSitecore);
