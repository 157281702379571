import React, { useEffect } from 'react';
import './project-map-list.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ProjectMapListItem from '../ProjectMapListItem/ProjectMapListItem.jsx';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { fetchMapPoints } from '../../actions';

export const ProjectMapList = ({
    t,
    fields,
    listData,
    mapTags,
    filterFacets,
    isLoading,
    isListLoading,
    spriteData,
    searchInput,
    setIsLoading,
    setIsListLoading,
    updateFilterFacets,
    setListData,
    searchConditions,
    clearAllFilters,
    setIsMobileListViewOpen,
    ctaText,
    includesProjectMap,
}) => {
    const { NumberOfResults } = fields;
    const resultsLimit = NumberOfResults?.value ? NumberOfResults?.value : 50;
    const projectMapListBlock = 'zn-project-map-list';

    const getMapSvgPath = (tagId) => {
        const tagIcon = mapTags.find((mapTag) => mapTag?.id === tagId)?.path;
        return tagIcon;
    };

    const getMarkerTag = (tagId) => {
        const tagTitle = mapTags.find((mapTag) => mapTag?.id === tagId)?.title;
        return tagTitle;
    };

    const getMarkerTitle = (marker) => {
        const title = marker?.markertitle ? marker?.markertitle : marker?.teasertitle;
        return title;
    };

    const configureUrlFromPath = (path) => {
        const url = path?.split('/home')[1];
        return url;
    };

    useEffect(() => {
        if (!includesProjectMap) {
            fetchMapPoints(searchConditions, null, filterFacets, null, searchInput)
                .then((x) => {
                    updateFilterFacets(x.facets);
                    setListData(x.results);
                    setIsLoading(false);
                    setIsListLoading(false);
                })
                .catch(() => {});
        }
    }, [searchConditions]);

    useEffect(() => {
        const escFunction = (event) => {
            if (event?.key === 'Escape') {
                setIsMobileListViewOpen(false);
            }
        };

        window.addEventListener('keydown', escFunction);

        return () => {
            window.removeEventListener('keydown', escFunction);
        };
    }, []);

    return (
        <div className={classnames(`${projectMapListBlock}`)}>
            <ul className={classnames(`${projectMapListBlock}__list-container`)}>
                {listData?.length > 0 || isLoading || isListLoading ? (
                    listData?.map((item, index) => {
                        if (item && index < resultsLimit) {
                            let markerTags = item?.industries?.split('|');
                            let svgPath = `circle_${getMapSvgPath(
                                markerTags?.[markerTags?.length - 1]
                            )}`;
                            let highLevelTag = getMarkerTag(markerTags?.[0]);
                            let lowLevelTag =
                                markerTags?.length > 1
                                    ? getMarkerTag(markerTags?.[markerTags?.length - 1])
                                    : '';
                            return (
                                <ProjectMapListItem
                                    key={index}
                                    icon={spriteData[svgPath]}
                                    title={getMarkerTitle(item)}
                                    tags={[highLevelTag, lowLevelTag]}
                                    notNavigatable={item?.notNavigatable}
                                    disableMapCTA={item?.disableMapCTA}
                                    url={configureUrlFromPath(item?.path)}
                                    ctaText={ctaText}
                                    isLoading={isLoading}
                                    isListLoading={isListLoading}
                                />
                            );
                        }
                    })
                ) : (
                    <div className={`${projectMapListBlock}__no-results-container`}>
                        <div className={`${projectMapListBlock}__no-results-title`}>
                            {t('NoMapResultsTitle')}
                        </div>
                        <div className={`${projectMapListBlock}__no-results-description`}>
                            {t('NoMapResultsDescription')}
                        </div>
                        <button
                            className={classnames(
                                `zn-button`,
                                `zn-button--primary`,
                                `zn-button--light`,
                                `zn-button--primary--light`
                            )}
                            onClick={clearAllFilters}
                        >
                            {t('ClearFilters')}
                        </button>
                    </div>
                )}
            </ul>
        </div>
    );
};

export const BasicProjectMapList = ProjectMapList;
const ProjectMapListContext = withSitecoreContext()(ProjectMapList);
const ProjectMapListWithTranslation = withTranslation()(ProjectMapListContext);
export default ProjectMapListWithTranslation;
