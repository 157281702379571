import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../SocialMediaLinks/social-media-links.scss';
import { withSitecoreContext, withPlaceholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import forStorybook from '../../utils/forStorybook.jsx';
import './bio-professional-involvement.scss';
import withErrorBoundary from '../../utils/withErrorBoundary.jsx';

const BioProfessionalInvolvement = ({ sitecoreContext, sectionTitle }) => {
    const { ProfessionalInvolvement } = sitecoreContext?.route?.fields;

    const znBlock = 'zn-bio-professional-involvement';

    return (
        <div className={classnames(`${znBlock}`)}>
            {sitecoreContext?.pageEditing && (
                <>
                    <h6 className="zn-page-editing-title">**Section Title placeholder**</h6>
                </>
            )}
            {(sitecoreContext?.pageEditing || ProfessionalInvolvement?.value) && (
                <>
                    <section>{sectionTitle}</section>
                    <RichText
                        className={classnames(`${znBlock}__body`)}
                        field={ProfessionalInvolvement}
                    />
                </>
            )}
        </div>
    );
};

BioProfessionalInvolvement.propTypes = {
    fields: PropTypes.object,
    sitecoreContext: PropTypes.object,
    t: PropTypes.func,
};

const BioProfessionalInvolvementSitecore = withSitecoreContext()(
    withPlaceholder('sectionTitle')(withErrorBoundary()(BioProfessionalInvolvement))
);

export const BioProfessionalInvolvementStorybook = forStorybook(BioProfessionalInvolvementSitecore);
export default BioProfessionalInvolvementSitecore;
