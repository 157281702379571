import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './quarterly-article-navigation-banner.scss';
import forStorybook from '../../utils/forStorybook.jsx';
import { ArrowLeft, ArrowRight } from '../../assets/LinkIcons.js';

export const QuarterlyArticleNavigationBanner = ({ fields }) => {
    const {
        Title,
        PreviousArticleText,
        PreviousArticleAltText,
        PreviousArticleLink,
        NextArticleText,
        NextArticleAltText,
        NextArticleLink,
    } = fields;

    const quarArticleNavBannerBlock = 'zn-quarterly-article-navigation-banner';

    return (
        <div className={classnames(quarArticleNavBannerBlock)}>
            <div className={classnames(`${quarArticleNavBannerBlock}__content`)}>
                <div
                    className={classnames(
                        `${quarArticleNavBannerBlock}__title-block`,
                        `${quarArticleNavBannerBlock}__title-block--mobile`
                    )}
                >
                    <div className={classnames(`${quarArticleNavBannerBlock}__title`)}>
                        {Title?.value}
                    </div>
                </div>
                <div className={classnames(`${quarArticleNavBannerBlock}__links`)}>
                    <a
                        className={classnames(`${quarArticleNavBannerBlock}__link`, {
                            [`${quarArticleNavBannerBlock}__link--disabled`]: !PreviousArticleLink?.value,
                        })}
                        {...(PreviousArticleLink?.value
                            ? { href: PreviousArticleLink?.value }
                            : {})}
                        target="_blank
                        "
                        alt={PreviousArticleAltText?.value}
                    >
                        <div
                            className={classnames(
                                `${quarArticleNavBannerBlock}__icon`,
                                `${quarArticleNavBannerBlock}__icon--left`,
                                {
                                    [`${quarArticleNavBannerBlock}__icon--disabled`]: !PreviousArticleLink?.value,
                                }
                            )}
                        >
                            <ArrowLeft />
                        </div>
                        <div
                            className={classnames(
                                `${quarArticleNavBannerBlock}__text`,
                                `${quarArticleNavBannerBlock}__text--left`,
                                {
                                    [`${quarArticleNavBannerBlock}__text--disabled`]: !PreviousArticleLink?.value,
                                }
                            )}
                        >
                            {PreviousArticleText?.value}
                        </div>
                    </a>
                    <div className={classnames(`${quarArticleNavBannerBlock}__title-block`)}>
                        <div className={classnames(`${quarArticleNavBannerBlock}__title`)}>
                            {Title?.value}
                        </div>
                    </div>
                    <a
                        className={classnames(`${quarArticleNavBannerBlock}__link`, {
                            [`${quarArticleNavBannerBlock}__link--disabled`]: !NextArticleLink?.value,
                        })}
                        {...(NextArticleLink?.value ? { href: NextArticleLink?.value } : {})}
                        target="_blank
                        "
                        alt={NextArticleAltText?.value}
                    >
                        <div
                            className={classnames(
                                `${quarArticleNavBannerBlock}__text`,
                                `${quarArticleNavBannerBlock}__text--right`,
                                {
                                    [`${quarArticleNavBannerBlock}__text--disabled`]: !NextArticleLink?.value,
                                }
                            )}
                        >
                            {NextArticleText?.value}
                        </div>
                        <div
                            className={classnames(
                                `${quarArticleNavBannerBlock}__icon`,
                                `${quarArticleNavBannerBlock}__icon--right`,
                                {
                                    [`${quarArticleNavBannerBlock}__icon--disabled`]: !NextArticleLink?.value,
                                }
                            )}
                        >
                            <ArrowRight />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

QuarterlyArticleNavigationBanner.propTypes = {
    fields: PropTypes.shape({
        Title: PropTypes.shape({
            value: PropTypes.string,
        }),
        PreviousArticleText: PropTypes.shape({
            value: PropTypes.string,
        }),
        PreviousArticleAltText: PropTypes.shape({
            value: PropTypes.string,
        }),
        PreviousArticleLink: PropTypes.shape({
            value: PropTypes.string,
        }),
        NextArticleText: PropTypes.shape({
            value: PropTypes.string,
        }),
        NextArticleAltText: PropTypes.shape({
            value: PropTypes.string,
        }),
        NextArticleLink: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

export const QuarterlyArticleNavigationBannerStorybook = forStorybook(
    QuarterlyArticleNavigationBanner
);

export default QuarterlyArticleNavigationBanner;
