import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import { withSitecoreContext, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';
import { AccordionTitleColorEnum } from '../../utils/enums';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import './accordion.scss';
import '../RichText/rich-text.scss';
import usePdfView from '../../utils/hooks/usePdfView.jsx';

const Accordion = ({ sitecoreContext, fields, params }) => {
    const { Title, Body } = fields;
    const { TitleColor } = params;
    const [isActive, setIsActive] = useState(false);
    const [maxHeight, setMaxHeight] = useState('0px');
    const contentRef = useRef(null);
    const accordionBlock = 'zn-accordion';
    const isEditing = sitecoreContext?.pageEditing;
    const TitleTag = isEditing ? 'div' : 'button';
    const pdfView = usePdfView();

    const toggleAccordion = () => {
        setIsActive((prevIsActive) => !prevIsActive);
        setMaxHeight(() => (isActive ? '0px' : `${contentRef.current.scrollHeight}px`));
    };

    useEffect(() => {
        if (pdfView) {
            setIsActive(true);
            setMaxHeight('none');
        }
    }, [pdfView]);

    return (
        (Title?.value || isEditing) && (
            <div className={accordionBlock}>
                {isEditing && (
                    <p className={classnames(`${accordionBlock}__editor`)}>Accordion Editor View</p>
                )}
                <TitleTag
                    className={classnames(`${accordionBlock}__info`, {
                        [`${accordionBlock}__info--active`]: isActive || isEditing,
                    })}
                    onClick={isEditing ? undefined : toggleAccordion}
                >
                    <div
                        className={classnames(`${accordionBlock}__title`, {
                            [`${accordionBlock}__title--navy`]:
                                TitleColor === AccordionTitleColorEnum.navy,
                        })}
                    >
                        <Text field={Title} />
                    </div>
                    <div className={classnames(`${accordionBlock}__icon`)}>
                        {!isActive ? <ChevronDown /> : <ChevronUp />}
                    </div>
                </TitleTag>
                <div
                    ref={contentRef}
                    style={sitecoreContext.pageEditing ? { maxHeight: 'none' } : { maxHeight }}
                    className={classnames(`${accordionBlock}__content`)}
                >
                    <RichText
                        className={classnames('zn-rich-text', ['zn-rich-text--light'])}
                        field={Body}
                    />
                </div>
                <div className={classnames(`${accordionBlock}__accent`)}></div>
            </div>
        )
    );
};

Accordion.propTypes = {
    fields: PropTypes.exact({
        Title: PropTypes.shape({
            value: PropTypes.string,
        }),
        TitleColor: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(AccordionTitleColorEnum)),
        }),
        Body: PropTypes.shape({
            value: PropTypes.object,
        }),
    }),
    params: PropTypes.exact({
        TitleColor: PropTypes.oneOf(Object.values(AccordionTitleColorEnum)),
    }),
};

const AccordionSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(Accordion)
);

export const AccordionStorybook = forStorybookV2(AccordionSitecore);

export default AccordionSitecore;
