import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './two-col-sticky-side-banner.scss';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const twoColStickySideBannerBlock = 'zn-two-col-sticky-side-banner';

const TwoColStickySideBanner = ({
    stickySideBanner,
    leftColumnContent,
    rightColumnContent,
    sitecoreContext,
    fields,
}) => {
    const { BackgroundCustomColor } = fields;
    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    return (
        <div
            className={classnames(twoColStickySideBannerBlock, {
                [`${twoColStickySideBannerBlock}--sc-editing`]: sitecoreContext?.pageEditing,
            })}
        >
            <div
                className={classnames(`${twoColStickySideBannerBlock}__content`, {
                    [`${twoColStickySideBannerBlock}__content--sc-editing`]: sitecoreContext?.pageEditing,
                })}
            >
                <div className={classnames(`${twoColStickySideBannerBlock}__left-content`)}>
                    {sitecoreContext?.pageEditing && <div>Left Container Content</div>}
                    {leftColumnContent}
                </div>
                <div className={classnames(`${twoColStickySideBannerBlock}__right-content`)}>
                    {sitecoreContext?.pageEditing && <div>Right Container Content</div>}
                    {rightColumnContent}
                </div>
            </div>
            {!pdfView && (
                <div
                    className={classnames(`${twoColStickySideBannerBlock}__banner-container`, {
                        [`${twoColStickySideBannerBlock}__banner-container--sc-editing`]: sitecoreContext?.pageEditing,
                    })}
                >
                    <div
                        className={classnames(`${twoColStickySideBannerBlock}__banner`, {
                            [`${twoColStickySideBannerBlock}__banner--sc-editing`]: sitecoreContext?.pageEditing,
                        })}
                    >
                        <div
                            className={classnames(`${twoColStickySideBannerBlock}__banner-content`)}
                            style={{ backgroundColor: BackgroundCustomColor?.value }}
                        >
                            {stickySideBanner}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const TwoColStickySideBannerWithContext = withSitecoreContext()(TwoColStickySideBanner);

export default withPlaceholder(['stickySideBanner', 'rightColumnContent', 'leftColumnContent'])(
    TwoColStickySideBannerWithContext
);
