import React, { useEffect } from 'react';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import { Image as Img, RichText } from '@sitecore-jss/sitecore-jss-react';
import { LinkTargetValueEnum, FeaturedContentImageDisplayEnum } from '../../utils/enums.js';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import './featured-content.scss';
import { setAnimationInterObserver } from '../../utils/observers.jsx';

const featuredContent = 'zn-featured-content';

const FeaturedContent = ({ sitecoreContext, fields, params, t }) => {
    const { data } = fields;
    const { ColorTheme, ImageDisplay, EnableAnimation } = params;
    const CTAText = data?.datasource?.cTAText || '';
    const CTAAltText = data?.datasource?.cTAAltText || '';
    const DescriptionOverride = data?.datasource?.descriptionOverride || '';
    const Image = data?.datasource?.imageOverride?.src
        ? data?.datasource?.imageOverride
        : data?.datasource?.contentURL?.targetItem?.teaserImage;
    const PageLink = data?.datasource?.contentURL?.targetItem || '';
    const teaserTitle = PageLink?.teaserTitle;
    const teaserDescription = PageLink?.teaserDescription;
    const Description = DescriptionOverride?.value ? DescriptionOverride : teaserDescription;
    const isEditing = sitecoreContext.pageEditing;
    const withImage =
        Image?.src && ImageDisplay !== FeaturedContentImageDisplayEnum.hideImage ? true : false;
    const animationEnabled = EnableAnimation && !isEditing;

    useEffect(() => {
        if (animationEnabled) {
            const selector = `.${featuredContent} .fadeIn`;
            const newClass = 'fadeInActive';
            // Adds the class that activates the animation whenever the components are in the view
            setAnimationInterObserver(selector, newClass);
        }
    }, []);

    return (
        (PageLink || isEditing) && (
            <div
                className={classnames(
                    `${featuredContent}`,
                    `${featuredContent}--${ColorTheme?.toLowerCase()}`,
                    { [`${featuredContent}__animate`]: animationEnabled }
                )}
            >
                {withImage && (
                    <div
                        className={classnames(
                            `${featuredContent}__image-display`,
                            {
                                fadeIn: animationEnabled,
                            },
                            {
                                [`${featuredContent}__image-display--accent`]:
                                    ImageDisplay !== FeaturedContentImageDisplayEnum.none,
                            },
                            {
                                [`${featuredContent}__image-display--blue`]:
                                    ImageDisplay === FeaturedContentImageDisplayEnum.blue,
                            },
                            {
                                [`${featuredContent}__image-display--orange`]:
                                    ImageDisplay === FeaturedContentImageDisplayEnum.orange,
                            },
                            {
                                [`${featuredContent}__image-display--teal`]:
                                    ImageDisplay === FeaturedContentImageDisplayEnum.teal,
                            },
                            {
                                [`${featuredContent}__image-display--navy`]:
                                    ImageDisplay === FeaturedContentImageDisplayEnum.navy,
                            }
                        )}
                    >
                        <Img field={Image} className={classnames(`${featuredContent}__image`)} />
                    </div>
                )}
                <div
                    className={classnames(
                        `${featuredContent}__content`,
                        {
                            [`${featuredContent}__content--dark`]: ColorTheme !== 'White',
                            fadeIn: animationEnabled,
                        },
                        {
                            [`${featuredContent}__content--full-width`]:
                                ImageDisplay === FeaturedContentImageDisplayEnum.hideImage ||
                                !withImage,
                        }
                    )}
                >
                    <div className={classnames(`${featuredContent}__title`)}>
                        {data?.datasource?.titleOverride?.value && PageLink ? (
                            <RichText field={data?.datasource?.titleOverride}></RichText>
                        ) : (
                            <RichText field={teaserTitle} />
                        )}
                        {!PageLink && isEditing && t('NoPageLinkSet')}
                    </div>
                    <div className={classnames(`${featuredContent}__description`)}>
                        <RichText field={Description} />
                    </div>
                    <ButtonLink
                        fields={{
                            LinkURL: {
                                value: {
                                    text: CTAText?.value,
                                    href: PageLink?.url,
                                    title: CTAAltText?.value,
                                    target: LinkTargetValueEnum.self,
                                },
                            },
                        }}
                        params={{
                            Theme: ColorTheme !== 'White' ? 'Dark' : 'Light',
                            Variant: 'tertiary',
                        }}
                    />
                </div>
            </div>
        )
    );
};

const FeaturedContentSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.params])(
        FeaturedContent
    )
);
export const FeaturedContentStorybook = forStorybookV2(FeaturedContentSitecore);
export default withTranslation()(FeaturedContentSitecore);
