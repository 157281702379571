import React from 'react';
import PropTypes from 'prop-types';
import './simple-hero.scss';
import forStorybook from '../../utils/forStorybook.jsx';
import Text from '../Text';

const SimpleHero = ({ fields }) => {
    const block = 'zn-simple-hero';
    const { BackgroundColor, Color, HeroTitle, LineColor, HeroDescription } = fields;

    return (
        <div
            className={`${block}`}
            style={{
                backgroundColor: BackgroundColor.value,
                color: Color.value,
            }}
        >
            <div className={`${block}__container`}>
                <Text type="h2" field={HeroTitle} useDefaultMargin={false} />
                <span
                    className={`${block}__divider`}
                    style={{
                        backgroundColor: LineColor.value,
                    }}
                ></span>
                <Text field={HeroDescription} type="bodyMd" useDefaultMargin={false} />

                {/*TODO: Render as inputs when editable*/}
                {/*{!!BackgroundColor.editable && <Text tag="div" field={BackgroundColor} />}*/}
                {/*{!!LineColor.editable && <Text tag="div" field={LineColor} />}*/}
                {/*{!!Color.editable && <Text tag="div" field={Color} />}*/}
            </div>
        </div>
    );
};

SimpleHero.propTypes = {
    fields: PropTypes.shape({
        BackgroundColor: PropTypes.object,
        Color: PropTypes.object,
        HeroTitle: PropTypes.object,
        LineColor: PropTypes.object,
        HeroDescription: PropTypes.object,
    }),
};

export default SimpleHero;

export const SimpleHeroStorybook = forStorybook(SimpleHero);
