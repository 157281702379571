import React, { useState } from 'react';
import classnames from 'classnames';
import './advanced-filter.scss';
import CheckboxWrapper from '../Checkbox/CheckboxWrapper.jsx';

const AdvancedFilter = ({
    t,
    fields,
    index,
    activeFilter,
    checkAdvancedFilter,
    isLoading,
    setIsLoading,
}) => {
    const advancedFilterBlock = 'zn-advanced-filter';
    const { title, identifier, highLevelFilters } = fields;
    const [showAllCheckboxes, setShowAllCheckboxes] = useState(false);

    const initialNumCheckboxes = 4;
    let visibleCheckboxes = 0;

    const showAdditionalControls =
        highLevelFilters?.filter((option) => option?.hasResults).length > initialNumCheckboxes ||
        highLevelFilters?.filter(
            (option) =>
                option?.lowLevelFilters?.length &&
                option?.lowLevelFilters?.filter((childOption) => childOption?.hasResults).length > 0
        ).length > 0;

    const hasAvailableFilters =
        highLevelFilters?.filter(
            (option) =>
                option.hasResults ||
                option?.lowLevelFilters.filter((childOption) => childOption?.hasResults).length > 0
        ).length > 0;

    return (
        <div
            className={classnames(`${advancedFilterBlock}`, {
                [`${advancedFilterBlock}--active`]: index === activeFilter,
            })}
        >
            <div className={`${advancedFilterBlock}__title`}>{title}</div>
            <div className={`${advancedFilterBlock}__checkbox-list`}>
                {hasAvailableFilters ? (
                    highLevelFilters?.map((option, index) => {
                        if (visibleCheckboxes >= initialNumCheckboxes && !showAllCheckboxes) return;
                        if (
                            !option.hasResults ||
                            (!option.hasResults &&
                                option?.lowLevelFilters.length &&
                                option?.lowLevelFilters.filter(
                                    (childOption) => childOption?.hasResults
                                ).length === 0)
                        )
                            return;
                        visibleCheckboxes++;
                        return (
                            <div
                                className={`${advancedFilterBlock}__high-level-option`}
                                key={index}
                            >
                                <CheckboxWrapper
                                    checked={option.checked}
                                    change={checkAdvancedFilter}
                                    key={index}
                                    id={option.identifier}
                                    pId={identifier}
                                    label={option.title}
                                    disabledState={isLoading}
                                    setDisabledState={setIsLoading}
                                />
                                {showAllCheckboxes && (
                                    <div className={`${advancedFilterBlock}__low-level-options`}>
                                        {option?.lowLevelFilters?.map((childOption, index) => {
                                            if (!childOption.hasResults) return;
                                            return (
                                                <CheckboxWrapper
                                                    checked={childOption.checked}
                                                    change={checkAdvancedFilter}
                                                    key={index}
                                                    id={childOption.identifier}
                                                    pId={identifier}
                                                    label={childOption.title}
                                                    disabledState={isLoading}
                                                    setDisabledState={setIsLoading}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <div>{t('NoMatchingFiltersDescription')}</div>
                )}
            </div>
            {showAdditionalControls && hasAvailableFilters && (
                <button
                    className={`${advancedFilterBlock}__view-more-button`}
                    onClick={() => {
                        setShowAllCheckboxes(
                            !showAllCheckboxes && highLevelFilters?.length > initialNumCheckboxes
                        );
                    }}
                >
                    {!showAllCheckboxes && highLevelFilters?.length > initialNumCheckboxes
                        ? t('ViewMore')
                        : t('ShowLess')}
                </button>
            )}
        </div>
    );
};

export default AdvancedFilter;
