import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Text as TextJss } from '@sitecore-jss/sitecore-jss-react';
import './text.scss';

const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const bodyTypes = ['body', 'bodyMd', 'bodyLg', 'allCapsSmall', 'allCaps'];
export const textTags = ['span', 'div', 'p', 'a', ...headingLevels];
export const types = [...bodyTypes, ...textTags];

const Text = ({ type, as, children, field, className, useDefaultMargin = true, ...props }) => {
    let TagName;

    if (as) {
        TagName = as;
    } else if (headingLevels.includes(type)) {
        TagName = type;
    } else if (bodyTypes.includes(type)) {
        TagName = 'p';
    } else {
        TagName = 'span';
    }

    return (
        <TagName
            className={cx(className, `type-${type}`, {
                'default-margin': useDefaultMargin,
            })}
            {...props}
        >
            {!!field && <TextJss field={field} />}
            {children}
        </TagName>
    );
};

Text.propTypes = {
    type: PropTypes.oneOf(types),
    as: PropTypes.oneOf(textTags),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    useDefaultMargin: PropTypes.bool,
    className: PropTypes.string,
    field: PropTypes.object,
};

export default Text;
