import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';
// eslint-disable-next-line import/no-unresolved
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import Text from '../Text';
import { LinkTargetValueEnum } from '../../utils/enums';
import './item-with-icon.scss';

const ItemWithIcon = ({ icon, description, href, linkName, theme, className }) => {
    return (
        <div className={`zn-item--icon ${className}`}>
            <div className="zn-item--icon__icon">
                <Image field={icon} />
            </div>
            <div className="zn-item--icon__content">
                <ButtonLink
                    fields={{
                        LinkURL: {
                            value: {
                                text: linkName,
                                href: href,
                                title: `nav link to ${linkName}`,
                                target: LinkTargetValueEnum.self,
                            },
                        },
                    }}
                    params={{
                        Theme: theme,
                        Variant: 'tertiary',
                    }}
                />
                <Text
                    type="body"
                    className="zn-item--icon__content__description"
                    field={description}
                />
            </div>
        </div>
    );
};

ItemWithIcon.propTypes = {
    icon: PropTypes.string,
    description: PropTypes.string,
    linkName: PropTypes.string,
    theme: PropTypes.string,
    className: PropTypes.string,
};

ItemWithIcon.defaultProps = {
    icon: '',
    description: null,
    link: {},
};

export default ItemWithIcon;
