import {
    allTemplatesConditions,
    templateNameCondition,
    tagNameCondition,
    tagIdentifierCondition,
    peopleRelationsArray,
} from './constants';

import { ContentCardType } from './enums';

//Function to format a key value pair to be used as a condition in a solr query
export const formatSearchParams = (key, value) => {
    return { name: key, value: value, useor: true };
};

export const formatDate = (date) => {
    return (
        date.substr(0, 4) +
        '-' +
        date.substr(4, 2) +
        '-' +
        date.substr(6, 5) +
        ':' +
        date.substr(11, 2) +
        ':' +
        date.substr(13, 3)
    );
};

export const bioParseDate = (date, formatWithCommas) => {
    let formattedDate = new Date(date).toDateString().split(' ');
    if (formatWithCommas) {
        return [formattedDate[1], formattedDate[2] + ',', formattedDate[3]].join(' ');
    } else {
        return formattedDate.slice(1).join(' ');
    }
};

export const parseDate = (date, formatWithCommas) => {
    let formattedDate = new Date(formatDate(date)).toDateString().split(' ');
    if (formatWithCommas) {
        return [formattedDate[1], formattedDate[2] + ',', formattedDate[3]].join(' ');
    } else {
        return formattedDate.slice(1).join(' ');
    }
};

export const PeopleFiltersValues = [
    {
        fieldValue: 'education',
        label: 'School',
    },
    {
        fieldValue: 'yearofcall',
        label: 'Year Of Call',
    },
];

//Maps the display names used in the content filter to the sitecore template names to be used in search queries
export const mapFacetTypeToDisplay = (facetTypes) => {
    let displayTypes = [];
    for (let i = 0; i < facetTypes.length; i++) {
        //Group of templates for filter group, default apply template name specified
        switch (facetTypes[i]) {
            case 'work page':
                displayTypes.push('Work');
                break;
            case 'premium article':
                displayTypes.push('Insights');
                break;
            case 'publication page':
                displayTypes.push('Insights');
                break;
            case 'podcast':
                displayTypes.push('Podcasts');
                break;
            case 'video':
                displayTypes.push('Videos');
                break;
            case 'event':
                displayTypes.push('Events');
                break;
            case 'news':
                displayTypes.push('News');
                break;
        }
    }
    return displayTypes;
};

//Maps the display names used in the content filter to the sitecore template names to be used in search queries
export const mapDisplayTypeToTemplate = (selectedContentTypes) => {
    let templateTypes = [];
    for (let i = 0; i < selectedContentTypes.length; i++) {
        //Group of templates for filter group, default apply template name specified
        switch (selectedContentTypes[i]) {
            case 'Work':
                templateTypes.push('Work Page');
                break;
            case 'News':
                templateTypes.push('News');
                break;
            case 'Events':
                templateTypes.push('Event', 'Webinar');
                break;
            case 'Insights':
                templateTypes.push('Publication Page', 'Premium Article', 'Podcast', 'Video');
                break;
            case 'Podcasts':
                templateTypes.push('Podcast');
                break;
            case 'Videos':
                templateTypes.push('Video');
                break;
            default:
                templateTypes.push(selectedContentTypes[i]);
                break;
        }
    }
    return templateTypes;
};
//Maps the sitecore template names to the display names used in the content type filter
export const mapTemplateTypeToDisplay = (selectedContentTypes) => {
    let templateTypes = [];
    for (let i = 0; i < selectedContentTypes?.length; i++) {
        switch (selectedContentTypes[i]) {
            case 'Work Page':
                templateTypes.push('Work');
                break;
            case 'Work':
                templateTypes.push('Work');
                break;
            case 'News':
                templateTypes.push('News');
                break;
            case 'Events':
                templateTypes.push('Events');
                break;
            case 'Event':
                templateTypes.push('Events');
                break;
            case 'Publication Page':
                templateTypes.push('Insights');
                break;
            case 'Premium Article':
                templateTypes.push('Insights');
                break;
            case 'Insights':
                templateTypes.push('Insights');
                break;
            case 'Video':
                templateTypes.push('Videos');
                break;
            case 'Videos':
                templateTypes.push('Videos');
                break;
            case 'Podcast':
                templateTypes.push('Podcasts');
                break;
            case 'Podcasts':
                templateTypes.push('Podcasts');
                break;
        }
    }
    return templateTypes;
};

/* 
    Construct Solr Search Query Conditions for fetching tagIds associated to chosen filter names.
    For all query parameters parsed from the search url the conditions created will fetch all the 
    Tag data item that matches the selected identifiers from the filters.
*/
export const tagIdConditions = (filterValues) => {
    let tagIds = [];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(filterValues)) {
        filterValues[key]?.length > 0 &&
            key !== 'q' &&
            key !== 'start' &&
            key != 'end' &&
            key != 'contenttype' &&
            key != 'person' &&
            key.substring(0, 2) != 'sc_' &&
            tagIds.push(
                filterValues[key]
                    ?.filter((item) => item !== '')
                    .map((item) => formatSearchParams(tagIdentifierCondition, item))
            );
    }
    return tagIds;
};

/* 
    Construct Solr Search Query Conditions for selecting items to fetch based on the template types chosen by the user.
    For all entries in the url search parameters, format a search condition that fetches items
    with the template types chosen, if no template types chosen default to all template types.
*/
export const templateTypesConditions = (filterValues) => {
    let templateTypes = [];
    if (filterValues['contenttype']) {
        let mappedFilters = mapDisplayTypeToTemplate(filterValues['contenttype']);
        let condition = {
            group: mappedFilters?.map((item) => formatSearchParams(templateNameCondition, item)),
        };
        condition.group.length > 0 && templateTypes.push(condition);
    } else
        templateTypes.push({
            group: [
                ...allTemplatesConditions.map((item) =>
                    formatSearchParams(templateNameCondition, item)
                ),
            ],
        });

    return templateTypes;
};

/* 
    Construct Solr Search Query condition array for selected tags. 
    Params:
        - TagIdResults - the ids of the selected tags
        - filterValues - parsed url query parameters to use as the search conditions
    Returns the formatted group search conditions to be used in the search query for results based on the users selected filters
    
*/
export const tagSearchConditions = (tagIdResults, filterValues, peopleFilterKeys, t) => {
    let searchConditions = [];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(filterValues)) {
        // For query parameters that are to apply tag conditions (excluding the people filter values, contenttypes, and search parameters)
        if (
            filterValues[key]?.length > 0 &&
            key !== 'q' &&
            key !== 'start' &&
            key != 'end' &&
            key != 'contenttype' &&
            key != 'school' &&
            key != 'yearofcall' &&
            key != 'person' &&
            !peopleFilterKeys.includes(key) &&
            key.substring(0, 3) != 'sc_'
        ) {
            //if the query parameter has values, construct a group condition that applies an OR relationship between all items in the given filter
            if (filterValues[key].length > 0 && filterValues[key][0] !== '') {
                let condition = {
                    group: filterValues[key]
                        //For every item selected in the filter, find the associated tagId from the tag search results where the tag identifier matches the value in the search params url
                        ?.filter((item) =>
                            tagIdResults?.results.find(
                                (x) => x?.identifier === decodeURIComponent(item)
                            )
                        )
                        // From the found ids, construct a condition so search for items that have tags equal to the ids
                        .map((item) =>
                            formatSearchParams(
                                tagNameCondition,
                                tagIdResults?.results
                                    .find((x) => x?.identifier === decodeURIComponent(item))
                                    ?.id.toLowerCase()
                            )
                        ),
                };
                //Add the filter group condition to overall search condition applying an AND relationship between filters
                condition.group.length > 0 && searchConditions.push(condition);
            }
        }
        // For all people filters based on facets names, construct a group condition that applies an OR relationship between all selected items in the given filter
        else if (
            peopleFilterKeys.includes(key) &&
            filterValues[key].length > 0 &&
            filterValues[key][0] !== ''
        ) {
            let mappedFacetValue = '';
            if (key.toLowerCase() === t('School').toLowerCase().replace(/\s/g, '')) {
                mappedFacetValue = 'education';
            } else if (key.toLowerCase() === t('YearOfCall').toLowerCase().replace(/\s/g, '')) {
                mappedFacetValue = 'yearofcall';
            }
            let condition = {
                group: filterValues[key]
                    //For every people filter item, construct a search condition that matches the sitecore facet value to the selected filter item
                    ?.filter((item) => item != '')
                    .map((item) => formatSearchParams(mappedFacetValue, item)),
            };
            //Add the filter group condition to overall search condition applying an AND relationship between filters
            condition.group.length > 0 && searchConditions.push(condition);
        }
    }

    return searchConditions;
};

export const parseContentType = (template) => {
    let contentType;

    switch (template) {
        case 'Publication Page':
            contentType = ContentCardType.publication;
            break;
        case 'Event':
            contentType = ContentCardType.events;
            break;
        case 'Video':
            contentType = ContentCardType.video;
            break;
        case 'Webinar':
            contentType = ContentCardType.webinar;
            break;
        case 'Podcast':
            contentType = ContentCardType.podcast;
            break;
        case 'Work Page':
            contentType = ContentCardType.workItem;
            break;
        case 'Project Map Work Item':
            contentType = ContentCardType.workItem;
            break;
        case 'News':
            contentType = ContentCardType.news;
            break;
        case 'Quarterly Landing':
            contentType = ContentCardType.quarterlyIssue;
            break;
        case 'Quarterly Report Landing':
            contentType = ContentCardType.quarterlyIssue;
            break;
        case 'Expertise Landing':
            contentType = ContentCardType.contentSeries;
            break;
        case 'Premium Expertise Landing':
            contentType = ContentCardType.contentSeries;
            break;
        case 'Homepage':
            contentType = ContentCardType.contentSeries;
            break;
        case 'About Us':
            contentType = ContentCardType.contentSeries;
            break;
        case 'Office':
            contentType = ContentCardType.contentSeries;
            break;
        case 'Knowledge Hub':
            contentType = ContentCardType.contentSeries;
            break;
        case 'Premium Article':
            contentType = ContentCardType.quarterlyIssue;
            break;
        case 'Bio Page':
            contentType = ContentCardType.person;
            break;
    }

    return contentType;
};

export const sortFilterValues = (a, b) => {
    if (isNaN(a?.label) || isNaN(b?.label)) {
        return a?.label?.localeCompare(b?.label);
    }
    return a?.label - b?.label;
};

export const relatedPeopleConditions = (personId) => {
    let personRelationCondition = [];
    if (personId) {
        let condition = {
            group: peopleRelationsArray?.map((item) => formatSearchParams(item, personId)),
        };
        condition.group.length > 0 && personRelationCondition.push(condition);
    }

    return personRelationCondition;
};

export const decodeURIComponentSafe = (s) => {
    if (!s) {
        return s;
    }
    return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
};
