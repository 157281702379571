import React, { useEffect, useState, useRef } from 'react';
import './project-map-search-bar.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import ProjectMapSearchBarFilter from '../ProjectMapSearchBarFilter/ProjectMapSearchBarFilter.jsx';
import ProjectMapSearchBarFilterMobile from '../ProjectMapSearchBarFilter/ProjectMapSearchBarFilterMobile.jsx';
import AdvancedFilterModal from '../AdvancedFilterModal/AdvancedFilterModal.jsx';
import {
    SearchBarIcon,
    MapFilters,
    CloseIcon,
    MapAdvancedFilters as MapAdvancedFiltersIcon,
} from '../../assets/common.js';
import { ChevronLeft, ChevronRight } from '../../assets/LinkIcons.js';
import { Button } from '../Button/Button.js';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import getScrollbarWidth from '../../utils/getScrollbarWidth.js';
import { getAdFilTotalSelected } from '../ProjectMapContainer/projectMapHelper.jsx';

const showMobileFilters = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
};

const searchBarFilterBlock = 'zn-project-map-search-bar-filter';
const searchBarFilterMobileBlock = 'zn-project-map-search-bar-filter-mobile';

export const ProjectMapSearchBar = ({
    t,
    fields,
    filters,
    activeFilterGroup,
    isLoading,
    searchInput,
    isMobileFilterViewOpen,
    toggleActiveFilterGroup,
    checkLowLevelFilter,
    checkAdvancedFilter,
    toggleHighLevelFilter,
    toggleAdvancedHighLevelFilter,
    checkHighLevelFilter,
    clearAllFilters,
    clearAllAdvancedFiltersFunc,
    clearCategory,
    toggleAdvancedFilter,
    setIsLoading,
    handleSearchInput,
    submitForm,
    toggleMobileFilterView,
    setIsMobileFilterViewOpen,
    sitecoreContext,
}) => {
    const { data } = fields;
    const mapTitle = data?.datasource?.mapTitle?.value ? data?.datasource?.mapTitle : '';
    const mainSearchPlaceholderText = data?.datasource?.mainPlaceholderText?.value
        ? data?.datasource?.mainPlaceholderText?.value
        : '';
    const dropdownPlaceholderText = data?.datasource?.dropdownPlaceholderText?.value
        ? data?.datasource?.dropdownPlaceholderText?.value
        : '';

    const projectMapSearchBarBlock = 'zn-project-map-search-bar';
    const [searchReset, setSearchReset] = useState(false);
    const [scrollWidth, setScrollWidth] = useState(0);
    const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
    const [isAdvancedFiltersMobileOpen, setIsAdvancedFiltersMobileOpen] = useState(false);
    const filterContainer = useRef(null);
    const modalRef = useRef(null);
    const mobileFilterContainer = useRef(null);
    let mainContent;

    useEffect(() => {
        mainContent = document?.querySelector('body');
    }, []);
    const modalOpenClass = 'modal-open';

    const hideMobileFilters = () => {
        const body = document?.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        toggleActiveFilterGroup(null);
        setIsAdvancedFiltersMobileOpen(false);
    };

    const focusModalElements = () => {
        const focusableElements =
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'; //will need to be updated for custom checkboxes?

        if (modalRef.current) {
            const firstFocusableElement = modalRef.current.querySelectorAll(focusableElements)[0];
            const focusableContent = modalRef.current.querySelectorAll(focusableElements);
            const lastFocusableElement = focusableContent[focusableContent.length - 1];

            document.addEventListener('keydown', function (e) {
                let isTabPressed = e.key === 'Tab';

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) {
                    // if shift key pressed for shift + tab combination
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus(); // add focus for the last focusable element
                        e.preventDefault();
                    }
                } else {
                    // if tab key is pressed
                    if (document.activeElement === lastFocusableElement) {
                        // if focused has reached to last focusable element then focus first focusable element after pressing tab
                        firstFocusableElement.focus(); // add focus for the first focusable element
                        e.preventDefault();
                    }
                }
            });
            firstFocusableElement.focus();
        }
    };

    const toggleAdvancedFilterModal = () => {
        const nav = document?.querySelector('.zn-main-nav');
        const map = document?.querySelector('.zn-projects-map-container');
        if (!isAdvancedFiltersOpen) {
            nav.style.paddingRight = `${scrollWidth}px`;
            map.style.paddingRight = `${scrollWidth}px`;
            mainContent?.classList?.add(modalOpenClass);
            toggleActiveFilterGroup(null);
        } else {
            nav.style.removeProperty('padding-right');
            map.style.removeProperty('padding-right');
            mainContent?.classList?.remove(modalOpenClass);
        }
        setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen);
    };

    const toggleAdvancedFilterModalMobile = () => {
        setIsAdvancedFiltersMobileOpen(!isAdvancedFiltersMobileOpen);
    };

    useEffect(() => {
        if (isAdvancedFiltersOpen) focusModalElements();
    }, [isAdvancedFiltersOpen]);

    useEffect(() => {
        if (searchReset) setSearchReset(false);
    }, [searchReset]);

    //close filters when click on any other part of the page
    useEffect(() => {
        setScrollWidth(getScrollbarWidth());
        function unsetActiveFilterGroup(event) {
            if (
                mobileFilterContainer.current &&
                mobileFilterContainer.current.contains(event.target)
            )
                return;
            if (filterContainer.current && !filterContainer.current.contains(event.target)) {
                toggleActiveFilterGroup(null);
            }
        }
        if (!sitecoreContext?.pageEditing) {
            document.addEventListener('mousedown', unsetActiveFilterGroup);
            return () => {
                document.removeEventListener('mousedown', unsetActiveFilterGroup);
                const body = document.body;
                body.style.position = '';
                body.style.top = '';
            };
        }
    }, []);

    useEffect(() => {
        const escFunction = (event) => {
            const nav = document?.querySelector('.zn-main-nav');
            const map = document?.querySelector('.zn-projects-map-container');
            if (event?.key === 'Escape') {
                setIsMobileFilterViewOpen(false);
                hideMobileFilters();
                nav.style.removeProperty('padding-right');
                map.style.removeProperty('padding-right');
                mainContent?.classList?.remove(modalOpenClass);
                setIsAdvancedFiltersOpen(false);
            }
        };

        window.addEventListener('keydown', escFunction);

        return () => {
            window.removeEventListener('keydown', escFunction);
        };
    }, []);

    return (
        <div className={classnames(`${projectMapSearchBarBlock}`)}>
            {isAdvancedFiltersOpen && (
                <div className={classnames(`${projectMapSearchBarBlock}__overlay`)}></div>
            )}
            <RichText
                className={classnames(`${projectMapSearchBarBlock}__title`)}
                field={mapTitle}
            />
            <div className={classnames(`${projectMapSearchBarBlock}__divider`)}></div>
            <div className={`${projectMapSearchBarBlock}__input-container`}>
                <div className={`${projectMapSearchBarBlock}__input`}>
                    <form className={`${projectMapSearchBarBlock}__form`} onSubmit={submitForm}>
                        <Button
                            className={classnames(`${projectMapSearchBarBlock}__search-button`)}
                            aria-label="Submit Search"
                            type="submit"
                            variant="icon"
                        >
                            <SearchBarIcon />
                        </Button>
                        <input
                            className={`${projectMapSearchBarBlock}__form-input`}
                            type="text"
                            placeholder={mainSearchPlaceholderText}
                            value={searchInput}
                            onChange={handleSearchInput}
                        ></input>
                    </form>
                </div>
                <button
                    className={classnames(`${projectMapSearchBarBlock}__mb-filters-button`)}
                    onClick={() => {
                        toggleMobileFilterView();
                        showMobileFilters();
                    }}
                >
                    <MapFilters />
                    <div className={`${projectMapSearchBarBlock}__mb-filters-button-text`}>
                        {t('Filters')}
                    </div>
                </button>
            </div>
            <div className={classnames(`${projectMapSearchBarBlock}__dt-filters`)}>
                <div
                    className={classnames(`${projectMapSearchBarBlock}__filter-container`)}
                    ref={filterContainer}
                >
                    {filters?.mapTagFilters?.map((filter, index) => {
                        if (!filter) return;
                        return (
                            <ProjectMapSearchBarFilter
                                key={index}
                                t={t}
                                fields={filter}
                                index={index}
                                activeFilter={activeFilterGroup}
                                checkLowLevelFilter={checkLowLevelFilter}
                                toggleHighLevelFilter={toggleHighLevelFilter}
                                clearCategory={clearCategory}
                                toggleFilter={toggleActiveFilterGroup}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                searchReset={searchReset}
                                dropdownPlaceholderText={dropdownPlaceholderText}
                            />
                        );
                    })}
                    <button
                        className={classnames(`${projectMapSearchBarBlock}__advanced-filters`, {
                            [`${projectMapSearchBarBlock}__advanced-filters--disabled`]: isLoading,
                        })}
                        onClick={toggleAdvancedFilterModal}
                    >
                        <MapAdvancedFiltersIcon />
                    </button>
                    {isAdvancedFiltersOpen && (
                        <AdvancedFilterModal
                            t={t}
                            modalRef={modalRef}
                            filters={filters}
                            activeFilter={activeFilterGroup}
                            checkAdvancedFilter={checkAdvancedFilter}
                            clearCategory={clearCategory}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            toggleAdvancedFilterModal={toggleAdvancedFilterModal}
                            isAdvancedFiltersOpen={isAdvancedFiltersOpen}
                        />
                    )}
                    <button
                        className={classnames(`${projectMapSearchBarBlock}__clear-all-button`, {
                            [`${projectMapSearchBarBlock}__clear-all-button--disabled`]: isLoading,
                        })}
                        onClick={() => {
                            clearAllFilters();
                            setSearchReset(true);
                        }}
                    >
                        {t('ClearAll')}
                    </button>
                </div>
            </div>
            <div
                className={classnames(`${projectMapSearchBarBlock}__mb-filters`, {
                    [`${projectMapSearchBarBlock}__mb-filters--open`]: isMobileFilterViewOpen,
                })}
                ref={mobileFilterContainer}
            >
                <div className={`${projectMapSearchBarBlock}__mb-filter-top-container`}>
                    <div className={`${projectMapSearchBarBlock}__mb-filter-header`}>
                        <div className={`${projectMapSearchBarBlock}__mb-filter-title`}>
                            {t('Filters')}
                        </div>
                        <span>
                            <Button
                                aria-label={t('CloseNavigation')}
                                type="button"
                                variant="icon"
                                onClick={() => {
                                    setIsMobileFilterViewOpen(false);
                                    hideMobileFilters();
                                }}
                            >
                                <CloseIcon />
                            </Button>
                        </span>
                    </div>
                    {activeFilterGroup == null && !isAdvancedFiltersMobileOpen && (
                        <div className={`${projectMapSearchBarBlock}__mb-filter-divider`} />
                    )}
                </div>
                <div
                    className={classnames(`${projectMapSearchBarBlock}__mb-filter-container`, {
                        [`${projectMapSearchBarBlock}__mb-filter-container--ad-open`]: isAdvancedFiltersMobileOpen,
                    })}
                >
                    {!isAdvancedFiltersMobileOpen &&
                        filters?.mapTagFilters?.map((filter, index) => {
                            if (!filter) return;
                            return (
                                <ProjectMapSearchBarFilterMobile
                                    key={index}
                                    t={t}
                                    fields={filter}
                                    index={index}
                                    activeFilter={activeFilterGroup}
                                    toggleFilter={toggleActiveFilterGroup}
                                    checkLowLevelFilter={checkLowLevelFilter}
                                    checkHighLevelFilter={checkHighLevelFilter}
                                    clearCategory={clearCategory}
                                    setIsLoading={setIsLoading}
                                    isLoading={isLoading}
                                    isMobileFilterViewOpen={isMobileFilterViewOpen}
                                    setIsMobileFilterViewOpen={setIsMobileFilterViewOpen}
                                    hideMobileFilters={hideMobileFilters}
                                    isAdvancedFiltersMobileOpen={isAdvancedFiltersMobileOpen}
                                    toggleAdvancedHighLevelFilter={toggleAdvancedHighLevelFilter}
                                />
                            );
                        })}
                    {!isAdvancedFiltersMobileOpen ? (
                        <>
                            <button
                                className={classnames(`${searchBarFilterBlock}__button`, {
                                    [`${searchBarFilterBlock}__button--disabled`]: isLoading,
                                })}
                                onClick={toggleAdvancedFilterModalMobile}
                            >
                                <div
                                    className={classnames(
                                        `${searchBarFilterBlock}__title`,
                                        `${searchBarFilterBlock}__title--mobile`,
                                        {
                                            [`${searchBarFilterBlock}__title--mobile--disabled`]: isLoading,
                                        }
                                    )}
                                >
                                    {t('AdvancedFilters')}
                                </div>
                                <div
                                    className={classnames(
                                        `${searchBarFilterBlock}__mobile-chevron`,
                                        {
                                            [`${searchBarFilterBlock}__mobile-chevron--disabled`]: isLoading,
                                        }
                                    )}
                                >
                                    <ChevronRight />
                                </div>
                            </button>
                            <div
                                className={classnames(
                                    `${searchBarFilterBlock}__mobile-selected-filters`,
                                    {
                                        [`${searchBarFilterBlock}__mobile-selected-filters--disabled`]: isLoading,
                                    }
                                )}
                            >
                                {getAdFilTotalSelected(filters?.advancedFilters) +
                                    ' ' +
                                    t('FiltersSelected')}
                            </div>
                        </>
                    ) : (
                        <div
                            className={classnames(`${searchBarFilterMobileBlock}__back-btn-header`)}
                        >
                            <button
                                className={classnames(`${searchBarFilterMobileBlock}__back-btn`)}
                                onClick={toggleAdvancedFilterModalMobile}
                            >
                                <div
                                    className={classnames(
                                        `${searchBarFilterMobileBlock}__back-btn-chevron`,
                                        {
                                            [`${searchBarFilterMobileBlock}__back-btn-chevron--disabled`]: isLoading,
                                        }
                                    )}
                                >
                                    <ChevronLeft />
                                </div>
                                <div
                                    className={classnames(
                                        `${searchBarFilterMobileBlock}__back-btn-title`,
                                        {
                                            [`${searchBarFilterMobileBlock}__back-btn-title--disabled`]: isLoading,
                                        }
                                    )}
                                >
                                    {t('AdvancedFilters')}
                                </div>
                            </button>
                            <div className={`${searchBarFilterMobileBlock}__divider`} />
                        </div>
                    )}
                    {isAdvancedFiltersMobileOpen && (
                        <div
                            className={classnames(
                                `${projectMapSearchBarBlock}__mb-advanced-filters`
                            )}
                        >
                            {filters?.advancedFilters?.map((filter, index) => {
                                if (!filter) return;
                                if (
                                    !filter?.highLevelFilters?.filter(
                                        (option) => option?.hasResults
                                    ).length
                                )
                                    return;
                                return (
                                    <ProjectMapSearchBarFilterMobile
                                        key={index}
                                        t={t}
                                        fields={filter}
                                        index={index + filters?.mapTagFilters.length}
                                        activeFilter={activeFilterGroup}
                                        toggleFilter={toggleActiveFilterGroup}
                                        checkLowLevelFilter={checkLowLevelFilter}
                                        checkAdvancedFilter={checkAdvancedFilter}
                                        checkHighLevelFilter={checkHighLevelFilter}
                                        clearCategory={clearCategory}
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                        isMobileFilterViewOpen={isMobileFilterViewOpen}
                                        setIsMobileFilterViewOpen={setIsMobileFilterViewOpen}
                                        hideMobileFilters={hideMobileFilters}
                                        toggleAdvancedFilter={toggleAdvancedFilter}
                                        isAdvancedFiltersMobileOpen={isAdvancedFiltersMobileOpen}
                                        toggleAdvancedHighLevelFilter={
                                            toggleAdvancedHighLevelFilter
                                        }
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
                {activeFilterGroup == null && (
                    <div className={classnames(`${projectMapSearchBarBlock}__mb-footer`)}>
                        <button
                            className={classnames(`${projectMapSearchBarBlock}__mb-clear-button`)}
                            onClick={() => {
                                if (isAdvancedFiltersMobileOpen) clearAllAdvancedFiltersFunc();
                                else clearAllFilters();
                            }}
                        >
                            {t('ClearAll')}
                        </button>
                        <div className={classnames(`${projectMapSearchBarBlock}__mb-apply-button`)}>
                            <Button
                                className="zn-main-nav-bar--hide-mobile"
                                type="submit"
                                onClick={() => {
                                    setIsMobileFilterViewOpen(false);
                                    hideMobileFilters();
                                }}
                            >
                                {t('ShowResults')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export const BasicProjectMapSearchBar = ProjectMapSearchBar;
const ProjectMapSearchBarContext = withSitecoreContext()(BasicProjectMapSearchBar);
const ProjectMapSearchBarWithTranslation = withTranslation()(ProjectMapSearchBarContext);
export default ProjectMapSearchBarWithTranslation;
