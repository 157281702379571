import React, { useEffect } from 'react';
import { CloseIcon } from '../../assets/common.js';
import { Button } from '../Button/Button.js';
import classnames from 'classnames';
import AdvancedFilter from '../AdvancedFilter/AdvancedFilter.jsx';
import './advanced-filter-modal.scss';

const AdvancedFilterModal = ({
    t,
    modalRef,
    filters,
    checkAdvancedFilter,
    isLoading,
    setIsLoading,
    toggleAdvancedFilterModal,
    isAdvancedFiltersOpen,
}) => {
    const advancedFilterModalBlock = 'zn-advanced-filter-modal';

    //close modal when any other part of the page is clicked
    useEffect(() => {
        function closeModal(event) {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                isAdvancedFiltersOpen
            ) {
                toggleAdvancedFilterModal();
            }
        }
        document.addEventListener('mousedown', closeModal);
        return () => {
            document.removeEventListener('mousedown', closeModal);
        };
    }, []);

    return (
        <div className={classnames(`${advancedFilterModalBlock}`)} ref={modalRef}>
            <div className={classnames(`${advancedFilterModalBlock}__content`)}>
                <div className={classnames(`${advancedFilterModalBlock}__header`)}>
                    <div className={classnames(`${advancedFilterModalBlock}__title`)}>
                        {t('AdvancedFilters')}
                    </div>
                    <button
                        className={classnames(`${advancedFilterModalBlock}__close-button`)}
                        onClick={toggleAdvancedFilterModal}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className={classnames(`${advancedFilterModalBlock}__filters`)}>
                    {filters?.advancedFilters?.map((filter, index) => {
                        if (!filter) return;
                        return (
                            <AdvancedFilter
                                key={index}
                                t={t}
                                fields={filter}
                                checkAdvancedFilter={checkAdvancedFilter}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                        );
                    })}
                </div>
                <div className={classnames(`${advancedFilterModalBlock}__footer`)}>
                    <div className={classnames(`${advancedFilterModalBlock}__cancel`)}>
                        <Button
                            variant="secondary"
                            type="submit"
                            onClick={toggleAdvancedFilterModal}
                        >
                            {t('Cancel')}
                        </Button>
                    </div>
                    <div className={classnames(`${advancedFilterModalBlock}__apply`)}>
                        <Button type="submit" onClick={toggleAdvancedFilterModal}>
                            {t('Apply')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedFilterModal;
