import React from 'react';
import forStorybook from '../../utils/forStorybook.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import Quote from '../Quote';

const BioQuote = ({ sitecoreContext, params }) => {
    const fields = sitecoreContext?.route?.fields;
    return (
        <>
            {(sitecoreContext?.pageEditing ||
                fields?.QuoteText?.value ||
                fields?.Subtext?.value) && <Quote fields={fields} params={params} />}
        </>
    );
};

const BioQuoteSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.sitecoreContext, ErrorBoundaryConditions.params])(
        BioQuote
    )
);

export const BioQuoteStorybook = forStorybook(BioQuoteSitecore);

export default BioQuoteSitecore;
