import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './side-navigation.scss';
import forStorybook from '../../utils/forStorybook.jsx';
import { SideNavigationTypeEnum } from '../../utils/enums';
import SocialMediaLink from '../SocialMediaLinks/SocialMediaLink.jsx';
import SideNavigationItem from '../SideNavigationItem/SideNavigationItem.jsx';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { PdfIconComponent } from '../SocialMediaLinks/SocialMediaIcons.jsx';
import { ThemeEnum, SizeEnum } from '../../utils/enums';
import { withTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';

const options = {
    rootMargin: '-10% 0px -90%',
    threshold: 0,
};

export const SideNavigation = ({
    sitecoreContext,
    fields,
    sideNavigationBottom,
    rendering,
    sideNavigation,
    t,
}) => {
    const sideNavigationBlock = 'zn-side-navigation';

    const sideNavigationItems =
        rendering?.placeholders?.sideNavigation?.filter(
            (item) => item && item.fields && item.fields.Label
        ) || [];

    const pageTitle = sitecoreContext?.route?.displayName;
    const { NavigationTitleOverride, NavigationType, AddPDFDownloadLink } = fields;
    const [sectionIds, setSectionIds] = useState([]);
    const [setActive, setActiveState] = useState(false);
    const [mobileTitle, setMobileTitleState] = useState(
        sideNavigationItems[0]?.fields?.Label?.value || ''
    );
    const navRef = useRef(null);

    const SocialMediaLinkArgs = {
        theme: ThemeEnum.light,
        include: true,
        link: {
            value: '#',
        },
        alt: {
            value: 'alt',
        },
        IconComponent: PdfIconComponent,
        label: {
            value: t('ExportAsPDF'),
        },
        showLabel: true,
        labelsMarginSize: SizeEnum.small,
    };

    const toggleDropdown = () => {
        setActiveState(!setActive ? true : false);
    };

    const toggleTitle = (mobileTitle) => {
        setMobileTitleState(mobileTitle);
    };

    const setInteractionObserver = (NavigationType, sitecoreContext) => {
        let content = document.getElementsByClassName('zn-sticky-side-section__content');
        let sections = content[0].children;
        let viewportOffset = navRef.current?.getBoundingClientRect();

        if (
            NavigationType?.value === SideNavigationTypeEnum.inPage &&
            !sitecoreContext.pageEditing
        ) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const currentId = entry.target.getAttribute('id');
                    if (entry.isIntersecting) {
                        document
                            .querySelector(`nav ul li a[href="#${currentId}"]`)
                            ?.parentElement.classList.add('current');

                        Array.from(sections).forEach((section) => {
                            const id = section.id;
                            if (id !== currentId) {
                                document
                                    .querySelector(`nav ul li a[href="#${id}"]`)
                                    ?.parentElement.classList.remove('current');
                            }
                        });
                    } else {
                        if (viewportOffset.top === 0) {
                            document
                                .querySelector(`nav ul li a[href="#${currentId}"]`)
                                ?.parentElement.classList.remove('current');
                        }
                    }
                });
            }, options);

            Array.from(sections).forEach((section) => {
                observer.observe(section);
            });
        }
    };

    useEffect(() => {
        setInteractionObserver(NavigationType, sitecoreContext);
        let content = document.getElementsByClassName('zn-sticky-side-section__content');
        let sections = content[0].children;

        if (NavigationType?.value === SideNavigationTypeEnum.inPage) {
            setSectionIds(
                Array.from(sections).map((section) => ({
                    id: section.id,
                    display: section.style.display,
                }))
            );
        }
    }, []);

    useEffect(() => {
        if (!sitecoreContext?.pageEditing) {
            for (let j = 0; j < rendering?.placeholders?.sideNavigation?.length; j++) {
                if (
                    sectionIds?.find(
                        (x) =>
                            x.id ===
                            rendering?.placeholders?.sideNavigation[j]?.fields?.SectionID?.value
                    )
                ) {
                    document
                        .querySelector(
                            `nav ul li a[href="#${rendering?.placeholders?.sideNavigation[j]?.fields?.SectionID?.value}"]`
                        )
                        ?.parentElement.classList.add('current');
                    setMobileTitleState(
                        rendering?.placeholders?.sideNavigation[j]?.fields?.Label?.value
                    );
                    break;
                }
            }
        }
    }, [sectionIds]);

    const contactCardInclude = sideNavigationBottom?.length > 0;

    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    return (
        !pdfView && (
            <div className={classnames(`${sideNavigationBlock}`)} ref={navRef}>
                <button
                    className={classnames(`${sideNavigationBlock}__info`)}
                    onClick={toggleDropdown}
                >
                    <div className={classnames(`${sideNavigationBlock}__title`)}>
                        {NavigationTitleOverride?.value || mobileTitle}
                    </div>

                    <div className={classnames(`${sideNavigationBlock}__icon`)}>
                        {!setActive ? <ChevronDown /> : <ChevronUp />}
                    </div>
                </button>
                <div className={classnames(`${sideNavigationBlock}__content-nav`)}>
                    <nav style={setActive ? { display: 'block' } : { display: 'none' }}>
                        <ul className={classnames(`${sideNavigationBlock}__list`)}>
                            {!sitecoreContext.pageEditing &&
                                sideNavigationItems.map((item, index) => (
                                    <SideNavigationItem
                                        key={index}
                                        NavigationType={NavigationType}
                                        fields={item.fields}
                                        changeDropdown={toggleDropdown}
                                        changeTitle={toggleTitle}
                                        pageTitle={pageTitle}
                                        sectionIds={sectionIds}
                                        sitecoreContext={sitecoreContext}
                                    />
                                ))}

                            {sitecoreContext.pageEditing && <>{sideNavigation}</>}
                        </ul>
                        {(contactCardInclude || sitecoreContext.pageEditing) && (
                            <div className={classnames(`${sideNavigationBlock}__contact`)}>
                                <div className={classnames(`${sideNavigationBlock}__accent`)}></div>
                                <ul className={classnames(`${sideNavigationBlock}__contact-cards`)}>
                                    {sideNavigationBottom}
                                </ul>
                            </div>
                        )}

                        {AddPDFDownloadLink?.value && (
                            <div className={classnames(`${sideNavigationBlock}_pdf-download`)}>
                                <div
                                    className={classnames(
                                        `${sideNavigationBlock}__accent`,
                                        `${sideNavigationBlock}__accent--pdf`
                                    )}
                                ></div>
                                <SocialMediaLink {...SocialMediaLinkArgs}></SocialMediaLink>
                            </div>
                        )}
                    </nav>
                </div>
            </div>
        )
    );
};

SideNavigation.propTypes = {
    fields: PropTypes.shape({
        NavigationType: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(SideNavigationTypeEnum)),
        }),
        NavigationItems: PropTypes.array,
        AddPDFDownloadLink: PropTypes.shape({
            value: PropTypes.bool,
        }),
    }),
};

const SideNavigationWithContext = withSitecoreContext()(SideNavigation);

const SideNavigationWithTranslation = withTranslation()(SideNavigationWithContext);

export const SideNavigationStorybook = forStorybook(SideNavigationWithContext);

export default withPlaceholder(['sideNavigationBottom', 'sideNavigation'])(
    SideNavigationWithTranslation
);
