import React from 'react';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { ContactCards } from '../ContactCards/ContactCards.jsx';
import { ContactCard } from '../ContactCard/ContactCard.jsx';
import { ContextContactCardsEnum } from '../../utils/enums';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';

const ContextContactCards = ({ fields, sitecoreContext, params, contextContactCardList }) => {
    const { TemplateFieldName, SectionTitle } = fields;
    const contextFields = sitecoreContext?.route?.fields;
    const dataField = TemplateFieldName?.value || ContextContactCardsEnum.authors;
    const ContactCardsItems = contextFields[dataField] || [];
    const contactCardItemsNodes =
        Array.isArray(ContactCardsItems) &&
        ContactCardsItems.map((contact, index) => (
            <ContactCard
                key={index}
                sitecoreContext={sitecoreContext}
                fields={{
                    data: {
                        datasource: {
                            profileURL: {
                                targetItem: {
                                    url: contact?.url,
                                    firstName: { jss: contact?.fields?.FirstName },
                                    lastName: { jss: contact?.fields?.LastName },
                                    middleName: { jss: contact?.fields?.MiddleName },
                                    lowResolutionImage: {
                                        jss: contact?.fields?.LowResolutionImage,
                                    },
                                    notNavigatable: { jss: contact?.fields?.NotNavigatable },
                                },
                            },
                        },
                    },
                }}
            />
        ));

    const contactCardsContext = sitecoreContext?.pageEditing
        ? contactCardItemsNodes
        : [...contactCardItemsNodes, ...contextContactCardList];

    const sectionTitle =
        contactCardsContext.length === 0 && !sitecoreContext?.pageEditing ? {} : SectionTitle;

    return (
        <>
            {sitecoreContext?.pageEditing && (
                <h6 className="zn-page-editing-title">
                    **This component is using {dataField} Page field as datasource, updates should
                    be made there**<br></br>
                </h6>
            )}
            <ContactCards
                fields={{ SectionTitle: sectionTitle }}
                params={params}
                contactCardsContext={contactCardsContext}
            >
                {sitecoreContext?.pageEditing && (
                    <div>
                        <h6 className="zn-page-editing-title">
                            **Add here Manual cards(Only for cards not using People linked data)**
                        </h6>
                        <br></br>
                        {contextContactCardList}
                    </div>
                )}
            </ContactCards>
        </>
    );
};

export const ContextContactCardsSitecore = withSitecoreContext()(ContextContactCards);
export const ContextContactCardsStorybook = forStorybook(ContextContactCardsSitecore);

export default withPlaceholder('contextContactCardList')(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
        ContextContactCardsSitecore
    )
);
