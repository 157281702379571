import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './general-hero.scss';
import classnames from 'classnames';
import forStorybook from '../../utils/forStorybook.jsx';
import HeadingAccent from '../HeadingAccent';
import { Image as Img, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import Lottie from 'react-lottie';
import {
    GeneralHeroColourTheme,
    ArticleHeroAccentColorEnum,
    LinkTargetEnum,
    ThemeEnum,
    ButtonVariantEnum,
} from '../../utils/enums';

const generalHeroBlock = 'zn-general-hero';

const GeneralHero = ({ fields }) => {
    const {
        Title,
        Image,
        ColourTheme,
        AccentColor,
        Description,
        LinkURL,
        Eyebrow,
        PdfImage,
        LottieAnimationDesktop,
        LottieAnimationMobile,
    } = fields;

    const lottieDesktop = LottieAnimationDesktop?.value?.src;

    const lottieMobile = LottieAnimationMobile?.value?.src || lottieDesktop;

    const withImage = !!Image?.value?.src && Object.keys(Image?.value).length > 0;

    const colourTheme = (ColourTheme?.value || GeneralHeroColourTheme.blue).toLowerCase();
    const lightTheme = ColourTheme?.value === GeneralHeroColourTheme.white;
    const [responsiveLottie, setResponsiveLottie] = useState(lottieDesktop);

    useEffect(() => {
        const handleResize = () => {
            setResponsiveLottie(window.innerWidth <= 768 ? lottieMobile : lottieDesktop);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={classnames(`${generalHeroBlock}`, `${generalHeroBlock}--${colourTheme}`)}>
            <div className={classnames(`${generalHeroBlock}__container`)}>
                <div
                    className={classnames(`${generalHeroBlock}__text`, {
                        [`${generalHeroBlock}__text--half`]: lottieMobile || withImage,
                    })}
                >
                    <div className={classnames(`${generalHeroBlock}__text__container`)}>
                        {Eyebrow && (
                            <div className={classnames(`${generalHeroBlock}__eyebrow`)}>
                                <Text field={Eyebrow} />
                            </div>
                        )}
                        <div className={classnames(`${generalHeroBlock}__title`)}>
                            <Text field={Title} />
                        </div>
                        {AccentColor?.value && (
                            <HeadingAccent params={{ AccentColor: AccentColor?.value }} />
                        )}
                        <div
                            className={classnames(`${generalHeroBlock}__description`, {
                                [`${generalHeroBlock}__description--half`]:
                                    lottieMobile || withImage,
                            })}
                        >
                            <RichText field={Description} />
                        </div>
                        {LinkURL?.value?.text && LinkURL?.value?.href && (
                            <div className={classnames(`${generalHeroBlock}__button`)}>
                                <ButtonLink
                                    fields={{
                                        LinkURL: {
                                            value: {
                                                text: LinkURL?.value?.text,
                                                href: LinkURL?.value?.href,
                                                title: LinkURL?.value?.title,
                                                target: LinkURL?.value?.target,
                                            },
                                        },
                                    }}
                                    params={{
                                        Theme: lightTheme ? ThemeEnum.light : ThemeEnum.dark,
                                        Variant: ButtonVariantEnum.tertiary,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={classnames(
                        `${generalHeroBlock}__image`,
                        `${generalHeroBlock}__pdfImage`
                    )}
                >
                    <Img field={PdfImage?.value} />
                </div>

                {responsiveLottie ? (
                    <div className={classnames(`${generalHeroBlock}__lottie-container`)}>
                        <Lottie
                            key={responsiveLottie}
                            options={{
                                loop: true,
                                autoplay: true,
                                path: responsiveLottie,
                            }}
                        />
                    </div>
                ) : (
                    withImage && (
                        <div className={classnames(`${generalHeroBlock}__image`)}>
                            <Img field={Image?.value} />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

GeneralHero.propTypes = {
    fields: PropTypes.exact({
        Title: PropTypes.shape({
            value: PropTypes.string,
        }),
        Image: PropTypes.shape({
            value: PropTypes.object,
        }),
        PdfImage: PropTypes.shape({
            value: PropTypes.object,
        }),
        LottieAnimationDesktop: PropTypes.shape({
            value: PropTypes.object,
        }),
        LottieAnimationMobile: PropTypes.shape({
            value: PropTypes.object,
        }),

        ColourTheme: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(GeneralHeroColourTheme)).isRequired,
        }),
        AccentColor: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(ArticleHeroAccentColorEnum)).isRequired,
        }),
        Description: PropTypes.shape({
            value: PropTypes.string,
        }),
        Eyebrow: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkText: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkURL: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkTarget: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(LinkTargetEnum)).isRequired,
        }),
        LinkAltText: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

export default withErrorBoundary([ErrorBoundaryConditions.fields])(GeneralHero);

export const GeneralHeroStorybook = forStorybook(GeneralHero);
