import React from 'react';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RichText from '../RichText/RichText.jsx';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';

const ContextRichText = ({ params, fields, sitecoreContext, sectionTitle }) => {
    const { TemplateFieldName, TemplateFieldNameOverride } = fields;
    const contextFields = sitecoreContext?.route?.fields;
    const dataField = TemplateFieldNameOverride?.value || TemplateFieldName?.value;
    const TextInput = {
        value: contextFields[dataField]?.value || '',
    };
    return (
        <>
            {sitecoreContext?.pageEditing && (
                <>
                    <h6 className="zn-page-editing-title">
                        **This component is using page field {dataField} as datasource**
                    </h6>
                    <h6 className="zn-page-editing-title">**Section Title placeholder**</h6>
                </>
            )}
            {(sitecoreContext?.pageEditing || TextInput?.value) && (
                <>
                    <section>{sectionTitle}</section>
                    <RichText params={{ ...params }} fields={{ ...fields, TextInput }} />
                </>
            )}
        </>
    );
};

export const ContextRichTextSitecore = withSitecoreContext()(
    withPlaceholder('sectionTitle')(ContextRichText)
);
export const ContextRichTextStorybook = forStorybookV2(ContextRichTextSitecore);
export default withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
    ContextRichTextSitecore
);
