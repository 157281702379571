import React from 'react';
import PropTypes from 'prop-types';
import LinkListItem from '../LinkListItem/LinkListItem.jsx';
import { ThemeEnum, LinkListLayoutEnum as LayoutEnum } from '../../utils/enums';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import './link-list.scss';

const LinkList = ({ style, linkList, children, params }) => {
    const linkListBlock = 'zn-link-list';
    const { Layout } = params;

    return (
        <ul
            className={classnames(linkListBlock, {
                [`${linkListBlock}--two-columns`]:
                    Layout === LayoutEnum.doubleColumn ||
                    Layout === LayoutEnum.doubleColumnSpaceBetween,
                [`${linkListBlock}--two-columns-space-between`]:
                    Layout === LayoutEnum.doubleColumnSpaceBetween,
            })}
            style={style}
        >
            {linkList}
            {children}
        </ul>
    );
};

LinkList.prototype = {
    fields: PropTypes.exact({
        Layout: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(LayoutEnum)).isRequired,
        }).isRequired,
        HideDividerLines: PropTypes.bool,
        Links: PropTypes.arrayOf(LinkListItem.prototype),
        Theme: PropTypes.shape({ value: PropTypes.oneOf(Object.values(ThemeEnum)) }),
    }),
};

export const BasicLinkList = LinkList;
export default withPlaceholder('linkList')(LinkList);
export const LinkListStorybook = forStorybook(LinkList);
