import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { DateTime } from 'luxon';
import { IsEmptyDate } from '../../utils/dateExtensions';
import { TimeZoneEnum } from '../../utils/enums.js';
import { Image as Img, Text, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { ArrowRight } from '../../assets/LinkIcons.js';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import forStorybookV2 from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import './knowledge-hub-hero.scss';
import {
    DocumentIcon,
    MovieCameraIcon,
    HeadPhonesIcon,
    NewsPaperIcon,
    CalendarIcon,
    BookmarkIcon,
    BriefcaseIcon,
    QuarterlyIcon,
    PersonIcon,
} from '../../assets/common.js';
import { LinkTargetValueEnum } from '../../utils/enums.js';

const TypeToIconEmum = {
    Podcast: HeadPhonesIcon,
    Insight: NewsPaperIcon,
    Event: CalendarIcon,
    Video: MovieCameraIcon,
    News: BriefcaseIcon,
    WorkPage: DocumentIcon,
    QuarterlyReportLanding: QuarterlyIcon,
    ContentSeries: BookmarkIcon,
    Webinar: MovieCameraIcon,
    People: PersonIcon,
};

const knowledgeHubHero = 'zn-knowledge-hub-hero';

const KnowledgeHubHero = ({ dates1, dates2, fields, params, sitecoreContext }) => {
    const {
        Title,
        Subtitle,
        FeaturedPageImage,
        FeaturedContentStyle,
        FeaturedContentStyleTextOverride,
        FeaturedPageEyebrow,
        FeaturedPageDescriptionOverride,
        FeaturedPageDateOverride,
        FeaturedPage,
        DatedLinks1Title,
        DatedLinks2Title,
        DatedLinks1LandingURL,
        DatedLinks2LandingURL,
    } = fields;
    const { HighlightColor } = params;
    const teaserDescription = FeaturedPage?.fields?.TeaserDescription;
    const teaserDate = FeaturedPage?.fields?.TeaserDate;
    const FeaturedPageDescription = FeaturedPageDescriptionOverride?.value
        ? FeaturedPageDescriptionOverride
        : teaserDescription;
    const ArticleDate = FeaturedPageDateOverride?.value ? FeaturedPageDateOverride : teaserDate;
    const contextDate = DateTime.fromISO(ArticleDate?.value, {
        locale: sitecoreContext?.route?.itemLanguage,
    })
        .setZone(TimeZoneEnum.server)
        .toLocaleString(DateTime.DATE_MED);
    const FeaturedPageDate = !IsEmptyDate(contextDate)
        ? {
              value: contextDate,
          }
        : {
              value: '',
          };
    const IconTag = FeaturedContentStyle?.value
        ? TypeToIconEmum[FeaturedContentStyle?.value?.replace(/\s/g, '')]
        : NewsPaperIcon;
    const ContentStyleText = FeaturedContentStyleTextOverride?.value
        ? FeaturedContentStyleTextOverride?.value
        : `Featured ${FeaturedContentStyle?.value}`;
    const linksTarget1 =
        DatedLinks1LandingURL?.value?.target === LinkTargetValueEnum.blank
            ? LinkTargetValueEnum.blank
            : LinkTargetValueEnum.self;
    const linksTarget2 =
        DatedLinks2LandingURL?.value?.target === LinkTargetValueEnum.blank
            ? LinkTargetValueEnum.blank
            : LinkTargetValueEnum.self;

    return (
        <div className={classnames(`${knowledgeHubHero}`)}>
            <div className={classnames(`${knowledgeHubHero}__container`)}>
                <div className={classnames(`${knowledgeHubHero}__title`)}>{Title?.value}</div>

                <div
                    className={classnames(
                        `${knowledgeHubHero}__accent`,
                        `${knowledgeHubHero}__accent--${HighlightColor?.toLowerCase()}`
                    )}
                />

                <div className={classnames(`${knowledgeHubHero}__subtitle`)}>
                    <Text field={Subtitle} />
                </div>

                <div className={classnames(`${knowledgeHubHero}__content`)}>
                    <div className={classnames(`${knowledgeHubHero}__left`)}>
                        {FeaturedPageImage?.value && (
                            <a
                                href={FeaturedPage?.url}
                                className={classnames(`${knowledgeHubHero}__link`)}
                            >
                                <div className={classnames(`${knowledgeHubHero}__image`)}>
                                    <Img field={FeaturedPageImage?.value} />
                                </div>
                            </a>
                        )}

                        <div className={classnames(`${knowledgeHubHero}__featured-page`)}>
                            <div className={classnames(`${knowledgeHubHero}__content-style`)}>
                                <div className={classnames(`${knowledgeHubHero}__content-icon`)}>
                                    {IconTag && <IconTag />}
                                </div>
                                <Text
                                    field={{
                                        value: ContentStyleText,
                                    }}
                                />
                            </div>

                            <div
                                className={classnames(`${knowledgeHubHero}__featured-page-eyebrow`)}
                            >
                                <a
                                    href={FeaturedPage?.url}
                                    className={classnames(`${knowledgeHubHero}__link`)}
                                >
                                    <Text field={FeaturedPageEyebrow} />
                                </a>
                            </div>

                            <div
                                className={classnames(
                                    `${knowledgeHubHero}__featured-page-description`
                                )}
                            >
                                <Text field={FeaturedPageDescription} />
                            </div>

                            <div className={classnames(`${knowledgeHubHero}__article-footer`)}>
                                <div className={classnames(`${knowledgeHubHero}__article-date`)}>
                                    <Text field={FeaturedPageDate} />
                                </div>

                                <div className={classnames(`${knowledgeHubHero}__arrow-link`)}>
                                    <a
                                        href={FeaturedPage?.url}
                                        className={classnames(`${knowledgeHubHero}__link`)}
                                    >
                                        <ArrowRight />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classnames(`${knowledgeHubHero}__right`)}>
                        {DatedLinks1Title?.value && (
                            <div className={classnames(`${knowledgeHubHero}__links-1`)}>
                                <a
                                    href={DatedLinks1LandingURL?.value?.href}
                                    target={linksTarget1}
                                    className={classnames(`${knowledgeHubHero}__links-title`)}
                                >
                                    <Text field={DatedLinks1Title} />
                                </a>
                                {dates1}
                            </div>
                        )}
                        {DatedLinks2Title?.value && (
                            <div className={classnames(`${knowledgeHubHero}__links-2`)}>
                                <a
                                    href={DatedLinks2LandingURL?.value?.href}
                                    target={linksTarget2}
                                    className={classnames(`${knowledgeHubHero}__links-title`)}
                                >
                                    <Text field={DatedLinks2Title} />
                                </a>
                                {dates2}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const KnowledgeHubHeroSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.params])(
        KnowledgeHubHero
    )
);

const KnowledgeHubHeroPlaceholder = withPlaceholder('dates1')(
    withPlaceholder('dates2')(KnowledgeHubHeroSitecore)
);
export const KnowledgeHubHeroStorybook = forStorybookV2(KnowledgeHubHeroSitecore);
export default KnowledgeHubHeroPlaceholder;
