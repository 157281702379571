import React from 'react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Text from '../Text/Text.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withTranslation } from 'react-i18next';

import './project-map-work-table.scss';

const ProjectMapWorkTable = ({ sitecoreContext, t }) => {
    const contextFields = sitecoreContext?.route?.fields;
    const projectMapBlock = 'zn-project-map-table';
    const hasLocationAttributes =
        contextFields?.Jurisdiction?.value ||
        contextFields?.Province?.value ||
        contextFields?.Country?.value;
    const hasLevelAttributes =
        contextFields?.ProcurementModel?.value || contextFields?.GovernmentLevel?.value;
    const hasClientAttributes =
        contextFields?.ClientName?.value ||
        contextFields?.ClientRole?.value ||
        contextFields?.Value?.value;
    return (
        <div className={projectMapBlock}>
            {hasLocationAttributes && (
                <table className={`${projectMapBlock}__table`}>
                    <tbody>
                        {contextFields?.Jurisdiction?.value && (
                            <tr>
                                <td className={`${projectMapBlock}__title`}>{t('Jurisdiction')}</td>
                                <td>
                                    <Text field={contextFields?.Jurisdiction} />
                                </td>
                            </tr>
                        )}
                        {contextFields?.Province?.value && (
                            <tr>
                                <td className={`${projectMapBlock}__title`}>{t('Province')}</td>
                                <td>
                                    <Text field={contextFields?.Province} />
                                </td>
                            </tr>
                        )}
                        {contextFields?.Country?.value && (
                            <tr>
                                <td className={`${projectMapBlock}__title`}>{t('Country')}</td>
                                <td>
                                    <Text field={contextFields?.Country} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
            {hasLevelAttributes && (
                <table className={`${projectMapBlock}__table`}>
                    <tbody>
                        {contextFields?.ProcurementModel?.value && (
                            <tr>
                                <td className={`${projectMapBlock}__title`}>
                                    {t('ProcurementModel')}
                                </td>
                                <td>
                                    <Text field={contextFields?.ProcurementModel} />
                                </td>
                            </tr>
                        )}
                        {contextFields?.GovernmentLevel?.value && (
                            <tr>
                                <td className={`${projectMapBlock}__title`}>
                                    {t('GovernmentLevel')}
                                </td>
                                <td>
                                    <Text field={contextFields?.GovernmentLevel} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
            {hasClientAttributes && (
                <table className={`${projectMapBlock}__table`}>
                    <tbody>
                        {contextFields?.ClientName?.value && (
                            <tr>
                                <td className={`${projectMapBlock}__title`}>{t('Client')}</td>
                                <td>
                                    <Text field={contextFields?.ClientName} />
                                </td>
                            </tr>
                        )}
                        {contextFields?.ClientRole?.value && (
                            <tr>
                                <td className={`${projectMapBlock}__title`}>{t('ClientRole')}</td>
                                <td>
                                    <Text field={contextFields?.ClientRole} />
                                </td>
                            </tr>
                        )}
                        {contextFields?.Value?.value && (
                            <tr>
                                <td className={`${projectMapBlock}__title`}>{t('Value')}</td>
                                <td>
                                    <Text field={contextFields?.Value} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export const ProjectMapWorkTableSitecore = withSitecoreContext()(
    withTranslation()(ProjectMapWorkTable)
);
export const ProjectMapWorkTableStorybook = forStorybookV2(ProjectMapWorkTableSitecore);
export default withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
    ProjectMapWorkTableSitecore
);
