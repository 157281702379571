import React, { useState } from 'react';
import Link from '../Link/Link.jsx';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import MegaNavHeader from '../MegaNavHeader/MegaNavHeader.jsx';
import { parsePageTitle } from '../../utils/pageTitleGenerator.js';
import {
    LinkTargetEnum,
    ButtonVariantEnum,
    LinkVariantEnum,
    LinkSubVariantsEnum,
} from '../../utils/enums';
import classnames from 'classnames';
import './mega-nav-primary.scss';

const OfficesName = 'Offices';

const hasLanguage = (versions, language) => {
    const langFound = versions.find((lan) => lan?.language?.name === language);
    return langFound;
};

const MegaNavPrimary = ({
    expertiseItem,
    expertiseList,
    openMegaMenuHome,
    language,
    theme,
    authorableExpertiseLink,
}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [activeGroup, setActiveGroup] = useState(0);
    const [columnLists, setColumnList] = useState(
        Array.isArray(expertiseList) ? expertiseList[activeTab]?.children : []
    );
    const [mobileDisplay, setMobileDisplay] = useState(0);
    const [mobileHeaderValues, setMobileHeaderValues] = useState({
        title: expertiseItem?.title,
        link: expertiseItem?.descriptionLink?.url,
        linkText: expertiseItem?.descriptionLink?.text,
        secondaryLink: expertiseItem?.secondaryDescriptionLink?.url,
        secondaryLinkText: expertiseItem?.secondaryDescriptionLink?.text,
        linkTarget: LinkTargetEnum.sameTab,
    });
    const [onBackArray, setOnBackArray] = useState([openMegaMenuHome]);

    const changeScreenMobile = (
        pActiveTab,
        pColumnList,
        pMobileDisplay,
        pMobileHeaderValues,
        pBackArray,
        pActiveGroup
    ) => {
        setActiveGroup(pActiveGroup);
        setActiveTab(pActiveTab);
        setColumnList(pColumnList);
        setMobileDisplay(pMobileDisplay);
        setMobileHeaderValues(pMobileHeaderValues);
        setOnBackArray(pBackArray);
    };

    return (
        <section className="zn-mega-menu-primary">
            <div className="zn-mega-menu-primary zn-mega-menu-primary--lg">
                <MegaNavHeader
                    title={expertiseItem?.title}
                    description={expertiseItem?.description}
                    link={expertiseItem?.descriptionLink?.url}
                    linkTarget={LinkTargetEnum.sameTab}
                    linkText={expertiseItem?.descriptionLink?.text}
                    secondaryDescription={expertiseItem?.secondaryDescription}
                    secondaryLink={expertiseItem?.secondaryDescriptionLink?.url}
                    secondaryLinkText={expertiseItem?.secondaryDescriptionLink?.text}
                    onBack={openMegaMenuHome}
                />
                <section className="zn-mega-menu-primary__body">
                    <ul className="zn-mega-menu-primary__sidebar">
                        {Array.isArray(expertiseList) &&
                            expertiseList.map((tab, index) => {
                                if (tab?.displayName) {
                                    return (
                                        <li
                                            key={index}
                                            className={classnames(
                                                'zn-mega-menu-primary-list__item',
                                                {
                                                    active: index === activeTab,
                                                }
                                            )}
                                        >
                                            <Link
                                                Theme={{ value: theme }}
                                                Variant={{
                                                    value: `${LinkVariantEnum.nav.toLowerCase()}--mega--medium`,
                                                }}
                                                SubVariant={{
                                                    value: [
                                                        LinkSubVariantsEnum.chevronRight,
                                                        ...(index === activeTab
                                                            ? [LinkSubVariantsEnum.active]
                                                            : [LinkSubVariantsEnum.inactive]),
                                                    ],
                                                }}
                                                LinkText={{
                                                    value: parsePageTitle(tab?.displayName),
                                                }}
                                                LinkURL={{ value: tab?.url }}
                                                LinkAltText={{
                                                    value: parsePageTitle(tab?.displayName),
                                                }}
                                                LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setActiveTab(index);
                                                    setColumnList(expertiseList[index]?.children);
                                                }}
                                            />
                                        </li>
                                    );
                                }
                            })}
                        {authorableExpertiseLink?.url && (
                            <li key={-1} className={classnames('zn-mega-menu-primary-list__item')}>
                                <Link
                                    Theme={{ value: theme }}
                                    Variant={{
                                        value: `${LinkVariantEnum.nav.toLowerCase()}--mega--medium`,
                                    }}
                                    SubVariant={{
                                        value: [LinkSubVariantsEnum.chevronRight],
                                    }}
                                    LinkText={{ value: authorableExpertiseLink?.text }}
                                    LinkURL={{ value: authorableExpertiseLink?.url }}
                                    LinkAltText={{ value: authorableExpertiseLink?.text }}
                                    LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                />
                            </li>
                        )}
                    </ul>
                    <section
                        className={classnames('zn-mega-menu-primary__content', {
                            'zn-mega-menu-primary__content--one':
                                expertiseList[activeTab]?.name === OfficesName,
                        })}
                    >
                        {Array.isArray(columnLists) &&
                            columnLists.map((list, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={classnames(
                                            'zn-mega-menu-primary__content__column',
                                            {
                                                'zn-mega-menu-primary__content__column--one':
                                                    expertiseList[activeTab]?.name === OfficesName,
                                            }
                                        )}
                                    >
                                        {list?.displayName &&
                                            hasLanguage(list?.versions, language) && (
                                                <>
                                                    {expertiseList[activeTab]?.name ===
                                                    OfficesName ? (
                                                        <Link
                                                            key={index}
                                                            Theme={{ value: theme }}
                                                            Variant={{
                                                                value: LinkVariantEnum.nav.toLowerCase(),
                                                            }}
                                                            SubVariant={{
                                                                value: [
                                                                    LinkSubVariantsEnum.inactive,
                                                                    LinkSubVariantsEnum.small,
                                                                    LinkSubVariantsEnum.bold,
                                                                ],
                                                            }}
                                                            LinkText={{
                                                                value: parsePageTitle(
                                                                    list?.displayName
                                                                ),
                                                            }}
                                                            LinkURL={{ value: list?.url }}
                                                            LinkAltText={{
                                                                value: parsePageTitle(
                                                                    list?.displayName
                                                                ),
                                                            }}
                                                            LinkTarget={{
                                                                value: LinkTargetEnum.sameTab,
                                                            }}
                                                        />
                                                    ) : (
                                                        <ButtonLink
                                                            key={index}
                                                            fields={{
                                                                LinkURL: {
                                                                    value: {
                                                                        text: parsePageTitle(
                                                                            list?.displayName
                                                                        ),
                                                                        href: list?.url,
                                                                        title: parsePageTitle(
                                                                            list?.displayName
                                                                        ),
                                                                        target:
                                                                            LinkTargetEnum.sameTab,
                                                                    },
                                                                },
                                                            }}
                                                            params={{
                                                                Theme: theme,
                                                                Variant: ButtonVariantEnum.tertiary.toLowerCase(),
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        {Array.isArray(list?.children) &&
                                            list?.children.map((link, index) => {
                                                if (
                                                    link?.displayName &&
                                                    hasLanguage(link?.versions, language)
                                                ) {
                                                    return (
                                                        <Link
                                                            key={index}
                                                            Theme={{ value: theme }}
                                                            Variant={{
                                                                value: LinkVariantEnum.nav.toLowerCase(),
                                                            }}
                                                            SubVariant={{
                                                                value: [
                                                                    LinkSubVariantsEnum.inactive,
                                                                    LinkSubVariantsEnum.small,
                                                                ],
                                                            }}
                                                            LinkText={{
                                                                value: parsePageTitle(
                                                                    link?.displayName
                                                                ),
                                                            }}
                                                            LinkURL={{ value: link?.url }}
                                                            LinkAltText={{
                                                                value: parsePageTitle(
                                                                    link?.displayName
                                                                ),
                                                            }}
                                                            LinkTarget={{
                                                                value: LinkTargetEnum.sameTab,
                                                            }}
                                                        />
                                                    );
                                                }
                                            })}
                                    </div>
                                );
                            })}
                    </section>
                </section>
            </div>
            <div className="zn-mega-menu-primary zn-mega-menu-primary--xs">
                <section className="zn-mega-menu-primary__body--xs">
                    <MegaNavHeader
                        {...mobileHeaderValues}
                        onBack={() => {
                            onBackArray[mobileDisplay]();
                        }}
                    />
                    <ul className="zn-mega-menu-primary__content--xs">
                        {mobileDisplay === 0 &&
                            Array.isArray(expertiseList) &&
                            expertiseList.map((list, index) => {
                                if (list?.displayName) {
                                    return (
                                        <li key={index} className="zn-mega-menu-primary-list__item">
                                            <Link
                                                Theme={{ value: theme }}
                                                Variant={{
                                                    value: `${LinkVariantEnum.nav.toLowerCase()}--mega--medium`,
                                                }}
                                                SubVariant={{
                                                    value: [LinkSubVariantsEnum.chevronRight],
                                                }}
                                                LinkText={{
                                                    value: parsePageTitle(list?.displayName),
                                                }}
                                                LinkURL={{ value: list?.url }}
                                                LinkAltText={{
                                                    value: parsePageTitle(list?.displayName),
                                                }}
                                                LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onBackArray[1] = () => {
                                                        setMobileDisplay(0);
                                                        setMobileHeaderValues({
                                                            linkTarget: LinkTargetEnum.sameTab,

                                                            secondaryLink:
                                                                expertiseItem
                                                                    ?.secondaryDescriptionLink?.url,
                                                            title: expertiseItem?.title,
                                                            linkText:
                                                                expertiseItem?.descriptionLink
                                                                    ?.text,
                                                            secondaryLinkText:
                                                                expertiseItem
                                                                    ?.secondaryDescriptionLink
                                                                    ?.text,

                                                            link:
                                                                expertiseItem?.descriptionLink?.url,
                                                        });
                                                    };
                                                    changeScreenMobile(
                                                        index,
                                                        expertiseList[index]?.children,
                                                        1,
                                                        {
                                                            title: {
                                                                value: parsePageTitle(
                                                                    list?.displayName
                                                                ),
                                                            },
                                                        },
                                                        onBackArray,
                                                        activeGroup
                                                    );
                                                }}
                                            />
                                        </li>
                                    );
                                }
                            })}
                        {mobileDisplay === 1 &&
                            Array.isArray(columnLists) &&
                            columnLists.map(
                                (list, index) =>
                                    list?.displayName &&
                                    hasLanguage(list?.versions, language) && (
                                        <li key={index} className="zn-mega-menu-primary-list__item">
                                            <>
                                                {Array.isArray(expertiseList) &&
                                                expertiseList[activeTab]?.name === OfficesName ? (
                                                    <ButtonLink
                                                        key={index}
                                                        className="mobile-navigation-item"
                                                        fields={{
                                                            LinkURL: {
                                                                value: {
                                                                    text: parsePageTitle(
                                                                        list?.displayName
                                                                    ),
                                                                    href: list?.url,
                                                                    title: parsePageTitle(
                                                                        list?.displayName
                                                                    ),
                                                                    target: LinkTargetEnum.sameTab,
                                                                },
                                                            },
                                                        }}
                                                        params={{
                                                            Theme: theme,
                                                            Variant: ButtonVariantEnum.tertiary,
                                                        }}
                                                    />
                                                ) : (
                                                    <Link
                                                        key={index}
                                                        Theme={{ value: theme }}
                                                        className="mobile-navigation-item"
                                                        Variant={{ value: LinkVariantEnum.nav }}
                                                        SubVariant={{
                                                            value: [
                                                                LinkSubVariantsEnum.chevronRight,
                                                                LinkSubVariantsEnum.bold,
                                                            ],
                                                        }}
                                                        LinkText={{
                                                            value: parsePageTitle(
                                                                list?.displayName
                                                            ),
                                                        }}
                                                        LinkURL={{ value: list?.url }}
                                                        LinkAltText={{
                                                            value: parsePageTitle(
                                                                list?.displayName
                                                            ),
                                                        }}
                                                        LinkTarget={{
                                                            value: LinkTargetEnum.sameTab,
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            onBackArray[2] = () => {
                                                                setMobileDisplay(1);
                                                                setMobileHeaderValues({
                                                                    title: {
                                                                        value: parsePageTitle(
                                                                            expertiseList[activeTab]
                                                                                ?.displayName
                                                                        ),
                                                                    },
                                                                });
                                                            };
                                                            changeScreenMobile(
                                                                activeTab,
                                                                expertiseList[activeTab]?.children,
                                                                2,
                                                                {
                                                                    title: {
                                                                        value: parsePageTitle(
                                                                            list?.displayName
                                                                        ),
                                                                    },
                                                                    link: list?.url,
                                                                    linkText: parsePageTitle(
                                                                        list?.displayName
                                                                    ),
                                                                    linkTarget:
                                                                        LinkTargetEnum.sameTab,
                                                                },
                                                                onBackArray,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </>
                                        </li>
                                    )
                            )}
                        {mobileDisplay === 2 &&
                            Array.isArray(columnLists[activeGroup]?.children) &&
                            columnLists[activeGroup]?.children.map((item, index) => {
                                if (item?.displayName && hasLanguage(item?.versions, language)) {
                                    return (
                                        <li key={index} className="zn-mega-menu-primary-list__item">
                                            <Link
                                                key={index}
                                                Theme={{ value: theme }}
                                                Variant={{
                                                    value: LinkVariantEnum.nav.toLowerCase(),
                                                }}
                                                SubVariant={{
                                                    value: [
                                                        LinkSubVariantsEnum.inactive,
                                                        LinkSubVariantsEnum.small,
                                                    ],
                                                }}
                                                LinkText={{
                                                    value: parsePageTitle(item?.displayName),
                                                }}
                                                LinkURL={{ value: item?.url }}
                                                LinkAltText={{
                                                    value: parsePageTitle(item?.displayName),
                                                }}
                                                className="mobile-navigation-item"
                                                LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                            />
                                        </li>
                                    );
                                }
                            })}
                    </ul>
                </section>
            </div>
        </section>
    );
};

export default MegaNavPrimary;
