import React, { useEffect } from 'react';
import Link from '../Link/Link.jsx';
import { Button } from '../Button/Button.js';
import Dropdown from '../Dropdown/Dropdown.jsx';
import { SearchIcon } from '../../assets/common.js';
import paramToBool from '../../utils/paramToBool.js';
import {
    LinkTargetEnum,
    ThemeEnum,
    LanguageNamesEnum,
    LinkVariantEnum,
    LinkSubVariantsEnum,
} from '../../utils/enums';
import classnames from 'classnames';

const NavBarMenuPrimary = ({
    language,
    languages,
    pageUrl,
    homeMainLinksPrimary,
    homeMainLinksSecondary,
    theme,
    activeItem,
    isMegaMenuOpen,
    onClose,
    openMegaMenuPrimary,
    openMegaMenuSecondary,
    openMegaMenuTertiary,
    setDisplaySearch,
    displaySearch,
    mainNavBarRef,
}) => {
    const closeLanguageToggle = () => {
        const toggle = document?.getElementById('zn-language-toggle')?.firstElementChild;
        const toggleButton = toggle?.firstElementChild;
        const isToggleOpen = toggle?.children.length > 1;
        if (isToggleOpen) {
            toggleButton?.click();
        }
    };

    useEffect(() => {
        const toggle = document?.getElementById('zn-language-toggle');
        toggle?.addEventListener('focusout', function (event) {
            if (toggle.contains(event.relatedTarget)) {
                return;
            }
            closeLanguageToggle();
        });
    }, []);

    return (
        <div className="zn-main-nav-bar__menu" ref={mainNavBarRef}>
            <div
                as="nav"
                className="zn-main-nav-bar__list zn-main-nav-bar__list__divider zn-main-nav-bar__list--bold"
            >
                {Array.isArray(homeMainLinksPrimary) &&
                    homeMainLinksPrimary.map((item, index) => {
                        const hasDropdown =
                            paramToBool(item?.isInsights?.value) ||
                            paramToBool(item?.isExpertise?.value) ||
                            paramToBool(item?.isAboutUs?.value);
                        return (
                            <li key={index} className="zn-main-nav-bar__menu__item ">
                                <Link
                                    className="zn-main-nav-bar__menu__item-link"
                                    Theme={{ value: ThemeEnum[theme] }}
                                    Variant={{ value: LinkVariantEnum.nav.toLowerCase() }}
                                    SubVariant={{
                                        value: [
                                            LinkSubVariantsEnum.bold,
                                            LinkSubVariantsEnum.small,
                                            LinkSubVariantsEnum.noMargin,
                                            ...(index === activeItem
                                                ? [LinkSubVariantsEnum.active]
                                                : []),
                                            ...(hasDropdown
                                                ? [LinkSubVariantsEnum.chevronDown]
                                                : []),
                                        ],
                                    }}
                                    LinkText={item?.title}
                                    LinkURL={{ value: item?.navigationLink?.url }}
                                    LinkAltText={{ value: `nav link to ${item?.title?.value}` }}
                                    LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                    onClick={(e) => {
                                        if (hasDropdown) {
                                            e.preventDefault();
                                            if (isMegaMenuOpen && index === activeItem) {
                                                onClose();
                                                return;
                                            }

                                            if (paramToBool(item?.isExpertise?.value)) {
                                                openMegaMenuPrimary(index);
                                            } else if (paramToBool(item?.isInsights?.value)) {
                                                openMegaMenuSecondary(index);
                                            } else {
                                                openMegaMenuTertiary(index);
                                            }
                                        }
                                    }}
                                />
                            </li>
                        );
                    })}
            </div>
            <div
                className={classnames('zn-main-nav-bar__list', {
                    'zn-main-nav-bar__list__divider': languages.length > 0,
                })}
            >
                {Array.isArray(homeMainLinksSecondary) &&
                    homeMainLinksSecondary.map((item, index) => {
                        index += 100;
                        const hasDropdown =
                            paramToBool(item?.isInsights?.value) ||
                            paramToBool(item?.isExpertise?.value) ||
                            paramToBool(item?.isAboutUs?.value);
                        return (
                            <li key={index} className="zn-main-nav-bar__menu__item">
                                <Link
                                    className="zn-main-nav-bar__menu__item-link"
                                    Theme={{ value: ThemeEnum[theme] }}
                                    Variant={{ value: LinkVariantEnum.nav.toLowerCase() }}
                                    SubVariant={{
                                        value: [
                                            LinkSubVariantsEnum.small,
                                            LinkSubVariantsEnum.noMargin,
                                            ...(index === activeItem
                                                ? [LinkSubVariantsEnum.active]
                                                : []),
                                            ...(hasDropdown
                                                ? [LinkSubVariantsEnum.chevronDown]
                                                : []),
                                        ],
                                    }}
                                    LinkText={item?.title}
                                    LinkURL={{ value: item?.navigationLink?.url }}
                                    LinkAltText={{ value: `nav link to ${item?.title?.value}` }}
                                    LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                    onClick={(e) => {
                                        if (hasDropdown) {
                                            e.preventDefault();
                                            if (isMegaMenuOpen && index === activeItem) {
                                                onClose();
                                                return;
                                            }

                                            if (paramToBool(item?.isExpertise?.value)) {
                                                openMegaMenuPrimary(index);
                                            } else if (paramToBool(item?.isInsights?.value)) {
                                                openMegaMenuSecondary(index);
                                            } else {
                                                openMegaMenuTertiary(index);
                                            }
                                        }
                                    }}
                                />
                            </li>
                        );
                    })}
                <li className="zn-main-nav-bar__menu__item zn-main-nav-bar__menu__item--search-icon">
                    <Button
                        aria-label="Open Search Bar"
                        type="button"
                        variant="icon"
                        onClick={() => {
                            setDisplaySearch(!displaySearch);
                        }}
                    >
                        <SearchIcon theme={theme} />
                    </Button>
                </li>
            </div>
            {languages.length > 0 && (
                <div id="zn-language-toggle" className="zn-main-nav-bar__list">
                    <Dropdown
                        title={LanguageNamesEnum[language]}
                        theme={theme}
                        content={languages.map((lan, index) => {
                            return (
                                <li key={index} className="zn-dropdown__list-item">
                                    <Link
                                        className="zn-dropdown__list-item-content"
                                        Theme={{ value: ThemeEnum.light }}
                                        Variant={{ value: LinkVariantEnum.nav.toLowerCase() }}
                                        SubVariant={{
                                            value: [
                                                LinkSubVariantsEnum.small,
                                                LinkSubVariantsEnum.noMargin,
                                            ],
                                        }}
                                        LinkText={{ value: LanguageNamesEnum[lan] }}
                                        LinkURL={{
                                            value: `${pageUrl}${
                                                pageUrl?.includes('?') ? '&' : '?'
                                            }sc_lang=${lan}`,
                                        }}
                                        LinkAltText={{
                                            value: `${LanguageNamesEnum[lan]}`,
                                        }}
                                        LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                        FullPageRefresh={true}
                                    />
                                </li>
                            );
                        })}
                    />
                </div>
            )}
        </div>
    );
};

export default NavBarMenuPrimary;
