import React from 'react';
import classnames from 'classnames';
import { ThemeEnum, LinkTargetEnum, LinkSubVariantsEnum } from '../../utils/enums';
import { ChevronRight, ChevronLeft, ChevronDown, ExternalLink } from '../../assets/LinkIcons.js';
import { Link as RouterLink } from 'react-router-dom';
import './link.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

function decodeSpecialChars(input) {
    if (!input) {
        return input;
    }

    const chars = { '&amp;': '&', '&#38;': '&', '&#40;': '(', '&#41;': ')' };
    return input.replace(RegExp(Object.keys(chars).join('|'), 'g'), (i) => chars[i]);
}

const classBase = 'zn-link';
const Link = ({
    Variant,
    Theme,
    LinkText,
    LinkURL,
    LinkAltText,
    LinkTarget,
    SubVariant,
    className,
    onClick,
    children,
    t,
    FullPageRefresh,
}) => {
    const variant = Variant?.value;
    const theme = Theme?.value === ThemeEnum.dark ? 'dark' : 'light';
    const target = LinkTarget?.value === LinkTargetEnum.newTab ? '_blank' : '_self';
    const subVariant = SubVariant?.value ? SubVariant.value : [];

    return LinkURL?.value?.startsWith('/') && !FullPageRefresh ? (
        <RouterLink
            className={classnames(classBase, `${classBase}--${theme}`, className, {
                [`${classBase}--${variant}`]: variant,
                [`${classBase}--${variant}--${theme}`]: variant,
                ['active']: subVariant.includes(LinkSubVariantsEnum.active),
                ['inactive']: subVariant.includes(LinkSubVariantsEnum.inactive),
                ['bold']: subVariant.includes(LinkSubVariantsEnum.bold),
                ['small']: subVariant.includes(LinkSubVariantsEnum.small),
                ['no-margin']: subVariant.includes(LinkSubVariantsEnum.noMargin),
                ['dropdown']: subVariant.includes(LinkSubVariantsEnum.chevronDown),
            })}
            to={LinkURL?.value}
            target={target}
            alt={decodeSpecialChars(LinkAltText?.value)}
            onClick={onClick}
        >
            {subVariant.includes(LinkSubVariantsEnum.chevronLeft) &&
                !subVariant.includes(LinkSubVariantsEnum.active) &&
                target !== '_blank' && (
                    <>
                        <span
                            style={{ color: '#006EAA' }}
                            className={`${classBase}__icon ${classBase}__icon--left ${classBase}__icon--${LinkSubVariantsEnum.chevronLeft}`}
                        >
                            <ChevronLeft />
                        </span>
                    </>
                )}
            {LinkText?.value ? decodeSpecialChars(LinkText?.value) : children}
            {target === '_blank' && (
                <>
                    <span className="sr-only">{t('OpensInANewTab')}</span>
                    <span className={`${classBase}__icon ${classBase}__icon--right`}>
                        <ExternalLink />
                    </span>
                </>
            )}
            {subVariant.includes(LinkSubVariantsEnum.chevronRight) &&
                !subVariant.includes(LinkSubVariantsEnum.active) &&
                target !== '_blank' && (
                    <>
                        <span
                            style={{ color: '#006EAA' }}
                            className={`${classBase}__icon ${classBase}__icon--right ${classBase}__icon--${LinkSubVariantsEnum.chevronRight}`}
                        >
                            <ChevronRight />
                        </span>
                    </>
                )}

            {subVariant.includes(LinkSubVariantsEnum.chevronDown) && (
                <span
                    className={`${classBase}__icon ${classBase}__icon--right ${classBase}__icon--${LinkSubVariantsEnum.chevronRight}`}
                >
                    <ChevronDown theme={theme} />
                </span>
            )}
        </RouterLink>
    ) : (
        <a
            className={classnames(classBase, `${classBase}--${theme}`, className, {
                [`${classBase}--${variant}`]: variant,
                [`${classBase}--${variant}--${theme}`]: variant,
                ['active']: subVariant.includes(LinkSubVariantsEnum.active),
                ['inactive']: subVariant.includes(LinkSubVariantsEnum.inactive),
                ['bold']: subVariant.includes(LinkSubVariantsEnum.bold),
                ['small']: subVariant.includes(LinkSubVariantsEnum.small),
                ['no-margin']: subVariant.includes(LinkSubVariantsEnum.noMargin),
                ['dropdown']: subVariant.includes(LinkSubVariantsEnum.chevronDown),
            })}
            href={LinkURL?.value}
            target={target}
            alt={decodeSpecialChars(LinkAltText?.value)}
            onClick={onClick}
        >
            {subVariant.includes(LinkSubVariantsEnum.chevronLeft) &&
                !subVariant.includes(LinkSubVariantsEnum.active) &&
                target !== '_blank' && (
                    <>
                        <span
                            style={{ color: '#006EAA' }}
                            className={`${classBase}__icon ${classBase}__icon--left ${classBase}__icon--${LinkSubVariantsEnum.chevronLeft}`}
                        >
                            <ChevronLeft />
                        </span>
                    </>
                )}
            {LinkText?.value ? decodeSpecialChars(LinkText?.value) : children}
            {target === '_blank' && (
                <>
                    <span className="sr-only">{t('OpensInANewTab')}</span>
                    <span className={`${classBase}__icon ${classBase}__icon--right`}>
                        <ExternalLink />
                    </span>
                </>
            )}
            {subVariant.includes(LinkSubVariantsEnum.chevronRight) &&
                !subVariant.includes(LinkSubVariantsEnum.active) &&
                target !== '_blank' && (
                    <>
                        <span
                            style={{ color: '#006EAA' }}
                            className={`${classBase}__icon ${classBase}__icon--right ${classBase}__icon--${LinkSubVariantsEnum.chevronRight}`}
                        >
                            <ChevronRight />
                        </span>
                    </>
                )}

            {subVariant.includes(LinkSubVariantsEnum.chevronDown) && (
                <span
                    className={`${classBase}__icon ${classBase}__icon--right ${classBase}__icon--${LinkSubVariantsEnum.chevronRight}`}
                >
                    <ChevronDown theme={theme} />
                </span>
            )}
        </a>
    );
};

export default withTranslation()(withSitecoreContext()(Link));
