import React, { useEffect } from 'react';
import classnames from 'classnames';
import './in-page-navigation-item.scss';
import Text from '../Text/Text.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export const InPageNavigationItem = ({
    changeTitle,
    changeDropdown,
    changeCurrent,
    fields,
    sectionIds,
    sitecoreContext,
}) => {
    const inPageNavigationItemBlock = 'zn-in-page-navigation-item';
    const { SectionName, SectionID } = fields;
    const toggle = () => {
        changeTitle(SectionName?.value);
        changeDropdown();
        changeCurrent(SectionID?.value);
    };

    useEffect(() => {
        const section = document.getElementById(SectionID?.value);
        // Adds extra scroll margin for the stacking navs
        section?.classList.add('zn-section--in-page-nav-current');
    }, []);

    return (
        <li
            className={classnames(`${inPageNavigationItemBlock}`)}
            id={`nav-${SectionID?.value}`}
            style={
                (sectionIds || [])?.find(
                    (x) => x.id === SectionID?.value && x.display !== 'none'
                ) || sitecoreContext?.pageEditing
                    ? { display: 'inline-block' }
                    : { display: 'none' }
            }
        >
            <a
                className={classnames(`${inPageNavigationItemBlock}__link`)}
                href={`#${SectionID?.value}`}
                onClick={toggle}
            >
                <Text field={SectionName}></Text>
            </a>
        </li>
    );
};

export default withSitecoreContext()(InPageNavigationItem);
