import React from 'react';
import { Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks.jsx';
import '../Footer/footer.scss';

const FooterContactBar = ({ legalText, legalLinks, socialMediaLinks, params }) => {
    return (
        <div className="zn-footer__contact-bar">
            <hr className="zn-footer__divider" />
            <div className="zn-footer__info">
                <div className="zn-footer__legal-list">
                    <div className="zn-footer__legal-title">
                        <RichText field={legalText} />
                    </div>
                    <ul>
                        {Array.isArray(legalLinks?.targetItems) &&
                            legalLinks?.targetItems.map((link, index) => {
                                return (
                                    <li key={index} className="zn-footer__legal-list-item">
                                        <Link
                                            className="zn-footer__legal-link"
                                            field={link.link.jss}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                <div className="zn-footer__social-list">
                    {socialMediaLinks && (
                        <SocialMediaLinks
                            params={params}
                            fields={{
                                FacebookLabel: socialMediaLinks.facebookLabel,
                                FacebookPageLink: socialMediaLinks.facebookPageLink,
                                FacebookIconAltText: socialMediaLinks.facebookIconAltText,
                                TwitterLabel: socialMediaLinks.twitterLabel,
                                TwitterPageLink: socialMediaLinks.twitterPageLink,
                                TwitterIconAltText: socialMediaLinks.twitterIconAltText,
                                LinkedInLabel: socialMediaLinks.linkedInLabel,
                                LinkedInPageLink: socialMediaLinks.linkedInPageLink,
                                LinkedInIconAltText: socialMediaLinks.linkedInIconAltText,
                                EmailLabel: socialMediaLinks.emailLabel,
                                EmailLink: socialMediaLinks.emailLink,
                                EmailIconAltText: socialMediaLinks.emailIconAltText,
                                PDFLabel: socialMediaLinks.pDFLabel,
                                PDFLink: socialMediaLinks.pDFLink,
                                PDFIconAltText: socialMediaLinks.pDFIconAltText,
                                CopyUrlLabel: socialMediaLinks.copyUrlLabel,
                                CopyUrlIconAltText: socialMediaLinks.copyUrlIconAltText,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FooterContactBar;
