import React from 'react';
import PropTypes from 'prop-types';
import './social-media-links.scss';
import Text from '../Text';
import classnames from 'classnames';
import { ThemeEnum, SizeEnum } from '../../utils/enums';

const socialMediaLinkBlock = 'zn-social-media-link';

const SocialMediaLink = ({
    include,
    link,
    theme,
    label,
    IconComponent,
    alt,
    showLabel,
    className,
    children,
    labelsMarginSize,
}) => {
    if (include !== true) {
        return null;
    }

    return (
        <a
            href={link?.value}
            className={classnames(className, socialMediaLinkBlock, {
                [`${socialMediaLinkBlock}--dark`]: theme === ThemeEnum.dark,
            })}
            rel="noopener noreferrer"
            target="_blank"
        >
            <IconComponent alt={alt?.value} className={`${socialMediaLinkBlock}__icon`} />
            <Text
                field={label}
                useDefaultMargin={false}
                className={classnames(`${socialMediaLinkBlock}__label`, {
                    [`${socialMediaLinkBlock}__label--dark`]: theme === ThemeEnum.dark,
                    [`${socialMediaLinkBlock}__label--sm-margin`]:
                        labelsMarginSize === SizeEnum.small,
                    [`${socialMediaLinkBlock}__label--lg-margin`]:
                        labelsMarginSize === SizeEnum.large,
                    'sr-only': !showLabel,
                })}
            />
            {children}
        </a>
    );
};

SocialMediaLink.propTypes = {
    theme: PropTypes.oneOf(Object.values(ThemeEnum)).isRequired,
    include: PropTypes.bool.isRequired,
    showLabel: PropTypes.bool.isRequired,
    IconComponent: PropTypes.instanceOf(Object).isRequired,
    link: PropTypes.shape({ value: PropTypes.string }),
    alt: PropTypes.shape({ value: PropTypes.string }),
    labelsMarginSize: PropTypes.oneOf(Object.values(SizeEnum)),
    label: PropTypes.shape({ value: PropTypes.string }),
    className: PropTypes.string,
    children: PropTypes.node,
};

export default SocialMediaLink;
