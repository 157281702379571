import React from 'react';
import Link from '../Link/Link.jsx';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import { ButtonVariantEnum, LinkSubVariantsEnum, LinkVariantEnum } from '../../utils/enums';
import Text from '../Text';
import './mega-nav-header.scss';
import classnames from 'classnames';

const MegaNavHeader = ({
    title,
    description,
    link,
    linkText,
    linkTarget,
    onBack,
    theme,
    secondaryDescription,
    secondaryLink,
    secondaryLinkText,
}) => {
    return (
        <div className="zn-mega-menu-header">
            <aside className="zn-mega-menu-header__title">
                <Link
                    Theme={{ value: theme }}
                    Variant={{ value: `${LinkVariantEnum.nav.toLowerCase()}--mega` }}
                    SubVariant={{ value: [LinkSubVariantsEnum.chevronLeft] }}
                    LinkText={title}
                    LinkAltText={{ value: `nav link to ${title?.value}` }}
                    onClick={onBack}
                />
            </aside>
            <div className="zn-mega-menu-header__content-container">
                {((linkText && link) || description?.value) && (
                    <div className="zn-mega-menu-header__content">
                        <Text type="body" field={description}></Text>
                        {linkText && link && (
                            <ButtonLink
                                fields={{
                                    LinkURL: {
                                        value: {
                                            href: link,
                                            title: linkText,
                                            target: linkTarget,
                                            text: linkText,
                                        },
                                    },
                                }}
                                params={{
                                    Theme: theme,
                                    Variant: ButtonVariantEnum.tertiary.toLowerCase(),
                                }}
                            />
                        )}
                    </div>
                )}
                {((secondaryLink && secondaryLinkText) || secondaryDescription?.value) && (
                    <div
                        className={classnames(
                            'zn-mega-menu-header__content',
                            'zn-mega-menu-header__content--secondary'
                        )}
                    >
                        <Text type="body" field={secondaryDescription}></Text>
                        {secondaryLinkText && secondaryLink && (
                            <ButtonLink
                                fields={{
                                    LinkURL: {
                                        value: {
                                            href: secondaryLink,
                                            title: secondaryLinkText,
                                            target: linkTarget,
                                            text: secondaryLinkText,
                                        },
                                    },
                                }}
                                params={{
                                    Theme: theme,
                                    Variant: ButtonVariantEnum.tertiary.toLowerCase(),
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MegaNavHeader;
