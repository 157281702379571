import React from 'react';
import classnames from 'classnames';
import { ChevronRight, ArrowRight } from '../../assets/LinkIcons.js';
import '../ButtonLink/button-link.scss';
import './project-map-list-item.scss';

const ProjectMapListItem = ({
    icon,
    title,
    tags,
    notNavigatable,
    disableMapCTA,
    url,
    ctaText,
    isLoading,
    isListLoading,
}) => {
    const projectMapListItemBlock = 'zn-project-map-list-item';
    const nonNavigable = notNavigatable === 'True' || disableMapCTA === 'True';
    const Type = nonNavigable || isListLoading || isLoading ? 'div' : 'a';

    return (
        <Type
            href={nonNavigable ? null : url}
            target={nonNavigable ? null : '_self'}
            className={`${projectMapListItemBlock}`}
        >
            <div className={classnames(`${projectMapListItemBlock}__divider-container`)}>
                <div className={classnames(`${projectMapListItemBlock}__divider`)}></div>
            </div>
            <div className={classnames(`${projectMapListItemBlock}__content`)}>
                <div className={`${projectMapListItemBlock}__icon-container`}>
                    <div
                        className={classnames(`${projectMapListItemBlock}__icon`, {
                            [`${projectMapListItemBlock}__icon--loading`]:
                                isListLoading || isLoading,
                        })}
                        style={isListLoading || isLoading ? { backgroundImage: null } : {}}
                        dangerouslySetInnerHTML={
                            !isListLoading && !isLoading ? { __html: icon } : { __html: null }
                        }
                    ></div>
                </div>
                <div className={`${projectMapListItemBlock}__text`}>
                    <div
                        className={classnames(`${projectMapListItemBlock}__title-container`, {
                            [`${projectMapListItemBlock}__title-container--not-loading`]:
                                !isListLoading && !isLoading,
                        })}
                    >
                        <span
                            className={classnames(`${projectMapListItemBlock}__title`, {
                                [`${projectMapListItemBlock}__title--loading`]:
                                    isListLoading || isLoading,
                            })}
                            dangerouslySetInnerHTML={{ __html: title }}
                        ></span>
                    </div>

                    <ul className={`${projectMapListItemBlock}__tags`}>
                        {tags?.map((tag, index) => (
                            <li
                                className={classnames(`${projectMapListItemBlock}__tag`, {
                                    [`${projectMapListItemBlock}__tag--loading`]:
                                        isListLoading || isLoading,
                                })}
                                key={index}
                            >
                                {tag}
                            </li>
                        ))}
                    </ul>
                    {ctaText?.value && !nonNavigable && (
                        <div className={`${projectMapListItemBlock}__link`}>
                            <div
                                className={classnames(
                                    `zn-button`,
                                    `zn-button--tertiary`,
                                    `zn-button--light`,
                                    `zn-button--tertiary--light`,
                                    {
                                        [`zn-button--loading`]: isListLoading || isLoading,
                                    }
                                )}
                            >
                                {ctaText?.value}
                                <div
                                    className={classnames(
                                        `zn-button__icon`,
                                        `zn-button--tertiary--light__icon`,
                                        {
                                            [`zn-button__icon--loading`]:
                                                isListLoading || isLoading,
                                        }
                                    )}
                                >
                                    <ArrowRight />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {!nonNavigable && !isListLoading && !isLoading && (
                <div className={`${projectMapListItemBlock}__chevron`}>
                    <ChevronRight />
                </div>
            )}
        </Type>
    );
};

export default ProjectMapListItem;
