import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';
import { CalendarSearchBarIcon } from '../../assets/common.js';
import { SearchBarCalendarEnum } from '../../utils/enums.js';
import { Button } from '../Button/Button.js';
import classnames from 'classnames';
import './month-picker.scss';

const monthMap = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const date = new Date();

const MonthPicker = ({
    translation,
    monthYear,
    setMonthYear,
    activeFilter,
    toggleFilter,
    toggleMobilePublishMenu,
    type,
    onApplyFilters,
}) => {
    const monthPickerBlock = 'zn-month-picker';

    const [currentYear, setYearState] = useState(null);

    let monthType =
        type === SearchBarCalendarEnum.start ? translation('StartDate') : translation('EndDate');

    const updateYear = (offset) => {
        const updatedYear = currentYear + offset;
        setYearState(updatedYear);
    };

    useEffect(() => {
        setYearState(date.getFullYear());
    }, []);

    return (
        <div
            className={classnames(`${monthPickerBlock}`, {
                [`${monthPickerBlock}--active`]: type === activeFilter,
            })}
        >
            <button
                className={classnames(`${monthPickerBlock}__button`, {
                    [`${monthPickerBlock}__button--active`]: type === activeFilter,
                })}
                onClick={() => {
                    toggleFilter(type);
                }}
            >
                <div className={classnames(`${monthPickerBlock}__calendar-icon`)}>
                    <CalendarSearchBarIcon />
                </div>
                <div className={classnames(`${monthPickerBlock}__title`)}>
                    {monthYear.month !== null && monthYear.year !== null
                        ? `${monthMap[monthYear.month]} ${String(monthYear.year).slice(-2)}'`
                        : monthType}
                </div>
                <div className={classnames(`${monthPickerBlock}__title-chevron`)}>
                    {type === activeFilter ? <ChevronUp /> : <ChevronDown />}
                </div>
            </button>
            <div
                className={classnames(`${monthPickerBlock}__calendar`, {
                    [`${monthPickerBlock}__calendar--active`]: type === activeFilter,
                })}
            >
                <a
                    className={classnames(`${monthPickerBlock}__mobile-back-button`)}
                    onClick={() => {
                        toggleFilter(SearchBarCalendarEnum.publish);
                        toggleMobilePublishMenu(true);
                    }}
                >
                    <div
                        className={classnames(
                            `${monthPickerBlock}__chevron`,
                            `${monthPickerBlock}__chevron--right`
                        )}
                    >
                        <ChevronLeft />
                    </div>

                    <div className={classnames(`${monthPickerBlock}__mobile-back-title`)}>
                        {translation('BackToPublishDate')}
                    </div>
                </a>
                <div className={`${monthPickerBlock}__header`}>
                    <button className={`${monthPickerBlock}__arrow`} onClick={() => updateYear(-1)}>
                        <div
                            className={classnames(
                                `${monthPickerBlock}__chevron`,
                                `${monthPickerBlock}__chevron--left`
                            )}
                        >
                            <ChevronLeft currentColor={true} />
                        </div>
                    </button>
                    <div className={`${monthPickerBlock}__year`}>{currentYear}</div>
                    <button className={`${monthPickerBlock}__arrow`} onClick={() => updateYear(1)}>
                        <div
                            className={classnames(
                                `${monthPickerBlock}__chevron`,
                                `${monthPickerBlock}__chevron--right`
                            )}
                        >
                            <ChevronRight currentColor={true} />
                        </div>
                    </button>
                </div>
                <div className={`${monthPickerBlock}__body`}>
                    {monthMap.map((month, index) => (
                        <div className={`${monthPickerBlock}__item-wrapper`} key={index}>
                            <button
                                className={classnames(`${monthPickerBlock}__item`, {
                                    [`${monthPickerBlock}__item--active`]:
                                        monthYear.month === index && monthYear.year === currentYear,
                                })}
                                onClick={() => {
                                    setMonthYear(index, currentYear);
                                }}
                            >
                                <div className={`${monthPickerBlock}__month`}>{month}</div>
                            </button>
                        </div>
                    ))}
                </div>
                <div className={classnames(`${monthPickerBlock}__bottom-buttons`)}>
                    <button
                        className={classnames(`${monthPickerBlock}__clear-button`)}
                        onClick={() => {
                            setMonthYear(null, null);
                        }}
                    >
                        {translation('ClearDate')}
                    </button>
                    <div className={classnames(`${monthPickerBlock}__apply-button`)}>
                        <Button onClick={onApplyFilters}>{translation('ShowResults')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonthPicker;
