import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { TwoColumnLayoutEnum } from '../../utils/enums';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import './two-column-profile-list.scss';

export const TwoColumnProfileList = ({ params, twoColumnListItems }) => {
    const { Layout } = params;
    const twoColumnProfileListBlock = 'zn-two-column-profile-list';

    return (
        <div
            className={classnames(`${twoColumnProfileListBlock}`, {
                [`${twoColumnProfileListBlock}--paragraph`]:
                    Layout === TwoColumnLayoutEnum.paragraph,
            })}
        >
            <ul>{twoColumnListItems}</ul>
        </div>
    );
};

TwoColumnProfileList.propTypes = {
    params: PropTypes.shape({
        Layout: PropTypes.oneOf(Object.values(TwoColumnLayoutEnum)),
    }),
};

const TwoColumnProfileListSitecore = TwoColumnProfileList;

export const TwoColumnProfileListStorybook = forStorybookV2(TwoColumnProfileListSitecore);

export default withPlaceholder('twoColumnListItems')(TwoColumnProfileListSitecore);
