import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import classnames from 'classnames';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import Link from '../Link/Link.jsx';

import {
    LinkTargetEnum,
    LinkVariantEnum,
    ThemeEnum,
    LinkListLayoutEnum,
    ButtonVariantEnum,
} from '../../utils/enums';

import './link-list-item.scss';
import usePdfView from '../../utils/hooks/usePdfView.jsx';

const linkListItemBlock = 'zn-link-list-item';

const LinkListItem = ({ fields, className, params }) => {
    const { LinkName, LinkURL, LinkEyebrow, LinkSubtext } = fields;
    const { Layout, NoPadding, HideDividerLines, Theme, HideArrows } = params;
    const isHighlighted = Layout === LinkListLayoutEnum.highlighted;
    const hasDivider = HideDividerLines !== '1';
    const theme = Theme === ThemeEnum.dark ? 'dark' : 'light';
    const linkText = LinkName?.value || LinkURL?.value?.text || '';

    const pdfView = usePdfView();
    if (pdfView) {
        const linkURL = LinkURL?.value?.href;
        const Tag = linkURL ? 'a' : 'span';
        const props = linkURL ? { href: linkURL } : {};

        return (
            <Tag {...props} className="zn-link-list-item__pdf">
                <RichText field={{ value: linkText }} />
            </Tag>
        );
    }

    return (
        <li
            className={classnames(linkListItemBlock, className, {
                [`${linkListItemBlock}--no-padding`]: NoPadding === '1',
                [`${linkListItemBlock}--divider`]: hasDivider,
                [`${linkListItemBlock}--eyebrow`]: LinkEyebrow?.value,
                [`${linkListItemBlock}--highlighted`]: isHighlighted,
            })}
        >
            {isHighlighted && LinkURL?.value?.href ? (
                HideArrows !== '1' ? (
                    <ButtonLink
                        fields={{
                            LinkURL: {
                                value: {
                                    text: linkText,
                                    href: LinkURL?.value?.href,
                                    title: LinkURL?.value?.title,
                                    target: LinkURL?.value?.target,
                                },
                            },
                        }}
                        params={{
                            Theme: Theme,
                            Variant: ButtonVariantEnum.tertiary,
                            showInPDF: '1',
                        }}
                    />
                ) : (
                    <Link
                        Theme={{ value: Theme }}
                        Variant={{ value: LinkVariantEnum.hyperlink }}
                        LinkURL={{ value: LinkURL?.value?.href }}
                        LinkAltText={{ value: LinkURL?.value?.title }}
                        LinkTarget={
                            LinkURL?.value?.target === '_blank'
                                ? { value: LinkTargetEnum.newTab }
                                : { value: LinkTargetEnum.sameTab }
                        }
                    >
                        <RichText field={{ value: linkText }} />
                    </Link>
                )
            ) : (
                <>
                    {LinkEyebrow?.value && (
                        <p
                            className={`${linkListItemBlock}__eyebrow ${linkListItemBlock}__eyebrow--${theme}`}
                        >
                            <RichText field={LinkEyebrow} />
                        </p>
                    )}
                    {LinkURL?.value?.href ? (
                        <Link
                            Theme={{ value: Theme }}
                            Variant={{ value: LinkVariantEnum.hyperlink }}
                            LinkURL={{ value: LinkURL?.value?.href }}
                            LinkAltText={{ value: LinkURL?.value?.title }}
                            LinkTarget={
                                LinkURL?.value?.target === '_blank'
                                    ? { value: LinkTargetEnum.newTab }
                                    : { value: LinkTargetEnum.sameTab }
                            }
                        >
                            <RichText field={{ value: linkText }} />
                        </Link>
                    ) : (
                        <p
                            className={`${linkListItemBlock}__subtext ${linkListItemBlock}__subtext--${theme}`}
                        >
                            <RichText field={LinkName} />
                        </p>
                    )}

                    {LinkSubtext?.value && (
                        <p
                            className={`${linkListItemBlock}__subtext ${linkListItemBlock}__subtext--${theme}`}
                        >
                            <RichText field={LinkSubtext} />
                        </p>
                    )}
                </>
            )}
        </li>
    );
};

LinkListItem.prototype = {
    fields: PropTypes.shape({
        LinkName: PropTypes.shape({
            value: PropTypes.string,
        }).isRequired,
        LinkURL: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkAltText: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkEyebrow: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkSubtext: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkTarget: PropTypes.objectOf(LinkTargetEnum),
    }),
    isHighlighted: PropTypes.bool,
    color: PropTypes.array,
    Theme: PropTypes.shape({ value: PropTypes.oneOf(Object.values(ThemeEnum)) }),
};

export default withErrorBoundary([ErrorBoundaryConditions.fields])(LinkListItem);
