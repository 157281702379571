import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import './project-map-search-bar-filter.scss';
import { ChevronLeft, ChevronRight } from '../../assets/LinkIcons.js';
import { Button } from '../Button/Button.js';
import CheckboxWrapper from '../Checkbox/CheckboxWrapper.jsx';

const ProjectMapSearchBarFilterMobile = ({
    t,
    fields,
    index,
    activeFilter,
    toggleFilter,
    checkLowLevelFilter,
    checkAdvancedFilter,
    checkHighLevelFilter,
    clearCategory,
    setIsLoading,
    isLoading,
    isMobileFilterViewOpen,
    setIsMobileFilterViewOpen,
    hideMobileFilters,
    toggleAdvancedFilter,
    isAdvancedFiltersMobileOpen,
    toggleAdvancedHighLevelFilter,
}) => {
    const searchBarFilterBlock = 'zn-project-map-search-bar-filter';
    const searchBarFilterMobileBlock = 'zn-project-map-search-bar-filter-mobile';

    const { title, identifier, highLevelFilters, totalSelected, isAdFilter } = fields;

    const [activeHighLevelFilterGroup, setActiveHighLevelFilterGroup] = useState(null);
    const [activeHighLevelFilterGroupTitle, setActiveHighLevelFilterGroupTitle] = useState(null);

    const prevActiveHighLevelGroup = useRef();
    const prevActiveHighLevelGroupTitle = useRef();

    const includeHighLevelSelectAll =
        highLevelFilters?.filter((highLevelFilter) => !highLevelFilter?.lowLevelFilters.length)
            .length === highLevelFilters?.length;

    useEffect(() => {
        if (!isMobileFilterViewOpen) {
            setActiveHighLevelFilterGroup(null);
            setActiveHighLevelFilterGroupTitle(null);
        }
    }, [isMobileFilterViewOpen]);

    useEffect(() => {
        prevActiveHighLevelGroup.current = activeHighLevelFilterGroup;
    });

    const toggleActiveFilterGroup = (index) => {
        if (prevActiveHighLevelGroup.current === index) {
            setActiveHighLevelFilterGroup(null);
        } else {
            setActiveHighLevelFilterGroup(index);
        }
    };
    const toggleActiveFilterGroupTitle = (title) => {
        if (prevActiveHighLevelGroupTitle.current === title) {
            setActiveHighLevelFilterGroupTitle(null);
        } else {
            setActiveHighLevelFilterGroupTitle(title);
        }
    };

    return (
        <div
            className={classnames(`${searchBarFilterMobileBlock}`, {
                [`${searchBarFilterMobileBlock}--active`]: index === activeFilter,
            })}
        >
            <button
                className={classnames(`${searchBarFilterBlock}__button`, {
                    [`${searchBarFilterBlock}__button--active`]:
                        index === activeFilter && !isLoading,
                    [`${searchBarFilterBlock}__button--disabled`]: isLoading,
                })}
                onClick={() => {
                    if (!isLoading) toggleFilter(index);
                }}
            >
                <div
                    className={classnames(
                        `${searchBarFilterBlock}__title`,
                        `${searchBarFilterBlock}__title--mobile`,
                        { [`${searchBarFilterBlock}__title--mobile--disabled`]: isLoading }
                    )}
                >
                    {title}
                </div>
                <div
                    className={classnames(`${searchBarFilterBlock}__mobile-chevron`, {
                        [`${searchBarFilterBlock}__mobile-chevron--disabled`]: isLoading,
                    })}
                >
                    <ChevronRight />
                </div>
            </button>
            {!isAdFilter && (
                <div
                    className={classnames(`${searchBarFilterBlock}__mobile-selected-filters`, {
                        [`${searchBarFilterBlock}__mobile-selected-filters--disabled`]: isLoading,
                    })}
                >
                    {totalSelected > 0
                        ? totalSelected + ' ' + t('FiltersSelected')
                        : t('MapsNoFiltersSelected').replace('%0', title)}
                </div>
            )}
            <div
                className={classnames(`${searchBarFilterMobileBlock}__primary-menu`, {
                    [`${searchBarFilterMobileBlock}__primary-menu--active`]: index === activeFilter,
                })}
            >
                <div className={classnames(`${searchBarFilterMobileBlock}__back-btn-header`)}>
                    <button
                        className={classnames(`${searchBarFilterMobileBlock}__back-btn`)}
                        onClick={() => {
                            if (!isLoading && activeHighLevelFilterGroup == null)
                                toggleFilter(index);
                            else if (!isLoading && activeHighLevelFilterGroup != null)
                                toggleActiveFilterGroup(activeHighLevelFilterGroup);
                        }}
                    >
                        <div
                            className={classnames(
                                `${searchBarFilterMobileBlock}__back-btn-chevron`,
                                {
                                    [`${searchBarFilterMobileBlock}__back-btn-chevron--disabled`]: isLoading,
                                }
                            )}
                        >
                            <ChevronLeft />
                        </div>
                        <div
                            className={classnames(`${searchBarFilterMobileBlock}__back-btn-title`, {
                                [`${searchBarFilterMobileBlock}__back-btn-title--disabled`]: isLoading,
                            })}
                        >
                            {activeHighLevelFilterGroup == null
                                ? title
                                : activeHighLevelFilterGroupTitle}
                        </div>
                    </button>
                    <div className={`${searchBarFilterMobileBlock}__divider`} />
                </div>
                {includeHighLevelSelectAll && (
                    <div
                        className={classnames(`${searchBarFilterMobileBlock}__sd-header-container`)}
                    >
                        <button
                            className={classnames(`${searchBarFilterMobileBlock}__select-all`, {
                                [`${searchBarFilterMobileBlock}__select-all--disabled`]: isLoading,
                            })}
                            onClick={() => {
                                if (!isLoading) toggleAdvancedFilter(identifier, false);
                            }}
                        >
                            {t('SelectAll')}
                        </button>
                        <div className={`${searchBarFilterMobileBlock}__divider`} />
                    </div>
                )}
                <ul
                    className={classnames(`${searchBarFilterMobileBlock}__hl-list`, {
                        [`${searchBarFilterMobileBlock}__hl-list--ad-open`]:
                            activeFilter !== null &&
                            isAdvancedFiltersMobileOpen &&
                            includeHighLevelSelectAll,
                    })}
                >
                    {highLevelFilters?.map((highLevelFilter, index) => {
                        if (
                            !highLevelFilter?.lowLevelFilters.length &&
                            highLevelFilter?.hasResults
                        ) {
                            return (
                                <CheckboxWrapper
                                    checked={highLevelFilter.checked}
                                    change={checkAdvancedFilter}
                                    key={index}
                                    id={highLevelFilter.identifier}
                                    pId={identifier}
                                    label={highLevelFilter?.title}
                                    disabledState={isLoading}
                                    setDisabledState={setIsLoading}
                                />
                            );
                        }
                        if (
                            !highLevelFilter?.lowLevelFilters.filter(
                                (dropdownOption) => dropdownOption?.hasResults
                            ).length
                        )
                            return;
                        return (
                            <div
                                key={index}
                                className={classnames(`${searchBarFilterMobileBlock}__hl-item`)}
                            >
                                <button
                                    className={classnames(`${searchBarFilterBlock}__button`, {
                                        [`${searchBarFilterBlock}__button--active`]:
                                            index === activeHighLevelFilterGroup && !isLoading,
                                        [`${searchBarFilterBlock}__button--disabled`]: isLoading,
                                    })}
                                    onClick={() => {
                                        if (!isLoading) {
                                            toggleActiveFilterGroup(index);
                                            toggleActiveFilterGroupTitle(highLevelFilter?.title);
                                        }
                                    }}
                                >
                                    <div
                                        className={classnames(
                                            `${searchBarFilterBlock}__title`,
                                            `${searchBarFilterBlock}__title--mobile`,
                                            {
                                                [`${searchBarFilterBlock}__title--mobile--disabled`]: isLoading,
                                            }
                                        )}
                                    >
                                        {highLevelFilter?.title}
                                    </div>
                                    <div
                                        className={classnames(
                                            `${searchBarFilterBlock}__mobile-chevron`,
                                            {
                                                [`${searchBarFilterBlock}__mobile-chevron--disabled`]: isLoading,
                                            }
                                        )}
                                    >
                                        <ChevronRight />
                                    </div>
                                </button>
                                <div
                                    className={classnames(
                                        `${searchBarFilterMobileBlock}__secondary-menu`,
                                        {
                                            [`${searchBarFilterMobileBlock}__secondary-menu--active`]:
                                                index === activeHighLevelFilterGroup,
                                        }
                                    )}
                                >
                                    <div
                                        className={classnames(
                                            `${searchBarFilterMobileBlock}__sd-header-container`
                                        )}
                                    >
                                        <button
                                            className={classnames(
                                                `${searchBarFilterMobileBlock}__select-all`,
                                                {
                                                    [`${searchBarFilterMobileBlock}__select-all--disabled`]: isLoading,
                                                }
                                            )}
                                            onClick={() => {
                                                if (!isLoading)
                                                    isAdFilter
                                                        ? toggleAdvancedHighLevelFilter(
                                                              highLevelFilter?.identifier,
                                                              identifier,
                                                              false
                                                          )
                                                        : checkHighLevelFilter(
                                                              highLevelFilter?.identifier,
                                                              identifier,
                                                              false
                                                          );
                                            }}
                                        >
                                            {t('SelectAll')}
                                        </button>
                                        <div className={`${searchBarFilterMobileBlock}__divider`} />
                                    </div>
                                    <ul className={`${searchBarFilterMobileBlock}__checkbox-list`}>
                                        {highLevelFilter?.lowLevelFilters?.map(
                                            (lowLevelFilter, index) => {
                                                if (!lowLevelFilter.hasResults) return;

                                                return (
                                                    <CheckboxWrapper
                                                        checked={lowLevelFilter.checked}
                                                        change={
                                                            isAdFilter
                                                                ? checkAdvancedFilter
                                                                : checkLowLevelFilter
                                                        }
                                                        key={index}
                                                        id={lowLevelFilter.identifier}
                                                        highLevelId={highLevelFilter?.identifier}
                                                        pId={identifier}
                                                        label={lowLevelFilter?.title}
                                                        disabledState={isLoading}
                                                        setDisabledState={setIsLoading}
                                                        isDropdownFilter={!isAdFilter}
                                                    />
                                                );
                                            }
                                        )}
                                    </ul>
                                    {activeHighLevelFilterGroup === index && (
                                        <div
                                            className={classnames(
                                                `${searchBarFilterMobileBlock}__mb-footer`
                                            )}
                                        >
                                            <button
                                                className={classnames(
                                                    `${searchBarFilterMobileBlock}__mb-clear-button`,
                                                    {
                                                        [`${searchBarFilterMobileBlock}__mb-clear-button--disabled`]: isLoading,
                                                    }
                                                )}
                                                onClick={() => {
                                                    if (!isLoading)
                                                        isAdFilter
                                                            ? toggleAdvancedHighLevelFilter(
                                                                  highLevelFilter?.identifier,
                                                                  identifier,
                                                                  true
                                                              )
                                                            : checkHighLevelFilter(
                                                                  highLevelFilter?.identifier,
                                                                  identifier,
                                                                  true
                                                              );
                                                }}
                                            >
                                                {t('ClearFilter')}
                                            </button>
                                            <div
                                                className={classnames(
                                                    `${searchBarFilterMobileBlock}__mb-apply-button`,
                                                    {
                                                        [`${searchBarFilterMobileBlock}__mb-apply-button--disabled`]: isLoading,
                                                    }
                                                )}
                                            >
                                                <Button
                                                    className="zn-main-nav-bar--hide-mobile"
                                                    type="submit"
                                                    onClick={() => {
                                                        if (!isLoading) {
                                                            setIsMobileFilterViewOpen(false);
                                                            hideMobileFilters();
                                                        }
                                                    }}
                                                >
                                                    {t('ShowResults')}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </ul>
                {activeFilter === index && activeHighLevelFilterGroup == null && (
                    <div className={classnames(`${searchBarFilterMobileBlock}__mb-footer`)}>
                        <button
                            className={classnames(
                                `${searchBarFilterMobileBlock}__mb-clear-button`,
                                {
                                    [`${searchBarFilterMobileBlock}__mb-clear-button--disabled`]: isLoading,
                                }
                            )}
                            onClick={() => {
                                if (!isLoading) {
                                    isAdFilter
                                        ? toggleAdvancedFilter(identifier, true)
                                        : clearCategory(identifier);
                                }
                            }}
                        >
                            {t('ClearFilter')}
                        </button>
                        <div
                            className={classnames(
                                `${searchBarFilterMobileBlock}__mb-apply-button`,
                                {
                                    [`${searchBarFilterMobileBlock}__mb-apply-button--disabled`]: isLoading,
                                }
                            )}
                        >
                            <Button
                                className="zn-main-nav-bar--hide-mobile"
                                type="submit"
                                onClick={() => {
                                    if (!isLoading) {
                                        setIsMobileFilterViewOpen(false);
                                        hideMobileFilters();
                                    }
                                }}
                            >
                                {t('ShowResults')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectMapSearchBarFilterMobile;
