import React, { useEffect } from 'react';
import classnames from 'classnames';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import './statistics.scss';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { ThemeEnum, ButtonVariantEnum } from '../../utils/enums.js';

const options = {
    threshold: 0.4,
};

const setInteractionObserver = () => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('fadeIn');
            }
        });
    }, options);

    let content = document.getElementsByClassName('zn-statistics__right-col');
    let sections = content;
    Array.from(sections).forEach((section) => {
        observer.observe(section);
    });
};

const Statistics = ({ fields, params, sitecoreContext }) => {
    const {
        SectionTitle,
        Subtitle1,
        Paragraph1,
        CTAURL1,
        Subtitle2,
        Paragraph2,
        CTAURL2,
        Statistic1,
        StatisticSubtitle1,
        Statistic2,
        StatisticSubtitle2,
        Statistic3,
        StatisticSubtitle3,
    } = fields;
    const { EnableAnimation } = params;
    const statBlock = 'zn-statistics';

    const isEditing = sitecoreContext.pageEditing;
    const animationEnabled = EnableAnimation === '1';

    useEffect(() => {
        if (animationEnabled && !isEditing) {
            setInteractionObserver();
        }
    }, []);

    return (
        <div className={classnames(`${statBlock}`)}>
            <div className={classnames(`${statBlock}__left-col`)}>
                <div className={classnames(`${statBlock}__title`)}>
                    <RichText field={SectionTitle} />
                </div>
                <div className={classnames(`${statBlock}__subtitle-container`)}>
                    <div className={classnames(`${statBlock}__subtitle-container`)}>
                        {((Subtitle1?.value && Paragraph1?.value) || isEditing) && (
                            <div className={classnames(`${statBlock}__subtitle`)}>
                                <div className={classnames(`${statBlock}__subtitle-header`)}>
                                    <RichText field={Subtitle1} />
                                </div>
                                <div className={classnames(`${statBlock}__subtitle-description`)}>
                                    <RichText field={Paragraph1} />
                                </div>
                                {CTAURL1?.value?.text && (
                                    <div className={classnames(`${statBlock}__subtitle-link`)}>
                                        <ButtonLink
                                            fields={{
                                                LinkURL: {
                                                    value: {
                                                        text: CTAURL1?.value?.text,
                                                        href: CTAURL1?.value?.href,
                                                        title: CTAURL1?.value?.title,
                                                        target: CTAURL1?.value?.target,
                                                    },
                                                },
                                            }}
                                            params={{
                                                Variant: ButtonVariantEnum.tertiary,
                                                Theme: ThemeEnum.light,
                                            }}
                                        ></ButtonLink>
                                    </div>
                                )}
                            </div>
                        )}
                        {((Subtitle2?.value && Paragraph2?.value) || isEditing) && (
                            <div className={classnames(`${statBlock}__subtitle`)}>
                                <div className={classnames(`${statBlock}__subtitle-header`)}>
                                    <RichText field={Subtitle2} />
                                </div>
                                <div className={classnames(`${statBlock}__subtitle-description`)}>
                                    <RichText field={Paragraph2} />
                                </div>
                                {CTAURL2?.value?.text && (
                                    <div className={classnames(`${statBlock}__subtitle-link`)}>
                                        <ButtonLink
                                            fields={{
                                                LinkURL: {
                                                    value: {
                                                        text: CTAURL2?.value?.text,
                                                        href: CTAURL2?.value?.href,
                                                        title: CTAURL2?.value?.title,
                                                        target: CTAURL2?.value?.target,
                                                    },
                                                },
                                            }}
                                            params={{
                                                Variant: ButtonVariantEnum.tertiary,
                                                Theme: ThemeEnum.light,
                                            }}
                                        ></ButtonLink>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={classnames(`${statBlock}__right-col`)}>
                <div className={classnames(`${statBlock}__right-col-container`)}>
                    <div
                        className={classnames(
                            `${statBlock}__statistic`,
                            `${statBlock}__statistic--primary`
                        )}
                    >
                        <div
                            className={classnames(
                                `${statBlock}__statistic-value`,
                                `${statBlock}__statistic-value--primary`,
                                {
                                    [`${statBlock}__statistic-value--animation`]:
                                        animationEnabled && !isEditing,
                                },
                                {
                                    [`${statBlock}__statistic-value--sc-editing`]: isEditing,
                                }
                            )}
                        >
                            <Text field={Statistic1} />
                        </div>
                        <div
                            className={classnames(
                                `${statBlock}__statistic-value-label`,
                                `${statBlock}__statistic-value-label--primary`,
                                {
                                    [`${statBlock}__statistic-value-label--animation`]:
                                        animationEnabled && !isEditing,
                                }
                            )}
                        >
                            <RichText field={StatisticSubtitle1} />
                        </div>
                    </div>
                    {(Statistic2?.value || Statistic3?.value || isEditing) && (
                        <div className={classnames(`${statBlock}__statistic-container`)}>
                            {(Statistic2?.value || isEditing) && (
                                <div
                                    className={classnames(
                                        `${statBlock}__statistic`,
                                        `${statBlock}__statistic--secondary`
                                    )}
                                >
                                    <div
                                        className={classnames(
                                            `${statBlock}__statistic-value`,
                                            `${statBlock}__statistic-value--secondary`,
                                            {
                                                [`${statBlock}__statistic-value--animation`]:
                                                    animationEnabled && !isEditing,
                                            },
                                            {
                                                [`${statBlock}__statistic-value--sc-editing`]: isEditing,
                                            },
                                            {
                                                [`${statBlock}__statistic-value--secondary-small`]:
                                                    Statistic2?.value && Statistic3?.value,
                                            }
                                        )}
                                    >
                                        <Text field={Statistic2} />
                                    </div>
                                    <div
                                        className={classnames(
                                            `${statBlock}__statistic-value-label`,
                                            {
                                                [`${statBlock}__statistic-value-label--animation`]:
                                                    animationEnabled && !isEditing,
                                            }
                                        )}
                                    >
                                        <RichText field={StatisticSubtitle2} />
                                    </div>
                                </div>
                            )}
                            {((Statistic3?.value && Statistic2?.value) || isEditing) && (
                                <div
                                    className={classnames(`${statBlock}__statistic-divider`)}
                                ></div>
                            )}
                            {(Statistic3?.value || isEditing) && (
                                <div
                                    className={classnames(
                                        `${statBlock}__statistic`,
                                        `${statBlock}__statistic--secondary`
                                    )}
                                >
                                    <div
                                        className={classnames(
                                            `${statBlock}__statistic-value`,
                                            `${statBlock}__statistic-value--secondary`,
                                            {
                                                [`${statBlock}__statistic-value--animation`]:
                                                    animationEnabled && !isEditing,
                                            },
                                            {
                                                [`${statBlock}__statistic-value--sc-editing`]: isEditing,
                                            },
                                            {
                                                [`${statBlock}__statistic-value--secondary-small`]:
                                                    Statistic2?.value && Statistic3?.value,
                                            }
                                        )}
                                    >
                                        <Text field={Statistic3} />
                                    </div>
                                    <div
                                        className={classnames(
                                            `${statBlock}__statistic-value-label`,
                                            {
                                                [`${statBlock}__statistic-value-label--animation`]:
                                                    animationEnabled && !isEditing,
                                            }
                                        )}
                                    >
                                        <RichText field={StatisticSubtitle3} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {((Statistic3?.value && !Statistic2?.value) ||
                        (!Statistic3?.value && Statistic2?.value)) && (
                        <div
                            className={classnames(
                                `${statBlock}__statistic-divider`,
                                `${statBlock}__statistic-divider--horizontal`
                            )}
                        ></div>
                    )}
                </div>
            </div>
        </div>
    );
};

const StatisticsSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.params])(Statistics)
);

export const StatisticsStorybook = forStorybookV2(StatisticsSitecore);

export default StatisticsSitecore;
