import vcard from 'vcard-generator';

export const getVCardUrl = (
    FirstName,
    MiddleName,
    LastName,
    Position,
    PrimaryPhoneNumber,
    SecondaryPhoneNumber,
    PrimaryPhoneLabel,
    SecondaryPhoneLabel,
    Email
) => {
    const contactCardJSON = {};

    // name
    if (FirstName?.value || LastName?.value || MiddleName?.value) {
        const name = {};
        FirstName?.value && (name.givenName = FirstName?.value);
        LastName?.value && (name.familyName = LastName?.value);
        MiddleName?.value && (name.middleName = MiddleName?.value);
        contactCardJSON.name = name;
    }

    // work
    const works = [
        {
            organization: 'Torys LLP',
        },
    ];
    if (Position?.value) {
        works[0].title = Position?.value;
    }
    contactCardJSON.works = works;

    // phone
    const primaryNumber = PrimaryPhoneNumber?.value;
    const secondaryNumber = SecondaryPhoneNumber?.value;

    if (primaryNumber || secondaryNumber) {
        const phones = [];
        if (primaryNumber) {
            const primaryNumberJSON = {};
            if (PrimaryPhoneLabel?.value) {
                primaryNumberJSON.type = PrimaryPhoneLabel?.value;
            }
            primaryNumberJSON.text = '' + primaryNumber;
            phones.push(primaryNumberJSON);
        }

        if (secondaryNumber) {
            const secondaryNumberJSON = {};
            if (SecondaryPhoneLabel?.value) {
                secondaryNumberJSON.type = SecondaryPhoneLabel?.value;
            }
            secondaryNumberJSON.text = '' + secondaryNumber;
            phones.push(secondaryNumberJSON);
        }
        contactCardJSON.phones = phones;
    }

    // email
    if (Email?.value) {
        const emails = [
            {
                type: 'work', // WORK is the type for all emails on the legacy Torys site vCards
                text: Email?.value,
            },
        ];
        contactCardJSON.emails = emails;
    }

    const contactCard = vcard.generate(contactCardJSON);
    return 'data:text/vcard;charset=utf-8,' + encodeURIComponent(contactCard);
};
