import React, { useEffect, useState } from 'react';
import { Button } from '../Button/Button.js';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import Link from '../Link/Link.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { CloseIcon, HamburguerMenu } from '../../assets/common.js';
import Text from '../Text';
import { ButtonVariantEnum, LinkTargetEnum } from '../../utils/enums';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

const NavBarPremium = ({
    quarter,
    title,
    theme,
    qNavRef,
    subscribeButton,
    toggleMenuOpen,
    isMenuOpen,
    pageEditing,
    quarterUrl,
    t,
    isMenuOpenOverlapping,
    closeMenuWithoutAnimation,
    openStatePadding,
}) => {
    const navBlock = 'zn-quarterly-nav-bar-menu';
    const [pdfView, setPdfView] = useState(false);
    const TitleLink = quarterUrl ? Link : React.Fragment;
    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    return (
        !pdfView && (
            <div
                ref={qNavRef}
                className={classNames(`${navBlock}--padding-container`, {
                    [`${navBlock}--padding-container--open`]: isMenuOpen,
                    [`in-main-menu`]: isMenuOpenOverlapping,
                    [`no-animation-close`]: closeMenuWithoutAnimation,
                })}
                style={{ paddingRight: isMenuOpen ? `${openStatePadding}px` : '' }}
            >
                <nav
                    className={classNames(navBlock, {
                        [`${navBlock}--open`]: isMenuOpen,
                        [`in-main-menu`]: isMenuOpenOverlapping,
                        [`no-animation-close`]: closeMenuWithoutAnimation,
                    })}
                >
                    <div className={`${navBlock}__container`}>
                        <div className={`${navBlock}__content`}>
                            <span className={`${navBlock}__hamburger`}>
                                <Button
                                    className="zn-button-simple--border"
                                    as="button"
                                    variant="icon"
                                    onClick={toggleMenuOpen}
                                >
                                    {isMenuOpen ? <CloseIcon /> : <HamburguerMenu theme={theme} />}
                                    <span className="sr-only">{t('OpenArticleNavigation')}</span>
                                </Button>
                            </span>
                            <TitleLink
                                LinkURL={{
                                    value: quarterUrl,
                                }}
                                LinkAltText={{
                                    value: `${quarter} - ${title}`,
                                }}
                                LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                onClick={() => {
                                    isMenuOpen && toggleMenuOpen();
                                }}
                            >
                                <Text
                                    type="bodyMd"
                                    useDefaultMargin={false}
                                    className={`${navBlock}__title ${navBlock}__title--division`}
                                    field={quarter}
                                />
                                <Text
                                    type="bodyMd"
                                    useDefaultMargin={false}
                                    className={`${navBlock}__sub-title`}
                                    field={title}
                                />
                            </TitleLink>
                        </div>
                        {(subscribeButton?.value?.text || pageEditing) && (
                            <div className={`${navBlock}__subscribe`}>
                                <ButtonLink
                                    params={{
                                        Theme: theme,
                                        Variant: ButtonVariantEnum.primary.toLowerCase(),
                                    }}
                                    fields={{
                                        LinkURL: subscribeButton,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </nav>
            </div>
        )
    );
};

export default withTranslation()(withSitecoreContext()(NavBarPremium));
