import React from 'react';
import Text from '../Text';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import { ThemeEnum, ButtonVariantEnum } from '../../utils/enums.js';
import '../Footer/footer.scss';

const FooterTeaser = ({ title, description, buttonLink, pageEditing }) => {
    return (
        <div className="zn-footer__subscribe-teaser">
            <Text type="h4" field={title} />
            {(buttonLink?.value?.text || pageEditing) && (
                <ButtonLink
                    fields={{
                        LinkURL: {
                            value: {
                                text: buttonLink?.value?.text,
                                href: buttonLink?.value?.href,
                                title: buttonLink?.value?.title,
                                target: buttonLink?.value?.target,
                            },
                        },
                    }}
                    params={{
                        Variant: ButtonVariantEnum.primary,
                        Theme: ThemeEnum.light,
                    }}
                />
            )}
            <Text
                className="zn-footer__subscribe-subtext"
                type="body"
                useDefaultMargin={false}
                field={description}
            />
        </div>
    );
};

export default FooterTeaser;
