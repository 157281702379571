import React from 'react';
import classnames from 'classnames';
import { Checkmark } from '../../assets/common.js';
import './checkbox.scss';
import Checkbox from './Checkbox.jsx';

const CheckboxWrapper = ({
    checked,
    label,
    change,
    id,
    highLevelId,
    pId,
    disabledState,
    setDisabledState,
    isDropdownFilter,
    onClick,
    notTabbable,
}) => {
    return (
        <div
            className={classnames(`zn-checkbox`, {
                [`checked`]: checked,
                [`zn-checkbox--disabled`]: disabledState,
            })}
            tabIndex={notTabbable ? -1 : 0}
            onKeyPress={(event) => {
                if (event.key === 'Enter') {
                    if (isDropdownFilter) change(id, highLevelId, pId);
                    else change(id, pId);
                    if (onClick) onClick();
                }
            }}
        >
            <label className={classnames(`zn-checkbox__wrapper`)}>
                <Checkbox
                    checked={checked}
                    onChange={() => {
                        if (isDropdownFilter) change(id, highLevelId, pId);
                        else change(id, pId);
                        if (onClick) onClick();
                        setDisabledState(true);
                    }}
                    change={change}
                    id={id}
                    pId={pId}
                    disabledState={disabledState}
                    setDisabledState={setDisabledState}
                />
                <span
                    className={classnames(`zn-checkbox__label`, {
                        [`zn-checkbox__label--disabled`]: disabledState,
                    })}
                >
                    {label}
                </span>
                {checked && (
                    <div className={classnames(`zn-checkbox__mobile-icon`)}>
                        <Checkmark />
                    </div>
                )}
            </label>
        </div>
    );
};

export default CheckboxWrapper;
