import React from 'react';
import Link from '../Link/Link.jsx';
import Text from '../Text';
import { LinkTargetEnum, ThemeEnum, LinkSubVariantsEnum } from '../../utils/enums';
import './colored-list.scss';

const ColoredList = ({ isLink, list, color, title, theme, onClick }) => {
    return (
        <div className="zn-colored-list">
            <Text type="h4" className="zn-colored-list__title" field={title} />
            <ul className={`zn-colored-list__content zn-colored-list__content--${color}`}>
                {Array.isArray(list) &&
                    list.map((item, index) => {
                        if (item?.title?.value) {
                            return (
                                <li key={index} className="zn-colored-list__item">
                                    {isLink ? (
                                        <Link
                                            Theme={{ value: ThemeEnum[theme] }}
                                            Variant={{ value: '' }}
                                            SubVariant={{
                                                value: [
                                                    LinkSubVariantsEnum.noMargin,
                                                    LinkSubVariantsEnum.noPading,
                                                    LinkSubVariantsEnum.small,
                                                ],
                                            }}
                                            LinkText={item?.title}
                                            LinkURL={{ value: item.url }}
                                            LinkAltText={{ value: item?.title?.value }}
                                            LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                            onClick={onClick}
                                        />
                                    ) : (
                                        <Text
                                            type="body"
                                            useDefaultMargin={false}
                                            field={item?.title}
                                        />
                                    )}
                                </li>
                            );
                        }
                    })}
            </ul>
        </div>
    );
};

export default ColoredList;
