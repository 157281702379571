import React, { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import {
    startOfWeek as getStartOfWeek,
    endOfWeek as getEndOfWeek,
    subWeeks as subtractWeeks,
    startOfMonth as getStartOfMonth,
    endOfMonth as getEndOfMonth,
    subMonths as subtractMonths,
    subDays as subtractDays,
} from 'date-fns';
import 'react-day-picker/dist/style.css';
import './search-bar-range-datepicker.scss';
import { ChevronDown, ChevronUp, ChevronRight, ChevronLeft } from '../../assets/LinkIcons.js';
import classNames from 'classnames';

import { frCA, enCA } from 'date-fns/locale';
const now = new Date();
const searchBarBase = 'zn-search-bar-filter';

const RangeDatePicker = (props) => {
    const { date, onChange, disabled, t, sitecoreContext } = props;

    const language = sitecoreContext?.language;

    const locale = language === 'en' ? enCA : language === 'fr-CA' ? frCA : enCA;
    const formatDate = (date) => {
        return date.toLocaleDateString(language === 'fr-CA' ? 'fr-CA' : 'en-CA', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
        });
    };

    const [dateRange, setDateRange] = useState(date);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const datePickerRef = useRef(null);

    useEffect(() => {
        setDateRange(date);
    }, [date]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsDatePickerOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const isApplyDisabled = dateRange?.from === date?.from && dateRange?.to === date?.to;

    const handleApply = () => {
        onChange(dateRange);
        setIsDatePickerOpen(false);
    };

    const handleDateRangeChange = (range) => {
        setDateRange(range ? range : { from: null, to: null });
    };

    return (
        <div
            ref={datePickerRef}
            className={classNames(searchBarBase, `${searchBarBase}-range-datepicker-wrapper`)}
        >
            <button
                onClick={() => {
                    if (!disabled) setIsDatePickerOpen(!isDatePickerOpen);
                }}
                className={classNames(`${searchBarBase}__button`, {
                    [`${searchBarBase}__button--disabled`]: disabled,
                })}
            >
                <span className={`${searchBarBase}__title ${searchBarBase}__title--desktop`}>
                    {t('From')} {dateRange.from && ': ' + formatDate(dateRange.from)} - {t('To')}
                    {dateRange.to && ': ' + formatDate(dateRange.to)}
                </span>
                <span className={`${searchBarBase}__title ${searchBarBase}__title--mobile`}>
                    {t('From')} - {t('To')}
                </span>

                <div className={`${searchBarBase}__desktop-chevron`}>
                    {isDatePickerOpen ? <ChevronUp /> : <ChevronDown />}
                </div>
                <div className={`${searchBarBase}__mobile-chevron`}>
                    <ChevronRight />
                </div>
            </button>
            <div className={`${searchBarBase}__mobile-selected-filters`}>
                {!dateRange.from && !dateRange.to && 'Any dates'}
                {dateRange.from && formatDate(dateRange.from) + ' - '}
                {dateRange.to && formatDate(dateRange.to)}
            </div>

            {isDatePickerOpen && (
                <div className={`${searchBarBase}-range-datepicker-popup`}>
                    <button
                        className={`${searchBarBase}__mobile-dropdown-back-button`}
                        onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                    >
                        <div className={`${searchBarBase}__mobile-chevron`}>
                            <ChevronLeft />
                        </div>

                        <div className={`${searchBarBase}__mobile-dropdown-title`}>From - to </div>
                    </button>
                    <div className={`${searchBarBase}-range-datepicker-popup-container`}>
                        <div className="current-date">
                            {dateRange.from && (
                                <div className={'date'}>{formatDate(dateRange.from)}</div>
                            )}
                            {dateRange.to && (
                                <div className={'date'}>{formatDate(dateRange.to)}</div>
                            )}
                        </div>

                        <DayPicker
                            mode="range"
                            selected={dateRange}
                            onSelect={handleDateRangeChange}
                            numberOfMonths={2}
                            captionLayout="dropdown-buttons"
                            fromYear={1900}
                            toYear={now.getFullYear()}
                            locale={locale}
                        />
                    </div>

                    <div
                        className={classNames(`${searchBarBase}-range-datepicker-popup__buttons`, {
                            [`${searchBarBase}-range-datepicker-popup__buttons__disabled`]: disabled,
                        })}
                    >
                        <button
                            onClick={() => {
                                handleDateRangeChange({ from: now, to: now });
                            }}
                        >
                            {t('Today')}
                        </button>
                        <button
                            onClick={() =>
                                handleDateRangeChange({
                                    from: subtractDays(now, 1),
                                    to: subtractDays(now, 1),
                                })
                            }
                        >
                            {t('Yesterday')}
                        </button>

                        <button
                            onClick={() =>
                                handleDateRangeChange({
                                    from: getStartOfWeek(now),
                                    to: getEndOfWeek(now),
                                })
                            }
                        >
                            {t('ThisWeek')}
                        </button>
                        <button
                            onClick={() => {
                                const lastWeek = subtractWeeks(now, 1);
                                handleDateRangeChange({
                                    from: getStartOfWeek(lastWeek),
                                    to: getEndOfWeek(lastWeek),
                                });
                            }}
                        >
                            {t('LastWeek')}
                        </button>

                        <button
                            onClick={() =>
                                handleDateRangeChange({
                                    from: getStartOfMonth(now),
                                    to: getEndOfMonth(now),
                                })
                            }
                        >
                            {t('ThisMonth')}
                        </button>
                        <button
                            onClick={() => {
                                const lastMonth = subtractMonths(now, 1);
                                handleDateRangeChange({
                                    from: getStartOfMonth(lastMonth),
                                    to: getEndOfMonth(lastMonth),
                                });
                            }}
                        >
                            {t('LastMonth')}
                        </button>
                        <button
                            onClick={() => {
                                handleDateRangeChange({ from: null, to: null });
                                onChange({ from: null, to: null });
                            }}
                        >
                            {t('Reset')}
                        </button>
                        <button
                            onClick={handleApply}
                            disabled={isApplyDisabled}
                            className={classNames(`${searchBarBase}__apply`, {
                                [`${searchBarBase}__apply-button--disabled`]: isApplyDisabled,
                            })}
                        >
                            {t('Apply')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withSitecoreContext()(withTranslation()(RangeDatePicker));
