import React, { useEffect } from 'react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import { ThemeEnum, HighlightedBlockEnum } from '../../utils/enums';
import { RichText as RichTextJss, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import './rich-text.scss';

const setTableCellWidths = () => {
    const cellHeight = 250;
    const smallScreenSize = 767;

    let tablesArray = document.getElementsByTagName('table');
    for (let i = 0; i < tablesArray.length; i++) {
        let body = tablesArray[i]?.getElementsByTagName('tbody');
        let rows = Array.isArray(body) ? body[0]?.getElementsByTagName('tr') : null;
        if (!rows) {
            return;
        }
        let totalColumns = rows[1]?.children?.length;
        let maxHeight = 0;

        for (let j = 0; j < rows.length; j++) {
            if (rows[j].offsetHeight > maxHeight) {
                maxHeight = rows[j].offsetHeight;
            }
        }
        if (
            (totalColumns < 3 && maxHeight < cellHeight) ||
            rows[0].offsetWidth + 1 < rows[0].parentElement.offsetWidth
        ) {
            tablesArray[i].classList.add('mobile');
            body[0].classList.add('mobile');
            let cells = body[0].getElementsByTagName('td');

            for (let k = 0; k < cells.length; k++) {
                cells[k].classList.add('mobile');
            }
        } else {
            body[0].style.minWidth = `100%`;

            let cells = body[0].getElementsByTagName('td');
            let colTotal = smallScreenSize / totalColumns;

            for (let k = 0; k < cells.length; k++) {
                cells[k].style.minWidth = `${colTotal}px`;
            }
        }
    }
};

const RichText = ({ fields, params, sitecoreContext }) => {
    const { TextInput } = fields;
    const { Theme, HighlightedColor, RichTextSize, CustomRichTextClass } = params;
    const richTextBlock = 'zn-rich-text';

    const highlightColour =
        HighlightedColor?.toLowerCase() || HighlightedBlockEnum.none.toLowerCase();
    const hasHighlight = highlightColour !== HighlightedBlockEnum.none.toLowerCase();
    const theme = hasHighlight
        ? ThemeEnum.light.toLowerCase()
        : Theme?.toLowerCase() || ThemeEnum.light.toLowerCase();
    const isEditing = sitecoreContext.pageEditing;

    useEffect(() => {
        setTableCellWidths();
    }, []);

    return (
        (TextInput?.value || isEditing) && (
            <div
                className={classnames(`${richTextBlock}__wrapper`, {
                    [`${richTextBlock}__wrapper--highlight`]: hasHighlight,
                })}
            >
                <RichTextJss
                    className={classnames(
                        richTextBlock,
                        [`${richTextBlock}--${theme}`],
                        {
                            [`${richTextBlock}--highlight`]: hasHighlight,
                            [`zn-${CustomRichTextClass}`]: CustomRichTextClass,
                        },
                        {
                            [`${richTextBlock}--${RichTextSize?.toLowerCase()}`]: highlightColour,
                        },
                        {
                            [`${richTextBlock}--highlight-${highlightColour}`]: hasHighlight,
                        }
                    )}
                    field={TextInput}
                />
            </div>
        )
    );
};

const RichTextSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(RichText)
);
export const RichTextStorybook = forStorybookV2(RichTextSitecore);

export default RichTextSitecore;
