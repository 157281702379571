import React from 'react';
import classnames from 'classnames';
import './quarterly-introduction-animation.scss';
import '../QuarterlyIntroduction/quarterly-introduction.scss';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const qLIntroAnimationBlock = 'zn-quarterly-introduction-animation';
const qLIntroBlock = 'zn-quarterly-introduction';

export const QuarterlyIntroductionAnimation = ({ fields, sectionLinks, sitecoreContext }) => {
    const isEditing = sitecoreContext?.pageEditing;

    const { IntroductionParagraph, SectionsLabel } = fields;
    return (
        <div
            className={classnames(qLIntroAnimationBlock, {
                [`${qLIntroAnimationBlock}--editing`]: isEditing,
            })}
        >
            <div className={classnames(`${qLIntroBlock}__content`)}>
                <div className={classnames(`${qLIntroBlock}__paragraph`)}>
                    {IntroductionParagraph?.value}
                </div>
                <div className={classnames(`${qLIntroBlock}__sections`)}>
                    <div className={classnames(`${qLIntroBlock}__sections-title`)}>
                        {SectionsLabel?.value}
                    </div>
                    <div className={classnames(`${qLIntroBlock}__accent`)}></div>
                    <ul className={classnames(`${qLIntroBlock}__list`)}>{sectionLinks}</ul>
                </div>
            </div>
        </div>
    );
};
export default withSitecoreContext()(
    withPlaceholder('sectionLinks')(QuarterlyIntroductionAnimation)
);
