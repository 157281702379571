import React, { useState, useRef, useEffect } from 'react';
import ColoredList from '../ColoredList/ColoredList.jsx';
import Link from '../Link/Link.jsx';
import HorizontalRule from '../HorizontalRule/HorizontalRule.jsx';
import QuarterlyNavigationNavBar from './QuarterlyNavigationNavBar.jsx';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks.jsx';
import getScrollbarWidth from '../../utils/getScrollbarWidth.js';
import {
    ThemeEnum,
    ButtonVariantEnum,
    LinkTargetEnum,
    LinkVariantEnum,
    HorizontalRuleColorEnum,
} from '../../utils/enums';
import './quarterly-navigation.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';

const TemplatesEnum = {
    quarterly: 'Quarterly',
    quarterlyPublication: 'Quarterly Publication',
    premiumArticle: 'Premium Article',
    quarterlyReportLanding: 'Quarterly Report Landing',
};

const colors = ['teal', 'orange', 'blue', 'navy'];

const getChaptersFormPublication = (TableOfContentsArticles) => {
    if (!Array.isArray(TableOfContentsArticles)) {
        return { chapters: [], name: '' };
    }
    const quarterlyItem = TableOfContentsArticles.find(
        (item) =>
            item?.template?.name === TemplatesEnum.quarterly ||
            item?.template?.name === TemplatesEnum.quarterlyReportLanding
    );

    return {
        Chapters: quarterlyItem?.Chapters,
        displayName: quarterlyItem?.displayName,
        IssueTitle: quarterlyItem?.IssueTitle,
        Title: quarterlyItem?.Title,
        url: quarterlyItem?.url,
    };
};

const QuarterlyNavigation = ({ sitecoreContext, fields, params }) => {
    const navBlock = 'zn-quarterly-nav';
    const theme = ThemeEnum.light.toLowerCase();

    if (!fields?.data?.datasource || !fields?.data?.contextItem) {
        return <nav className={navBlock}></nav>;
    }

    if (typeof document === 'undefined') {
        global.document = null;
    }

    const menuOpenClass = 'nav-open';
    const slideMenuOpenClass = 'slide-in';
    const slideMenuCloseClass = 'slide-out';
    const mainContent = document?.querySelector('body');

    if (!sitecoreContext?.pageEditing) {
        mainContent?.classList?.add('body-nav');
    }

    const datasource = fields?.data?.datasource;
    const contextItem = fields?.data?.contextItem;
    const socialMedia = datasource?.socialMediaLinks?.targetItem;

    let chapters;
    let quarterName;
    let issueTitle;
    let quarterUrl;

    if (
        contextItem?.template?.name === TemplatesEnum.quarterly ||
        contextItem?.template?.name === TemplatesEnum.quarterlyReportLanding
    ) {
        chapters = contextItem?.Chapters;
        issueTitle = contextItem.Title?.value
            ? `${contextItem.Title?.value}: ${contextItem?.IssueTitle?.value}`
            : contextItem?.IssueTitle?.value;
        quarterName = contextItem?.displayName;
    } else {
        const quarterlyInfo = getChaptersFormPublication(
            contextItem?.TableOfContentsArticles?.targetItems
        );
        chapters = quarterlyInfo?.Chapters;
        issueTitle = quarterlyInfo?.Title?.value
            ? `${quarterlyInfo?.Title?.value}: ${quarterlyInfo?.IssueTitle?.value}`
            : quarterlyInfo?.IssueTitle?.value;
        quarterName = quarterlyInfo?.displayName;
        quarterUrl = quarterlyInfo?.url;
    }

    const quarterDisplay = datasource?.quarter?.jss?.value
        ? datasource?.quarter?.jss
        : { value: quarterName?.substring(0, 2) };
    const title = datasource?.title?.jss?.value ? datasource?.title?.jss : { value: issueTitle };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuOpenOverlapping, setIsMenuOpenOverlapping] = useState(false);
    const [closeMenuWithoutAnimation, setCloseMenuWithoutAnimation] = useState(false);
    const [openStatePadding, setOpenStatePadding] = useState('');
    const qNavRef = useRef(null);
    const qNavContentRef = useRef(null);
    const [mainNav, setMainNav] = useState(null);

    const toggleMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen);
        const nav = document?.querySelector('.zn-main-nav');
        const navHeight = nav.getBoundingClientRect()?.height;
        const qNavTop = qNavRef?.current?.getBoundingClientRect()?.top;
        const navMarginTop = nav?.style?.marginTop;

        if (!isMenuOpen) {
            mainContent?.classList?.add(menuOpenClass);
            qNavContentRef?.current?.classList?.add(slideMenuOpenClass);
            qNavContentRef?.current?.classList?.remove(slideMenuCloseClass);
            if (qNavRef?.current) {
                qNavRef.current.style.removeProperty('background-color');
                setCloseMenuWithoutAnimation(true);
            }
            if (mainNav?.style && qNavTop > navHeight) {
                mainNav.style.marginTop = `-${mainNav?.getBoundingClientRect()?.height}px`;
                setIsMenuOpenOverlapping(false);
                setCloseMenuWithoutAnimation(false);
            }
        } else {
            mainContent?.classList?.remove(menuOpenClass);
            qNavContentRef?.current?.classList?.remove(slideMenuOpenClass);
            qNavContentRef?.current?.classList?.add(slideMenuCloseClass);
            if (mainNav?.style) {
                mainNav.style.removeProperty('margin-top');
                setIsMenuOpenOverlapping(false);
                setCloseMenuWithoutAnimation(false);
            }
            if (navMarginTop) {
                qNavRef.current.style.removeProperty('background-color');
                setIsMenuOpenOverlapping(false);
                setCloseMenuWithoutAnimation(false);
            } else {
                qNavRef.current.style.backgroundColor = 'transparent';
                setIsMenuOpenOverlapping(true);
                setCloseMenuWithoutAnimation(true);
            }
        }
    };

    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        setMainNav(document?.querySelector('.zn-main-nav'));
        setOpenStatePadding(getScrollbarWidth());

        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    useEffect(() => {
        function closeQuarterlyNav(event) {
            const nav = document?.querySelector('.zn-main-nav');
            const navMarginTop = nav?.style?.marginTop;
            if (
                qNavRef.current &&
                !qNavRef.current.contains(event.target) &&
                qNavContentRef.current &&
                !qNavContentRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false);
                const nav = document?.querySelector('.zn-main-nav');
                mainContent?.classList?.remove(menuOpenClass);
                qNavContentRef?.current?.classList?.remove(menuOpenClass);
                if (nav?.style) {
                    nav.style.removeProperty('margin-top');
                    setIsMenuOpenOverlapping(false);
                    setCloseMenuWithoutAnimation(false);
                }
                if (navMarginTop) {
                    qNavRef.current.style.removeProperty('background-color');
                    setIsMenuOpenOverlapping(false);
                    setCloseMenuWithoutAnimation(false);
                } else {
                    qNavRef.current.style.backgroundColor = 'transparent';
                    setIsMenuOpenOverlapping(true);
                    setCloseMenuWithoutAnimation(true);
                }
            }
        }
        document.addEventListener('mousedown', closeQuarterlyNav);
        return () => {
            document.removeEventListener('mousedown', closeQuarterlyNav);
        };
    }, []);

    return (
        !pdfView && (
            <>
                <QuarterlyNavigationNavBar
                    qNavRef={qNavRef}
                    toggleMenuOpen={toggleMenuOpen}
                    isMenuOpen={isMenuOpen}
                    quarter={quarterDisplay}
                    theme={theme}
                    title={title}
                    quarterUrl={quarterUrl}
                    pageEditing={sitecoreContext?.pageEditing}
                    subscribeButton={datasource?.subscribeButton?.jss}
                    isMenuOpenOverlapping={isMenuOpenOverlapping}
                    closeMenuWithoutAnimation={closeMenuWithoutAnimation}
                    openStatePadding={openStatePadding}
                />
                {isMenuOpen && (
                    <div className={`${navBlock}__content`}>
                        <div
                            className={`${navBlock}__content-container slide-in`}
                            ref={qNavContentRef}
                        >
                            <div className={`${navBlock}__content-container-lists`}>
                                {Array.isArray(chapters) &&
                                    chapters.map((chapter, index) => (
                                        <ColoredList
                                            key={index}
                                            color={
                                                chapter?.ChapterColor?.value.toLowerCase() ||
                                                colors[index % colors.length]
                                            }
                                            isLink={true}
                                            theme={theme}
                                            title={chapter?.ChapterTitle}
                                            list={chapter?.Articles?.targetItems}
                                            onClick={() => {
                                                toggleMenuOpen();
                                            }}
                                        />
                                    ))}
                                <div className={`${navBlock}__mobile`}>
                                    <Link
                                        Theme={{ value: theme }}
                                        Variant={{
                                            value: `${LinkVariantEnum.nav.toLowerCase()}--mega--medium`,
                                        }}
                                        LinkText={{
                                            value: datasource?.previousIssues?.jss?.value?.text,
                                        }}
                                        LinkURL={{
                                            value: datasource?.previousIssues?.jss?.value?.url,
                                        }}
                                        LinkAltText={{
                                            value: `nav link to ${datasource?.previousIssues?.jss?.value?.text}`,
                                        }}
                                        LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                    />
                                    <HorizontalRule
                                        params={{
                                            HorizontalRuleColor: HorizontalRuleColorEnum.light,
                                        }}
                                    />
                                    <div className={`${navBlock}__social`}>
                                        {socialMedia && (
                                            <SocialMediaLinks
                                                params={params}
                                                fields={{
                                                    FacebookLabel: socialMedia.facebookLabel,
                                                    FacebookPageLink: socialMedia.facebookPageLink,
                                                    FacebookIconAltText:
                                                        socialMedia.facebookIconAltText,
                                                    TwitterLabel: socialMedia.twitterLabel,
                                                    TwitterPageLink: socialMedia.twitterPageLink,
                                                    TwitterIconAltText:
                                                        socialMedia.twitterIconAltText,
                                                    LinkedInLabel: socialMedia.linkedInLabel,
                                                    LinkedInPageLink: socialMedia.linkedInPageLink,
                                                    LinkedInIconAltText:
                                                        socialMedia.linkedInIconAltText,
                                                    EmailLabel: socialMedia.emailLabel,
                                                    EmailLink: socialMedia.emailLink,
                                                    EmailIconAltText: socialMedia.emailIconAltText,
                                                    PDFLabel: socialMedia.pDFLabel,
                                                    PDFLink: socialMedia.pDFLink,
                                                    PDFIconAltText: socialMedia.pDFIconAltText,
                                                    CopyUrlLabel: socialMedia.copyUrlLabel,
                                                    CopyUrlIconAltText:
                                                        socialMedia.copyUrlIconAltText,
                                                }}
                                            />
                                        )}
                                    </div>
                                    {(datasource?.subscribeButton?.jss?.value?.text ||
                                        sitecoreContext?.pageEditing) && (
                                        <div className={`${navBlock}__subscribe`}>
                                            <ButtonLink
                                                params={{
                                                    Theme: theme,
                                                    Variant: ButtonVariantEnum.primary.toLowerCase(),
                                                }}
                                                fields={{
                                                    LinkURL: datasource?.subscribeButton?.jss,
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    );
};

export const QuarterlyNavigationSitecore = withSitecoreContext()(QuarterlyNavigation);
export const QuarterlyNavigationStorybook = forStorybookV2(QuarterlyNavigationSitecore);
export default withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
    QuarterlyNavigationSitecore
);
