import React from 'react';
import classnames from 'classnames';
import './heading-accent.scss';
import { AccentColorEnum, SectionHeadingSizeEnum } from '../../utils/enums';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';

const HeadingAccent = ({ params }) => {
    const { AccentColor, SectionHeadingSize, EnableAnimation } = params;

    const sectionHeadingBlock = 'zn-heading-accent';

    // Don't render anything if Color is set as 'None'
    if (!AccentColor || AccentColor === AccentColorEnum.none) return null;

    return (
        <div
            className={classnames(
                `${sectionHeadingBlock}`,
                `${sectionHeadingBlock}--${AccentColor?.toLowerCase()}`,
                {
                    [`${sectionHeadingBlock}--medium`]:
                        SectionHeadingSize === SectionHeadingSizeEnum.medium,
                    [`${sectionHeadingBlock}__animation`]: EnableAnimation,
                }
            )}
        ></div>
    );
};

export const HeadingAccentStorybook = forStorybook(HeadingAccent);

export default HeadingAccent;
