import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button/Button.js';
import { SearchIcon, CloseIcon } from '../../assets/common.js';
import './search-input.scss';
import { scoreSortBy } from '../../utils/constants.js';
import { fetchResults } from '../../actions';
import AutofillSearchResult from '../AutofillSearchResult/AutofillSearchResult.jsx';
import { SearchResultTypeEnum } from '../../utils/enums.js';

/**
 * Primary UI component for user interaction
 */
const SearchInput = ({ alt, searchPage, placeholder, dark, onClose, searchInputRef }) => {
    const theme = dark ? 'dark' : 'light';
    const seearchInputBlock = 'zn-search-form';
    const [searchParams, setSearchParams] = useState('');
    const [autofillResults, setAutofillResults] = useState([]);
    const autoloadSuggestionsRef = useRef(null);

    useEffect(() => {
        //set timer so search doesn't run with every new value typed

        const searchCondition = {
            group: [
                { name: '_name', value: `${searchParams}`, useor: true },
                { name: '_displayname', value: `${searchParams}`, useor: true },
            ],
        };

        const timer = setTimeout(() => {
            if (searchParams !== '') {
                fetchResults([searchCondition], [], 4, '0', scoreSortBy, true, [''], '', true)
                    .then((x) => {
                        setAutofillResults(x.results);
                    })
                    .catch(() => {
                        setAutofillResults([]);
                    });
            } else {
                setAutofillResults([]);
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [searchParams]);

    const handleSearchParams = (e) => {
        setSearchParams(e.target.value);
        autoloadSuggestionsRef.current.style.display = 'block';
    };

    const submitForm = (event) => {
        event.preventDefault();
        let searchCondition = `q=${encodeURIComponent(searchParams)}`;
        window.location.href = `${searchPage?.url.split('?')[0]}?${searchCondition}`;
    };

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const suggestedResults = document.getElementsByClassName(
                'zn-search-form__suggested-results'
            )[0];
            const searchInputBar = document.getElementsByClassName('zn-search-form__input')[0];

            let targetElement = e.target;
            do {
                if (targetElement == suggestedResults || targetElement == searchInputBar) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            if (autoloadSuggestionsRef.current)
                autoloadSuggestionsRef.current.style.display = 'none';
        });
    }, []);

    function searchKeyBoard(event) {
        if (event.keyCode == 13) {
            event.target.blur();
        }
    }

    useEffect(() => {
        searchInputRef?.current?.addEventListener('keypress', searchKeyBoard);
        return function cleanup() {
            searchInputRef?.current?.removeEventListener('keypress', searchKeyBoard);
        };
    }, []);

    return (
        <div className={`zn-search-form__wrapper`}>
            <form
                method="GET"
                onSubmit={submitForm}
                className={`zn-search-form zn-search-form--${theme}`}
            >
                <Button
                    className="zn-search-form__search-button"
                    aria-label="Submit Search"
                    type="submit"
                    variant="icon"
                >
                    <SearchIcon customColor="#006EAA" />
                </Button>
                <input
                    className="zn-search-form__input"
                    type="text"
                    name="search"
                    placeholder={placeholder?.value ? placeholder?.value : ''}
                    onChange={handleSearchParams}
                    ref={searchInputRef}
                    autoComplete="off"
                    onFocus={() => {
                        autoloadSuggestionsRef.current.style.display = 'block';
                    }}
                ></input>
                <Button
                    className="zn-search-form__close-button"
                    aria-label="Close Search"
                    variant="icon"
                    alt={alt}
                    onClick={onClose}
                >
                    <CloseIcon />
                </Button>
            </form>
            <div
                className={`${seearchInputBlock}__suggested-results-container`}
                ref={autoloadSuggestionsRef}
            >
                {autofillResults?.length > 0 && (
                    <ul className={`${seearchInputBlock}__suggested-results`}>
                        {autofillResults.map((result, index) => {
                            if (result?.item) {
                                result.fields = {
                                    Title:
                                        result?.templateName === SearchResultTypeEnum.biopage
                                            ? {
                                                  value: `${result?.item?.firstName?.value} ${result?.item?.middleName?.value} ${result?.item?.lastName?.value}`,
                                              }
                                            : { value: result?.item?.teaserTitle?.value },
                                    Image:
                                        result?.templateName === SearchResultTypeEnum.biopage
                                            ? { value: result?.item?.profileImage }
                                            : {},
                                    ResultType: { value: result?.templateName },
                                    LinkUrl:
                                        result?.item?.notNavigatable?.value === '1'
                                            ? {}
                                            : { value: result?.item?.url },
                                };
                                return (
                                    <li
                                        key={index}
                                        className={`${seearchInputBlock}__suggested-results-item`}
                                    >
                                        <AutofillSearchResult
                                            fields={result.fields}
                                            searchValue={searchParams}
                                        />
                                    </li>
                                );
                            }
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};

SearchInput.propTypes = {
    action: PropTypes.string,
    alt: PropTypes.string,
    dark: PropTypes.bool,
    placeholder: PropTypes.string,
    onClose: PropTypes.func,
};

SearchInput.defaultProps = {
    action: '/search',
    alt: 'search',
    dark: false,
    onClose: undefined,
};

export default SearchInput;
