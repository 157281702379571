import React from 'react';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const PartialContainer = ({
    'content-pre': contentPre,
    'content-main': contentMain,
    'content-post': contentPost,
    sitecoreContext,
}) => {
    return (
        <>
            {sitecoreContext?.pageEditing ? (
                <>
                    <section>{contentPre}</section>
                    <section>{contentMain}</section>
                    <section>{contentPost}</section>
                </>
            ) : (
                <>
                    {contentPre}
                    {contentMain}
                    {contentPost}
                </>
            )}
        </>
    );
};

export default withSitecoreContext()(
    withPlaceholder(['content-pre', 'content-main', 'content-post'])(PartialContainer)
);
