export const ThemeEnum = {
    light: 'Light',
    dark: 'Dark',
};

export const DisplayStyleEnum = {
    vertical: 'Vertical',
    horizontal: 'Horizontal',
};

export const LinkTypeEnum = {
    pageLink: 'Page Link',
    shareLink: 'Share Link',
};

export const SizeEnum = { small: 'Small', medium: 'Medium', large: 'Large' };

export const BackgroundColorEnum = {
    white: 'White',
    grey: 'Grey',
    blue: 'Blue',
    navy: 'Navy',
    lightBlue: 'Light Blue',
};

export const HighlightColorEnum = {
    teal: 'Teal',
    orange: 'Orange',
    blue: 'Blue',
    navy: 'Navy',
    grey: 'Grey',
};

export const LinkTargetEnum = {
    sameTab: 'Same Tab',
    newTab: 'New Tab',
};

export const AccentColorEnum = {
    none: 'None',
    grey: 'Grey',
    teal: 'Teal',
    orange: 'Orange',
    blue: 'Blue',
    navy: 'Navy',
};

export const HighlightColorItemEnum = {
    none: 'None',
    teal: 'Teal',
    orange: 'Orange',
    blue: 'Blue',
    navy: 'Navy',
};

export const SectionHeadingSizeEnum = {
    large: 'Large',
    medium: 'Medium',
    eyebrow: 'Eyebrow',
};

export const OverrideAlignmentEnum = {
    none: 'None',
    left: 'Left',
    center: 'Center',
    right: 'Right',
};

export const TwoColumnLayoutEnum = {
    paragraph: 'Paragraph Style',
    bulletPoint: 'Bullet Point Style',
};

export const LinkListLayoutEnum = {
    highlighted: 'Highlighted',
    singleColumn: 'Single-Column',
    doubleColumn: 'Two-Column',
    doubleColumnSpaceBetween: 'Two-Column-Space-Between',
};
export const TagTypeEnum = {
    link: 'Link',
    button: 'Button',
};

export const LinkVariantEnum = {
    small: 'Small',
    hyperlink: 'Hyperlink',
    nav: 'Nav',
};

export const ArticleHeroAccentColorEnum = {
    teal: 'Teal',
    orange: 'Orange',
    blue: 'Blue',
    navy: 'Navy',
    white: 'White',
};

export const ArticleHeroThemeColorEnum = {
    white: 'White',
    blue: 'Blue',
    navy: 'Navy',
};

export const FeaturedContentBackgroundColorEnum = {
    white: 'White',
    blue: 'Blue',
    navy: 'Navy',
};

export const HomepageHeroBackgroundColorEnum = {
    white: 'White',
    navy: 'Navy',
    blue: 'Blue',
};

export const HomepageHeroAccentColorEnum = {
    grey: 'Grey',
    white: 'White',
    black: 'Black',
    blue: 'Blue',
    teal: 'Teal',
    orange: 'Orange',
};

export const AccordionTitleColorEnum = {
    black: 'Black',
    navy: 'Navy',
};

export const ButtonVariantEnum = {
    primary: 'Primary',
    secondary: 'Secondary',
    tertiary: 'Tertiary',
};

export const FeaturedListLayoutEnum = {
    oneColumn: 'One Column',
    twoColumn: 'Two Column',
};

export const HeadingLevelEnum = {
    h1: 'H1',
    h2: 'H2',
    h3: 'H3',
    h4: 'H4',
    h5: 'H5',
    h6: 'H6',
};

export const HorizontalAlignmentEnum = {
    left: 'Left',
    center: 'Center',
    right: 'Right',
};

export const VerticalAlignmentEnum = {
    top: 'Top',
    center: 'Center',
    bottom: 'Bottom',
    spaceBetween: 'Space Between',
};

export const LanguageNamesEnum = {
    en: 'English',
    fr: 'Français',
    'fr-FR': 'Français',
    'fr-CA': 'Français',
};

export const SideNavigationTypeEnum = {
    inPage: 'In-page',
    betweenPage: 'Between-pages',
};

export const QuoteColorEnum = {
    blue: 'Blue',
    orange: 'Orange',
    teal: 'Teal',
    grey: 'Grey',
    navy: 'Navy',
};

export const GeneralHeroColourTheme = {
    navy: 'Navy',
    blue: 'Blue',
    white: 'White',
};

export const ArticleHeadingColorEnum = {
    blue: 'Blue',
    orange: 'Orange',
    teal: 'Teal',
    navy: 'Navy',
    black: 'Black',
    default: 'Default',
};

export const GridPaddingEnum = {
    full: 'Full',
    half: 'Half',
    none: 'None',
};

export const SearchResultTypeEnum = {
    people: 'People',
    video: 'Video',
    audio: 'Audio',
    document: 'Document',
    news: 'News',
    notes: 'Notes',
    event: 'Event',
    biopage: 'Bio Page',
    publication: 'Publication',
};

export const HighlightedBlockEnum = {
    blue: 'Blue',
    orange: 'Orange',
    teal: 'Teal',
    grey: 'Grey',
    none: 'None',
};

export const ContextRichTextEnum = {
    overview: 'Overview',
    description: 'Description',
    mainBio: 'MainBio',
    introductionToDeal: 'IntroductionToDeal',
    projectOverview: 'ProjectOverview',
    additionalDetail: 'AdditionalDetail',
    dealTeamList: 'DealTeamListText',
};

export const RichTextSizeEnum = {
    none: 'None',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
};

export const SearchBarCalendarEnum = {
    start: 'Start',
    end: 'End',
    publish: 'Publish',
};

export const ContextLinkListEnum = {
    relatedServices: 'RelatedServices',
};

export const ContextLinkListItemEnum = {
    name: 'Name',
    description: 'Overview',
};

export const ContextFeatureListEnum = {
    relatedServices: 'RelatedServices',
    relatedWork: 'RelatedOffices',
    pinnedExperience: 'PinnedExperience',
};

export const ContextFeatureListItemEnum = {
    none: 'None',
    name: 'displayName',
    description: 'Description',
};

export const ContentCardColour = {
    white: 'White',
    grey: 'Grey',
};

export const ContentCardType = {
    podcast: 'Podcast',
    publication: 'Insight',
    events: 'Event',
    video: 'Video',
    news: 'News',
    workItem: 'Work Item',
    quarterlyIssue: 'Quarterly Issue',
    contentSeries: 'Content Series',
    webinar: 'Webinar',
    person: 'Bio Page',
};

export const ContextContactCardsEnum = {
    authors: 'Authors',
    relatedProfessionals: 'RelatedProfessionals',
    eventSpeakers: 'EventSpeakers',
    relatedPeople: 'RelatedPeople',
    relatedLawyers: 'RelatedLawyers',
    dealTeamList: 'DealTeamList',
};

export const HorizontalRuleColorEnum = {
    light: 'Light',
    medium: 'Medium',
    dark: 'Dark',
};

export const InsightTypeEnum = {
    podcast: 'Podcast',
    video: 'Video',
    standard: 'Standard',
};

export const LinkTargetValueEnum = {
    self: '_self',
    blank: '_blank',
};

export const LinkSubVariantsEnum = {
    chevronRight: 'chevron-right',
    chevronLeft: 'chevron-left',
    chevronDown: 'chevron-down',
    highlight: 'highlight',
    active: 'active',
    inactive: 'inactive',
    bold: 'bold',
    small: 'small',
    noMargin: 'no-margin',
    noPadding: 'no-padding',
};

export const FeaturedContentImageDisplayEnum = {
    blue: 'Display with Blue Accent',
    navy: 'Display with Navy Accent',
    orange: 'Display with Orange Accent',
    teal: 'Display with Teal Accent',
    hideImage: 'Hide Image',
    none: 'Display with No Accent',
};

export const TimeZoneEnum = {
    edt: 'utc-4',
    get server() {
        return this.edt;
    },
};

export const NavigationThemeEnum = {
    None: 'None',
    Light: 'Light',
    Dark: 'Dark',
};

export const ContentTypeEnum = {
    //TODO: update to complete list of content types
    publication: 'Publication Page',
    work: 'Work Page',
    news: 'News',
    event: 'Event',
    quarterly: 'Quarterly',
    people: 'People',
    podcast: 'Podcast',
    video: 'Video',
    webinar: 'Webinar',
    projectMapWork: 'Project Map Work',
    premiumArticle: 'Premium Article',
    quarterlyReportLanding: 'Quarterly Report Landing',
    about: 'About Us',
    homepage: 'Homepage',
    office: 'Office',
    bioPage: 'Bio Page',
    expertise: 'Expertise Landing',
    insight: 'Insight',
};

export const RepresentativeItemsEnum = {
    representativeWork: 'Representative Work',
    representativeInsights: 'Representative Insights',
    representativeNews: 'Representative News',
};

export const CustomRichTextClassEnum = {
    expertiseRichText: 'ExpertiseRichText',
    recognitionRichText: 'RecognitionRichText',
};

export const SpacingsEnum = {
    none: 'None',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
};

export const SearchSortingOrderEnum = {
    mostRecent: 'Most Recent',
    relevancy: 'Relevancy',
};

export const QuarterlyOverlayColor = {
    white: 'White',
    grey: 'Grey',
    navy: 'Navy',
};
