import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ExternalLink } from '../../assets/LinkIcons.js';
import './button.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

export const Button = ({
    variant,
    as,
    backgroundColor,
    type,
    dark,
    target,
    href,
    children,
    className,
    t,
    ...props
}) => {
    const theme = dark ? 'dark' : 'light';
    return as === 'a' ? (
        <a
            href={href}
            target={target}
            className={classnames('zn-button-simple', `zn-button-simple--${variant}`, className, {
                'zn-link-external': target === '_blank',
            })}
        >
            {children}
            {target === '_blank' && <span className="sr-only"> {t('OpensInANewTab')}</span>}
            {target === '_blank' && (
                <div
                    className={classnames(`zn-button__icon`, {
                        [`zn-link--${variant}--${theme}__icon`]: variant === 'tertiary',
                    })}
                >
                    <ExternalLink />
                </div>
            )}
        </a>
    ) : (
        <button
            className={classnames('zn-button-simple', `zn-button-simple--${variant}`, className, {
                'zn-link-external': target === '_blank',
            })}
            type={type}
            style={backgroundColor && { backgroundColor }}
            {...props}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    backgroundColor: PropTypes.string,
    as: PropTypes.oneOf(['button', 'a']),
    type: PropTypes.oneOf(['button', 'submit']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'icon']),
    target: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    onClick: PropTypes.func,
    href: PropTypes.string,
};

Button.defaultProps = {
    backgroundColor: null,
    as: 'button',
    type: 'button',
    children: 'Button',
    variant: 'primary',
    target: '_self',
    className: null,
    onClick: undefined,
    href: '#',
};

export default withTranslation()(withSitecoreContext()(Button));
