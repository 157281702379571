import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../SocialMediaLinks/social-media-links.scss';
import {
    Image as Img,
    withSitecoreContext,
    withPlaceholder,
    RichText,
} from '@sitecore-jss/sitecore-jss-react';
import { DownloadFile } from '../../assets/LinkIcons';
import forStorybook from '../../utils/forStorybook.jsx';
import { getVCardUrl } from '../../utils/vcardUrl.jsx';
import './bio-profile-hero.scss';
import Text from '../Text';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withTranslation } from 'react-i18next';

const BioProfileHero = ({ socialmedia, dropdown, sitecoreContext, t }) => {
    // const { BusinessPhoneLabel, OtherPhoneLabel } = fields;

    if (
        sitecoreContext?.route?.templateName !== 'Bio Page' &&
        sitecoreContext?.route?.templateName !== 'Partial Design' &&
        sitecoreContext?.route?.templateName !== 'Page Design '
    ) {
        return (
            <p>
                Bio Hero Component must be used with a content item with the template name Bio Page
            </p>
        );
    }

    const {
        Role,
        LeadParagraph,
        OtherPhone,
        BusinessEmail,
        BusinessPhone,
        Prefix,
        LastName,
        FirstName,
        MiddleName,
        Suffix,
        HighResolutionImage,
        RelatedOffices,
    } = sitecoreContext?.route?.fields || {};

    const profileHeroBlock = 'zn-profile-hero';
    const isEditing = sitecoreContext.pageEditing;
    const hasImage = !!HighResolutionImage?.value?.src;

    const relatedOffices = RelatedOffices?.value;
    let BusinessPhoneLabel = { value: '' };
    let OtherPhoneLabel = { value: '' };
    if (relatedOffices) {
        const offices = relatedOffices.split(',');
        BusinessPhoneLabel.value = offices[0];
        if (offices.length > 1) {
            OtherPhoneLabel.value = offices[1];
        }
    }

    const hasContactDetails =
        BusinessPhone?.value || OtherPhone?.value || BusinessEmail?.value || isEditing;

    let fullName = '';
    FirstName?.value && (fullName += FirstName?.value + ' ');
    MiddleName?.value && (fullName += MiddleName?.value + ' ');
    LastName?.value && (fullName += LastName?.value);
    fullName = fullName.trim();

    return (
        <div className={classnames(`${profileHeroBlock}`)}>
            <div className={classnames(`${profileHeroBlock}__columns`)}>
                {(hasImage || isEditing) && (
                    <div className={classnames(`${profileHeroBlock}__photo-col`)}>
                        <div
                            className={classnames(`${profileHeroBlock}__image`, {
                                [`${profileHeroBlock}__image--sc-editing`]: isEditing,
                            })}
                        >
                            <Img field={HighResolutionImage} />
                        </div>
                    </div>
                )}
                <div
                    className={classnames(`${profileHeroBlock}__info-col`, {
                        [`${profileHeroBlock}__info-col--no-image`]: !hasImage,
                    })}
                >
                    <div className={classnames(`${profileHeroBlock}__icon-bar`)}>
                        <div className={`${profileHeroBlock}__icon-element`}>{dropdown}</div>
                        <div className={`${profileHeroBlock}__download`}>
                            <a
                                className={`${profileHeroBlock}__download-link`}
                                download={fullName ? fullName + '.vcf' : 'contact-card.vcf'}
                                href={getVCardUrl(
                                    FirstName,
                                    MiddleName,
                                    LastName,
                                    Role,
                                    BusinessPhone,
                                    OtherPhone,
                                    { value: 'work' },
                                    {},
                                    BusinessEmail
                                )}
                            >
                                <DownloadFile />
                            </a>
                        </div>
                        <div className={`${profileHeroBlock}__icon-element`}>{socialmedia}</div>
                    </div>
                    <div className={classnames(`${profileHeroBlock}__title`)}>
                        {(Prefix?.value || isEditing) && (
                            <>
                                <Text type="span" useDefaultMargin={false} field={Prefix} />
                                &nbsp;
                            </>
                        )}
                        {(FirstName?.value || isEditing) && (
                            <>
                                <Text type="span" useDefaultMargin={false} field={FirstName} />
                                &nbsp;
                            </>
                        )}
                        {(MiddleName?.value || isEditing) && (
                            <>
                                <Text type="span" useDefaultMargin={false} field={MiddleName} />
                                &nbsp;
                            </>
                        )}
                        {(LastName?.value || isEditing) && (
                            <>
                                <Text type="span" useDefaultMargin={false} field={LastName} />
                            </>
                        )}
                        {(Suffix?.value || isEditing) && (
                            <>
                                ,&nbsp;
                                <Text type="span" useDefaultMargin={false} field={Suffix} />
                            </>
                        )}
                    </div>
                    {(Role?.value || isEditing) && (
                        <Text
                            className={classnames(`${profileHeroBlock}__position`)}
                            type="div"
                            useDefaultMargin={false}
                            field={Role}
                        />
                    )}
                    <div className={classnames(`${profileHeroBlock}__accent`)}></div>
                    <RichText
                        className={classnames(`${profileHeroBlock}__description`, {
                            [`${profileHeroBlock}__description--no-image`]: !hasImage,
                        })}
                        type="div"
                        field={LeadParagraph}
                    />
                    <div
                        className={classnames(`${profileHeroBlock}__contact-accent`, {
                            [`${profileHeroBlock}__contact-accent--no-image`]: !hasImage,
                        })}
                    ></div>
                    {hasContactDetails && (
                        <div
                            className={classnames(`${profileHeroBlock}__contact-bar`, {
                                [`${profileHeroBlock}__contact-accent--no-image`]: !hasImage,
                            })}
                        >
                            <div className={classnames(`${profileHeroBlock}__contact-title`)}>
                                {t('Contact')}
                            </div>
                            <div className={classnames(`${profileHeroBlock}__contact-info`)}>
                                <ul className={classnames(`${profileHeroBlock}__phone-numbers`)}>
                                    {(BusinessPhone?.value || isEditing) && (
                                        <li>
                                            <Text
                                                type="span"
                                                useDefaultMargin={false}
                                                field={BusinessPhoneLabel}
                                            />
                                            {':'}
                                            <Text
                                                className={classnames(
                                                    `${profileHeroBlock}__phone-number`
                                                )}
                                                href={`tel:${BusinessPhone?.value}`}
                                                as="a"
                                                type="a"
                                                useDefaultMargin={false}
                                                field={BusinessPhone}
                                            />
                                        </li>
                                    )}
                                    {(OtherPhone?.value || isEditing) && (
                                        <li>
                                            <Text
                                                type="span"
                                                useDefaultMargin={false}
                                                field={OtherPhoneLabel}
                                            />
                                            {':'}
                                            <a
                                                className={classnames(
                                                    `${profileHeroBlock}__phone-number`
                                                )}
                                                href={`tel:${OtherPhone?.value}`}
                                            >
                                                <Text
                                                    type="span"
                                                    useDefaultMargin={false}
                                                    field={OtherPhone}
                                                />
                                            </a>
                                        </li>
                                    )}
                                </ul>
                                {(BusinessEmail?.value || isEditing) && (
                                    <a
                                        className={classnames(`${profileHeroBlock}__email`)}
                                        href={`mailto:${BusinessEmail?.value}`}
                                    >
                                        <Text
                                            type="span"
                                            useDefaultMargin={false}
                                            field={BusinessEmail}
                                        />
                                    </a>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

BioProfileHero.propTypes = {
    fields: PropTypes.exact({
        OtherPhoneLabel: PropTypes.shape({
            value: PropTypes.string,
        }),
        BusinessPhoneLabel: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
    socialmedia: PropTypes.object,
    dropdown: PropTypes.object,
    sitecoreContext: PropTypes.object,
};

const BioProfileHeroSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.sitecoreContext])(
        BioProfileHero
    )
);

export const BioProfileHeroStorybook = forStorybook(BioProfileHeroSitecore);
export default withPlaceholder(['socialmedia', 'dropdown'])(
    withTranslation()(BioProfileHeroSitecore)
);
