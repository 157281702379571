/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './checkbox.scss';
import CheckboxWrapper from '../Checkbox/CheckboxWrapper.jsx';
import { ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';

const CheckboxWithDropdown = ({
    index,
    id,
    pId,
    label,
    checked,
    checkSingularBox,
    toggleHighLevelFilter,
    isLoading,
    setIsLoading,
    dropdownOptions,
    activeHighLevelFilterGroup,
    toggleActiveFilterGroup,
    setActiveHighLevelFilterGroup,
    searchInput,
}) => {
    const checkboxWithDropdown = 'zn-checkbox-dropdown';
    const [hasChildren, setHasChildren] = useState(
        dropdownOptions.filter((dropdownOption) => dropdownOption?.hasResults).length
    );
    let parentIndex = index;

    useEffect(() => {
        setHasChildren(
            dropdownOptions.filter((dropdownOption) => dropdownOption?.hasResults).length
        );
    }, [dropdownOptions]);

    useEffect(() => {
        if (!searchInput) {
            setHasChildren(
                dropdownOptions.filter((dropdownOption) => dropdownOption?.hasResults).length
            );
        } else {
            let matchingChildren = dropdownOptions.filter(
                (dropdownOption) =>
                    dropdownOption.title.toUpperCase().indexOf(searchInput) > -1 &&
                    dropdownOption?.hasResults
            );
            let hasResultsChildren = dropdownOptions.filter(
                (dropdownOption) => dropdownOption?.hasResults
            );
            //if search term matches parent, and it has available children show it
            if (label.toUpperCase().indexOf(searchInput) > -1 && hasResultsChildren.length)
                setHasChildren(true);
            //else show if it has children matching search term
            else setHasChildren(matchingChildren.length ? true : false);
        }
    }, [searchInput]);

    return (
        <div
            className={classnames(`${checkboxWithDropdown}`, {
                [`checked`]: checked,
                [`${checkboxWithDropdown}--disabled`]: isLoading,
            })}
            style={
                hasChildren
                    ? {
                          display: '',
                      }
                    : {
                          display: 'none',
                      }
            }
        >
            <div className={classnames(`${checkboxWithDropdown}__header-container`)}>
                <CheckboxWrapper
                    checked={checked}
                    change={toggleHighLevelFilter}
                    id={id}
                    pId={pId}
                    disabledState={isLoading}
                    setDisabledState={setIsLoading}
                    onClick={() => {
                        if (!isLoading) setActiveHighLevelFilterGroup(index);
                    }}
                />
                <button
                    className={classnames(`${checkboxWithDropdown}__title-container`)}
                    onClick={() => {
                        if (!isLoading) toggleActiveFilterGroup(index);
                    }}
                >
                    <div
                        className={classnames(`${checkboxWithDropdown}__title`, {
                            [`${checkboxWithDropdown}__title--disabled`]: isLoading,
                        })}
                    >
                        {label}
                    </div>
                    {!searchInput && (
                        <div
                            className={classnames(`${checkboxWithDropdown}__chevron`, {
                                [`${checkboxWithDropdown}__chevron--disabled`]: isLoading,
                            })}
                        >
                            {index === activeHighLevelFilterGroup ? <ChevronUp /> : <ChevronDown />}
                        </div>
                    )}
                </button>
            </div>
            <label className={classnames(`${checkboxWithDropdown}__wrapper`)}>
                <ul
                    className={classnames(`${checkboxWithDropdown}__dropdown-list`, {
                        [`${checkboxWithDropdown}__dropdown-list--active`]:
                            activeHighLevelFilterGroup === index || searchInput,
                    })}
                >
                    {dropdownOptions?.map((lowLevelFilter, index) => {
                        if (!lowLevelFilter.hasResults) return;
                        if (searchInput) {
                            if (
                                !(lowLevelFilter.title.toUpperCase().indexOf(searchInput) > -1) &&
                                !(label.toUpperCase().indexOf(searchInput) > -1)
                            ) {
                                return;
                            }
                        }
                        return (
                            <CheckboxWrapper
                                checked={lowLevelFilter.checked}
                                change={checkSingularBox}
                                key={index}
                                id={lowLevelFilter.identifier}
                                highLevelId={id}
                                pId={pId}
                                label={lowLevelFilter?.title}
                                disabledState={isLoading}
                                setDisabledState={setIsLoading}
                                isDropdownFilter={true}
                                notTabbable={
                                    !(activeHighLevelFilterGroup === parentIndex || searchInput)
                                }
                            />
                        );
                    })}
                </ul>
            </label>
        </div>
    );
};

export default CheckboxWithDropdown;
