import React from 'react';
import { Image as Img, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Link from '../Link/Link.jsx';
import Text from '../Text/Text.jsx';
import { ThemeEnum, LinkTargetEnum } from '../../utils/enums';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';

import './contact-card.scss';

const contactCard = 'zn-contact-card';

export const ContactCard = ({ sitecoreContext, fields }) => {
    const data = fields?.data?.datasource;
    const contactNameOverride = data?.contactNameOverride?.jss;
    const imageOverride = data?.imageOverride?.jss;
    const profileURL = data?.profileURL?.targetItem;
    const Image =
        imageOverride?.value?.src || !profileURL
            ? imageOverride
            : profileURL?.lowResolutionImage?.jss;
    const Name =
        contactNameOverride?.value || !profileURL
            ? contactNameOverride
            : {
                  value: `${profileURL?.firstName?.jss?.value?.trim() || ''} ${
                      profileURL?.middleName?.jss?.value?.trim() || ''
                  } ${profileURL?.lastName?.jss?.value?.trim() || ''}`,
              };
    return (
        <>
            {(sitecoreContext?.pageEditing || Name?.value) && (
                <li className={contactCard}>
                    <span className={`${contactCard}__img`}>
                        {Image?.value?.src ? (
                            <Img field={Image} />
                        ) : (
                            <Text
                                className={`${contactCard}__middle-initial`}
                                type="bodyLg"
                                useDefaultMargin={false}
                                field={{ value: Name?.value?.charAt(0) }}
                            />
                        )}
                    </span>

                    {profileURL?.url && !profileURL?.notNavigatable?.jss?.value ? (
                        <Link
                            Variant={{ value: 'bold' }}
                            className={`${contactCard}__title`}
                            LinkText={Name}
                            LinkURL={{ value: profileURL?.url }}
                            LinkAltText={Name}
                            LinkTarget={{ value: LinkTargetEnum.sameTab }}
                            Theme={{ value: ThemeEnum.light }}
                        />
                    ) : (
                        <Text
                            className={`${contactCard}__title`}
                            type="bodyMd"
                            useDefaultMargin={false}
                            field={Name}
                        />
                    )}
                </li>
            )}
        </>
    );
};

export default withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(ContactCard)
);
