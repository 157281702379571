import React, { useState, useEffect, useRef } from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import classnames from 'classnames';
import { Button } from '../Button/Button.js';
import { CloseIcon, SearchIcon } from '../../assets/common.js';
import { LinkTargetValueEnum, ThemeEnum } from '../../utils/enums';
import SearchInput from '../SearchInput/SearchInput.jsx';
import Text from '../Text';
import NavBarMenuPrimary from './NavBarMenuPrimary.jsx';

import './nav-bar-main.scss';

const NavNarMain = ({
    t,
    CustomBackgroundColor,
    logoLink,
    theme,
    language,
    languages,
    pageUrl,
    homeMainLinksPrimary,
    homeMainLinksSecondary,
    lightThemeLogo,
    darkThemeLogo,
    searchResultsPage,
    searchPlaceholderText,
    isMegaMenuOpen,
    onOpen,
    onClose,
    openMegaMenuPrimary,
    openMegaMenuSecondary,
    openMegaMenuTertiary,
    activeItem,
    setNavBarDisplay,
    navBarDisplay,
    animationEnabled,
    style,
    mainNavBarRef,
    mainNavBarMenuButtonRef,
    mainNavBarMenuLogoRef,
    scrollWidth,
}) => {
    const [displaySearch, setDisplaySearch] = useState(false);
    const searchInputRef = useRef(null);

    const dark = theme === ThemeEnum.dark.toLowerCase();
    const logoIcon = dark ? darkThemeLogo : lightThemeLogo;

    useEffect(() => {
        if (displaySearch) {
            searchInputRef.current.focus();
        }
    }, [displaySearch]);

    useEffect(() => {
        const escFunction = (event) => {
            if (event?.key === 'Escape') {
                setDisplaySearch(false);
                onClose();
            }
        };

        window.addEventListener('keydown', escFunction);

        return () => {
            window.removeEventListener('keydown', escFunction);
        };
    }, [scrollWidth]);

    return (
        <div className={`zn-main-nav-bar--${theme}`} style={style}>
            <nav
                style={{ backgroundColor: CustomBackgroundColor }}
                className={classnames('zn-main-nav-bar', {
                    fadeIn: animationEnabled,
                })}
            >
                <div className={classnames('zn-main-nav-bar__logo')} ref={mainNavBarMenuLogoRef}>
                    <Button
                        as="a"
                        variant="icon"
                        dark={dark}
                        target={LinkTargetValueEnum.self}
                        href={logoLink?.url}
                    >
                        <Image field={{ value: logoIcon }} />
                    </Button>
                </div>

                {navBarDisplay &&
                    (displaySearch ? (
                        <SearchInput
                            dark={dark}
                            searchPage={searchResultsPage}
                            onClose={() => {
                                setDisplaySearch(!displaySearch);
                            }}
                            searchInputRef={searchInputRef}
                            placeholder={searchPlaceholderText}
                        />
                    ) : (
                        <NavBarMenuPrimary
                            language={language}
                            languages={languages}
                            pageUrl={pageUrl}
                            homeMainLinksPrimary={homeMainLinksPrimary}
                            homeMainLinksSecondary={homeMainLinksSecondary}
                            theme={theme}
                            activeItem={activeItem}
                            isMegaMenuOpen={isMegaMenuOpen}
                            onClose={onClose}
                            openMegaMenuPrimary={openMegaMenuPrimary}
                            openMegaMenuSecondary={openMegaMenuSecondary}
                            openMegaMenuTertiary={openMegaMenuTertiary}
                            setDisplaySearch={setDisplaySearch}
                            displaySearch={displaySearch}
                            mainNavBarRef={mainNavBarRef}
                        />
                    ))}

                {isMegaMenuOpen ? (
                    <div className="zn-main-nav-bar__hamburger zn-main-nav-bar__hamburger--close">
                        <span className="zn-main-nav-bar__close">
                            <Button
                                className="zn-button-simple--border"
                                aria-label="Close navigation"
                                type="button"
                                variant="icon"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <CloseIcon />
                            </Button>
                        </span>
                    </div>
                ) : (
                    <div
                        className={classnames('zn-main-nav-bar__menu-icons')}
                        ref={mainNavBarMenuButtonRef}
                    >
                        <Button
                            className={classnames(
                                'zn-main-nav-bar--hide-desktop',
                                'zn-main-nav-bar__search-mobile'
                            )}
                            as="a"
                            aria-label="Submit Search"
                            type="button"
                            variant="icon"
                            href={searchResultsPage?.url}
                        >
                            <SearchIcon theme={theme} />
                        </Button>
                        <Button
                            className="zn-main-nav-bar__hamburger"
                            onClick={() => {
                                setNavBarDisplay(false);
                                onOpen();
                            }}
                        >
                            <div>
                                <span className="zn-hamburger__line"></span>
                                <span className="zn-hamburger__line"></span>
                                <span className="zn-hamburger__line"></span>
                            </div>
                            <Text className="zn-main-nav-bar__hamburger_text" type="body">
                                {t('Menu')}
                            </Text>
                        </Button>
                    </div>
                )}
            </nav>
        </div>
    );
};

export default NavNarMain;
