import React, { useState, useRef, useEffect, useCallback } from 'react';
import MegaNavHome from '../MegaNavHome/MegaNavHome.jsx';
import MegaNavPrimary from '../MegaNavPrimary/MegaNavPrimary.jsx';
import MegaNavSecondary from '../MegaNavSecondary/MegaNavSecondary.jsx';
import MegaNavTertiary from '../MegaNavTertiary/MegaNavTertiary.jsx';
import NavBarMain from '../NavBarMain/NavBarMain.jsx';
import { withTranslation } from 'react-i18next';
import paramToBool from '../../utils/paramToBool.js';
import { ThemeEnum } from '../../utils/enums';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import './navigation.scss';
import classNames from 'classnames';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { setAnimationInterObserver } from '../../utils/observers.jsx';
import getScrollbarWidth from '../../utils/getScrollbarWidth.js';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import BypassButton from './BypassButton.jsx';

const getOtherLanguages = (versions, language) => {
    const languages = new Set();

    versions.forEach((lan) => {
        if (lan?.language?.name !== language) {
            languages.add(lan?.language?.name);
        }
    });

    return Array.from(languages);
};
const Navigation = ({ sitecoreContext, fields, params, t }) => {
    const navBlock = 'zn-main-nav';
    if (!fields?.data || !fields?.data?.datasource) {
        return (
            <nav
                className={classNames(navBlock, {
                    'page-editing': sitecoreContext?.pageEditing,
                    [`${navBlock}__animate`]: animationEnabled,
                })}
            >
                {sitecoreContext?.pageEditing && <h5>Please select a datasource</h5>}
            </nav>
        );
    }
    const { CustomBackgroundColor, Theme, EnableAnimation } = params;
    const { Services, Industries, Offices } = fields?.data;
    const {
        lightThemeLogo,
        darkThemeLogo,
        logoLink,
        searchResultsPage,
        searchPlaceholderText,
        homeMainLinksPrimary,
        homeMainLinksSecondary,
        homeSecondaryLinks,
        homeSubscribeText,
        homeSubscribeButton,
        homeSocialMediaText,
        homeSocialMediaLinks,
        insightsContentTypeTitle,
        insightsContentTypeLinks,
        aboutUsContentTypeTitle,
        aboutUsContentTypeLinks,
        editorsPicksLinkOne,
        editorsPicksLinkTwo,
        editorsPicksLinkThree,
        editorsPicksLinkFour,
        authorableExpertiseLink,
    } = fields?.data?.datasource;

    const language = fields?.data?.contextItem?.language?.name;
    const languages = Array.isArray(fields?.data?.contextItem?.versions)
        ? getOtherLanguages(fields?.data?.contextItem?.versions, language)
        : [];

    if (typeof window === 'undefined') {
        global.window = {};
    }

    if (typeof document === 'undefined') {
        global.document = null;
    }

    const [scrollWidth, setScrollWidth] = useState(0);
    const initialTheme = Theme ? Theme?.toLowerCase() : ThemeEnum.light.toLowerCase();
    const pageUrl = window?.location?.href?.replace(/#.*$/, '').replace(`sc_lang=${language}`, '');
    const [megaMenuHome, setMegaMenuHome] = useState(false);
    const [megaMenuPrimary, setMegaMenuPrimary] = useState(false);
    const [megaMenuSecondary, setMegaMenuSecondary] = useState(false);
    const [megaMenuTertiary, setMegaMenuTertiary] = useState(false);
    const [isMegaMenuOpen, setMegaMenuOpen] = useState(false);
    const [menuActive, setMenuActive] = useState(null);
    const [navBarDisplay, setNavBarDisplay] = useState(true);
    const [qNav, setQNav] = useState(null);
    const [theme, setTheme] = useState(initialTheme);
    const [scrollPosition, setScrollPosition] = useState(window?.scrollY);

    const mainNavRef = useRef(null);
    const mainNavBarRef = useRef(null);
    const mainNavBarMenuButtonRef = useRef(null);
    const mainNavBarMenuLogoRef = useRef(null);
    const menuOpenClass = 'nav-open';
    const mainContent = document?.querySelector('body');
    const quarterlyElement = '.zn-quarterly-nav-bar-menu--padding-container';

    if (!sitecoreContext?.pageEditing) {
        mainContent?.classList?.add('body-nav');
    }
    const animationEnabled = EnableAnimation && !sitecoreContext?.pageEditing;

    useEffect(() => {
        setScrollWidth(getScrollbarWidth());
        if (animationEnabled) {
            // Scroll fade up reveal
            const fadeUpSelector = `.${navBlock} .fadeIn`;
            const fadeUpClass = 'fadeInActive';
            // Adds the class that activates the animation whenever the components are in the view
            setAnimationInterObserver(fadeUpSelector, fadeUpClass);
        }
    }, []);

    const resetMegaMenu = (isOpen) => {
        let isQuarterlyAnimationApplied = document
            ?.querySelector('body')
            ?.classList.contains('quarterly-animation');
        if (!isOpen) {
            if (isQuarterlyAnimationApplied && mainNavRef?.current?.style)
                mainNavRef.current.style.paddingRight = `${scrollWidth}px`;
            else if (mainNavRef?.current?.style) {
                mainNavRef.current.style.paddingRight = ``;
            }
        } else if (mainNavRef?.current?.style) {
            mainNavRef.current.style.paddingRight = `${scrollWidth}px`;
        }
        setMenuActive(null);
        setMegaMenuHome(false);
        setMegaMenuTertiary(false);
        setMegaMenuSecondary(false);
        setMegaMenuPrimary(false);
        setTheme(initialTheme);
        setMegaMenuOpen(isOpen);
        mainContent?.classList?.remove(menuOpenClass);
    };

    const openMegaMenuHome = () => {
        if (mainNavRef?.current) mainNavRef.current.style.paddingRight = `${scrollWidth}px`;
        setMenuActive(null);
        setNavBarDisplay(false);
        resetMegaMenu(true);
        setMegaMenuHome(true);
        setTheme(ThemeEnum.light.toLowerCase());
        mainContent?.classList?.add(menuOpenClass);
    };

    const openMegaMenuPrimary = (activeItem) => {
        resetMegaMenu(true);
        setMenuActive(activeItem);
        setMegaMenuPrimary(true);
        setTheme(ThemeEnum.light.toLowerCase());
        mainContent?.classList?.add(menuOpenClass);
    };

    const openMegaMenuSecondary = (activeItem) => {
        resetMegaMenu(true);
        setMenuActive(activeItem);
        setMegaMenuSecondary(true);
        setTheme(ThemeEnum.light.toLowerCase());
        mainContent?.classList?.add(menuOpenClass);
    };

    const openMegaMenuTertiary = (activeItem) => {
        resetMegaMenu(true);
        setMenuActive(activeItem);
        setMegaMenuTertiary(true);
        setTheme(ThemeEnum.light.toLowerCase());
        mainContent?.classList?.add(menuOpenClass);
    };

    const allMainLinks = []
        .concat(homeMainLinksPrimary?.targetItems)
        .concat(homeMainLinksSecondary?.targetItems);

    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
            mainContent?.classList?.add('no-nav');
        }
    }, []);

    useEffect(() => {
        resetMegaMenu(false);
        setTheme(initialTheme);
        setNavBarDisplay(true);
        mainContent?.classList?.remove(menuOpenClass);
        mainNavBarMenuButtonRef?.current?.classList?.remove('hide-mobile');
        mainNavBarMenuLogoRef?.current?.classList?.remove('hide-all');
        mainNavBarMenuLogoRef?.current?.classList?.remove('hide-mobile');
        let qNav = document?.querySelector(quarterlyElement);
        qNav?.classList?.remove('in-main-menu');
        qNav?.classList?.remove('no-animation-close');
        qNav?.classList?.remove('fade-out');
    }, [fields?.data?.contextItem?.id]);

    useEffect(() => {
        let qNav = document?.querySelector(quarterlyElement);
        const qNavTop = qNav?.getBoundingClientRect()?.top;
        const logoLeft = mainNavBarMenuLogoRef?.current?.getBoundingClientRect()?.left;
        const logoWidth = mainNavBarMenuLogoRef?.current?.getBoundingClientRect()?.width;
        const qNavLeft = qNav?.children[0]?.getBoundingClientRect()?.left;
        const navHeight = mainNavRef?.current.getBoundingClientRect()?.height;
        if (qNav?.style) {
            if (!isMegaMenuOpen && qNavTop > navHeight) {
                setNavBarDisplay(true);
            } else {
                if (mainNavBarMenuLogoRef.current)
                    if (logoLeft + logoWidth > qNavLeft - 16)
                        mainNavBarMenuLogoRef.current.classList.add('hide-all');
                    else mainNavBarMenuLogoRef.current.classList.add('hide-mobile');
            }
        } else {
            if (!isMegaMenuOpen) setNavBarDisplay(true);
        }
        if (isMegaMenuOpen) {
            mainNavBarMenuLogoRef.current.classList.remove('hide-all');
            mainNavBarMenuLogoRef.current.classList.remove('hide-mobile');
        }
    }, [isMegaMenuOpen]);

    const handleScroll = useCallback(
        (e, scrollPosition) => {
            let qNav = document?.querySelector(quarterlyElement);
            let qNavMenu = document?.querySelector('.zn-quarterly-nav-bar-menu');
            const qNavTop = qNav?.getBoundingClientRect()?.top;
            const qNavHeight = qNav?.getBoundingClientRect()?.height;
            const navHeight = mainNavRef?.current?.getBoundingClientRect()?.height;
            const logoLeft = mainNavBarMenuLogoRef?.current?.getBoundingClientRect()?.left;
            const logoWidth = mainNavBarMenuLogoRef?.current?.getBoundingClientRect()?.width;
            const qNavLeft = qNavMenu?.children[0]?.getBoundingClientRect()?.left;
            let window = e?.currentTarget;
            if (qNavTop <= 5 && qNavTop >= -5) {
                setNavBarDisplay(false);
                if (qNav?.style) {
                    qNav?.classList.add('in-main-menu');
                }
                if (mainNavBarMenuLogoRef.current)
                    if (logoLeft + logoWidth > qNavLeft - 16)
                        mainNavBarMenuLogoRef.current.classList.add('hide-all');
                    else mainNavBarMenuLogoRef.current.classList.add('hide-mobile');
            } else if (qNavTop > navHeight) {
                qNav?.classList.remove('no-animation-close');
                setNavBarDisplay(true);
                if (qNav?.style) {
                    qNav.style.backgroundColor = '';
                    qNav?.classList.remove('in-main-menu');
                }
                if (mainNavBarMenuButtonRef.current)
                    mainNavBarMenuButtonRef.current.classList.remove('hide-mobile');
                if (mainNavBarMenuLogoRef.current) {
                    mainNavBarMenuLogoRef.current.classList.remove('hide-mobile');
                    mainNavBarMenuLogoRef.current.classList.remove('hide-all');
                }
            } else if (5 < qNavTop && qNavTop <= navHeight / 2) {
                qNav?.classList.remove('no-animation-close');
                qNav?.classList.remove('in-main-menu');
                qNav?.classList.add('fade-out');
            } else {
                if (scrollPosition > window?.scrollY) {
                    //scrolling up
                    if (qNavTop < -5) {
                        setNavBarDisplay(true);
                        mainNavBarMenuLogoRef.current.classList.remove('hide-all');
                        mainNavBarMenuLogoRef.current.classList.remove('hide-mobile');
                    }
                } else if (scrollPosition < window?.scrollY) {
                    //scrolling down
                    if (qNavTop < -Math.abs(qNavHeight)) {
                        setNavBarDisplay(true);
                        mainNavBarMenuLogoRef.current.classList.remove('hide-all');
                        mainNavBarMenuLogoRef.current.classList.remove('hide-mobile');
                    }
                }
                setScrollPosition(window?.scrollY);
            }
        },
        [qNav]
    );

    useEffect(() => {
        setQNav(document?.querySelector(quarterlyElement));

        function scrollCallback(e) {
            handleScroll(e, scrollPosition);
        }

        if (mainNavRef?.current && qNav) {
            window?.addEventListener('scroll', scrollCallback);
        }
        return () => {
            window?.removeEventListener('scroll', scrollCallback);
            qNav?.classList.remove('in-main-menu');
        };
    }, [handleScroll, qNav, scrollPosition, fields?.data?.contextItem?.id]);

    return (
        !pdfView && (
            <nav
                className={classNames(navBlock, `${navBlock}--${theme}`, {
                    'page-editing': sitecoreContext?.pageEditing,
                    [`${navBlock}__animate`]: animationEnabled,
                    [`${navBlock}--open`]: isMegaMenuOpen,
                })}
                ref={mainNavRef}
                style={{
                    paddingRight: `${scrollWidth}px`,
                }}
            >
                <BypassButton>{t('SkipContent')}</BypassButton>

                <NavBarMain
                    t={t}
                    CustomBackgroundColor={CustomBackgroundColor}
                    language={language}
                    languages={languages}
                    theme={theme}
                    pageUrl={pageUrl}
                    homeMainLinksPrimary={homeMainLinksPrimary?.targetItems}
                    homeMainLinksSecondary={homeMainLinksSecondary?.targetItems}
                    logoLink={logoLink}
                    lightThemeLogo={lightThemeLogo}
                    darkThemeLogo={darkThemeLogo}
                    searchResultsPage={searchResultsPage}
                    searchPlaceholderText={searchPlaceholderText}
                    isMegaMenuOpen={isMegaMenuOpen}
                    navBarDisplay={navBarDisplay}
                    setNavBarDisplay={setNavBarDisplay}
                    activeItem={menuActive}
                    onOpen={openMegaMenuHome}
                    onClose={() => {
                        resetMegaMenu(false);
                    }}
                    openMegaMenuPrimary={openMegaMenuPrimary}
                    openMegaMenuSecondary={openMegaMenuSecondary}
                    openMegaMenuTertiary={openMegaMenuTertiary}
                    animationEnabled={animationEnabled}
                    mainNavBarRef={mainNavBarRef}
                    mainNavBarMenuButtonRef={mainNavBarMenuButtonRef}
                    mainNavBarMenuLogoRef={mainNavBarMenuLogoRef}
                    scrollWidth={scrollWidth}
                />
                {isMegaMenuOpen && (
                    <section className="zn-mega-menu">
                        <div className="zn-mega-menu__content">
                            {megaMenuHome && (
                                <MegaNavHome
                                    params={{ ...params, Theme: ThemeEnum.light }}
                                    theme={ThemeEnum.light.toLowerCase()}
                                    language={language}
                                    languages={languages}
                                    pageUrl={pageUrl}
                                    openMegaMenuPrimary={openMegaMenuPrimary}
                                    homeSubscribeText={homeSubscribeText}
                                    homeSocialMediaText={homeSocialMediaText}
                                    openMegaMenuSecondary={openMegaMenuSecondary}
                                    openMegaMenuTertiary={openMegaMenuTertiary}
                                    homeMainLinksPrimary={homeMainLinksPrimary?.targetItems}
                                    homeMainLinksSecondary={homeMainLinksSecondary?.targetItems}
                                    homeSecondaryLinks={homeSecondaryLinks?.targetItems}
                                    homeSocialMediaLinks={homeSocialMediaLinks?.targetItem}
                                    homeSubscribeButton={homeSubscribeButton}
                                />
                            )}
                            {megaMenuPrimary && (
                                <MegaNavPrimary
                                    language={language}
                                    openMegaMenuHome={openMegaMenuHome}
                                    resetMegaMenu={resetMegaMenu}
                                    expertiseList={[Services, Industries, Offices]}
                                    expertiseItem={allMainLinks.find((item) =>
                                        paramToBool(item?.isExpertise?.value)
                                    )}
                                    authorableExpertiseLink={authorableExpertiseLink}
                                />
                            )}
                            {megaMenuSecondary && (
                                <MegaNavSecondary
                                    t={t}
                                    openMegaMenuHome={openMegaMenuHome}
                                    insightsItem={allMainLinks.find((item) =>
                                        paramToBool(item?.isInsights?.value)
                                    )}
                                    insightsContentTypeTitle={insightsContentTypeTitle}
                                    insightsContentTypeLinks={insightsContentTypeLinks?.targetItems}
                                    editorsPicksLinks={[
                                        editorsPicksLinkOne,
                                        editorsPicksLinkTwo,
                                        editorsPicksLinkThree,
                                        editorsPicksLinkFour,
                                    ]}
                                />
                            )}
                            {megaMenuTertiary && (
                                <MegaNavTertiary
                                    openMegaMenuHome={openMegaMenuHome}
                                    insightsItem={allMainLinks.find((item) =>
                                        paramToBool(item?.isAboutUs?.value)
                                    )}
                                    aboutUsContentTypeTitle={aboutUsContentTypeTitle}
                                    aboutUsContentTypeLinks={aboutUsContentTypeLinks?.targetItems}
                                />
                            )}
                        </div>
                    </section>
                )}
            </nav>
        )
    );
};

export const NavigationSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(Navigation)
);
export default withTranslation()(NavigationSitecore);
export const NavigationStorybook = forStorybookV2(NavigationSitecore);
