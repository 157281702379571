import React from 'react';
import { Placeholder, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

const SnippetComponent = ({ rendering, fields }) => {
    if (!fields) {
        if (isExperienceEditorActive()) {
            return <h1>Datasource is not set.</h1>;
        }
        return <h1>Data is not provided. Contact administrators, please.</h1>;
    }

    return (
        <section>
            <Placeholder name="snippet-content" rendering={rendering} />
        </section>
    );
};
export default SnippetComponent;
