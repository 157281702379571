import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './quarterly-landing-hero.scss';
import forStorybook from '../../utils/forStorybook.jsx';
import { AccentColorEnum } from '../../utils/enums';
import HeadingAccent from '../HeadingAccent';
import Text from '../Text';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const qLHeroBlock = 'zn-quarterly-landing-hero';

export const QuarterlyLandingHero = ({ t, fields }) => {
    const { Title, FeaturedImage, OverlayImage, Eyebrow, Description, AccentColor } = fields;
    return (
        <section className={classnames(qLHeroBlock, {})}>
            <div className={classnames(`${qLHeroBlock}__padding`)}>
                <div className={classnames(`${qLHeroBlock}__content`)}>
                    <Text
                        className={classnames(`${qLHeroBlock}__eyebrow`)}
                        type="h6"
                        useDefaultMargin={false}
                        field={Eyebrow}
                    />
                    <RichText
                        className={classnames(`${qLHeroBlock}__title`)}
                        tag="h1"
                        field={Title}
                    />
                    <HeadingAccent params={{ AccentColor: AccentColor?.value }} />
                    <RichText
                        className={classnames(`${qLHeroBlock}__description`)}
                        tag="p"
                        field={Description}
                    />
                </div>
            </div>
            <div
                className={classnames(`${qLHeroBlock}__background-shape`)}
                style={{
                    backgroundImage: `url(${OverlayImage?.value?.src})`,
                }}
            ></div>
            <div
                className={classnames(`${qLHeroBlock}__background-image`)}
                style={{
                    backgroundImage: `url(${FeaturedImage?.value?.src})`,
                }}
            ></div>
            <div className={classnames(`${qLHeroBlock}__scroll`)}>
                <p className={classnames(`${qLHeroBlock}__scroll-text`)}>{t('Scroll')}</p>
            </div>
            <img
                className={classnames(`${qLHeroBlock}__mobile-image`)}
                src={FeaturedImage?.value?.src}
            />
        </section>
    );
};

QuarterlyLandingHero.propTypes = {
    fields: PropTypes.shape({
        Title: PropTypes.shape({
            value: PropTypes.string,
        }),
        FeaturedImage: PropTypes.shape({
            value: PropTypes.object,
        }),
        OverlayImage: PropTypes.shape({
            value: PropTypes.object,
        }),
        AccentColor: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(AccentColorEnum)),
        }),
        Description: PropTypes.shape({
            value: PropTypes.string,
        }),
        Eyebrow: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

export const QuarterlyLandingHeroStorybook = forStorybook(QuarterlyLandingHero);

export default withTranslation()(QuarterlyLandingHero);
