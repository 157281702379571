import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './social-media-links.scss';
import classnames from 'classnames';
import {
    FacebookIconComponent,
    TwitterIconComponent,
    LinkedinIconComponent,
    PdfIconComponent,
    MailIconComponent,
} from './SocialMediaIcons.jsx';
import { useLocation } from 'react-router-dom';
import { getHostname } from '../../util';
import { ThemeEnum, DisplayStyleEnum, LinkTypeEnum, SizeEnum } from '../../utils/enums';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import SocialMediaLink from './SocialMediaLink.jsx';
import CopyToClipboardButton from './CopyToClipboardButton.jsx';

import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';

const socialMediaLinksBlock = 'zn-social-media-links';

const getLinkType = (linkType, pageLink, shareLink) =>
    linkType === LinkTypeEnum.shareLink ? { value: shareLink } : pageLink;

const SocialMediaLinks = ({ fields, params, sitecoreContext }) => {
    const { language } = sitecoreContext;

    const {
        Theme,
        DisplayStyle,
        ShowLabels,
        LabelsMarginSize,
        FacebookInclude,
        FacebookLinkType,
        TwitterInclude,
        TwitterLinkType,
        LinkedInInclude,
        LinkedInLinkType,
        EmailInclude,
        PDFInclude,
        CopyUrlInclude,
    } = params;

    const {
        FacebookLabel,
        FacebookPageLink,
        FacebookIconAltText,
        TwitterLabel,
        TwitterPageLink,
        TwitterIconAltText,
        LinkedInLabel,
        LinkedInPageLink,
        LinkedInIconAltText,
        EmailLabel,
        EmailLink,
        EmailIconAltText,
        PDFLabel,
        PDFLink,
        PDFServiceURL,
        PDFIconAltText,
        CopyUrlLabel,
        CopyUrlIconAltText,
    } = fields;

    const { pathname, search, hash } = useLocation();
    const locationString = `${getHostname()}${pathname}${search}${hash}`;
    const title = `${sitecoreContext.route.displayName}`;

    const [pdfURL, setPdfURL] = useState('');

    useEffect(() => {
        if (PDFLink?.value) {
            setPdfURL(PDFLink);
        } else if (PDFServiceURL?.value) {
            setPdfURL({
                value: PDFServiceURL?.value + pathname + `?sc_lang=${language ?? 'en'}`,
            });
        } else {
            setPdfURL({
                value: '',
            });
        }
    }, [PDFLink, PDFServiceURL, pathname, language]);

    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    return (
        !pdfView && (
            <div
                className={classnames(socialMediaLinksBlock, {
                    [`${socialMediaLinksBlock}--vertical`]:
                        DisplayStyle === DisplayStyleEnum.vertical,
                    [`${socialMediaLinksBlock}--dark`]: Theme === ThemeEnum.dark,
                })}
            >
                <SocialMediaLink
                    include={FacebookInclude === '1'}
                    theme={Theme}
                    link={getLinkType(
                        FacebookLinkType,
                        FacebookPageLink,
                        `https://www.facebook.com/sharer/sharer.php?u=${locationString}`
                    )}
                    IconComponent={FacebookIconComponent}
                    alt={FacebookIconAltText}
                    label={FacebookLabel}
                    showLabel={ShowLabels}
                    labelsMarginSize={LabelsMarginSize}
                />
                <SocialMediaLink
                    include={TwitterInclude === '1'}
                    theme={Theme}
                    link={getLinkType(
                        TwitterLinkType,
                        TwitterPageLink,
                        `https://twitter.com/intent/tweet?url=${locationString}`
                    )}
                    IconComponent={TwitterIconComponent}
                    alt={TwitterIconAltText}
                    label={TwitterLabel}
                    showLabel={ShowLabels}
                    labelsMarginSize={LabelsMarginSize}
                />
                <SocialMediaLink
                    include={LinkedInInclude === '1'}
                    theme={Theme}
                    link={getLinkType(
                        LinkedInLinkType,
                        LinkedInPageLink,
                        `https://www.linkedin.com/sharing/share-offsite/?url=${locationString}`
                    )}
                    IconComponent={LinkedinIconComponent}
                    alt={LinkedInIconAltText}
                    label={LinkedInLabel}
                    showLabel={ShowLabels}
                    labelsMarginSize={LabelsMarginSize}
                />
                <SocialMediaLink
                    include={PDFInclude === '1'}
                    theme={Theme}
                    link={pdfURL}
                    alt={PDFIconAltText}
                    IconComponent={PdfIconComponent}
                    label={PDFLabel}
                    showLabel={ShowLabels}
                    labelsMarginSize={LabelsMarginSize}
                />
                <SocialMediaLink
                    include={EmailInclude === '1'}
                    theme={Theme}
                    link={{
                        value: `mailto:${EmailLink?.value}?subject=${title}&body=${title}: ${locationString}`,
                    }}
                    alt={EmailIconAltText}
                    IconComponent={MailIconComponent}
                    label={EmailLabel}
                    showLabel={ShowLabels}
                    labelsMarginSize={LabelsMarginSize}
                />
                {CopyUrlInclude === '1' && (
                    <CopyToClipboardButton
                        theme={Theme}
                        contentToCopy={locationString}
                        showLabel={ShowLabels}
                        label={CopyUrlLabel}
                        alt={CopyUrlIconAltText}
                        labelsMarginSize={LabelsMarginSize}
                    />
                )}
            </div>
        )
    );
};

SocialMediaLinks.propTypes = {
    fields: PropTypes.exact({
        FacebookPageLink: SocialMediaLink.propTypes.link,
        FacebookLabel: SocialMediaLink.propTypes.label,
        FacebookIconAltText: SocialMediaLink.propTypes.alt,
        TwitterLabel: SocialMediaLink.propTypes.label,
        TwitterPageLink: SocialMediaLink.propTypes.link,
        TwitterIconAltText: SocialMediaLink.propTypes.alt,
        LinkedInLabel: SocialMediaLink.propTypes.label,
        LinkedInPageLink: SocialMediaLink.propTypes.link,
        LinkedInIconAltText: SocialMediaLink.propTypes.alt,
        EmailLabel: SocialMediaLink.propTypes.label,
        EmailLink: SocialMediaLink.propTypes.link,
        EmailIconAltText: SocialMediaLink.propTypes.alt,
        PDFLabel: SocialMediaLink.propTypes.label,
        PDFLink: SocialMediaLink.propTypes.link,
        PDFIconAltText: SocialMediaLink.propTypes.alt,
        CopyUrlIconAltText: SocialMediaLink.propTypes.alt,
        CopyUrlLabel: SocialMediaLink.propTypes.label,
    }),
    params: PropTypes.exact({
        Theme: SocialMediaLink.propTypes.theme,
        DisplayStyle: PropTypes.oneOf(Object.values(DisplayStyleEnum)).isRequired,
        ShowLabels: SocialMediaLink.propTypes.showLabel,
        LabelsMarginSize: PropTypes.oneOf(Object.values(SizeEnum)).isRequired,
        FacebookInclude: SocialMediaLink.propTypes.include,
        FacebookLinkType: PropTypes.oneOf(Object.values(LinkTypeEnum)).isRequired,
        TwitterInclude: SocialMediaLink.propTypes.include,
        TwitterLinkType: PropTypes.oneOf(Object.values(LinkTypeEnum)).isRequired,
        LinkedInInclude: SocialMediaLink.propTypes.include,
        LinkedInLinkType: PropTypes.oneOf(Object.values(LinkTypeEnum)).isRequired,
        EmailInclude: SocialMediaLink.propTypes.include,
        PDFInclude: SocialMediaLink.propTypes.include,
        CopyUrlInclude: SocialMediaLink.propTypes.include,
    }),
};

const SocialMediaLinksSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
        SocialMediaLinks
    )
);

export const SocialMediaLinksStorybook = forStorybookV2(SocialMediaLinksSitecore);

export default SocialMediaLinksSitecore;
