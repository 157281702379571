import React, { useEffect } from 'react';
import classnames from 'classnames';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import forStorybook from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { setAnimationInterObserver } from '../../utils/observers.jsx';
import './content-card-row.scss';

const ContentCardRow = ({ params, sitecoreContext, contentCards }) => {
    const { HideOnMobile, EnableAnimation } = params;
    const contentCardRowBlock = 'zn-content-card-row';
    const animationEnabled = EnableAnimation && !sitecoreContext?.pageEditing;

    useEffect(() => {
        if (animationEnabled) {
            const selector = `.${contentCardRowBlock} .zn-content-card.fadeIn`;
            const newClass = 'fadeInActive';
            // Adds the class that activates the animation whenever the components are in the view
            setAnimationInterObserver(selector, newClass, { threshold: 0.2 });
        }
    }, []);

    return (
        <div
            className={classnames(`${contentCardRowBlock}`, {
                [`${contentCardRowBlock}--hide-mobile`]: HideOnMobile === '1',
                [`${contentCardRowBlock}__animate`]: animationEnabled,
            })}
        >
            {contentCards}
        </div>
    );
};

const ContentCardRowSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params])(ContentCardRow)
);
export default withPlaceholder('contentCards')(ContentCardRowSitecore);
export const ContentCardRowStorybook = forStorybook(ContentCardRow);
