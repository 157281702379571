import { includeInMapCondition, mapMarkerInheritanceCondition } from '../../utils/constants';
import { sortFilterValues } from '../../utils/searchHelpers.jsx';

const updateTotalSelected = (categoryFilters) => {
    let totalSelected = 0;
    categoryFilters?.map((highLevelFilter) => {
        totalSelected += highLevelFilter?.lowLevelFilters?.filter(
            (lowLevelFilter) => lowLevelFilter?.checked && lowLevelFilter?.hasResults
        ).length;
    });
    return totalSelected;
};

export const getAdFilTotalSelected = (advancedFilters) => {
    let totalSelected = 0;
    advancedFilters?.map((filter) => {
        filter?.highLevelFilters?.map((highLevelFilter) => {
            if (highLevelFilter?.checked && highLevelFilter?.hasResults) totalSelected += 1;
            totalSelected += highLevelFilter?.lowLevelFilters?.filter(
                (lowLevelFilter) => lowLevelFilter?.checked && lowLevelFilter?.hasResults
            ).length;
        });
    });
    return totalSelected;
};

const flattenFilterOptions = (filter) => {
    let array = [];
    array.push({
        title: filter.title?.value,
        identifier: filter.identifier?.value,
        id: filter?.id,
    });
    if (Array.isArray(filter.children)) {
        filter.children.forEach(function (a) {
            array = array.concat(flattenFilterOptions(a));
        });
    }
    return array;
};

export const updateLowLevelFilter = (lowLevelId, highLevelId, categoryId, filters) => {
    let updatedHighLevelFilters;
    let updatedLowLevelFilters;
    return {
        advancedFilters: filters?.advancedFilters,
        mapTagFilters: filters?.mapTagFilters?.map((category) => {
            if (category?.identifier === categoryId) {
                updatedHighLevelFilters = category?.highLevelFilters?.map((highLevelFilter) => {
                    if (highLevelFilter?.identifier === highLevelId) {
                        updatedLowLevelFilters = highLevelFilter?.lowLevelFilters?.map(
                            (lowLevelFilter) => {
                                if (lowLevelFilter?.identifier === lowLevelId) {
                                    return {
                                        ...lowLevelFilter,
                                        checked: !lowLevelFilter.checked,
                                    };
                                } else return lowLevelFilter;
                            }
                        );
                        return {
                            ...highLevelFilter,
                            checked:
                                updatedLowLevelFilters.filter((filter) => filter?.checked).length >
                                0,
                            lowLevelFilters: updatedLowLevelFilters,
                        };
                    } else return highLevelFilter;
                });
                return {
                    ...category,
                    highLevelFilters: updatedHighLevelFilters,
                    totalSelected: updateTotalSelected(updatedHighLevelFilters),
                };
            } else return category;
        }),
    };
};

export const updateAdvancedFilter = (id, pId, filters) => {
    let updatedLowLevelFilters; //will probably need later
    let updatedHighLevelFilters;
    return {
        mapTagFilters: filters?.mapTagFilters,
        advancedFilters: filters?.advancedFilters?.map((category) => {
            if (category?.identifier === pId) {
                updatedHighLevelFilters = category?.highLevelFilters?.map((highLevelFilter) => {
                    let childOption = highLevelFilter?.lowLevelFilters.find(
                        (item) => item?.identifier === id
                    );
                    if (highLevelFilter?.identifier === id) {
                        return {
                            ...highLevelFilter,
                            checked: !highLevelFilter.checked,
                        };
                    } else if (childOption) {
                        //it is contained in its children
                        updatedLowLevelFilters = highLevelFilter?.lowLevelFilters?.map(
                            (lowLevelFilter) => {
                                if (lowLevelFilter?.identifier === id) {
                                    return {
                                        ...lowLevelFilter,
                                        checked: !lowLevelFilter.checked,
                                    };
                                } else return lowLevelFilter;
                            }
                        );
                        return {
                            ...highLevelFilter,
                            lowLevelFilters: updatedLowLevelFilters,
                        };
                    } else return highLevelFilter;
                });
                return {
                    ...category,
                    highLevelFilters: updatedHighLevelFilters,
                };
            } else return category;
        }),
    };
};

export const updateHighLevelFilter = (highLevelId, categoryId, filters) => {
    let updatedHighLevelFilters;
    let updatedLowLevelFilters;
    return {
        advancedFilters: filters?.advancedFilters,
        mapTagFilters: filters?.mapTagFilters?.map((category) => {
            if (category?.identifier === categoryId) {
                updatedHighLevelFilters = category?.highLevelFilters?.map((highLevelFilter) => {
                    if (highLevelFilter?.identifier === highLevelId) {
                        updatedLowLevelFilters = highLevelFilter?.lowLevelFilters?.map(
                            (lowLevelFilter) => {
                                return {
                                    ...lowLevelFilter,
                                    checked: lowLevelFilter?.hasResults
                                        ? !highLevelFilter.checked
                                        : false,
                                };
                            }
                        );
                        return {
                            ...highLevelFilter,
                            checked: !highLevelFilter.checked,
                            lowLevelFilters: updatedLowLevelFilters,
                        };
                    } else return highLevelFilter;
                });
                return {
                    ...category,
                    highLevelFilters: updatedHighLevelFilters,
                    totalSelected: updateTotalSelected(updatedHighLevelFilters),
                };
            } else return category;
        }),
    };
};

export const clearHighLevelFilter = (highLevelId, categoryId, filters, isClear) => {
    let updatedHighLevelFilters;
    let updatedLowLevelFilters;
    return {
        advancedFilters: filters?.advancedFilters,
        mapTagFilters: filters?.mapTagFilters?.map((category) => {
            if (category?.identifier === categoryId) {
                updatedHighLevelFilters = category?.highLevelFilters?.map((highLevelFilter) => {
                    if (highLevelFilter?.identifier === highLevelId) {
                        updatedLowLevelFilters = highLevelFilter?.lowLevelFilters?.map(
                            (lowLevelFilter) => {
                                return {
                                    ...lowLevelFilter,
                                    checked: lowLevelFilter?.hasResults ? !isClear : false,
                                };
                            }
                        );
                        return {
                            ...highLevelFilter,
                            checked: !isClear,
                            lowLevelFilters: updatedLowLevelFilters,
                        };
                    } else return highLevelFilter;
                });
                return {
                    ...category,
                    highLevelFilters: updatedHighLevelFilters,
                    totalSelected: updateTotalSelected(updatedHighLevelFilters),
                };
            } else return category;
        }),
    };
};

export const updateAdvancedFilterSection = (categoryId, filters, isClear) => {
    let updatedHighLevelFilters;
    let updatedLowLevelFilters;
    return {
        mapTagFilters: filters?.mapTagFilters,
        advancedFilters: filters?.advancedFilters?.map((category) => {
            if (category?.identifier === categoryId) {
                updatedHighLevelFilters = category?.highLevelFilters?.map((highLevelFilter) => {
                    updatedLowLevelFilters = highLevelFilter?.lowLevelFilters?.map(
                        (lowLevelFilter) => {
                            return {
                                ...lowLevelFilter,
                                checked: !isClear,
                            };
                        }
                    );
                    return {
                        ...highLevelFilter,
                        checked: !isClear,
                        lowLevelFilters: updatedLowLevelFilters,
                    };
                });
                return {
                    ...category,
                    highLevelFilters: updatedHighLevelFilters,
                };
            } else return category;
        }),
    };
};

export const updateAdvancedFilterHighLevelSection = (highLevelId, categoryId, filters, isClear) => {
    let updatedHighLevelFilters;
    let updatedLowLevelFilters;
    return {
        mapTagFilters: filters?.mapTagFilters,
        advancedFilters: filters?.advancedFilters?.map((category) => {
            if (category?.identifier === categoryId) {
                updatedHighLevelFilters = category?.highLevelFilters?.map((highLevelFilter) => {
                    updatedLowLevelFilters = highLevelFilter?.lowLevelFilters?.map(
                        (lowLevelFilter) => {
                            return {
                                ...lowLevelFilter,
                                checked: !isClear,
                            };
                        }
                    );
                    return {
                        ...highLevelFilter,
                        checked: !isClear,
                        lowLevelFilters: updatedLowLevelFilters,
                    };
                });
                return {
                    ...category,
                    highLevelFilters: updatedHighLevelFilters,
                };
            } else return category;
        }),
    };
};

export const updateCategory = (categoryId, filters) => {
    let updatedHighLevelFilters;
    let updatedLowLevelFilters;
    return {
        advancedFilters: filters?.advancedFilters,
        mapTagFilters: filters?.mapTagFilters?.map((category) => {
            if (category?.identifier === categoryId) {
                updatedHighLevelFilters = category?.highLevelFilters?.map((highLevelFilter) => {
                    updatedLowLevelFilters = highLevelFilter?.lowLevelFilters?.map(
                        (lowLevelFilter) => {
                            return {
                                ...lowLevelFilter,
                                checked: false,
                            };
                        }
                    );
                    return {
                        ...highLevelFilter,
                        checked: false,
                        lowLevelFilters: updatedLowLevelFilters,
                    };
                });
                return {
                    ...category,
                    highLevelFilters: updatedHighLevelFilters,
                    totalSelected: updateTotalSelected(updatedHighLevelFilters),
                };
            } else return category;
        }),
    };
};

export const updateAllCategories = (filters) => {
    let updatedHighLevelFiltersMap;
    let updatedLowLevelFiltersMap;
    let updatedHighLevelFiltersAd;
    let updatedLowLevelFiltersAd;
    return {
        advancedFilters: filters?.advancedFilters?.map((category) => {
            updatedHighLevelFiltersAd = category?.highLevelFilters?.map((highLevelFilter) => {
                updatedLowLevelFiltersAd = highLevelFilter?.lowLevelFilters?.map(
                    (lowLevelFilter) => {
                        return {
                            ...lowLevelFilter,
                            checked: false,
                        };
                    }
                );
                return {
                    ...highLevelFilter,
                    checked: false,
                    lowLevelFilters: updatedLowLevelFiltersAd,
                };
            });
            return {
                ...category,
                highLevelFilters: updatedHighLevelFiltersAd,
            };
        }),
        mapTagFilters: filters?.mapTagFilters?.map((category) => {
            updatedHighLevelFiltersMap = category?.highLevelFilters?.map((highLevelFilter) => {
                updatedLowLevelFiltersMap = highLevelFilter?.lowLevelFilters?.map(
                    (lowLevelFilter) => {
                        return {
                            ...lowLevelFilter,
                            checked: false,
                        };
                    }
                );
                return {
                    ...highLevelFilter,
                    checked: false,
                    lowLevelFilters: updatedLowLevelFiltersMap,
                };
            });
            return {
                ...category,
                highLevelFilters: updatedHighLevelFiltersMap,
                totalSelected: updateTotalSelected(updatedHighLevelFiltersMap),
            };
        }),
    };
};

export const clearAllAdvancedFilters = (filters) => {
    let updatedHighLevelFiltersAd;
    let updatedLowLevelFiltersAd;
    return {
        advancedFilters: filters?.advancedFilters?.map((category) => {
            updatedHighLevelFiltersAd = category?.highLevelFilters?.map((highLevelFilter) => {
                updatedLowLevelFiltersAd = highLevelFilter?.lowLevelFilters?.map(
                    (lowLevelFilter) => {
                        return {
                            ...lowLevelFilter,
                            checked: false,
                        };
                    }
                );
                return {
                    ...highLevelFilter,
                    checked: false,
                    lowLevelFilters: updatedLowLevelFiltersAd,
                };
            });
            return {
                ...category,
                highLevelFilters: updatedHighLevelFiltersAd,
            };
        }),
        mapTagFilters: filters?.mapTagFilters,
    };
};

export const updateFacets = (flattenedFacets, current) => {
    let updatedHighLevelFiltersMap;
    let updatedLowLevelFiltersMap;
    let updatedHighLevelFiltersAd;
    let updatedLowLevelFiltersAd;
    return {
        advancedFilters: current?.advancedFilters?.map((category) => {
            let categoryFacet = flattenedFacets.find(
                (facet) => facet?.name === category?.fieldName
            );
            updatedHighLevelFiltersAd = category?.highLevelFilters?.map((highLevelFilter) => {
                updatedLowLevelFiltersAd = highLevelFilter?.lowLevelFilters?.map(
                    (lowLevelFilter) => {
                        return {
                            ...lowLevelFilter,
                            hasResults: categoryFacet?.values.includes(lowLevelFilter?.id),
                        };
                    }
                );
                return {
                    ...highLevelFilter,
                    hasResults: categoryFacet?.values.includes(highLevelFilter?.id),
                    lowLevelFilters: updatedLowLevelFiltersAd,
                };
            });
            return {
                ...category,
                highLevelFilters: updatedHighLevelFiltersAd,
            };
        }),
        mapTagFilters: current?.mapTagFilters?.map((category) => {
            let categoryFacet = flattenedFacets.find(
                (facet) => facet?.name === category?.fieldName
            );
            updatedHighLevelFiltersMap = category?.highLevelFilters?.map((highLevelFilter) => {
                updatedLowLevelFiltersMap = highLevelFilter?.lowLevelFilters?.map(
                    (lowLevelFilter) => {
                        return {
                            ...lowLevelFilter,
                            hasResults: categoryFacet?.values.includes(lowLevelFilter?.id),
                        };
                    }
                );
                return {
                    ...highLevelFilter,
                    hasResults: categoryFacet?.values.includes(highLevelFilter?.id),
                    lowLevelFilters: updatedLowLevelFiltersMap,
                };
            });
            return {
                ...category,
                highLevelFilters: updatedHighLevelFiltersMap,
                totalSelected: updateTotalSelected(updatedHighLevelFiltersMap),
            };
        }),
    };
};

export const formatProjectSearchBarFilters = (projectSearchBarFilters, urlFilters) => {
    return projectSearchBarFilters?.map((filter) => ({
        fieldName: filter?.name.toLowerCase(),
        title: filter?.title?.value,
        identifier: filter?.name,
        searchReset: false,
        totalSelected: 0,
        highLevelFilters: filter?.children?.map((highLevelFilter) => ({
            id: highLevelFilter?.id.toLowerCase(),
            title: highLevelFilter?.title?.value,
            identifier: highLevelFilter?.identifier?.value,
            hasResults: true,
            checked: urlFilters?.includes(highLevelFilter?.identifier?.value), //will change
            lowLevelFilters: highLevelFilter?.children
                .map((lowLevelFilter) => ({
                    id: lowLevelFilter?.id.toLowerCase(),
                    title: lowLevelFilter?.title?.value,
                    label: lowLevelFilter?.title?.value,
                    identifier: lowLevelFilter?.identifier?.value,
                    hasResults: true,
                    checked: urlFilters?.includes(lowLevelFilter?.identifier?.value),
                }))
                .sort(sortFilterValues),
        })),
    }));
};

export const getTextFacetValues = (advancedFilters) => {
    return advancedFilters
        ? advancedFilters
              .filter((advancedFilter) => advancedFilter?.textValueProperty?.value)
              ?.map((advancedFilter) => ({
                  title: advancedFilter?.title?.value,
                  facetName: advancedFilter?.textValueProperty?.value,
              }))
        : [];
};

export const formatAdvancedFilters = (advancedFilters, pinnedAdvancedFilters, urlTags, urlObj) => {
    return advancedFilters?.map((advancedFilter) => ({
        fieldName: advancedFilter?.isTextAdFilter
            ? advancedFilter?.textValueProperty?.value
            : 'tags',
        title: advancedFilter?.title?.value,
        identifier: advancedFilter?.title?.value,
        searchReset: false,
        totalSelected: 0,
        isTextAdFilter: advancedFilter?.isTextAdFilter,
        isAdFilter: true,
        highLevelFilters: advancedFilter?.isTextAdFilter
            ? advancedFilter?.values?.map((item) => {
                  return {
                      title: item?.value,
                      hasResults: true,
                      checked: urlObj[advancedFilter?.textValueProperty?.value]?.includes(
                          item?.value
                      ),
                      id: item?.value,
                      identifier: item?.value,
                      lowLevelFilters: [],
                  };
              })
            : advancedFilter?.filter?.tags[0]?.children.map((item) => {
                  let optionValues = flattenFilterOptions(item);
                  let titleOption = optionValues.shift();
                  return {
                      id: titleOption?.id?.toLowerCase(),
                      title: titleOption?.title,
                      identifier: titleOption?.identifier,
                      hasResults: true,
                      checked:
                          urlTags?.includes(titleOption?.identifier) ||
                          pinnedAdvancedFilters?.includes(item?.id),
                      lowLevelFilters: optionValues.map((item) => ({
                          id: item?.id?.toLowerCase(),
                          title: item?.title,
                          identifier: item?.identifier,
                          hasResults: true,
                          checked:
                              urlTags?.includes(item?.identifier) ||
                              pinnedAdvancedFilters?.includes(item?.id),
                      })),
                  };
              }),
    }));
};

export const constructSearchConditions = (filtersObject) => {
    let searchConditions = [mapMarkerInheritanceCondition, includeInMapCondition];
    //each category is a group
    //need inheritance condition and include in map condition
    //the page tag condition
    filtersObject?.mapTagFilters?.map((category) => {
        let categoryConditions = [];
        category?.highLevelFilters?.map((highLevelFilter) => {
            //on map tag filters do not send the high level tag to search although applied visually
            highLevelFilter?.lowLevelFilters?.map((lowLevelFilter) => {
                if (lowLevelFilter?.checked)
                    categoryConditions.push({
                        name: category?.fieldName,
                        value: lowLevelFilter?.id,
                        useor: true,
                    });
            });
        });
        categoryConditions.length &&
            searchConditions.push({
                group: categoryConditions,
            });
    });
    filtersObject?.advancedFilters?.map((category) => {
        let categoryConditions = [];
        if (category?.isTextAdFilter) {
            category?.highLevelFilters?.map((highLevelFilter) => {
                if (highLevelFilter?.checked)
                    categoryConditions.push({
                        name: category?.fieldName,
                        value: highLevelFilter?.id,
                        useor: true,
                    });
            });
        } else {
            category?.highLevelFilters?.map((highLevelFilter) => {
                if (highLevelFilter?.checked)
                    categoryConditions.push({
                        name: 'tags',
                        value: highLevelFilter?.id,
                        useor: true,
                    });
                highLevelFilter?.lowLevelFilters?.map((lowLevelFilter) => {
                    if (lowLevelFilter?.checked)
                        categoryConditions.push({
                            name: 'tags',
                            value: lowLevelFilter?.id,
                            useor: true,
                        });
                });
            });
        }
        categoryConditions.length &&
            searchConditions.push({
                group: categoryConditions,
            });
    });

    return searchConditions;
};

export const formatFilterParams = (filters) => {
    let mapFilterKeyValuePairs = filters?.mapTagFilters?.map((category) => {
        let name = category?.fieldName;
        let value = [];
        category?.highLevelFilters?.map((highLevelFilter) => {
            if (highLevelFilter?.checked && highLevelFilter?.hasResults)
                value.push(highLevelFilter?.identifier);
            highLevelFilter?.lowLevelFilters?.map((lowLevelFilter) => {
                if (lowLevelFilter?.checked && lowLevelFilter?.hasResults)
                    value.push(lowLevelFilter?.identifier);
            });
        });
        return { name: name, value: value.join() };
    });
    let adFilterKeyValuePairs = [];
    let adFilterKeyValues = filters?.advancedFilters
        ?.map((category) => {
            if (category?.isTextAdFilter) {
                let name = category?.fieldName;
                let value = [];
                category?.highLevelFilters?.map((highLevelFilter) => {
                    if (highLevelFilter?.checked && highLevelFilter?.hasResults)
                        value.push(highLevelFilter?.identifier);
                    highLevelFilter?.lowLevelFilters?.map((lowLevelFilter) => {
                        if (lowLevelFilter?.checked && lowLevelFilter?.hasResults)
                            value.push(lowLevelFilter?.identifier);
                    });
                });
                adFilterKeyValuePairs.push({ name: name, value: value.join() });
            } else {
                let value = [];
                category?.highLevelFilters?.map((highLevelFilter) => {
                    if (highLevelFilter?.checked && highLevelFilter?.hasResults)
                        value.push(highLevelFilter?.identifier); //need to encode the variables
                    highLevelFilter?.lowLevelFilters?.map((lowLevelFilter) => {
                        if (lowLevelFilter?.checked && lowLevelFilter?.hasResults)
                            value.push(lowLevelFilter?.identifier);
                    });
                });
                return value.join();
            }
        })
        .filter((item) => item);
    let tagKeyValuePairs = { name: 'tags', value: adFilterKeyValues.join() };
    return [mapFilterKeyValuePairs, adFilterKeyValuePairs, tagKeyValuePairs];
};
