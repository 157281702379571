import React from 'react';
import paramToBool from '../../utils/paramToBool.js';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import classNames from 'classnames';

import './column-grid.scss';

const ColumnGrid = ({ column, params, style, sitecoreContext }) => {
    const {
        Columns,
        PaddingLeft,
        PaddingRight,
        HasYPadding,
        HideMobile,
        VerticalAlignment,
    } = params;
    return (
        (sitecoreContext?.pageEditing || column?.length > 0) && (
            <div
                style={style}
                className={classNames('zn-column', {
                    [`zn-column--${Columns}`]: Columns,
                    [`zn-column--padding-left--${PaddingLeft?.toLowerCase()}`]: PaddingLeft,
                    [`zn-column--padding-right--${PaddingRight?.toLowerCase()}`]: PaddingRight,
                    ['zn-column--hide-mobile']: paramToBool(HideMobile),
                    ['zn-column--has-y-padding']: paramToBool(HasYPadding),
                    [`zn-column--vertical-${VerticalAlignment?.toLowerCase()?.replace(
                        / /g,
                        '-'
                    )}`]: VerticalAlignment,
                })}
            >
                {column}
            </div>
        )
    );
};

export const ColumnGridPlaceholder = withSitecoreContext()(withPlaceholder('column')(ColumnGrid));
export const ColumnGridStorybook = forStorybook(ColumnGrid);
export default ColumnGridPlaceholder;
