import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import {
    Text,
    RichText as RichTextJss,
    withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import { withTranslation } from 'react-i18next';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import FeaturedList from '../FeaturedList/FeaturedList.jsx';
import FeaturedListItem from '../FeaturedListItem/FeaturedListItem.jsx';
import { BasicLinkList as LinkList } from '../LinkList/LinkList.jsx';
import LinkListItem from '../LinkListItem/LinkListItem.jsx';
import { ExternalLink } from '../../assets/LinkIcons.js';
import {
    ThemeEnum,
    FeaturedListLayoutEnum,
    LinkTargetValueEnum,
    HighlightColorItemEnum,
    LinkListLayoutEnum,
    RepresentativeItemsEnum,
    TimeZoneEnum,
} from '../../utils/enums';
import {
    relatedPeopleCondition,
    relatedFeaturedPeopleCondition,
    templateNameCondition,
} from '../../utils/constants';
import { mapDisplayTypeToTemplate, formatSearchParams } from '../../utils/searchHelpers.jsx';
import { fetchRepresentativeResults } from '../../actions';
import '../RichText/rich-text.scss';
import './bio-related-article-list.scss';

const bioRelatedArticleListBlock = 'zn-bio-related-article-list';

/**
 * Bio Related Article List with GraphQL CustomSearch Query
 *
 * Once this component is authored on a Bio Page the representative and featured items are displayed
 *
 * The following steps are taken to display the featured/representative items associated to the page template and component:
 *
 * 1. Retrieve itemId from the page context
 * 2. Retrieve selected content types from component authored field
 * 3. Retrieve page representative items from page template based on selected context item authored field
 * 4. Retrieve component representative items from the component authored treelist field
 * 5. Construct graphql custom search query conditions for featured result:
      (_templatename = 'Content Types' AND RelatedFeaturedPeople = 'Page Item Id')
 * 6. Construct graphql custom search query conditions for representative results: 
    (_templatename = 'Content Types' AND RelatedPeople = 'Page Item Id') AND excludeIds = (page context representative items, manually authored representative items)
 * 7. Fetch FeaturedItemsCount number of featured results
 * 8. Set Featured Results
 * 9. Append ids of Featured Results to excludedIds array of representative results query conditions
 * 10. Fetch the remaining number of representative results with count: 
       (Representative Items Count - PageContext Representative Items - Component Representative Items = Number of Fetched Representative Results)
 * 11. Append fetched representative results to the representative items to be displayed adn set representative results
 *
 */

const BioRelatedArticleList = ({ fields, sitecoreContext, t }) => {
    const {
        Description,
        FeaturedItemsCount,
        RepresentativeTitle,
        ContentTypes,
        ButtonText,
        SearchResultsPage,
        RepresentativeItemsCount,
        RepresentativeItems,
        RepresentativeItemType,
        ContextDescriptionField,
    } = fields;

    // 1. Page Item ID
    const bioPageId = sitecoreContext?.route?.itemId;

    if (
        sitecoreContext?.route?.templateName !== 'Bio Page' &&
        sitecoreContext?.route?.templateName !== 'Partial Design' &&
        sitecoreContext?.route?.templateName !== 'Page Design '
    ) {
        return (
            <p>
                Bio Related Articles List Component must be used with a content item with the
                template name Bio Page
            </p>
        );
    }
    let description = Description;
    if (!Description?.value) {
        description = {
            value: sitecoreContext?.route?.fields?.[ContextDescriptionField?.value]?.value,
        };
    }

    const isEditing = sitecoreContext?.pageEditing;
    const featuredItemsCount = FeaturedItemsCount?.value
        ? parseInt(FeaturedItemsCount?.value, 10)
        : 3;
    const representativeItemsCount = RepresentativeItemsCount?.value
        ? parseInt(RepresentativeItemsCount?.value, 10)
        : 5;
    const [featuredResults, setFeaturedResults] = useState([]);
    const [representativeResults, setRepresentativeResults] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const ButtonTag = isEditing ? 'div' : 'a';
    let featuredConditions = [];
    let representativeConditions = [];
    let contextItemField = RepresentativeItemType?.value
        ? RepresentativeItemType?.value.replace(/\s+/g, '')
        : '';

    // 2. Authored Content Types
    let templates = ContentTypes.map((item) => item.name);

    // 3. Page Representative Context Items
    const representativeContextItems =
        contextItemField && sitecoreContext?.route?.fields?.[contextItemField]
            ? sitecoreContext?.route?.fields?.[contextItemField].filter(
                  (item) => item?.fields?.IncludeInSearch?.value
              )
            : [];
    // 4. Page Representative Context Items and Component Representative Items
    let displayedRepresentativeItems = representativeContextItems.concat(
        RepresentativeItems.filter((x) => !representativeContextItems.some((y) => x?.id === y?.id))
    );

    // 5. Construct Featured Results Query Conditions, map content types filter types to page template names
    let formattedTemplates =
        templates.length > 0 &&
        mapDisplayTypeToTemplate(templates).map((item) =>
            formatSearchParams(templateNameCondition, item)
        );

    formattedTemplates.length > 0 && featuredConditions.push({ group: [...formattedTemplates] });
    featuredConditions.push({
        name: relatedFeaturedPeopleCondition,
        value: bioPageId.replace(/-/g, ''),
    });
    // 6. Construct Representative Query Conditions
    formattedTemplates.length > 0 &&
        representativeConditions.push({ group: [...formattedTemplates] });
    representativeConditions.push({
        name: relatedPeopleCondition,
        value: bioPageId.replace(/-/g, ''),
    });

    let templateCallParams = templates.join();

    // 11. Set Representative Results
    const representativeResultsHandler = (representativeResults) => {
        setRepresentativeResults(
            displayedRepresentativeItems.concat(
                representativeResults.map((result) => ({
                    name: result?.item?.teaserTitle?.value,
                    url: result?.item?.url,
                    date: result?.item?.teaserDate?.value,
                    fields: {
                        // map the display teaser date value from the graphql results to the representative results array
                        TeaserTitle: {
                            value: result?.item?.teaserTitle?.value,
                        },
                        FeaturedTeaserTitle: {
                            value: result?.item?.featuredTeaserTitle?.value,
                        },
                        Narrative: {
                            value: result?.item?.narrative?.value,
                        },
                        TeaserDate: {
                            value: result?.item?.teaserDate?.value,
                        },
                        TeaserDisplayDate: {
                            value: result?.item?.teaserDisplayDate?.value,
                        },
                        NotNavigatable: {
                            value: result?.item?.notNavigatable?.value === '1',
                        },
                        Source: {
                            value: result?.item?.source?.value,
                        },
                    },
                }))
            )
        );
        setIsLoading(false);
    };
    // 8. Set Featured Results
    const featuredResultsHandler = (featuredResults) => {
        setFeaturedResults(featuredResults);
        // 9. Append featured result ids to exclude ids condition
        let excludedIds = (displayedRepresentativeItems || [])
            .map((item) => item.id.replace(/-/g, '').toUpperCase())
            .concat((featuredResults || []).map((item) => item.id.replace(/-/g, '').toUpperCase()));
        // 10. Fetch Representative Results
        representativeItemsCount - displayedRepresentativeItems.length > 0
            ? fetchRepresentativeResults(
                  representativeConditions,
                  [],
                  representativeItemsCount - displayedRepresentativeItems.length,
                  '0',
                  'teaserdate',
                  true,
                  excludedIds.length ? excludedIds : [''],
                  '',
                  false
              )
                  .then((x) => {
                      representativeResultsHandler(x.results);
                  })
                  .catch(() => {
                      setIsLoading(false);
                  })
            : setRepresentativeResults(displayedRepresentativeItems);
        setIsLoading(false);
    };

    // Display logic
    const showButton =
        SearchResultsPage?.url &&
        ButtonText?.value &&
        (representativeResults.length > 0 || featuredResults.length > 0);

    useEffect(() => {
        if (ContentTypes.length > 0) {
            // 7. Fetch Featured Results
            fetchRepresentativeResults(
                featuredConditions,
                [],
                featuredItemsCount,
                '0',
                'teaserdate',
                true,
                [''],
                '',
                false
            )
                .then((x) => {
                    featuredResultsHandler(x.results);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setRepresentativeResults(displayedRepresentativeItems);
            setIsLoading(false);
        }
    }, []);

    return (
        <div className={`${bioRelatedArticleListBlock}`}>
            {(description?.value || isEditing) && (
                <div className={`${bioRelatedArticleListBlock}__description`}>
                    <div className={classnames(`zn-rich-text`, `zn-rich-text__wrapper`)}>
                        <RichTextJss field={description}></RichTextJss>
                    </div>
                </div>
            )}
            {(featuredResults.length > 0 || isEditing) && (
                <FeaturedList
                    params={{ Layout: FeaturedListLayoutEnum.oneColumn, EnableAnimation: '0' }}
                >
                    {(featuredResults || []).map((result, index) => {
                        if (result && index < featuredItemsCount) {
                            let featuredTeaserTitle = result?.item?.featuredTeaserTitle?.value;
                            let teaserTitle = result?.item?.teaserTitle?.value;
                            let featuredTeaserDescription =
                                result?.item?.featuredTeaserDescription?.value;
                            let teaserDescription = result?.item?.teaserDescription?.value;
                            if (!featuredTeaserTitle && !teaserTitle) return;
                            return (
                                <>
                                    <FeaturedListItem
                                        key={`degree-${index}`}
                                        params={{
                                            Highlights: templates?.includes('Insights')
                                                ? HighlightColorItemEnum.orange
                                                : HighlightColorItemEnum.teal,
                                            DividerLines: '0',
                                            AddTitleArrows: '0',
                                            EnableAnimation: false,
                                        }}
                                        fields={{
                                            Title: featuredTeaserTitle //always use featured teaser title on bio page if doesn't exist no fall back
                                                ? {
                                                      value: featuredTeaserTitle,
                                                  }
                                                : teaserTitle
                                                ? {
                                                      value: teaserTitle,
                                                  }
                                                : {},
                                            ParagraphText: featuredTeaserDescription
                                                ? {
                                                      value: featuredTeaserDescription,
                                                  }
                                                : teaserDescription
                                                ? {
                                                      value: teaserDescription,
                                                  }
                                                : {},
                                            LinkURL:
                                                result?.item?.notNavigatable?.value === '1'
                                                    ? {
                                                          value: {},
                                                      }
                                                    : {
                                                          value: {
                                                              href: result?.item.url,
                                                              text: t('ReadMore'),
                                                              title:
                                                                  result?.item?.teaserTitle?.value,
                                                              target: LinkTargetValueEnum.self,
                                                          },
                                                      },
                                        }}
                                    ></FeaturedListItem>
                                </>
                            );
                        }
                    })}
                </FeaturedList>
            )}
            {(representativeResults.length > 0 || isEditing) && (
                <div className={`${bioRelatedArticleListBlock}__representative-work`}>
                    {(RepresentativeTitle?.value || isEditing) && (
                        <div className={`${bioRelatedArticleListBlock}__secondary-title`}>
                            <Text field={RepresentativeTitle}></Text>
                        </div>
                    )}
                    <LinkList params={{ Layout: LinkListLayoutEnum.singleColumn }}>
                        {/* here we map the representative results into the link list */}
                        {(representativeResults || []).map((result, index) => {
                            if (result && index < representativeItemsCount) {
                                const teaserDate = result?.fields?.TeaserDate?.value
                                    ? result?.fields?.TeaserDate?.value
                                          .replace(/:/g, '')
                                          .replace(/-/g, '')
                                    : '';
                                const ContextTeaserDate = teaserDate
                                    ? DateTime.fromISO(teaserDate, {
                                          locale: sitecoreContext?.route?.itemLanguage,
                                      })
                                          .setZone(TimeZoneEnum.server)
                                          .toLocaleString(DateTime.DATE_MED)
                                    : '';

                                const teaserDisplayDate = result?.fields?.TeaserDisplayDate?.value
                                    ? result?.fields?.TeaserDisplayDate?.value
                                    : '';

                                const FinalDate = teaserDisplayDate
                                    ? teaserDisplayDate
                                    : ContextTeaserDate && DateTime.fromISO(teaserDate).year === 1
                                    ? ''
                                    : ContextTeaserDate && DateTime.fromISO(teaserDate).year !== 0
                                    ? ContextTeaserDate
                                    : '';

                                if (
                                    !result?.fields?.Narrative?.value &&
                                    !result?.fields?.TeaserTitle?.value
                                )
                                    return;
                                return (
                                    <>
                                        <LinkListItem
                                            key={index}
                                            params={{
                                                Layout: LinkListLayoutEnum.singleColumn,
                                                NoPadding: '0',
                                                HideDividerLines: '0',
                                                Theme: ThemeEnum.light,
                                                HideArrows: '1',
                                            }}
                                            fields={{
                                                LinkName: result?.fields?.Narrative?.value //use the narrative if it exists else use the teaser title
                                                    ? {
                                                          value: result?.fields?.Narrative?.value,
                                                      }
                                                    : {
                                                          value: result?.fields?.TeaserTitle?.value,
                                                      },
                                                LinkEyebrow: {
                                                    value:
                                                        result?.fields?.TeaserDate?.value &&
                                                        (templates.includes('Insights') ||
                                                            templates.includes('News') ||
                                                            ContextDescriptionField?.value ===
                                                                RepresentativeItemsEnum.representativeNews ||
                                                            ContextDescriptionField?.value ===
                                                                RepresentativeItemsEnum.representativeInsights)
                                                            ? FinalDate
                                                            : '',
                                                },
                                                LinkURL: result?.fields?.NotNavigatable?.value
                                                    ? {
                                                          value: {},
                                                      }
                                                    : {
                                                          value: {
                                                              href: result?.url,
                                                              text: result?.name,
                                                              title: result?.name,
                                                              target: LinkTargetValueEnum.self,
                                                          },
                                                      },
                                                LinkSubtext: result?.fields?.Source?.value
                                                    ? {
                                                          value: result?.fields?.Source?.value,
                                                      }
                                                    : {},
                                            }}
                                        ></LinkListItem>
                                    </>
                                );
                            }
                        })}
                    </LinkList>
                </div>
            )}
            {(showButton || isEditing) && (
                <div className={`${bioRelatedArticleListBlock}__desktop-button`}>
                    <ButtonTag
                        target={LinkTargetValueEnum.blank}
                        className={classnames(
                            `zn-button`,
                            `zn-button--primary`,
                            `zn-button--light`,
                            `zn-button--primary--light`
                        )}
                        alt={ButtonText?.value}
                        href={
                            isEditing
                                ? ''
                                : `${
                                      SearchResultsPage?.url.split('?')[0]
                                  }?contenttype=${templateCallParams}&person=${bioPageId.replace(
                                      /-/g,
                                      ''
                                  )}`
                        }
                    >
                        <Text field={ButtonText} />
                        <div className={classnames(`zn-button__icon`, {})}>
                            <ExternalLink />
                        </div>
                    </ButtonTag>
                </div>
            )}
            {ContentTypes.length === 0 && isEditing && !isLoading && (
                <div className={`${bioRelatedArticleListBlock}__no-results`}>
                    No Content Types chosen, please select content type to populate item solr
                    results.
                </div>
            )}
            {!RepresentativeItemType?.value && isEditing && !isLoading && (
                <div className={`${bioRelatedArticleListBlock}__no-results`}>
                    No Representative Item Type chosen, please select representative item type to
                    populate representative items associated with this bio page.
                </div>
            )}
            {featuredResults.length === 0 &&
                isEditing &&
                ContentTypes.length !== 0 &&
                !isLoading && (
                    <div className={`${bioRelatedArticleListBlock}__no-results`}>
                        No Featured Items associated with this bio page.
                    </div>
                )}
            {representativeResults.length === 0 &&
                RepresentativeItems.length === 0 &&
                representativeContextItems.length === 0 &&
                ContentTypes.length !== 0 &&
                !isLoading &&
                isEditing && (
                    <div className={`${bioRelatedArticleListBlock}__no-results`}>
                        No Representative Items associated with this bio page, and no Representative
                        Items selected for the component.
                    </div>
                )}
        </div>
    );
};

const BioRelatedArticleListSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields])(BioRelatedArticleList)
);

export const BioRelatedArticleListStorybook = forStorybookV2(BioRelatedArticleListSitecore);

export default withTranslation()(BioRelatedArticleListSitecore);
