import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import './project-map-search-bar-filter.scss';
import { ChevronDown, ChevronUp, ChevronRight } from '../../assets/LinkIcons.js';
import { Button } from '../Button/Button.js';
import { SearchBarIcon } from '../../assets/common.js';
import CheckboxWithDropdown from '../Checkbox/CheckboxWithDropdown.jsx';

const ProjectMapSearchBarFilter = ({
    t,
    fields,
    index,
    activeFilter,
    toggleFilter,
    checkLowLevelFilter,
    toggleHighLevelFilter,
    clearCategory,
    setIsLoading,
    isLoading,
    searchReset,
    dropdownPlaceholderText,
}) => {
    const searchBarFilterBlock = 'zn-project-map-search-bar-filter';
    const { title, identifier, highLevelFilters } = fields;

    const [activeHighLevelFilterGroup, setActiveHighLevelFilterGroup] = useState(null);
    const [searchInput, setSearchInput] = useState('');

    const prevActiveHighLevelGroup = useRef();

    useEffect(() => {
        if (searchReset) setSearchInput('');
    }, [searchReset]);

    useEffect(() => {
        prevActiveHighLevelGroup.current = activeHighLevelFilterGroup;
    });

    const toggleActiveFilterGroup = (index) => {
        if (prevActiveHighLevelGroup.current === index) {
            setActiveHighLevelFilterGroup(null);
        } else {
            setActiveHighLevelFilterGroup(index);
        }
    };

    return (
        <div
            className={classnames(`${searchBarFilterBlock}`, {
                [`${searchBarFilterBlock}--active`]: index === activeFilter,
            })}
        >
            <button
                className={classnames(`${searchBarFilterBlock}__button`, {
                    [`${searchBarFilterBlock}__button--active`]:
                        index === activeFilter && !isLoading,
                    [`${searchBarFilterBlock}__button--disabled`]: isLoading,
                })}
                onClick={() => {
                    if (!isLoading) toggleFilter(index);
                }}
            >
                <div
                    className={classnames(
                        `${searchBarFilterBlock}__title`,
                        `${searchBarFilterBlock}__title--desktop`
                    )}
                >
                    {title}
                </div>
                <div
                    className={classnames(
                        `${searchBarFilterBlock}__title`,
                        `${searchBarFilterBlock}__title--mobile`
                    )}
                >
                    {title}
                </div>
                <div className={classnames(`${searchBarFilterBlock}__desktop-chevron`)}>
                    {index === activeFilter ? <ChevronUp /> : <ChevronDown />}
                </div>
                <div className={classnames(`${searchBarFilterBlock}__mobile-chevron`)}>
                    <ChevronRight />
                </div>
            </button>
            <div
                className={classnames(`${searchBarFilterBlock}__dropdown`, {
                    [`${searchBarFilterBlock}__dropdown--active`]: index === activeFilter,
                })}
            >
                <div
                    className={classnames(`${searchBarFilterBlock}__input`, {
                        [`${searchBarFilterBlock}__input--disabled`]: isLoading,
                    })}
                >
                    <form className={`${searchBarFilterBlock}__form`}>
                        <Button
                            className={classnames(`${searchBarFilterBlock}__search-button`, {
                                [`${searchBarFilterBlock}__search-button--disabled`]: isLoading,
                            })}
                            aria-label="Submit Search"
                            type="submit"
                            variant="icon"
                        >
                            <SearchBarIcon />
                        </Button>
                        <input
                            className={classnames(`${searchBarFilterBlock}__form-input`, {
                                [`${searchBarFilterBlock}__form-input--disabled`]: isLoading,
                            })}
                            type="text"
                            placeholder={dropdownPlaceholderText}
                            onChange={(e) => setSearchInput(e.target.value)}
                            value={searchInput}
                        ></input>
                    </form>
                </div>
                <ul className={classnames(`${searchBarFilterBlock}__dropdown-list`)}>
                    {highLevelFilters?.map((highLevelFilter, index) => {
                        return (
                            <CheckboxWithDropdown
                                key={index}
                                index={index}
                                id={highLevelFilter.identifier}
                                pId={identifier}
                                label={highLevelFilter?.title}
                                checked={highLevelFilter.checked}
                                checkSingularBox={checkLowLevelFilter}
                                toggleHighLevelFilter={toggleHighLevelFilter}
                                setIsLoading={setIsLoading}
                                dropdownOptions={highLevelFilter?.lowLevelFilters}
                                activeHighLevelFilterGroup={activeHighLevelFilterGroup}
                                toggleActiveFilterGroup={toggleActiveFilterGroup}
                                setActiveHighLevelFilterGroup={setActiveHighLevelFilterGroup}
                                searchInput={searchInput.toUpperCase()}
                                isLoading={isLoading}
                            />
                        );
                    })}
                </ul>
                <div className={`${searchBarFilterBlock}__clear-filter-container`}>
                    <button
                        className={classnames(`${searchBarFilterBlock}__clear-filter-button`, {
                            [`${searchBarFilterBlock}__clear-filter-button--disabled`]: isLoading,
                        })}
                        onClick={() => {
                            clearCategory(identifier);
                            setSearchInput('');
                        }}
                    >
                        {t('ClearAll')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProjectMapSearchBarFilter;
