/* eslint-disable prettier/prettier */
import React from 'react';
import classnames from 'classnames';
import { QuoteIcon } from '../../assets/QuoteIcons.js';
import forStorybook from '../../utils/forStorybook.jsx';
import './quote.scss';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Quote = ({ fields, params }) => {
    const { QuoteText, Subtext } = fields;
    const { QuoteColor } = params;

    const quote = 'zn-quote';

    return (
        <section className={classnames(`${quote}`)}>
            <QuoteIcon
                className={classnames(`${quote}__icon`, {
                    [`${quote}__icon--${QuoteColor?.toLowerCase()}`]: QuoteColor,
                })}
            />
            <RichText className={classnames(`${quote}__text`)} field={QuoteText} />
            <RichText className={classnames(`${quote}__subtext`)} field={Subtext} />
        </section>
    );
};

const QuoteSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.params])(Quote)
);

export const QuoteStorybook = forStorybook(QuoteSitecore);

export default QuoteSitecore;
