import React from 'react';
import PropTypes from 'prop-types';

const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export const ErrorBoundaryConditions = {
    fields: 'fields',
    sitecoreContext: 'sitecoreContext',
    params: 'params',
};

const checkConditions = (conditions, value) => !conditions || conditions.includes(value);

const ErrorMessage = ({ message }) => <h1 style={{ color: 'red' }}>{message}</h1>;

const withErrorBoundary = (conditions) => (WrappedComponent) => {
    const ErrorBoundary = (props) => {
        const isEditing = props?.sitecoreContext?.pageEditing;

        if (checkConditions(conditions, ErrorBoundaryConditions.fields) && !props?.fields) {
            return isEditing ? <ErrorMessage message="No Datasource" /> : null;
        }
        if (
            checkConditions(conditions, ErrorBoundaryConditions.sitecoreContext) &&
            !props?.sitecoreContext
        ) {
            return isEditing ? (
                <>
                    <div style={{ position: 'absolute' }}>
                        <ErrorMessage message="No Context" />
                    </div>
                    <WrappedComponent {...props} />
                </>
            ) : null;
        }
        if (checkConditions(conditions, ErrorBoundaryConditions.params) && !props?.params) {
            return isEditing ? <ErrorMessage message="No Params" /> : null;
        }
        return <WrappedComponent {...props} />;
    };
    ErrorBoundary.displayName = `ErrorBoundary(${getDisplayName(WrappedComponent)})`;
    return ErrorBoundary;
};

withErrorBoundary.propTypes = {
    WrappedComponent: PropTypes.element,
};

export default withErrorBoundary;
