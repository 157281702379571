import React from 'react';
import { LinkTargetValueEnum } from '../../utils/enums';

import { useTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
const ContentRowPdfList = ({ results }) => {
    return (
        <div>
            {results.map((result, index) => (
                <Row result={result} key={index} />
            ))}
        </div>
    );
};
export default ContentRowPdfList;

const Row = ({ result }) => {
    const { t } = useTranslation();
    const classBase = 'zn-featured-list';
    const {
        featuredTeaserTitle,
        teaserTitle,
        displayName,
        url,
        featuredTeaserDescription,
        teaserDescription,
        notNavigatable,
    } = result.item ?? {};
    const getTitle = () => {
        if (featuredTeaserTitle?.value) {
            return featuredTeaserTitle;
        } else if (teaserTitle?.value) {
            return teaserTitle;
        } else {
            return displayName;
        }
    };
    const title = getTitle();

    const description = featuredTeaserDescription?.value || teaserDescription || { value: '' };

    const link =
        notNavigatable?.value === '1'
            ? { value: null }
            : {
                  value: {
                      href: url,
                      text: t('ReadMore'),
                      title: result?.name,
                      target: LinkTargetValueEnum.self,
                  },
              };
    return (
        <div className={`${classBase}__item`}>
            <div className={`${classBase}__item__title`}>
                <RichText tag="h4" field={title} />
            </div>
            <div className={`${classBase}__item__description`}>
                <RichText field={description} />
            </div>

            {link?.value && (
                <a
                    className="readmore-button"
                    alt={link?.value?.title}
                    href={link?.value?.href}
                    target={link?.value?.target}
                    rel="noppener noreferrer"
                >
                    {link.value.text}
                </a>
            )}
        </div>
    );
};
