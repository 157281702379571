// Also add values under jss/data/dictionary! This is just the mock for Storybook.

export default {
    Scroll: 'Scroll',
    SortBy: 'Sort By',
    SearchResultsLabel: 'Search Results for',
    Result: 'result',
    Results: 'results',
    Contact: 'Contact',
    Education: 'Education',
    BarAdmissions: 'Bar Admissions',
    ProfessionalInvolvement: 'Professional Involvement',
    ClearAll: 'Clear All',
    ClearFilter: 'Clear Filter',
    ShowResults: 'Show Results',
    ClearDate: 'Clear Date',
    Filter: 'Filter',
    Filters: 'Filters',
    FiltersSelected: 'Filter(s) Selected',
    Any: 'Any',
    SelectADate: 'Select a date',
    PublishDate: 'Publish date',
    Published: 'Published',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    BackToPublishDate: 'Back to publish date',
    Date: 'Date',
    Of: 'of',
    GoToSeries: 'Go to Series',
    Podcast: 'Podcast',
    Insight: 'Insight',
    Events: 'Event',
    Video: 'Video',
    News: 'News',
    WorkItem: 'Work',
    QuarterlyIssue: 'Quarterly',
    ContentSeries: 'Series',
    Webinar: 'Webinar',
    NoPageLinkSet: 'No page link set',
    AddtoCalendar: 'Add to Calendar',
    Menu: 'Menu',
    ContentType: 'Content Type',
    EditorsPicks: 'Editor’s Picks',
    SkipContent: 'Skip to main content',
    ResultsLoading: ' Results Loading',
    Error: 'Error',
    MostRecent: 'Most Recent',
    Municipality: 'Municipality',
    Province: 'Province',
    Country: 'Country',
    ProjectType: 'Project Type',
    ProcurementModel: 'Procurement Model',
    GovernmentLevel: 'Government Level',
    Client: 'Client',
    ClientRole: 'Client Role',
    Value: 'Value',
    NoResults: 'No Results',
    Reset: 'Reset',
    ResetFilter: 'Reset Filter',
    Keywords: 'Keywords',
    LearnMore: 'Learn More',
    ExportAsPDF: 'Export as PDF',
    Search: 'Search',
    Calculating: 'Calculating',
    MinRead: 'min read',
    Insights: 'Insights',
    TorysBrand: 'Torys LLP',
    ReadMore: 'Read More',
    WorkTitle: 'Our Work',
    People: 'People',
    About: 'About',
    Expertise: 'Expertise',
    Relevancy: 'Relevancy',
    Alphabetical: 'Alphabetical',
    SubmitSearch: 'Submit Search',
    CloseNavigation: 'CloseNavigation',
    Position: 'Position',
    School: 'School',
    YearOfCall: 'Year of Call',
    FilterWork: 'Work',
    FilterEvents: 'Events',
    FilterInsights: 'Insights',
    FilterPodcasts: 'Podcasts',
    FilterVideos: 'Videos',
    FilterNews: 'News',
    RecommendedFilters: 'Recommended Filters',
    NoMatchingFiltersTitle: 'No Matching Filters',
    NoMatchingFiltersDescription: 'No Matching Filters Description',
    Share: 'Share',
    OpensInANewTab: 'Opens in a new tab',
    OpensInANewWindow: 'Opens in a new window',
    OpenArticleNavigation: 'Open Article Navigation',
};
