import React from 'react';
import classnames from 'classnames';
import './sticky-side-section.scss';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const stickySideSectionBlock = 'zn-sticky-side-section';

export const StickySideSection = ({ leftStickyContent, rightContent, sitecoreContext, params }) => {
    const { AlternateColorsOverride } = params;

    return (
        <div
            className={classnames(stickySideSectionBlock, {
                [`${stickySideSectionBlock}--alternate-colors`]: AlternateColorsOverride === '1',
                [`${stickySideSectionBlock}--sc-editing`]: sitecoreContext?.pageEditing,
            })}
        >
            <div
                className={classnames(`${stickySideSectionBlock}__navigation`, {
                    [`${stickySideSectionBlock}__navigation--sc-editing`]: sitecoreContext?.pageEditing,
                })}
            >
                <div
                    className={classnames(`${stickySideSectionBlock}__navigation-container`, {
                        [`${stickySideSectionBlock}__navigation-container--sc-editing`]: sitecoreContext?.pageEditing,
                    })}
                >
                    {leftStickyContent}
                </div>
            </div>
            <div
                className={classnames(`${stickySideSectionBlock}__content`, {
                    [`${stickySideSectionBlock}__content--sc-editing`]: sitecoreContext?.pageEditing,
                })}
            >
                {rightContent}
            </div>
        </div>
    );
};

const StickySideSectionWithPlaceholder = withPlaceholder(['leftStickyContent', 'rightContent'])(
    StickySideSection
);

export const StickySideSectionStorybook = forStorybookV2(StickySideSection);

export default withSitecoreContext()(StickySideSectionWithPlaceholder);
