import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData from '../../assets/bubbleLoading';
import SearchResult from '../ItemWithImage/ItemWithImage.jsx';
import { ChevronDown } from '../../assets/LinkIcons.js';
import { Button } from '../Button/Button.js';
import Text from '../Text/Text.jsx';
import Pagination from '../Pagination/Pagination.jsx';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import {
    SearchResultTypeEnum,
    ThemeEnum,
    SearchSortingOrderEnum,
    ContentTypeEnum,
} from '../../utils/enums';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import 'url-search-params-polyfill';
import './search-results.scss';
import { fetchTagIds, fetchResults as fetchSearchResults } from '../../actions';
import {
    tagIdConditions,
    templateTypesConditions,
    tagSearchConditions,
    relatedPeopleConditions,
    decodeURIComponentSafe,
} from '../../utils/searchHelpers.jsx';
import parse from 'html-dom-parser';
import { getChildren } from '../../utils/pageTitleGenerator.js';
import { bioPageTemplateCondition, scoreSortBy } from '../../utils/constants';

const searchResults = 'zn-search-results';
const paragraphRegex = /<\/?p>/g;

const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const SearchResults = ({ t, fields, sitecoreContext }) => {
    const { LinkText, PeopleOnlySearch, ItemsPerPage, DefaultSortingOrder } = fields;
    const ItemsPerPageValue = parseInt(ItemsPerPage?.value) || 10;
    const queryParams = new URLSearchParams(useLocation().search);
    const personId = queryParams.get('person'); //for people filtering from bio related article list
    const sortDefault = DefaultSortingOrder?.value
        ? DefaultSortingOrder?.value
        : SearchSortingOrderEnum.relevancy;
    const orderByOptions = [
        {
            label: t('Relevancy'),
            filter: 'score',
        },
        {
            label: t('MostRecent'),
            filter: 'teaserdate',
        },
    ];

    let filterValuesInitial = decodeURI(useLocation().search)
        .replace('?', '')
        .split('&')
        .map((param) => {
            if (!param?.split('=')) return;
            else return param.split('=');
        })
        .reduce((values, [key, value]) => {
            values[key] = value?.split(',').map((item) => decodeURIComponentSafe(item));
            return values;
        }, {});

    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [paginationPressed, setPaginationPressed] = useState(false);
    const [page, setPage] = useState(1);
    const [resultCount, setResultCount] = useState();
    const [historyFilters, setHistoryFilters] = useState(filterValuesInitial);
    const [orderBy, setOrderBy] = PeopleOnlySearch?.value
        ? useState({ label: 'Alphabetical', filter: 'sortablelastname' })
        : sortDefault === SearchSortingOrderEnum.relevancy && !personId
        ? useState(orderByOptions[0])
        : useState(orderByOptions[1]); //set sorting order to default
    const [searchParam, setSearchParam] = useState(
        queryParams.get('q') ? decodeURIComponentSafe(queryParams.get('q')) : ''
    );
    let searchResultsRef = useRef();

    const updateOrderBy = (order) => {
        setOrderBy(order);
    };

    const scrollToRef = (top) => window.scrollTo(0, top);

    const executeScroll = () => {
        scrollToRef(searchResultsRef.current.offsetParent.offsetTop);
    };

    //Fetch Search Results based off chosen tags and content types
    const searchConditionsHandler = (searchConditions, templateTypes, peopleConditions) => {
        let peopleSearchOrder = PeopleOnlySearch?.value
            ? searchParam
                ? 'score'
                : orderBy.filter
            : '';
        let queryConditions = PeopleOnlySearch?.value
            ? searchConditions.concat(bioPageTemplateCondition) //if people search include only bio pages as templates, with tag conditions
            : searchConditions.concat(templateTypes, peopleConditions); //include template types in search query with tag conditions
        let facets = []; //no facetting
        let pageToFetch = paginationPressed ? ((page - 1) * ItemsPerPageValue).toString() : '0'; // update the page value or reset if new search performed
        let orderByValue = PeopleOnlySearch?.value ? peopleSearchOrder : orderBy.filter; //If on people page and have a search query - sort by score else by last name, if not people order by selected value
        let sortDescending =
            PeopleOnlySearch?.value && peopleSearchOrder !== 'score' ? false : true; //sort results in descending order
        let excludeIds = ['']; //no exluding ids
        let keyword = searchParam ? searchParam : ''; //keyword to search
        let includeBioPageOnlyExclusion = personId ? false : true; //include condition to exclude items that are listed as Bio Page Only
        fetchSearchResults(
            queryConditions,
            facets,
            ItemsPerPageValue,
            pageToFetch,
            orderByValue,
            sortDescending,
            excludeIds,
            keyword,
            includeBioPageOnlyExclusion
        )
            .then((x) => {
                setResults(x.results);
                setPaginationPressed(false);
                setResultCount(x.totalCount);
                setIsLoading(false);
                setHasError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setPaginationPressed(false);
                setHasError(true);
            });
    };
    const getDescription = (result) => {
        const isStatic = result?.item?.url.includes(
            '/our-latest-thinking/publications/publication-lawyer-bios'
        );

        if (isStatic) {
            return result?.item?.source?.value;
        } else if (result?.templateName === SearchResultTypeEnum.biopage) {
            return result?.item?.leadParagraph?.value;
        } else {
            return result?.item?.teaserDescription?.value;
        }
    };
    //Fetch Search Results
    const fetchResults = () => {
        //Solr query to fetch tag ids associated to chosen filters
        let tagIdConditionsArray = tagIdConditions(historyFilters); //Returns array with the tag names formatted correctly for graphql search query to retrieve associated tag ids
        let peopleFilterKeys = [
            t('YearOfCall').toLowerCase().replace(/\s/g, ''),
            t('School').toLowerCase().replace(/\s/g, ''),
        ];
        fetchTagIds(
            tagIdConditionsArray?.flat(),
            [], //no faceting
            500,
            '0', //retrieve the first page
            scoreSortBy, //sort by score
            true, //sort descending order
            [''], //no ids to exclude
            '' //no keyword
        )
            .then((x) => {
                searchConditionsHandler(
                    tagSearchConditions(x, historyFilters, peopleFilterKeys, t), //Returns array with the selected tags formatted correctly for graphql search query
                    templateTypesConditions(historyFilters), //Returns array with the selected content types formatted correctly for graphql search query
                    relatedPeopleConditions(personId) //Returns array with the four people relations with the person id defined in the search params
                );
                //add the date filters
            })
            .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
    };

    useEffect(() => {
        if (Object.keys(historyFilters)) {
            const timer = setTimeout(() => {
                setPage(1);
                fetchResults();
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [historyFilters]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPage(1);
            fetchResults();
        }, 500);
        return () => clearTimeout(timer);
    }, [orderBy]);

    useEffect(() => {
        (function (history) {
            var replaceState = history.replaceState;
            history.replaceState = function (state) {
                if (typeof history.onreplacestate == 'function') {
                    history.onreplacestate({
                        state: state,
                    });
                }
                return replaceState.apply(history, arguments);
            };
        })(window.history);
        window.onpopstate = history.onreplacestate = function (e) {
            setPage(1);
            let filters = decodeURI(e?.state?.state)
                .replace('?', '')
                .split('&')
                .map((param) => {
                    if (!param?.split('=')) return;
                    else return param.split('=');
                })
                .reduce((values, [key, value]) => {
                    values[key] = value?.split(',').map((item) => decodeURIComponentSafe(item));
                    return values;
                }, {});
            setSearchParam(filters['q'] ? filters['q'][0] : '');
            setHistoryFilters(filters);
        };
    }, [window.history]);

    useEffect(() => {
        if (!paginationPressed) return;
        else fetchResults();
    }, [paginationPressed]);

    return (
        <section className={searchResults} ref={searchResultsRef}>
            <nav
                className={classNames(`${searchResults}__nav`, {
                    [`${searchResults}__nav--people-only`]:
                        PeopleOnlySearch?.value && resultCount !== 0,
                })}
            >
                <div className={`${searchResults}__nav-result-filter`}>
                    {!PeopleOnlySearch?.value && (
                        <Text
                            type="body"
                            className={`${searchResults}__nav-result-filter-label`}
                            useDefaultMargin={false}
                        >
                            {t('SortBy')}
                        </Text>
                    )}
                    {!PeopleOnlySearch?.value && (
                        <span
                            className={classNames('zn-dropdown', { 'zn-dropdown--border': true })}
                        >
                            <Button
                                as="button"
                                variant="icon"
                                className={classNames('zn-dropdown__title', {
                                    [`zn-dropdown__title--${ThemeEnum.light}`]: ThemeEnum.light,
                                })}
                                onClick={() => {
                                    setIsDropdownActive(!isDropdownActive);
                                }}
                            >
                                <Text
                                    type="body"
                                    field={{ value: orderBy.label }}
                                    useDefaultMargin={false}
                                />
                                <span
                                    className={classNames('zn-dropdown__chevron', {
                                        active: isDropdownActive,
                                    })}
                                >
                                    <ChevronDown theme={ThemeEnum.light} />
                                </span>
                            </Button>
                            {isDropdownActive && (
                                <div className="zn-dropdown__content">
                                    {orderByOptions.map((result, index) => {
                                        return (
                                            <li key={index} className="zn-dropdown__list-item">
                                                <button
                                                    className="zn-dropdown__list-item-button"
                                                    type="body"
                                                    onClick={() => {
                                                        updateOrderBy(orderByOptions[index]);
                                                        setIsDropdownActive(false);
                                                    }}
                                                >
                                                    {result.label}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </div>
                            )}
                        </span>
                    )}
                </div>

                <div className={`${searchResults}__nav-result-details`}>
                    {searchParam && !personId && !PeopleOnlySearch?.value && (
                        <Text type="h4" useDefaultMargin={false}>{`${t('SearchResultsLabel')} “${
                            searchParam || ''
                        }”`}</Text>
                    )}
                    {!isLoading &&
                        !hasError &&
                        (!personId || (personId && resultCount === 0)) &&
                        (!PeopleOnlySearch?.value ||
                            (PeopleOnlySearch?.value && resultCount === 0)) && (
                            <Text type="body" useDefaultMargin={false}>{`${resultCount || 'No'} ${
                                resultCount !== 1 ? t('Results') : t('Result')
                            }`}</Text>
                        )}
                </div>
            </nav>

            <ul className={`${searchResults}__list`}>
                {!hasError ? (
                    !isLoading ? (
                        resultCount > 0 &&
                        results.map((result, index) => {
                            if (result?.item) {
                                let bioTitle = `${result?.item?.prefix?.value} ${result?.item?.firstName?.value} ${result?.item?.middleName?.value} ${result?.item?.lastName?.value}`;
                                result.fields = {
                                    Title:
                                        result?.templateName === SearchResultTypeEnum.biopage
                                            ? {
                                                  value: `${bioTitle}${
                                                      result?.item?.suffix?.value
                                                          ? `, ${result?.item?.suffix?.value}`
                                                          : ''
                                                  }`,
                                              }
                                            : result?.item?.teaserTitle?.value
                                            ? {
                                                  value: `${(result?.item?.teaserTitle?.value).replace(
                                                      paragraphRegex,
                                                      ''
                                                  )}`,
                                              }
                                            : {
                                                  value: getChildren(
                                                      parse(result?.item?.displayName)[0]
                                                  ),
                                              },
                                    Image:
                                        result?.templateName === SearchResultTypeEnum.biopage
                                            ? { value: result?.item?.profileImage }
                                            : { value: result?.item?.teaserImage },
                                    Eyebrow: { value: result?.item?.role?.value },
                                    Description: { value: getDescription(result) },
                                    Date:
                                        result?.templateName !== ContentTypeEnum.work &&
                                        result?.templateName !== ContentTypeEnum.projectMapWork
                                            ? { value: result?.item?.teaserDate?.value }
                                            : {},
                                    DisplayDate:
                                        result?.templateName !== ContentTypeEnum.work &&
                                        result?.templateName !== ContentTypeEnum.projectMapWork
                                            ? { value: result?.teaserDisplayDate }
                                            : {},
                                    Email: { value: result?.item?.businessEmail?.value },
                                    Phone: { value: result?.item?.businessPhone?.value },
                                    ResultType: { value: result?.templateName },
                                    LinkUrl:
                                        result?.item?.notNavigatable?.value === '1'
                                            ? {}
                                            : { value: result?.item?.url },
                                    LinkText: LinkText?.value ? LinkText : t('ReadMore'),
                                    PeopleOnly: PeopleOnlySearch,
                                    ItemLanguage: sitecoreContext?.route?.itemLanguage,
                                };
                                return (
                                    <li
                                        key={index}
                                        className={classNames(`${searchResults}__list-item`, {
                                            [`${searchResults}__list-item--people-only`]: PeopleOnlySearch?.value,
                                        })}
                                    >
                                        <SearchResult {...result} />
                                    </li>
                                );
                            }
                        })
                    ) : (
                        <div className={`${searchResults}__loading`}>
                            <Lottie options={defaultAnimationOptions} height={300} width={300} />
                        </div>
                    )
                ) : (
                    <div className={`${searchResults}__error`}>{t('Error')}</div>
                )}
            </ul>
            {resultCount > 0 && resultCount > ItemsPerPageValue && !isLoading && !hasError && (
                <div className={`${searchResults}__pagination`}>
                    <Pagination
                        Total={Math.ceil(resultCount / ItemsPerPageValue)}
                        Current={page}
                        OnNext={() => {
                            setPage(page + 1);
                            setPaginationPressed(true);
                            executeScroll();
                        }}
                        OnPrevious={() => {
                            setPage(page - 1);
                            setPaginationPressed(true);
                            executeScroll();
                        }}
                        OnNumberClick={(number) => {
                            setPage(number);
                            setPaginationPressed(true);
                            executeScroll();
                        }}
                    />
                </div>
            )}
        </section>
    );
};

SearchResults.propTypes = {
    LinkText: PropTypes.shape({ value: PropTypes.string }),
    PeopleOnlySearch: PropTypes.shape({ value: PropTypes.bool }),
};

const SearchResultsSitecore = withSitecoreContext()(SearchResults);

export const SearchResultsStorybook = forStorybook(SearchResults);
export default withTranslation()(SearchResultsSitecore);
