import React from 'react';
import classnames from 'classnames';
import './autofill-search-result.scss';
import { RichText, Image as Img } from '@sitecore-jss/sitecore-jss-react';
import {
    DocumentIcon,
    MovieCameraIcon,
    HeadPhonesIcon,
    NewsPaperIcon,
    CalendarIcon,
    BookmarkIcon,
    BriefcaseIcon,
    PersonIcon,
} from '../../assets/common.js';
import { SearchResultTypeEnum } from '../../utils/enums';
import { parseContentType } from '../../utils/searchHelpers.jsx';

const TypeToIconEmum = {
    Podcast: HeadPhonesIcon,
    Insight: NewsPaperIcon,
    Event: CalendarIcon,
    Video: MovieCameraIcon,
    News: BriefcaseIcon,
    WorkItem: DocumentIcon,
    QuarterlyIssue: NewsPaperIcon,
    ContentSeries: BookmarkIcon,
    Webinar: MovieCameraIcon,
    BioPage: PersonIcon,
};

const boldString = (str, query, resultType) => {
    const n = resultType?.value === SearchResultTypeEnum.biopage ? str : str.toUpperCase();
    const q = resultType?.value === SearchResultTypeEnum.biopage ? query : query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
        return str;
    }
    const l = q.length;
    return (
        str.substr(0, x) +
        '<span class="zn-autofill-search-result__text-bold">' +
        str.substr(x, l) +
        '</span>' +
        str.substr(x + l)
    );
};

const AutofillSearchResult = ({ fields, searchValue }) => {
    const autofillSearchResultsBlock = 'zn-autofill-search-result';
    const { Image, Title, LinkUrl, ResultType } = fields;
    const ContentTag = LinkUrl?.value ? 'a' : 'div';
    const ContentStyle = ResultType?.value ? parseContentType(ResultType?.value) : '';
    const IconTag = ContentStyle ? TypeToIconEmum[ContentStyle.replace(/\s/g, '')] : '';
    let titleValue = boldString(Title?.value, searchValue, ResultType);

    let withImage =
        !Image?.value?.class &&
        Image?.value &&
        Object.keys(Image?.value).length > 0 &&
        Image?.value?.src;

    return (
        <ContentTag
            href={LinkUrl?.value}
            className={classnames(`${autofillSearchResultsBlock}`, {
                [`${autofillSearchResultsBlock}__link`]: ContentTag == 'a',
            })}
        >
            {ResultType?.value === SearchResultTypeEnum.biopage && withImage && (
                <div className={`${autofillSearchResultsBlock}__image`}>
                    <Img field={Image} />
                </div>
            )}
            {((ResultType?.value !== SearchResultTypeEnum.biopage && !!IconTag) ||
                (ResultType?.value === SearchResultTypeEnum.biopage &&
                    !withImage &&
                    !!IconTag)) && (
                <span className={`${autofillSearchResultsBlock}__icon`}>
                    <IconTag />
                </span>
            )}
            <RichText
                className={classnames(`${autofillSearchResultsBlock}__text`)}
                field={{ value: titleValue }}
            />
        </ContentTag>
    );
};

export default AutofillSearchResult;
