import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import forStorybook from '../../utils/forStorybook.jsx';
import { SocialShare } from '../../assets/LinkIcons.js';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { ThemeEnum } from '../../utils/enums';
import './icon-dropdown.scss';
import { withTranslation } from 'react-i18next';

const handleClick = (e) => {
    let popupElement = e.target.previousSibling;
    popupElement.focus();
    let compStyles = window.getComputedStyle(popupElement);
    let height = parseInt(compStyles.getPropertyValue('height'));
    let halfMax = height / 2;
    let vpOffset = e.target.getBoundingClientRect();
    let topOffset = vpOffset.top;
    let bottomOffset = window.innerHeight - vpOffset.bottom;

    if (topOffset < halfMax && bottomOffset > halfMax) {
        popupElement.style.top = `-${topOffset}px`;
        popupElement.style.bottom = '';
    } else if (topOffset > halfMax && bottomOffset < halfMax) {
        popupElement.style.bottom = `-${bottomOffset}px`;
        popupElement.style.top = '';
    } else {
        popupElement.style.top = `-${halfMax - vpOffset.height / 2}px`;
    }
};

const IconDropdown = ({ sitecoreContext, fields, socialmedia, t }) => {
    const { Theme, DisplayRightOnMobile } = fields;
    const [socialOpen, setSocialOpen] = useState(false);
    const iconDropdownBlock = 'zn-icon-dropdown';
    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    useEffect(() => {
        const escFunction = (event) => {
            if (event?.key === 'Escape') {
                setSocialOpen(false);
            }
        };

        window.addEventListener('keydown', escFunction);

        return () => {
            window.removeEventListener('keydown', escFunction);
        };
    }, []);

    return (
        !pdfView && (
            <div
                className={classnames(`${iconDropdownBlock}`, {
                    [`${iconDropdownBlock}--sc-editing`]: sitecoreContext.pageEditing,
                })}
            >
                <div
                    tabIndex="0"
                    className={classnames(`${iconDropdownBlock}__popup`, {
                        [`${iconDropdownBlock}__popup--right-mobile`]: DisplayRightOnMobile.value,
                        [`${iconDropdownBlock}__popup--sc-editing`]: sitecoreContext.pageEditing,
                        [`${iconDropdownBlock}__popup--hidden`]:
                            !socialOpen && !sitecoreContext.pageEditing,
                    })}
                >
                    <div
                        className={classnames(`${iconDropdownBlock}__modal`, {
                            [`${iconDropdownBlock}__modal--dark`]: Theme.value === ThemeEnum.dark,
                            [`${iconDropdownBlock}__modal--hidden`]:
                                !socialOpen && !sitecoreContext.pageEditing,
                        })}
                    >
                        {socialmedia}
                    </div>
                </div>
                {(socialOpen || sitecoreContext.pageEditing) && (
                    <div
                        className={classnames(`${iconDropdownBlock}__modal-triangle`, {
                            [`${iconDropdownBlock}__modal-triangle--dark`]:
                                Theme.value === ThemeEnum.dark,
                            [`${iconDropdownBlock}__modal-triangle--right-mobile`]: DisplayRightOnMobile.value,
                        })}
                    ></div>
                )}
                <button
                    className={classnames(`${iconDropdownBlock}__button`, {
                        [`${iconDropdownBlock}__button--dark`]: Theme.value === ThemeEnum.dark,
                        [`${iconDropdownBlock}__button--right-mobile`]: DisplayRightOnMobile.value,
                    })}
                    onClick={(e) => {
                        setSocialOpen(!socialOpen);
                        handleClick(e);
                    }}
                >
                    <div className={`${iconDropdownBlock}__icon`}>
                        <span className="sr-only">{t('Share')}</span>
                        <SocialShare />
                    </div>
                </button>
            </div>
        )
    );
};

const IconDropdownWithContext = withTranslation()(withSitecoreContext()(IconDropdown));

export const IconDropdownStorybook = forStorybook(IconDropdownWithContext);

export default withPlaceholder('socialmedia')(IconDropdownWithContext);
