import React from 'react';
import classnames from 'classnames';
import './event-autofill-search-result.scss';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { MovieCameraIcon, InPersonEventIcon, StaticEventIcon } from '../../../assets/common.js';

const TypeToIconEmum = {
    Virtual: MovieCameraIcon,
    Event: StaticEventIcon,
    'In-Person': InPersonEventIcon,
};

const boldString = (str, query) => {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
        return str;
    }
    const l = q.length;
    return (
        str.substr(0, x) +
        '<span class="zn-event-autofill-search-result__text-bold">' +
        str.substr(x, l) +
        '</span>' +
        str.substr(x + l)
    );
};

const parseContentType = (template) => {
    let contentType;

    switch (template) {
        case 'Publication Page':
            contentType = 'Event';
            break;
        case 'Event':
            contentType = 'In-Person';
            break;
        case 'Webinar':
            contentType = 'Virtual';
            break;
    }

    return contentType;
};

const EventAutofillSearchResult = ({ fields, searchValue }) => {
    const autofillSearchResultsBlock = 'zn-event-autofill-search-result';
    const { Title, LinkUrl, ResultType } = fields;
    const ContentTag = LinkUrl?.value ? 'a' : 'div';
    const ContentStyle = ResultType?.value ? parseContentType(ResultType?.value) : '';
    const IconTag = ContentStyle ? TypeToIconEmum[ContentStyle.replace(/\s/g, '')] : '';
    let titleValue = boldString(Title?.value, searchValue);

    return (
        <ContentTag
            href={LinkUrl?.value}
            className={classnames(`${autofillSearchResultsBlock}`, {
                [`${autofillSearchResultsBlock}__link`]: ContentTag == 'a',
            })}
        >
            {!!IconTag && (
                <span className={`${autofillSearchResultsBlock}__icon`}>
                    <IconTag />
                </span>
            )}
            <RichText
                className={classnames(`${autofillSearchResultsBlock}__text`)}
                field={{ value: titleValue }}
            />
        </ContentTag>
    );
};

export default EventAutofillSearchResult;
