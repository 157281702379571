import React from 'react';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { BasicTagList } from '../TagList/TagList.jsx';
import forStorybook from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';

const ContextTagList = ({ fields, tagList, sitecoreContext, children }) => {
    const { Title, SearchResultsPage, ApplyTemplateToSearch } = fields;
    const contextFields = sitecoreContext?.route?.fields;
    const TagListItems = Array.isArray(contextFields?.Tags) ? contextFields?.Tags : [];
    return (
        <>
            {sitecoreContext?.pageEditing && (
                <h6 className="zn-page-editing-title">
                    **This component is using page field Tags as datasource**
                </h6>
            )}
            {(sitecoreContext?.pageEditing || TagListItems.length > 0) && (
                <BasicTagList
                    fields={{ Title, Tags: TagListItems, SearchResultsPage, ApplyTemplateToSearch }}
                    tagList={tagList}
                    sitecoreContext={sitecoreContext}
                    // eslint-disable-next-line react/no-children-prop
                    children={children}
                />
            )}
        </>
    );
};

export default withSitecoreContext()(
    withPlaceholder(['tagList'])(
        withErrorBoundary([ErrorBoundaryConditions.fields])(ContextTagList)
    )
);
export const ContextTagListStorybook = forStorybook(ContextTagList);
