import React from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button.js';
import { ChevronLeftCirlcle, ChevronRightCirlcle } from '../../assets/LinkIcons.js';

import './pagination.scss';

const paginationBase = 'zn-pagination';
const createPagination = (total, current, OnNumberClick) => {
    let leftmost = current - 2 > 0;
    let rightmost = current + 3 <= total;

    let start = leftmost ? current : 1;
    let end = rightmost ? current + 2 : total;

    const pagination = [];

    if (leftmost && rightmost) {
        end = current + 1;
    }

    if (!leftmost && current !== 1) {
        end = current + 1;
    }

    if (leftmost && !rightmost) {
        start = total - 2;
    }

    if (leftmost && !rightmost) {
        start = total - 2;
    }

    if (total <= 5) {
        leftmost = false;
        rightmost = false;
        start = 1;
        end = total;
    }

    if (leftmost) {
        pagination.push(
            <>
                <Button
                    aria-label="pagination button 1"
                    type="button"
                    as="button"
                    variant="icon"
                    key={1}
                    className={classNames(`${paginationBase}__page`, `${paginationBase}__button`, {
                        [`${paginationBase}__page--active`]: current === 1,
                    })}
                    onClick={() => {
                        OnNumberClick(1);
                    }}
                >
                    {1}
                </Button>
                <span key="leftmost">...</span>
            </>
        );
    }
    for (let index = start; index <= end; index++) {
        pagination.push(
            <Button
                aria-label={`pagination button ${index}`}
                type="button"
                as="button"
                variant="icon"
                key={index}
                className={classNames(`${paginationBase}__page`, `${paginationBase}__button`, {
                    [`${paginationBase}__page--active`]: current === index,
                })}
                onClick={() => {
                    OnNumberClick(index);
                }}
            >
                {index}
            </Button>
        );
    }

    if (rightmost) {
        pagination.push(
            <>
                <span key="rightmost">...</span>
                <Button
                    aria-label={`pagination button ${total}`}
                    type="button"
                    as="button"
                    variant="icon"
                    key={total}
                    className={classNames(`${paginationBase}__page`, `${paginationBase}__button`, {
                        [`${paginationBase}__page--active`]: current === total,
                    })}
                    onClick={() => {
                        OnNumberClick(total);
                    }}
                >
                    {total}
                </Button>
            </>
        );
    }
    return pagination;
};

const Pagination = ({ Total, Current, OnNext, OnPrevious, OnNumberClick }) => {
    return (
        <div className={classNames(paginationBase, {})}>
            <Button
                aria-label="carousel-next-button"
                disabled={Current === 1}
                type="button"
                as="button"
                variant="icon"
                className={`${paginationBase}__button`}
                onClick={() => {
                    if (Current !== 1) {
                        OnPrevious();
                    }
                }}
            >
                <ChevronLeftCirlcle inactive={Current === 1} />
            </Button>
            {createPagination(Total, Current, OnNumberClick)}
            <Button
                aria-label="carousel-next-button"
                disabled={Total === Current}
                type="button"
                as="button"
                variant="icon"
                className={`${paginationBase}__button`}
                onClick={() => {
                    if (Total !== Current) {
                        OnNext();
                    }
                }}
            >
                <ChevronRightCirlcle inactive={Total === Current} />
            </Button>
        </div>
    );
};

export default Pagination;
