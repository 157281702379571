import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import classnames from 'classnames';
import animationData from '../../assets/bubbleLoading';
import Pagination from '../Pagination/Pagination.jsx';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import SectionHeading from '../SectionHeading/SectionHeading.jsx';
import {
    ThemeEnum,
    SectionHeadingSizeEnum,
    HeadingLevelEnum,
    OverrideAlignmentEnum,
} from '../../utils/enums.js';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import 'url-search-params-polyfill';
import './event-search-results.scss';
import { fetchTagIds, fetchResults as fetchSearchResults } from '../../actions/index.js';
import { decodeURIComponentSafe } from '../../utils/searchHelpers.jsx';
import { tagIdConditions, getSearchConditions } from '../EventSearchBar/helpers.js';
import { scoreSortBy } from '../../utils/constants.js';
import ContentCard from './EventContentCard';
import { templateTypeConditions } from '../EventSearchBar/EventSearchBar.jsx';
import { subDays as subtractDays } from 'date-fns';

const searchResults = 'zn-event-search-results';

const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const SearchResults = ({ t, fields }) => {
    const { PastSessions, UpcomingSessions, UpcomingSessionsHeading, PastSessionsHeading } = fields;
    const queryParams = new URLSearchParams(useLocation().search);
    const [searchParam, setSearchParam] = useState(
        queryParams.get('q') ? decodeURIComponentSafe(queryParams.get('q')) : ''
    );

    let filterValuesInitial = decodeURI(useLocation().search)
        .replace('?', '')
        .split('&')
        .map((param) => {
            if (!param?.split('=')) return;
            else return param.split('=');
        })
        .reduce((values, [key, value]) => {
            values[key] = value?.split(',').map((item) => decodeURIComponentSafe(item));
            return values;
        }, {});

    const [historyFilters, setHistoryFilters] = useState(filterValuesInitial);
    const pastSessionItemsPerPage = parseInt(PastSessions?.value) || 6;
    const upcomingSessionItemsPerPage = parseInt(UpcomingSessions?.value) || 9;

    useEffect(() => {
        (function (history) {
            var replaceState = history.replaceState;
            history.replaceState = function (state) {
                if (typeof history.onreplacestate == 'function') {
                    history.onreplacestate({
                        state: state,
                    });
                }
                return replaceState.apply(history, arguments);
            };
        })(window.history);
        window.onpopstate = history.onreplacestate = function (e) {
            let filters = decodeURI(e?.state?.state)
                .replace('?', '')
                .split('&')
                .map((param) => {
                    if (!param?.split('=')) return;
                    else return param.split('=');
                })
                .reduce((values, [key, value]) => {
                    values[key] = value?.split(',').map((item) => decodeURIComponentSafe(item));
                    return values;
                }, {});
            setSearchParam(filters['q'] ? filters['q'][0] : '');
            setHistoryFilters(filters);
        };
    }, [window.history]);

    return (
        <>
            <Results
                t={t}
                itemsPerPage={upcomingSessionItemsPerPage}
                heading={UpcomingSessionsHeading}
                session="upcoming"
                searchParam={searchParam}
                selectedFilters={historyFilters}
            />
            <Results
                t={t}
                itemsPerPage={pastSessionItemsPerPage}
                heading={PastSessionsHeading}
                session="past"
                searchParam={searchParam}
                selectedFilters={historyFilters}
            />
        </>
    );
};

SearchResults.propTypes = {
    LinkText: PropTypes.shape({ value: PropTypes.string }),
    PeopleOnlySearch: PropTypes.shape({ value: PropTypes.bool }),
};

const Results = ({ t, itemsPerPage, heading, session, searchParam, selectedFilters }) => {
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [paginationPressed, setPaginationPressed] = useState(false);
    const [page, setPage] = useState(1);
    const [resultCount, setResultCount] = useState();

    let searchResultsRef = useRef();

    const scrollToRef = (top) => window.scrollTo(0, top);

    const executeScroll = () => {
        scrollToRef(searchResultsRef.current.offsetParent.offsetTop);
    };

    //Fetch Search Results based off chosen tags
    const searchConditionsHandler = (searchConditions, mappedObject) => {
        let updatedSearchConditions = refineEventFormatCondition(
            searchConditions,
            mappedObject,
            session
        );

        const dateCond = updatedSearchConditions?.find((el) => el.name === 'TeaserDate');
        const newDateCond = getUpdatedDateCond(dateCond, session);

        if (!newDateCond) return;

        updatedSearchConditions = updatedSearchConditions.filter((el) => el.name !== 'TeaserDate');
        updatedSearchConditions.push(newDateCond);

        let facets = []; //no facetting
        let pageToFetch = paginationPressed ? ((page - 1) * itemsPerPage).toString() : '0'; // update the page value or reset if new search performed
        let orderByValue = 'teaserdate';
        let sortDescending = session === 'past'; //sort results
        let excludeIds = ['']; //no exluding ids
        let keyword = searchParam ? searchParam : ''; //keyword to search
        let includeBioPageOnlyExclusion = true; //include condition to exclude items that are listed as Bio Page Only
        fetchSearchResults(
            updatedSearchConditions,
            facets,
            itemsPerPage,
            pageToFetch,
            orderByValue,
            sortDescending,
            excludeIds,
            keyword,
            includeBioPageOnlyExclusion
        )
            .then((x) => {
                setResults(x.results);
                setPaginationPressed(false);
                setResultCount(x.totalCount);
                setIsLoading(false);
                setHasError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setPaginationPressed(false);
                setHasError(true);
            });
    };

    //Fetch Search Results
    const fetchResults = () => {
        //Solr query to fetch tag ids associated to chosen filters
        let tagIdConditionsArray = tagIdConditions(selectedFilters); //Returns array with the tag names formatted correctly for graphql search query to retrieve associated tag ids

        if (['city', 'services', 'industries'].every((key) => !selectedFilters[key]?.length)) {
            searchConditionsHandler(
                getSearchConditions({ results: [] }, selectedFilters), //Returns array with the selected tags formatted correctly for graphql search query
                selectedFilters
            );
            return;
        }

        fetchTagIds(
            tagIdConditionsArray?.flat(),
            [], //no faceting
            500,
            '0', //retrieve the first page
            scoreSortBy, //sort by score
            true, //sort descending order
            [''], //no ids to exclude
            '' //no keyword
        )
            .then((x) => {
                searchConditionsHandler(
                    getSearchConditions(x, selectedFilters), //Returns array with the selected tags formatted correctly for graphql search query
                    selectedFilters
                );
                //add the date filters
            })
            .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
    };

    useEffect(() => {
        if (Object.keys(selectedFilters)) {
            const timer = setTimeout(() => {
                setPage(1);
                fetchResults();
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [selectedFilters]);

    useEffect(() => {
        if (!paginationPressed) return;
        else fetchResults();
    }, [paginationPressed]);

    return (
        <section className={searchResults} ref={searchResultsRef}>
            <nav className={classNames(`${searchResults}__nav`)}>
                <SectionHeading
                    params={{
                        Theme: ThemeEnum.light,
                        SectionHeadingSize: SectionHeadingSizeEnum.large,
                        HeadingLevel: HeadingLevelEnum.h2,
                        AccentColor: 'blue',
                        OverrideAlignment: OverrideAlignmentEnum.none,
                    }}
                    fields={{
                        TextInput: heading,
                    }}
                />
            </nav>

            <ul className={`${searchResults}__list`}>
                {!hasError ? (
                    !isLoading ? (
                        <div className={classnames(`${searchResults}__grid`)}>
                            {!hasError
                                ? (Array.isArray(results) ? results : []).map((result, index) => (
                                      <li
                                          key={index}
                                          className={classnames(`${searchResults}__item`, {
                                              [`${searchResults}__item--mobile-display`]:
                                                  index < itemsPerPage,
                                              [`${searchResults}__item--desktop-display`]:
                                                  index < itemsPerPage,
                                              fadeIn: false,
                                          })}
                                      >
                                          <ContentCard
                                              fields={getContentCardFields(result)}
                                              params={{
                                                  IncludeImage: '1',
                                                  DefaultCardColor: '',
                                                  ImageHighlightColor: '',
                                              }}
                                          />
                                      </li>
                                  ))
                                : false && (
                                      <div className={`${searchResults}__error`}>{t('Error')}</div>
                                  )}
                        </div>
                    ) : (
                        <div className={`${searchResults}__loading`}>
                            <Lottie options={defaultAnimationOptions} height={300} width={300} />
                        </div>
                    )
                ) : (
                    <div className={`${searchResults}__error`}>{t('Error')}</div>
                )}
            </ul>
            {resultCount > 0 && resultCount > itemsPerPage && !isLoading && !hasError && (
                <div className={`${searchResults}__pagination`}>
                    <Pagination
                        Total={Math.ceil(resultCount / itemsPerPage)}
                        Current={page}
                        OnNext={() => {
                            setPage(page + 1);
                            setPaginationPressed(true);
                            executeScroll();
                        }}
                        OnPrevious={() => {
                            setPage(page - 1);
                            setPaginationPressed(true);
                            executeScroll();
                        }}
                        OnNumberClick={(number) => {
                            setPage(number);
                            setPaginationPressed(true);
                            executeScroll();
                        }}
                    />
                </div>
            )}
        </section>
    );
};

const SearchResultsSitecore = withSitecoreContext()(SearchResults);

export const SearchResultsStorybook = forStorybook(SearchResults);
export default withTranslation()(SearchResultsSitecore);

const refineEventFormatCondition = (searchConditions, mappedObject, session) => {
    if (session === 'upcoming') {
        return !mappedObject['eventformat']?.length
            ? [...searchConditions, ...templateTypeConditions]
            : searchConditions;
    }

    return [
        ...searchConditions.filter((item) => {
            if (item.group) {
                return !item.group.some((groupItem) => groupItem.name === '_templatename');
            }
            return true;
        }),
        ...templateTypeConditions,
    ];
};

const getUpdatedDateCond = (dateCond, session) => {
    let fromDateStr = '';
    let toDateStr = '';
    const newDateCond = { operation: 'DateBetween', name: 'TeaserDate', value: '' };

    if (dateCond) {
        [fromDateStr, toDateStr] = dateCond.value?.split('|');
    }
    const fromDateObj = fromDateStr ? new Date(fromDateStr) : null;
    const toDateObj = toDateStr ? new Date(toDateStr) : null;
    const today = new Date();

    // Set hours, minutes, seconds and milliseconds to 0
    if (fromDateObj) fromDateObj.setHours(0, 0, 0, 0);
    if (toDateObj) toDateObj.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (session === 'past') {
        const newToDateStr =
            toDateObj && toDateObj.getTime() < today.getTime()
                ? toDateStr
                : `${today.getMonth() + 1}/${subtractDays(
                      today,
                      1
                  ).getDate()}/${today.getFullYear()}`;
        newDateCond.value = `${fromDateStr || ''}|${newToDateStr}`;
    } else if (session === 'upcoming') {
        const newFromDateStr =
            fromDateObj && fromDateObj.getTime() > today.getTime()
                ? fromDateStr
                : `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
        newDateCond.value = `${newFromDateStr}|${toDateStr || ''}`;
    }

    return newDateCond;
};

const getContentCardFields = (resultItem) => {
    if (!resultItem) return {};

    const item = resultItem.item || {};
    const title =
        resultItem.templateName === 'Publication Page'
            ? item.title?.value
            : item.teaserTitle?.value;
    return {
        data: {
            datasource: {
                pageLink: {
                    targetItem: {
                        displayName: title,
                        template: {
                            name: resultItem.templateName,
                        },
                        url: item.url,

                        teaserTitle: {
                            value: title,
                        },

                        teaserDescription: {
                            value: item.externalHost?.value,
                        },

                        teaserDate: {
                            value: item.teaserDate?.value,
                        },
                        displayDate: {
                            value: resultItem.teaserDisplayDate,
                        },
                        hideCardTeaserDate: {
                            value: item.hideCardTeaserDate?.value,
                        },

                        teaserTemplateTypeOverride: {
                            value: item.teaserTemplateTypeOverride?.value,
                        },

                        notNavigatable: {
                            value: resultItem.templateName === 'Publication Page' ? '1' : '0',
                        },
                    },
                },
            },
        },
    };
};
