import { useEffect, useState } from 'react';

const usePdfView = () => {
    const [pdfView, setPdfView] = useState(false);
    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        } else {
            setPdfView(false);
        }
    }, []);
    return pdfView;
};

export default usePdfView;
