import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './quarterly-introduction.scss';
import forStorybook from '../../utils/forStorybook.jsx';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';

const qLIntroBlock = 'zn-quarterly-introduction';

export const QuarterlyIntroduction = ({ fields, sectionLinks }) => {
    const { Image, IntroductionParagraph, SectionsLabel } = fields;
    return (
        <div className={classnames(qLIntroBlock, {})}>
            <div
                className={classnames(`${qLIntroBlock}__background-image`)}
                style={{
                    backgroundImage: `url(${Image?.value?.src})`,
                }}
            ></div>
            <div className={classnames(`${qLIntroBlock}__content`)}>
                <div className={classnames(`${qLIntroBlock}__paragraph`)}>
                    {IntroductionParagraph?.value}
                </div>
                <div className={classnames(`${qLIntroBlock}__sections`)}>
                    <div className={classnames(`${qLIntroBlock}__sections-title`)}>
                        {SectionsLabel?.value}
                    </div>
                    <div className={classnames(`${qLIntroBlock}__accent`)}></div>
                    <ul className={classnames(`${qLIntroBlock}__list`)}>{sectionLinks}</ul>
                </div>
            </div>
        </div>
    );
};

QuarterlyIntroduction.propTypes = {
    fields: PropTypes.shape({
        Image: PropTypes.shape({
            value: PropTypes.object,
        }),
        IntroductionParagraph: PropTypes.shape({
            value: PropTypes.string,
        }),
        SectionsLabel: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

export const QuarterlyIntroductionStorybook = forStorybook(QuarterlyIntroduction);

export default withPlaceholder('sectionLinks')(QuarterlyIntroduction);
