import React from 'react';
import { Image as Img } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import Text from '../Text/Text.jsx';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import ButtonLink from '../ButtonLink';
import { DateTime } from 'luxon';
import { IsEmptyDate } from '../../utils/dateExtensions';
import {
    DocumentIcon,
    MovieCameraIcon,
    HeadPhonesIcon,
    NewsPaperIcon,
    CalendarIcon,
    BookmarkIcon,
    BriefcaseIcon,
    PersonIcon,
    MailIcon,
    PhoneIcon,
} from '../../assets/common.js';
import {
    LinkTargetValueEnum,
    ThemeEnum,
    ButtonVariantEnum,
    SearchResultTypeEnum,
    TimeZoneEnum,
} from '../../utils/enums';
import forStorybook from '../../utils/forStorybook.jsx';
import { parseContentType } from '../../utils/searchHelpers.jsx';

import './item-with-image.scss';

const TypeToIconEmum = {
    Podcast: HeadPhonesIcon,
    Insight: NewsPaperIcon,
    Event: CalendarIcon,
    Video: MovieCameraIcon,
    News: BriefcaseIcon,
    WorkItem: DocumentIcon,
    QuarterlyIssue: NewsPaperIcon,
    ContentSeries: BookmarkIcon,
    Webinar: MovieCameraIcon,
    BioPage: PersonIcon,
};

const ItemWithImage = ({ fields, isPageEditing }) => {
    const {
        Image,
        Title,
        Eyebrow,
        EducationInformation,
        Description,
        Date,
        DisplayDate,
        Document,
        LinkText,
        LinkUrl,
        Email,
        Phone,
        ResultType,
        PeopleOnly,
        ItemLanguage,
    } = fields;

    const itemWithImageBlock = 'zn-item-with-image';
    const ContentStyle = ResultType?.value ? parseContentType(ResultType?.value) : '';
    const IconTag = ContentStyle ? TypeToIconEmum[ContentStyle.replace(/\s/g, '')] : '';
    const ContentTag = LinkUrl?.value !== '' ? 'a' : 'span';
    let withImage =
        !Image?.value?.class &&
        Image?.value &&
        Object.keys(Image?.value).length > 0 &&
        Image?.value?.src;

    const ContextTeaserDate = Date?.value
        ? DateTime.fromISO(Date?.value, {
              locale: ItemLanguage,
          })
              .setZone(TimeZoneEnum.server)
              .toLocaleString(DateTime.DATE_FULL)
        : '';

    const date = DisplayDate?.value
        ? DisplayDate.value
        : Date?.value && !IsEmptyDate(DateTime.fromISO(Date?.value))
        ? ContextTeaserDate
        : '';

    return (
        <div
            className={classNames(itemWithImageBlock, {
                [`${itemWithImageBlock}--people-only`]: PeopleOnly?.value,
            })}
        >
            <div
                className={classNames(`${itemWithImageBlock}__content`, {
                    [`${itemWithImageBlock}__content--with-image`]: withImage,
                })}
            >
                <ContentTag href={LinkUrl?.value} className={`${itemWithImageBlock}__content-link`}>
                    {ResultType?.value !== SearchResultTypeEnum.biopage && !!IconTag && (
                        <span
                            className={`${itemWithImageBlock}__text-icon ${itemWithImageBlock}--hide-desktop`}
                        >
                            <IconTag />
                        </span>
                    )}

                    {ResultType?.value !== SearchResultTypeEnum.biopage ? (
                        <RichText
                            type="h4"
                            className={`${itemWithImageBlock}__content-title`}
                            field={Title}
                        ></RichText>
                    ) : (
                        <Text
                            type="h4"
                            field={Title}
                            useDefaultMargin={false}
                            className={`${itemWithImageBlock}__content-title ${itemWithImageBlock}--hide-mobile`}
                        ></Text>
                    )}

                    {ResultType?.value === SearchResultTypeEnum.biopage && (
                        <div
                            className={`${itemWithImageBlock}__people-heading ${itemWithImageBlock}--hide-desktop`}
                        >
                            {withImage && (
                                <div
                                    className={`${itemWithImageBlock}__image ${itemWithImageBlock}__image--xs`}
                                >
                                    <Img field={Image} />
                                </div>
                            )}
                            <div
                                className={classNames(`${itemWithImageBlock}__content-xs`, {
                                    [`${itemWithImageBlock}__content-xs--with-image`]: withImage,
                                })}
                            >
                                <Text
                                    type="h4"
                                    field={Title}
                                    useDefaultMargin={false}
                                    className={`${itemWithImageBlock}__content-title`}
                                ></Text>
                                {Eyebrow?.value && (
                                    <Text
                                        type="allCapsSmall"
                                        field={Eyebrow}
                                        className={`${itemWithImageBlock}__content-eyebrow`}
                                    ></Text>
                                )}
                            </div>
                        </div>
                    )}

                    {(Eyebrow?.value || isPageEditing) && (
                        <Text
                            type="allCapsSmall"
                            field={Eyebrow}
                            className={`${itemWithImageBlock}__content-eyebrow ${itemWithImageBlock}--hide-mobile`}
                            useDefaultMargin={!EducationInformation?.value}
                        ></Text>
                    )}

                    {(EducationInformation?.value || isPageEditing) && (
                        <Text
                            as="div"
                            type="allCapsSmall"
                            className={`${itemWithImageBlock}__content-eyebrow`}
                        >
                            <RichText field={EducationInformation} />
                        </Text>
                    )}
                    {date && <div className={`${itemWithImageBlock}__date`}>{date}</div>}
                    {Description?.value && (
                        <Text
                            as="div"
                            type="bodyMd"
                            className={`${itemWithImageBlock}__content-description`}
                            useDefaultMargin={false}
                        >
                            <RichText field={Description} />
                        </Text>
                    )}
                </ContentTag>
                {(Email?.value || Phone?.value || isPageEditing) &&
                    ResultType?.value === SearchResultTypeEnum.biopage && (
                        <div className={`${itemWithImageBlock}__text-icons-container`}>
                            {(Email?.value || isPageEditing) && (
                                <a
                                    className={`${itemWithImageBlock}__text-icon`}
                                    href={`mailto:${Email?.value}`}
                                >
                                    <MailIcon />
                                    <Text
                                        type="bodyMd"
                                        as="span"
                                        field={Email}
                                        useDefaultMargin={false}
                                    ></Text>
                                </a>
                            )}
                            {(Phone?.value || isPageEditing) && (
                                <a
                                    className={`${itemWithImageBlock}__text-icon`}
                                    href={`tel:${Phone?.value}`}
                                >
                                    <PhoneIcon />
                                    <Text
                                        type="bodyMd"
                                        as="span"
                                        field={Phone}
                                        useDefaultMargin={false}
                                    ></Text>
                                </a>
                            )}
                        </div>
                    )}

                {Document?.value && (
                    <span className={`${itemWithImageBlock}__text-icon`}>
                        <DocumentIcon />
                        <Text
                            type="body"
                            as="span"
                            field={Document}
                            useDefaultMargin={false}
                        ></Text>
                    </span>
                )}

                {LinkUrl?.value && LinkText?.value && (
                    <div>
                        <ButtonLink
                            fields={{
                                LinkURL: {
                                    value: {
                                        text: LinkText?.value,
                                        href: LinkUrl?.value,
                                        title: LinkText?.value,
                                        target: LinkTargetValueEnum.self,
                                    },
                                },
                            }}
                            params={{
                                Variant: ButtonVariantEnum.tertiary,
                                Theme: ThemeEnum.light,
                            }}
                        />
                    </div>
                )}
            </div>
            {withImage && (
                <div
                    className={classNames(
                        `${itemWithImageBlock}__image ${itemWithImageBlock}--hide-mobile`,
                        { [`${itemWithImageBlock}__image--editing`]: isPageEditing }
                    )}
                >
                    <Img field={Image} />
                </div>
            )}
        </div>
    );
};

export default ItemWithImage;
export const ItemWithImageStorybook = forStorybook(ItemWithImage);
