import React from 'react';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import Text from '../Text';
import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks.jsx';
import { LinkTargetEnum, ThemeEnum, ButtonVariantEnum } from '../../utils/enums';

const MegaNavHomeFooter = ({
    homeSocialMediaText,
    homeSubscribeText,
    homeSocialMediaLinks,
    homeSubscribeButton,
    params,
}) => (
    <div className="zn-mega-menu-home__footer">
        <div className="zn-mega-menu-home__footer__text">
            <Text type="body" useDefaultMargin={false} field={homeSubscribeText} />
        </div>

        <div className="zn-mega-menu-home__footer__button">
            {homeSubscribeButton?.text && (
                <ButtonLink
                    fields={{
                        LinkURL: {
                            value: {
                                text: homeSubscribeButton?.text,
                                href: homeSubscribeButton?.url,
                                title: homeSubscribeButton?.text,
                                target: LinkTargetEnum.sameTab,
                            },
                        },
                    }}
                    params={{
                        Theme: ThemeEnum.light.toLowerCase(),
                        Variant: ButtonVariantEnum.primary.toLowerCase(),
                    }}
                />
            )}
        </div>

        <div className="zn-mega-menu-home__footer__social">
            {homeSocialMediaText && (
                <div className="zn-mega-menu-home__footer__text">
                    <Text type="body" useDefaultMargin={false} field={homeSocialMediaText} />
                </div>
            )}
            {homeSocialMediaLinks && (
                <SocialMediaLinks
                    params={params}
                    fields={{
                        FacebookLabel: homeSocialMediaLinks.facebookLabel,
                        FacebookPageLink: homeSocialMediaLinks.facebookPageLink,
                        FacebookIconAltText: homeSocialMediaLinks.facebookIconAltText,
                        TwitterLabel: homeSocialMediaLinks.twitterLabel,
                        TwitterPageLink: homeSocialMediaLinks.twitterPageLink,
                        TwitterIconAltText: homeSocialMediaLinks.twitterIconAltText,
                        LinkedInLabel: homeSocialMediaLinks.linkedInLabel,
                        LinkedInPageLink: homeSocialMediaLinks.linkedInPageLink,
                        LinkedInIconAltText: homeSocialMediaLinks.linkedInIconAltText,
                        EmailLabel: homeSocialMediaLinks.emailLabel,
                        EmailLink: homeSocialMediaLinks.emailLink,
                        EmailIconAltText: homeSocialMediaLinks.emailIconAltText,
                        PDFLabel: homeSocialMediaLinks.pDFLabel,
                        PDFLink: homeSocialMediaLinks.pDFLink,
                        PDFIconAltText: homeSocialMediaLinks.pDFIconAltText,
                        CopyUrlLabel: homeSocialMediaLinks.copyUrlLabel,
                        CopyUrlIconAltText: homeSocialMediaLinks.copyUrlIconAltText,
                    }}
                />
            )}
        </div>
    </div>
);

export default MegaNavHomeFooter;
