import React from 'react';

export const ArrowRight = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 11.5H21.2593"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path d="M14.5918 19L21.9992 11.5L14.5918 4" stroke="currentColor" strokeWidth="1.5" />
        </svg>
    );
};

export const ArrowLeft = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22 11.5002L2.74071 11.5002"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path d="M9.40744 4L2.00003 11.5L9.40744 19" stroke="currentColor" strokeWidth="1.5" />
        </svg>
    );
};

export const ExternalLink = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11 13L22 2" stroke="currentColor" strokeLinecap="round" />
            <path d="M22.0004 10.4614V2H13.5391" stroke="currentColor" />
            <path d="M13 6H3.5V21H18.5V10" stroke="currentColor" />
        </svg>
    );
};

export const ChevronRight = () => {
    return (
        <svg viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.41228 15.7426L0.507324 14.8376L7.09452 8.25008L0.507324 1.66256L1.41228 0.757599L8.90508 8.25008L1.41228 15.7426Z"
                fill="currentColor"
            />
            <path
                d="M1.41274 16.1951L0.0552979 14.8377L6.6425 8.25014L0.0552979 1.66262L1.41274 0.305176L9.35802 8.25014L1.41274 16.1951ZM0.960258 14.8377L1.41274 15.2901L8.45306 8.25014L1.41274 1.21014L0.960258 1.66262L7.54746 8.25014L0.960258 14.8377Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const SocialShare = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.29009 11.9823C8.29009 14.004 6.53198 15.6433 4.36282 15.6433C2.19366 15.6433 0.435547 14.004 0.435547 11.9823C0.435547 9.9602 2.19366 8.32129 4.36282 8.32129C6.53198 8.32129 8.29009 9.9602 8.29009 11.9823Z"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4134 22.5204C20.8796 23.9502 18.3932 23.9502 16.8593 22.5204C15.3255 21.0905 15.3255 18.7727 16.8593 17.3429C18.3932 15.9131 20.8796 15.9131 22.4134 17.3429C23.9472 18.7727 23.9472 21.0905 22.4134 22.5204Z"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6363 7.72926C17.4671 7.72926 15.709 6.09035 15.709 4.06824C15.709 2.04614 17.4671 0.407227 19.6363 0.407227C21.8054 0.407227 23.5635 2.04614 23.5635 4.06824C23.5635 6.09035 21.8054 7.72926 19.6363 7.72926Z"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <path d="M16.1117 5.68359L7.86133 10.316" stroke="currentColor" strokeLinecap="round" />
            <path
                d="M7.86133 13.6475L16.1117 18.3157"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ChevronLeft = () => {
    return (
        <svg viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.18806 15.7427L9.09302 14.8378L2.50582 8.25023L9.09302 1.66271L8.18806 0.757751L0.695256 8.25023L8.18806 15.7427Z"
                fill="currentColor"
            />
            <path
                d="M8.1876 16.1952L9.54504 14.8378L2.95784 8.25026L9.54504 1.66274L8.1876 0.305298L0.242324 8.25026L8.1876 16.1952ZM8.64008 14.8378L8.1876 15.2903L1.14728 8.25026L8.1876 1.21026L8.64008 1.66274L2.05288 8.25026L8.64008 14.8378Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const DownloadFile = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="32" height="32" fill="#002B51" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4145 22.5652C15.453 22.6024 15.4956 22.6356 15.5411 22.6652C15.5614 22.6784 15.5838 22.6876 15.6053 22.6988C15.6309 22.7124 15.6562 22.7276 15.6835 22.7388C15.7108 22.7496 15.7393 22.756 15.7675 22.764C15.7911 22.7708 15.8134 22.7796 15.8378 22.784C15.8916 22.7944 15.9458 22.8 16 22.8C16.0542 22.8 16.1084 22.7944 16.1622 22.784C16.1867 22.7796 16.209 22.7708 16.2326 22.764C16.2607 22.756 16.2893 22.7496 16.3166 22.7388C16.3439 22.7276 16.3691 22.7124 16.3952 22.6988C16.4163 22.6876 16.4387 22.6784 16.4589 22.6652C16.5044 22.6356 16.5471 22.6024 16.5855 22.5652L21.5507 17.7656C21.8738 17.4532 21.8738 16.9468 21.5507 16.6344C21.2279 16.322 20.7032 16.322 20.3804 16.6344L16.8276 20.0688V6.8C16.8276 6.358 16.4569 6 16 6C15.5432 6 15.1724 6.358 15.1724 6.8V20.0688L11.6196 16.6344C11.2969 16.322 10.7722 16.322 10.4494 16.6344C10.1262 16.9468 10.1262 17.4532 10.4494 17.7656L15.4145 22.5652Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.1724 19.6001C26.7156 19.6001 26.3448 19.9581 26.3448 20.4001V24.4001H5.65517V20.4001C5.65517 19.9581 5.28441 19.6001 4.82759 19.6001C4.37076 19.6001 4 19.9581 4 20.4001V26.0001H28V20.4001C28 19.9581 27.6292 19.6001 27.1724 19.6001Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const NewPDF = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="32" height="32" fill="#002B51" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 18H8C9.65371 18 11 16.6549 11 15C11 13.3463 9.65371 12 8 12H5V20H7V18ZM13 20H15C17.2057 20 19 18.2069 19 16C19 13.7931 17.2057 12 15 12H13V20ZM23 14H27V12H23C21.8971 12 21 12.8971 21 14V20H23V17H26V15H23V14ZM8 14H7V16H8C8.55086 16 9 15.5509 9 15C9 14.4491 8.55086 14 8 14ZM15 18C16.1029 18 17 17.1029 17 16C17 14.8971 16.1029 14 15 14V18Z"
                fill="white"
            />
        </svg>
    );
};

export const ChevronDown = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.50723 8.81121L5.41219 7.90625L11.9997 14.4935L18.5872 7.90625L19.4922 8.81121L11.9997 16.304L4.50723 8.81121Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.05539 8.81154L5.41283 7.4541L12.0004 14.0413L18.5879 7.4541L19.9453 8.81154L12.0004 16.7568L4.05539 8.81154ZM5.41283 8.35906L4.96035 8.81154L12.0004 15.8519L19.0404 8.81154L18.5879 8.35906L12.0004 14.9463L5.41283 8.35906Z"
                fill="currentColor"
            />
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="9.6"
                        height="16"
                        fill="white"
                        transform="translate(20 7.39941) rotate(90)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ChevronUp = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.4928 15.5882L18.5878 16.4932L12.0003 9.90596L5.41277 16.4932L4.50781 15.5882L12.0003 8.0954L19.4928 15.5882Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.9446 15.5879L18.5872 16.9453L11.9996 10.3581L5.41213 16.9453L4.05469 15.5879L11.9996 7.64259L19.9446 15.5879ZM18.5872 16.0404L19.0396 15.5879L11.9996 8.54755L4.95965 15.5879L5.41213 16.0404L11.9996 9.45315L18.5872 16.0404Z"
                fill="currentColor"
            />
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="9.6"
                        height="16"
                        fill="white"
                        transform="translate(4 17) rotate(-90)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ChevronLeftCirlcle = ({ inactive }) => {
    const borderColor = inactive ? '#E6E6E6' : 'black';
    const chevronColor = inactive ? '#4C4C4C' : 'black';
    return (
        <svg
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24.6724" cy="24" r="23.5" stroke={borderColor} />
            <g>
                <path
                    d="M27.8602 31.4923L28.7651 30.5873L22.1779 23.9998L28.7651 17.4123L27.8602 16.5073L20.3674 23.9998L27.8602 31.4923Z"
                    fill={chevronColor}
                />
                <path
                    d="M27.8601 31.9449L29.2175 30.5874L22.6303 23.9999L29.2175 17.4124L27.8601 16.0549L19.9148 23.9999L27.8601 31.9449ZM28.3126 30.5874L27.8601 31.0399L20.8198 23.9999L27.8601 16.9599L28.3126 17.4124L21.7254 23.9999L28.3126 30.5874Z"
                    fill={chevronColor}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="9.6"
                        height="16"
                        fill="white"
                        transform="matrix(-1 0 0 1 29.2725 16)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ChevronRightCirlcle = ({ inactive }) => {
    const borderColor = inactive ? '#E6E6E6' : 'black';
    const chevronColor = inactive ? '#4C4C4C' : 'black';
    return (
        <svg
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24.6724" cy="24" r="23.5" stroke={borderColor} />
            <g>
                <path
                    d="M21.0846 31.4923L20.1797 30.5873L26.7669 23.9998L20.1797 17.4123L21.0846 16.5073L28.5774 23.9998L21.0846 31.4923Z"
                    fill={chevronColor}
                />
                <path
                    d="M21.0847 31.9449L19.7273 30.5874L26.3145 23.9999L19.7273 17.4124L21.0847 16.0549L29.03 23.9999L21.0847 31.9449ZM20.6323 30.5874L21.0847 31.0399L28.1251 23.9999L21.0847 16.9599L20.6323 17.4124L27.2195 23.9999L20.6323 30.5874Z"
                    fill={chevronColor}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="9.6" height="16" fill="white" transform="translate(19.6724 16)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const CirlcleSmall = ({ inactive }) => {
    const color = inactive ? '#E6E6E6' : '#006EAA';
    return (
        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 0C5.15685 0 6.5 1.34315 6.5 3C6.5 4.65685 5.15685 6 3.5 6C1.84315 6 0.5 4.65685 0.5 3C0.5 1.34315 1.84315 0 3.5 0Z"
                fill={color}
            />
        </svg>
    );
};

export const ChevronLeftNav = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="16" cy="16" r="15.5" stroke="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1563 22.2436L18.9104 21.4894L13.4211 15.9998L18.9104 10.5102L18.1563 9.7561L11.9123 15.9998L18.1563 22.2436Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1562 22.6207L19.2874 21.4895L13.7981 15.9999L19.2874 10.5104L18.1562 9.37915L11.5352 15.9999L18.1562 22.6207ZM18.5333 21.4895L18.1562 21.8666L12.2893 15.9999L18.1562 10.1333L18.5333 10.5104L13.044 15.9999L18.5333 21.4895Z"
                fill="black"
            />
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="8"
                        height="13.3333"
                        fill="white"
                        transform="matrix(-1 0 0 1 19.3333 9.33325)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ChevronRightNav = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="16" cy="16" r="15.5" stroke="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5104 22.2436L13.7563 21.4894L19.2456 15.9998L13.7563 10.5102L14.5104 9.7561L20.7544 15.9998L14.5104 22.2436Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5104 22.6207L13.3792 21.4895L18.8685 15.9999L13.3792 10.5104L14.5104 9.37915L21.1315 15.9999L14.5104 22.6207ZM14.1333 21.4895L14.5104 21.8666L20.3773 15.9999L14.5104 10.1333L14.1333 10.5104L19.6227 15.9999L14.1333 21.4895Z"
                fill="black"
            />
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="8"
                        height="13.3333"
                        fill="white"
                        transform="translate(13.3333 9.33325)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
