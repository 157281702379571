import React from 'react';
import classnames from 'classnames';
import forStorybook from '../../utils/forStorybook.jsx';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import '../Footnotes/footnotes.scss';
import './footnotes-item.scss';
import '../RichText/rich-text.scss';

const FootnotesItem = ({ fields }) => {
    const { ReferenceText, ReferenceID } = fields;
    const footnotesItemBlock = 'zn-footnotes-item';

    return (
        <li className={classnames(`${footnotesItemBlock}`)}>
            {ReferenceID?.value ? (
                <a
                    className={classnames(`${footnotesItemBlock}__counter`, {
                        [`${footnotesItemBlock}__counter--link`]: ReferenceID?.value,
                    })}
                    href={`#${ReferenceID?.value}`}
                ></a>
            ) : (
                <div className={classnames(`${footnotesItemBlock}__counter`)}></div>
            )}
            <span className={'zn-rich-text'}>
                <RichText
                    className={classnames('zn-rich-text', [`zn-rich-text--light`])}
                    field={ReferenceText}
                />
            </span>
        </li>
    );
};
export const FootnotesItemStorybook = forStorybook(FootnotesItem);

export default FootnotesItem;
