import React, { useState, useRef } from 'react';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import { ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import classnames from 'classnames';

import './expertise-page-links.scss';
import { ButtonVariantEnum, ThemeEnum } from '../../utils/enums.js';

const ExpertisePageLinks = ({ sitecoreContext, fields, expertisePageLinks }) => {
    const [setActive, setActiveState] = useState(false);
    const [setHeight, setHeightState] = useState('0px');
    const dropdownContent = useRef(null);
    const toggleLinks = () => {
        setActiveState(!setActive ? true : false);
        setHeightState(setActive ? '0px' : `${dropdownContent.current.scrollHeight}px`);
    };

    const expPageLinks = 'zn-expertise-page-links';

    const { LinkURL, Description, ExpandText, CollapseText } = fields;
    return (
        <div className={classnames(`${expPageLinks}`)}>
            <div className={classnames(`${expPageLinks}__title`)}>
                <ButtonLink
                    fields={{
                        LinkURL: {
                            value: {
                                href: LinkURL?.value?.href,
                                text: LinkURL?.value?.text,
                                target: LinkURL?.value?.target,
                                title: LinkURL?.value?.title,
                            },
                        },
                    }}
                    params={{
                        Theme: ThemeEnum.light,
                        Variant: ButtonVariantEnum.tertiary,
                    }}
                />
            </div>
            <div className={classnames(`${expPageLinks}__description`)}>
                <RichText field={Description} />
            </div>
            {ExpandText?.value && (
                <button
                    className={classnames(`${expPageLinks}__dropdown-CTA`)}
                    onClick={toggleLinks}
                >
                    <div
                        className={classnames(`${expPageLinks}__dropdown-title`, {
                            [`${expPageLinks}__dropdown-title--open`]: setActive,
                        })}
                    >
                        {setActive ? CollapseText?.value : ExpandText?.value}
                    </div>
                    <div
                        className={classnames(`${expPageLinks}__dropdown-icon`, {
                            [`${expPageLinks}__dropdown-icon--closed`]: !setActive,
                        })}
                    >
                        {setActive ? <ChevronUp /> : <ChevronDown />}
                    </div>
                </button>
            )}
            <div
                ref={dropdownContent}
                style={
                    sitecoreContext.pageEditing
                        ? { maxHeight: 'auto' }
                        : { maxHeight: `${setHeight}` }
                }
                className={classnames(`${expPageLinks}__dropdown-links`)}
            >
                <ol>{expertisePageLinks}</ol>
            </div>
        </div>
    );
};

const ExpertisePageLinksSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields])(ExpertisePageLinks)
);
export const ExpertisePageLinksStorybook = forStorybookV2(ExpertisePageLinksSitecore);
export default withPlaceholder('expertisePageLinks')(ExpertisePageLinksSitecore);
