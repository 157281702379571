import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
// eslint-disable-next-line import/no-unresolved
import { DateTime } from 'luxon';
import { TimeZoneEnum } from '../../utils/enums.js';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import forStorybookV2 from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import './knowledge-hub-date.scss';

const knowledgeHubDate = 'zn-knowledge-hub-date';

const KnowledgeHubDate = ({ fields, sitecoreContext }) => {
    const { EventTitle, LinkURL, EventDate } = fields;
    const contextDate = DateTime.fromISO(EventDate?.value, {
        locale: sitecoreContext?.route?.itemLanguage,
    }).setZone(TimeZoneEnum.server);
    const monthString = `${contextDate?.monthShort}`;
    const dayString = `${contextDate?.day}`;
    return (
        <div className={classnames(`${knowledgeHubDate}`)}>
            <div className={classnames(`${knowledgeHubDate}__date`)}>
                <div className={classnames(`${knowledgeHubDate}__month`)}>
                    <Text field={{ value: monthString }} />
                </div>
                <div className={classnames(`${knowledgeHubDate}__day`)}>
                    <Text field={{ value: dayString }} />
                </div>
            </div>
            <div className={classnames(`${knowledgeHubDate}__info`)}>
                <div className={classnames(`${knowledgeHubDate}__title`)}>
                    <Text field={EventTitle} />
                </div>
                <div className={classnames(`${knowledgeHubDate}__link`)}>
                    <a href={LinkURL?.value?.href} alt={LinkURL?.value?.title}>
                        {LinkURL?.value?.text}
                    </a>
                </div>
            </div>
        </div>
    );
};

const KnowledgeHubDateSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields])(KnowledgeHubDate)
);

export const KnowledgeHubDateStorybook = forStorybookV2(KnowledgeHubDateSitecore);
export default KnowledgeHubDateSitecore;
