import React from 'react';
import Text from '../Text/Text.jsx';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';

import './contact-cards.scss';

const contactCardsBlock = 'zn-contact-cards';
export const ContactCards = ({
    fields,
    params,
    contactCardsContext,
    contactCardList,
    children,
}) => {
    const { SectionTitle } = fields;
    const { HideProfileImages, HideProfileImagesMobile, Inline } = params;
    return (
        <div
            className={classnames(contactCardsBlock, {
                [`${contactCardsBlock}--hide-images`]: HideProfileImages === '1',
                [`${contactCardsBlock}--hide-images-mobile`]: HideProfileImagesMobile === '1',
            })}
        >
            <Text
                type="allCaps"
                className={`${contactCardsBlock}__title`}
                field={SectionTitle}
                useDefaultMargin={false}
            />
            <ul
                className={classnames(`${contactCardsBlock}-list`, {
                    [`${contactCardsBlock}-list--inline`]: Inline === '1',
                })}
            >
                {contactCardsContext}
                {contactCardList}
                {children}
            </ul>
        </div>
    );
};

export default withPlaceholder('contactCardList')(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
        ContactCards
    )
);

export const ContactCardsStorybook = forStorybook(ContactCards);
