import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import './button-link.scss';
import { ArrowRight, ExternalLink } from '../../assets/LinkIcons.js';
import { LinkTargetValueEnum, ThemeEnum, ButtonVariantEnum } from '../../utils/enums';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import paramToBool from '../../utils/paramToBool';
import { Link as RouterLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const ButtonLink = ({ sitecoreContext, fields, params, onClick, t }) => {
    const { LinkURL } = fields;
    const { Variant, Theme, MarginBottom, MarginTop, isPeripheralContent, showInPDF } = params;
    const LinkText = LinkURL?.value?.text || '';
    const LinkAltText = LinkURL?.value?.title || '';

    const target =
        LinkURL?.value?.target === LinkTargetValueEnum.blank
            ? LinkTargetValueEnum.blank
            : LinkTargetValueEnum.self;
    const queryParams = LinkURL?.value?.querystring || '';
    const variant = Variant?.toLowerCase() || ButtonVariantEnum.primary.toLowerCase();
    const theme = Theme?.toLowerCase() || ThemeEnum.light.toLowerCase();
    const url = `${LinkURL?.value?.href?.split('?')[0]}?${queryParams}`;

    const isPdfUrl = (url) => (url.match(/\.pdf\??$/g) ? true : false);
    const isMedia = (url) => {
        return url?.startsWith('/-/media/') || false;
    };
    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    const showInPdf = showInPDF ? paramToBool(showInPDF) : false;

    if (sitecoreContext?.pageEditing) {
        return (
            <div
                className={classnames(
                    `zn-button`,
                    `zn-button--${variant}`,
                    `zn-button--${theme}`,
                    `zn-button--${variant}--${theme}`,
                    {
                        [`zn-link-external`]: target === '_blank',
                        [`zn-button--mb-${MarginBottom?.toLowerCase()}`]: MarginBottom,
                        [`zn-button--mt-${MarginTop?.toLowerCase()}`]: MarginTop,
                    }
                )}
                data-is-peripheral={paramToBool(isPeripheralContent) ? 'true' : undefined}
            >
                <p>{LinkText}</p>
                {target === '_blank' && <span className="sr-only">{t('OpensInANewTab')}</span>}
                {target === '_blank' && (
                    <div
                        className={classnames(`zn-button__icon`, {
                            [`zn-button--${variant}--${theme}__icon`]: variant === 'tertiary',
                            [`zn-link--${variant}--${theme}__icon`]: variant === 'tertiary',
                        })}
                    >
                        <ExternalLink />
                    </div>
                )}
                {variant === 'tertiary' && target !== '_blank' && (
                    <div
                        className={classnames(
                            `zn-button__icon`,
                            `zn-button--${variant}--${theme}__icon`
                        )}
                    >
                        <ArrowRight />
                    </div>
                )}
            </div>
        );
    }

    if (url?.startsWith('/') && !isPdfUrl) {
        return (
            <>
                {LinkText && (
                    <RouterLink
                        to={url}
                        target={target}
                        alt={LinkAltText}
                        data-is-peripheral={paramToBool(isPeripheralContent) ? 'true' : undefined}
                        className={classnames(
                            `zn-button`,
                            `zn-button--${variant}`,
                            `zn-button--${theme}`,
                            `zn-button--${variant}--${theme}`,
                            `${!showInPdf ? 'zn-button--pdf-disabled' : ''}`,
                            {
                                [`zn-link-external`]: target === '_blank',
                                [`zn-button--mb-${MarginBottom?.toLowerCase()}`]: MarginBottom,
                                [`zn-button--mt-${MarginTop?.toLowerCase()}`]: MarginTop,
                            }
                        )}
                        onClick={onClick ? onClick : undefined}
                    >
                        <RichText field={{ value: LinkText }} />
                        {target === '_blank' && (
                            <span className="sr-only">{t('OpensInANewTab')}</span>
                        )}
                        {target === '_blank' && (
                            <div
                                className={classnames(`zn-button__icon`, {
                                    [`zn-button--${variant}--${theme}__icon`]:
                                        variant === 'tertiary',
                                    [`zn-link--${variant}--${theme}__icon`]: variant === 'tertiary',
                                })}
                            >
                                <ExternalLink />
                            </div>
                        )}
                        {variant === 'tertiary' && target !== '_blank' && (
                            <div
                                className={classnames(
                                    `zn-button__icon`,
                                    `zn-button--${variant}--${theme}__icon`
                                )}
                            >
                                <ArrowRight />
                            </div>
                        )}
                    </RouterLink>
                )}
            </>
        );
    }

    if (!(pdfView && !showInPdf)) {
        return (
            <>
                {LinkText && (
                    <a
                        href={url}
                        alt={LinkAltText}
                        target={target}
                        className={classnames(
                            `zn-button`,
                            `zn-button--${variant}`,
                            `zn-button--${theme}`,
                            `zn-button--${variant}--${theme}`,
                            {
                                [`zn-link-external`]: target === '_blank',
                                [`zn-button--mb-${MarginBottom?.toLowerCase()}`]: MarginBottom,
                                [`zn-button--mt-${MarginTop?.toLowerCase()}`]: MarginTop,
                            }
                        )}
                        data-is-peripheral={paramToBool(isPeripheralContent) ? 'true' : undefined}
                        {...(isPdfUrl(url) || isMedia(url) ? { download: true } : {})}
                    >
                        <RichText field={{ value: LinkText }} />
                        {target === '_blank' && (
                            <span className="sr-only">{t('OpensInANewTab')}</span>
                        )}
                        {target === '_blank' && (
                            <div
                                className={classnames(`zn-button__icon`, {
                                    [`zn-button--${variant}--${theme}__icon`]:
                                        variant === 'tertiary',
                                    [`zn-link--${variant}--${theme}__icon`]: variant === 'tertiary',
                                })}
                            >
                                <ExternalLink />
                            </div>
                        )}
                        {variant === 'tertiary' && target !== '_blank' && (
                            <div
                                className={classnames(
                                    `zn-button__icon`,
                                    `zn-button--${variant}--${theme}__icon`
                                )}
                            >
                                <ArrowRight />
                            </div>
                        )}
                    </a>
                )}
            </>
        );
    }

    return null;
};

const ButtonLinkSitecore = withTranslation()(
    withSitecoreContext()(
        withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.params])(
            ButtonLink
        )
    )
);

export const ButtonLinkStorybook = forStorybookV2(ButtonLinkSitecore);

export default ButtonLinkSitecore;
