var luxon = require('luxon');

export const IsEmptyDate = function (date) {
    var parsed = date;
    var jsDate = new Date(date);
    if (date && jsDate) {
        parsed = luxon.DateTime.fromJSDate(jsDate);
    }
    return !date || parsed?.year === 0 || parsed?.year === 1;
};
