import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { DateTime } from 'luxon';
import { IsEmptyDate } from '../../utils/dateExtensions';
import { Image as Img, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import { ThemeEnum, LinkTargetValueEnum, TimeZoneEnum } from '../../utils/enums.js';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

import './news-card.scss';

const newsCard = 'zn-news-card';

const NewsCard = ({ fields, sitecoreContext, t }) => {
    const { data } = fields;
    const CTAText = data?.datasource?.cTAText || '';
    const CTAAltText = data?.datasource?.cTAAltText || '';
    const DescriptionOverride = data?.datasource?.descriptionOverride || '';
    const DateOverride = data?.datasource?.dateOverride || '';
    const Image = data?.datasource?.imageOverride?.src
        ? data?.datasource?.imageOverride
        : data?.datasource?.newsItemURL?.targetItem?.teaserImage;
    const PageLink = data?.datasource?.newsItemURL?.targetItem || '';
    const teaserTitle = PageLink?.teaserTitle;
    const teaserDescription = PageLink?.teaserDescription;
    const teaserDate = PageLink?.teaserDate;
    const Description = DescriptionOverride?.value ? DescriptionOverride : teaserDescription;
    const ContextDateOverride =
        DateOverride?.value &&
        DateTime.fromISO(DateOverride?.value, {
            locale: sitecoreContext?.route?.itemLanguage,
        })
            .setZone(TimeZoneEnum.server)
            .toLocaleString(DateTime.DATE_MED);
    const ContextTeaserDate =
        teaserDate?.value &&
        DateTime.fromISO(teaserDate?.value, {
            locale: sitecoreContext?.route?.itemLanguage,
        })
            .setZone(TimeZoneEnum.server)
            .toLocaleString(DateTime.DATE_MED);
    const FinalDate =
        DateOverride?.value && !IsEmptyDate(ContextDateOverride)
            ? ContextDateOverride
            : teaserDate?.value && !IsEmptyDate(ContextTeaserDate)
            ? ContextTeaserDate
            : '';
    const isEditing = sitecoreContext.pageEditing;

    const withImage = !!Image?.src;

    return (
        (PageLink || isEditing) && (
            <section className={classnames(`${newsCard}`)}>
                {withImage && (
                    <a href={PageLink?.url} className={classnames(`${newsCard}__image-link`)}>
                        <Img
                            alt={CTAAltText?.value}
                            field={Image}
                            className={classnames(`${newsCard}__image`)}
                        />
                    </a>
                )}
                {FinalDate && <div className={classnames(`${newsCard}__date`)}>{FinalDate}</div>}
                <a className={classnames(`${newsCard}__title-link`)} href={PageLink?.url}>
                    <div className={classnames(`${newsCard}__title`)}>
                        {data?.datasource?.titleOverride?.value && PageLink ? (
                            <RichText field={data?.datasource?.titleOverride}></RichText>
                        ) : (
                            <RichText field={teaserTitle} />
                        )}
                        {!PageLink && isEditing && t('NoPageLinkSet')}
                    </div>
                </a>
                {Description?.value &&
                    (!withImage ? (
                        <div className={classnames(`${newsCard}__description`)}>
                            <RichText field={Description}></RichText>
                        </div>
                    ) : (
                        isEditing && <p>Description will not be displayed if an image is present</p>
                    ))}
                {CTAText?.value && CTAAltText?.value && PageLink && (
                    <ButtonLink
                        fields={{
                            LinkURL: {
                                value: {
                                    text: CTAText?.value,
                                    href: PageLink?.url,
                                    title: CTAAltText?.value,
                                    target: LinkTargetValueEnum.self,
                                },
                            },
                        }}
                        params={{
                            Theme: ThemeEnum.light,
                            Variant: 'tertiary',
                        }}
                    />
                )}
            </section>
        )
    );
};

const NewsCardSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields])(NewsCard)
);
export default withTranslation()(NewsCardSitecore);
export const NewsCardStorybook = forStorybookV2(NewsCardSitecore);
