import React from 'react';
import classnames from 'classnames';
import { SelectedCheckbox, UnselectedCheckbox } from '../../assets/common.js';
import './checkbox.scss';

const Checkbox = ({ checked, change, id, pId, disabledState, setDisabledState, ...props }) => {
    return (
        <div
            className={classnames(`zn-checkbox__container`, {
                [`checked`]: checked,
            })}
        >
            <input
                type="checkbox"
                className="zn-checkbox__hidden"
                checked={checked}
                tabIndex={-1}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        change(id, pId);
                        setDisabledState(true);
                    }
                }}
                {...props}
            ></input>
            <div
                className={classnames(`zn-checkbox__styled`, {
                    [`checked`]: checked,
                    [`zn-checkbox__styled--disabled`]: disabledState,
                })}
            >
                {checked ? <SelectedCheckbox /> : <UnselectedCheckbox />}
            </div>
        </div>
    );
};

export default Checkbox;
