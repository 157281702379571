import React, { useEffect } from 'react';
import { Button } from '../Button/Button.js';
import { useLocation } from 'react-router-dom';
import { MAIN_CONTENT_PRE } from '../../Layout.js';
const BypassButton = ({ children }) => {
    const location = useLocation();

    const skipToMainContent = (e) => {
        const mainContent = document.getElementById(MAIN_CONTENT_PRE);

        if (!mainContent) return;
        mainContent.scrollIntoView({ behavior: 'smooth' });
        const currentTabIndex = mainContent.tabIndex;

        //Setting tabIndex to -1 to fix on firefox.
        mainContent.tabIndex = -1;
        e.target.blur();
        mainContent.focus();
        mainContent.tabIndex = currentTabIndex;
    };
    const resetTabOrder = () => {
        const dummy = document.createElement('button');

        dummy.style.opacity = '0';
        dummy.style.position = 'absolute';
        dummy.style.left = '-10000px';
        dummy.style.top = 'auto';
        dummy.style.width = '1px';
        dummy.style.height = '1px';
        dummy.style.overflow = 'hidden';

        document.body.insertBefore(dummy, document.body.firstChild);

        dummy.focus();
        dummy.blur();

        document.body.removeChild(dummy);
    };

    useEffect(() => {
        resetTabOrder();
    }, [location.pathname]);

    return (
        <span>
            <Button
                as="button"
                variant="primary"
                className="zn-main-nav__bypass-button"
                onClick={skipToMainContent}
            >
                {children}
            </Button>
        </span>
    );
};

export default BypassButton;
