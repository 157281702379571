import React from 'react';
import mapLayers from './mapLayerJson.json';
import ReactDOM from 'react-dom';
import InteractiveMapPopup from '../InteractiveMapPopup/InteractiveMapPopup.jsx';
import MapboxAccessibility from '../../lib/mapbox-gl-accessibility.js';
import { MAP_EASE_PADDING_CONST } from '../../utils/constants';
import * as turf from '@turf/turf';

//data manipulation helper methods
export const getBoundingBoxCoordinates = (coordinatesArray) => {
    let maxVal = coordinatesArray.reduce(function (a, b) {
        return Math.max(a, b);
    }, coordinatesArray[0]);
    let minVal = coordinatesArray.reduce(function (a, b) {
        return Math.min(a, b);
    }, coordinatesArray[0]);

    return [maxVal, minVal];
};

export const isBetween = (number, a, b, inclusive) => {
    var min = Math.min.apply(Math, [a, b]),
        max = Math.max.apply(Math, [a, b]);
    return inclusive ? number >= min && number <= max : number > min && number < max;
};

export const getMapSvgPath = (tagId, mapTags) => {
    const tagIcon = mapTags.find((mapTag) => mapTag?.id === tagId)?.path;
    return tagIcon;
};

export const getMarkerTag = (tagId, mapTags) => {
    const tagTitle = mapTags.find((mapTag) => mapTag?.id === tagId)?.title;
    return tagTitle;
};

export const getMarkerTitle = (marker) => {
    const title = marker?.markertitle ? marker?.markertitle : marker?.teasertitle;
    return title;
};

export const configureUrlFromPath = (path) => {
    const url = path?.split('/home')[1];
    return url;
};

export const getSearchRadius = (map) => {
    const curMap = map.current;
    const bounds = curMap.getBounds();
    const center = curMap.getCenter();
    const sw = bounds._sw;
    const radius = turf.distance([sw.lng, sw.lat], [center.lng, center.lat], 'kilometers');
    return radius;
};

//map manipulation methods
export const loadMapSource = (map, isClustersOn, MaxClusterZoom) => {
    map.current.addSource('places', {
        type: 'geojson',
        cluster: isClustersOn,
        clusterMaxZoom: MaxClusterZoom?.value ? MaxClusterZoom?.value : 7,
        clusterRadius: 50,
        data: { type: 'FeatureCollection', features: [] },
    });
};

export const loadMapLayers = (map) => {
    if (!map.current.getLayer(mapLayers.clusterLayer.id))
        map.current.addLayer(mapLayers.clusterLayer);
    if (!map.current.getLayer(mapLayers.clusterCountLayer.id))
        map.current.addLayer(mapLayers.clusterCountLayer);
    if (!map.current.getLayer(mapLayers.pointShadowLayer.id))
        map.current.addLayer(mapLayers.pointShadowLayer);
    if (!map.current.getLayer(mapLayers.pointLayer.id)) map.current.addLayer(mapLayers.pointLayer);
};

export const loadMapAccessibilityLayer = (map) => {
    let accessibilityControl = new MapboxAccessibility({
        accessibleLabelProperty: 'name',
        layers: ['place'],
    });

    map.current.addControl(accessibilityControl);
};

export const loadMapLanguage = (language, map) => {
    let languageLayers = [
        'country-label',
        'state-label',
        'settlement-subdivision-label',
        'airport-label',
        'poi-label',
        'water-point-label',
        'water-line-label',
        'natural-point-label',
        'natural-line-label',
        'waterway-label',
    ];
    if (language === 'fr-CA') {
        languageLayers.map((layerName) =>
            map.current.setLayoutProperty(layerName, 'text-field', ['get', `name_fr`])
        );
    }
};

export const loadClusterClickFunctionality = (e, map) => {
    const features = map.current.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
    });
    const clusterId = features[0].properties.cluster_id;
    map.current.getSource('places').getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        map.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
        });
    });
};

export const formatGeoJsonData = (mapMarkerData, mapTags) => {
    let geojson = {
        type: 'FeatureCollection',
        features: [],
    };
    mapMarkerData?.data.map((marker) => {
        let markerTags = marker?.industries?.split('|');
        let mapSvgPath = getMapSvgPath(
            marker?.industries?.split('|')?.[markerTags?.length - 1],
            mapTags
        );
        let feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    parseFloat(marker?.lng.replace(',', '.')),
                    parseFloat(marker?.lat.replace(',', '.')),
                ],
            },
            properties: {
                name: getMarkerTitle(marker),
                circleIcon: `circle_${mapSvgPath}`,
                icon: `pin_${mapSvgPath}`,
                lowLevelTag: getMarkerTag(markerTags?.[markerTags?.length - 1], mapTags),
                notNavigatable: marker?.notNavigatable,
                disableMapCTA: marker?.disableMapCTA,
                url: configureUrlFromPath(marker?.path),
            },
        };
        geojson.features.push(feature);
    });
    return geojson;
};

export const easeToMapPoints = (geojson, map) => {
    let longitudes = geojson.features.map((item) => parseFloat(item.geometry.coordinates[0]));
    let latitudes = geojson.features.map((item) => parseFloat(item.geometry.coordinates[1]));

    let latMaxMin = getBoundingBoxCoordinates(latitudes);
    let lngMaxMin = getBoundingBoxCoordinates(longitudes);

    let bbox = [lngMaxMin[1], latMaxMin[1], lngMaxMin[0], latMaxMin[0]];
    // if all values are finite then ease to the bounding box area of the points
    if (!bbox.filter((value) => !isFinite(value)).length)
        map.current.fitBounds(bbox, { padding: MAP_EASE_PADDING_CONST });
};

export const renderMapPopup = (marker, spriteData, CTAText) => {
    const coordinates = marker.features[0].geometry.coordinates.slice();
    const mapCardNode = document.createElement('div');
    ReactDOM.render(
        <InteractiveMapPopup
            icon={spriteData[marker?.features[0]?.properties?.circleIcon]}
            title={marker?.features[0]?.properties?.name}
            tags={marker?.features[0]?.properties?.lowLevelTag}
            ctaText={CTAText}
            notNavigatable={marker?.features[0]?.properties?.notNavigatable}
            disableMapCTA={marker?.features[0]?.properties?.disableMapCTA}
            url={marker?.features[0]?.properties?.url}
        />,
        mapCardNode
    );

    while (Math.abs(marker.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += marker.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    return { coordinates, mapCardNode };
};

export const exportCanvasBlob = (blob, MapImageExportName) => {
    var fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
    var downloadLink = document.createElement('a');
    downloadLink.download = MapImageExportName?.value ? MapImageExportName?.value : '';
    if (window.webkitURL != null) {
        downloadLink.href = window.webkitURL.createObjectURL(blob);
    } else {
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.onclick = document.body.removeChild(event.target);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
    }
    downloadLink.click();
};

export const filterInBoundMapPoints = (withinRadiusPoints, mapBounds) => {
    let nePoint = mapBounds?._ne;
    let swPoint = mapBounds?._sw;
    let inBoundsPoints = withinRadiusPoints.filter(
        (point) =>
            isBetween(parseFloat(point.lng.replace(',', '.')), swPoint.lng, nePoint.lng, true) &&
            isBetween(parseFloat(point.lat.replace(',', '.')), swPoint.lat, nePoint.lat, true)
    );

    return inBoundsPoints;
};
