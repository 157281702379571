import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import forStorybook from '../../utils/forStorybook.jsx';
import { ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import './footnotes.scss';

const Footnotes = ({ sitecoreContext, fields, footnotes }) => {
    const { FootnotesTitle } = fields;
    const [setActive, setActiveState] = useState(false);
    const [setHeight, setHeightState] = useState('0px');
    const content = useRef(null);
    const footnotesBlock = 'zn-footnotes';

    const toggleFootnotes = () => {
        setActiveState(!setActive ? true : false);
        setHeightState(setActive ? '0px' : `${content.current.scrollHeight}px`);
    };

    return (
        <div className={classnames(`${footnotesBlock}`)}>
            {sitecoreContext.pageEditing && (
                <p className={classnames(`${footnotesBlock}__editor`)}>Footnotes Editor View </p>
            )}
            <button className={classnames(`${footnotesBlock}__banner`)} onClick={toggleFootnotes}>
                <div className={classnames(`${footnotesBlock}__title`)}>
                    {FootnotesTitle?.value}
                </div>
                <div className={classnames(`${footnotesBlock}__icon`)}>
                    {!setActive ? <ChevronDown /> : <ChevronUp />}
                </div>
            </button>
            <div
                ref={content}
                style={
                    sitecoreContext.pageEditing
                        ? { maxHeight: 'auto' }
                        : { maxHeight: `${setHeight}` }
                }
                className={classnames(`${footnotesBlock}__content`)}
            >
                <ol>{footnotes}</ol>
            </div>
        </div>
    );
};

PropTypes.propTypes = {
    fields: PropTypes.exact({
        FootnotesTitle: PropTypes.shape({
            value: PropTypes.string,
        }),
        Footnotes: PropTypes.array,
    }),
};

const FootnotesWithContext = withSitecoreContext()(Footnotes);

export const FootnotesStorybook = forStorybook(FootnotesWithContext);

export default withPlaceholder('footnotes')(FootnotesWithContext);
