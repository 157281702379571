import { ContentTypeEnum } from './enums.js';
import parse from 'html-dom-parser';

export function getChildren(node) {
    let text = '';
    if (node?.children) {
        text += getChildren(node?.children[0]);
    }

    if (node?.next) {
        text += getChildren(node.next);
    }
    return node?.data ? node?.data + text : text;
}

export function parsePageTitle(title) {
    return getChildren(parse(title)[0]);
}

function createPageTitle(route, t) {
    const titleValue =
        route?.fields?.MetaTitle?.value || route?.fields?.TeaserTitle?.value || route?.displayName;
    const itemTitle = getChildren(parse(titleValue)[0]);

    let middleTitle = '';
    switch (route?.templateName) {
        case ContentTypeEnum.event:
        case ContentTypeEnum.premiumArticle:
        case ContentTypeEnum.quarterlyReportLanding:
        case ContentTypeEnum.publication:
            middleTitle = ` | ${t('Insights')}`;
            break;

        case ContentTypeEnum.work:
        case ContentTypeEnum.projectMapWork:
            middleTitle = ` | ${t('Work')}`;
            break;

        case ContentTypeEnum.news:
        case ContentTypeEnum.about:
            middleTitle = ` | ${t('About')}`;
            break;

        case ContentTypeEnum.people:
        case ContentTypeEnum.bioPage:
            middleTitle = ` | ${t('People')}`;
            break;

        default:
            middleTitle = '';
    }

    return `${itemTitle}${middleTitle} | ${t('TorysBrand')}`;
}

export default createPageTitle;
