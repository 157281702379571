import React from 'react';
import ItemWithIcon from '../ItemWithIcon/ItemWithIcon.jsx';
import Text from '../Text/Text.jsx';
import MegaNavHeader from '../MegaNavHeader/MegaNavHeader.jsx';
import { BasicLinkList as LinkList } from '../LinkList/LinkList.jsx';
import LinkListItem from '../LinkListItem/LinkListItem.jsx';
import { LinkTargetEnum, LinkListLayoutEnum, LinkTargetValueEnum } from '../../utils/enums';

import './mega-nav-secondary.scss';

const MegaNavSecondary = ({
    t,
    insightsItem,
    insightsContentTypeTitle,
    insightsContentTypeLinks,
    openMegaMenuHome,
    editorsPicksLinks,
}) => {
    return (
        <section className="zn-mega-menu-secondary">
            <MegaNavHeader
                title={insightsItem?.title}
                description={insightsItem?.description}
                link={insightsItem?.descriptionLink?.url}
                linkTarget={LinkTargetEnum.sameTab}
                linkText={insightsItem?.descriptionLink?.text}
                onBack={openMegaMenuHome}
            />
            <section className="zn-mega-menu-secondary__body">
                <div className="zn-mega-menu-secondary__body__column zn-mega-menu-secondary__body__main-column">
                    <Text type="h2" className="zn-mega-menu-secondary__title">
                        {insightsContentTypeTitle?.value}
                    </Text>
                    <ul className="zn-mega-menu-secondary__list">
                        {Array.isArray(insightsContentTypeLinks) &&
                            insightsContentTypeLinks.map((item, index) => (
                                <li key={index} className="zn-mega-menu-secondary__list__item">
                                    <ItemWithIcon
                                        linkName={item?.title?.value}
                                        href={item?.navigationLink?.url}
                                        description={item?.subTitle}
                                        icon={{ value: item?.itemIcon }}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="zn-mega-menu-secondary__body__column zn-mega-menu-secondary__body__secondary-column">
                    <Text type="h2" className="zn-mega-menu-secondary__title">
                        {t('EditorsPicks')}
                    </Text>
                    <LinkList params={{ Layout: LinkListLayoutEnum.highlighted }} fields={{}}>
                        {Array.isArray(editorsPicksLinks) &&
                            editorsPicksLinks.map((item, index) => {
                                if (item?.text && item?.url) {
                                    return (
                                        <LinkListItem
                                            params={{
                                                Layout: LinkListLayoutEnum.highlighted,
                                                HideArrows: '1',
                                            }}
                                            key={`item-${index}`}
                                            fields={{
                                                LinkName: item?.text,
                                                LinkURL: {
                                                    value: {
                                                        text: item?.text,
                                                        href: item?.url,
                                                        title: item?.text,
                                                        target: LinkTargetValueEnum.self,
                                                    },
                                                },
                                            }}
                                        />
                                    );
                                }
                            })}
                    </LinkList>
                </div>
            </section>
        </section>
    );
};

export default MegaNavSecondary;
