import React from 'react';

export const FacebookIconComponent = () => (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0v32h32V0H0zm20.2 10.7h-1.5c-1.2 0-1.4.6-1.4 1.4v1.8h2.8l-.4 2.8h-2.4V24h-2.9v-7.3h-2.5v-2.8h2.5v-2.1C14.3 9.3 15.8 8 18 8c1 0 1.9.1 2.2.1v2.6z"
            fill="currentColor"
        />
    </svg>
);

export const TwitterIconComponent = () => (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0v32h32V0H0zm23.21 12.31v.48a10.51 10.51 0 01-10.57 10.58 10.63 10.63 0 01-5.7-1.66c.296.032.593.049.89.05a7.48 7.48 0 004.62-1.6A3.73 3.73 0 019 17.58c.23.044.465.067.7.07a3.93 3.93 0 001-.13 3.73 3.73 0 01-3-3.65 3.79 3.79 0 001.68.46 3.73 3.73 0 01-1.68-3.14 3.68 3.68 0 01.5-1.87 10.55 10.55 0 007.66 3.88c-.06-.28-.09-.564-.09-.85a3.71 3.71 0 016.43-2.54 7.41 7.41 0 002.36-.9A3.74 3.74 0 0122.92 11a7.531 7.531 0 002.14-.59 7.55 7.55 0 01-1.85 1.9z"
            fill="currentColor"
        />
    </svg>
);

export const LinkedinIconComponent = () => (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0v32h32V0H0zm11.6 24H8.3V13.3h3.3V24zM9.9 11.9c-1 0-1.9-.9-1.9-2C8 8.9 8.9 8 9.9 8c1.1 0 1.9.9 1.9 1.9 0 1.1-.8 2-1.9 2zM24 24h-3.3v-5.2c0-1.2 0-2.8-1.7-2.8s-2 1.3-2 2.7V24h-3.3V13.3h3.2v1.5c.4-.8 1.5-1.7 3.1-1.7 3.4 0 4 2.2 4 5.1V24z"
            fill="currentColor"
        />
    </svg>
);

export const LinkIconComponent = () => (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0v32h32V0H0zm16.8 21.2l-2.54 2.55a4.26 4.26 0 01-6-6l3.7-3.7a4.26 4.26 0 016 0A.675.675 0 1117 15a3 3 0 00-2-.91 2.93 2.93 0 00-2.08.86l-3.7 3.71a2.942 2.942 0 004.16 4.16l2.55-2.54a.65.65 0 01.92.92h-.05zm7-6.94l-3.94 3.93a4.24 4.24 0 01-6 0 .65.65 0 01.92-.92 3 3 0 004.17 0l3.93-3.94a2.95 2.95 0 000-4.16 2.95 2.95 0 00-4.16 0l-3 3a.66.66 0 01-.93-.93l3-3a4.26 4.26 0 016 6l.01.02z"
            fill="currentColor"
        />
    </svg>
);

export const MailIconComponent = () => (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0v32h32V0H0zm24.27 8a1.7 1.7 0 01.83.23L17 16.3a1.44 1.44 0 01-2 0l-.26-.3-8-7.73a1.72 1.72 0 011-.31L24.27 8zM6 9.81c0-.354.1-.701.29-1L14 16.28l-7.72 6.89a1.81 1.81 0 01-.28-1V9.81zM7.73 24a1.64 1.64 0 01-1-.33l7.76-6.91h.05a1.917 1.917 0 00.67.44l.25.07h.13a2.09 2.09 0 001.84-.55l7.71 7a1.69 1.69 0 01-.94.29L7.73 24zM26 22.19a1.89 1.89 0 01-.31 1L18 16.28l7.63-7.6c.245.325.375.723.37 1.13v12.38z"
            fill="currentColor"
        />
    </svg>
);

export const PdfIconComponent = () => (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 14v4a2 2 0 000-4zm0 0v4a2 2 0 000-4zM0 0v32h32V0H0zm8 18H7v2H5v-8h3a3 3 0 010 6zm7 2h-2v-8h2a4 4 0 110 8zm12-6h-4v1h3v2h-3v3h-2v-6a2 2 0 012-2h4v2zm-12 0v4a2 2 0 000-4zm-7 0H7v2h1a1 1 0 000-2z"
            fill="currentColor"
        />
    </svg>
);
