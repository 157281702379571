import React from 'react';
import classnames from 'classnames';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './project-map-work-article-table.scss';

const projectTableBlock = 'zn-project-map-work-article-table';
const ProjectMapWorkArticleTable = ({ rows, sitecoreContext, rendering }) => {
    const contextFields = sitecoreContext?.route?.fields;
    let selectedAttributes = rendering?.placeholders?.rows?.map(
        (rowItem) => rowItem?.fields?.ContextValueField?.value
    );
    let hasAttributes =
        selectedAttributes.filter((attr) => contextFields?.[attr]?.value).length > 0;

    return (
        (hasAttributes || sitecoreContext?.pageEditing) && (
            <div className={classnames(projectTableBlock)}>
                {sitecoreContext?.pageEditing ? (
                    <div>{rows}</div>
                ) : (
                    <table className="zn-project-map-article-table">
                        <tbody>{rows}</tbody>
                    </table>
                )}
            </div>
        )
    );
};

export const BasicProjectMapWorkArticleTable = ProjectMapWorkArticleTable;

export default withSitecoreContext()(withPlaceholder('rows')(ProjectMapWorkArticleTable));
