import React from 'react';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import forStorybook from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import './news-card-row.scss';

const NewsCardRow = ({ newsCards }) => {
    const contentCardRowBlock = 'zn-news-card-row';

    return <div className={`${contentCardRowBlock}`}>{newsCards}</div>;
};

const NewsCardRowSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params])(NewsCardRow)
);
export default withPlaceholder('newsCards')(NewsCardRowSitecore);
export const NewsCardRowStorybook = forStorybook(NewsCardRow);
