import React from 'react';
import classnames from 'classnames';
import { TwoColumnLayoutEnum } from '../../utils/enums';
import { RichText as RichTextJss } from '@sitecore-jss/sitecore-jss-react';
import '../TwoColumnProfileList/two-column-profile-list.scss';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';

const TwoColumnProfileListItem = ({ params, fields }) => {
    const { Layout } = params;
    const { Label, Description } = fields;
    const twoColumnProfileListItemBlock = 'zn-two-column-profile-list-item';
    return (
        <li
            className={classnames(`${twoColumnProfileListItemBlock}`, {
                [`${twoColumnProfileListItemBlock}--paragraph`]:
                    Layout === TwoColumnLayoutEnum.paragraph,
            })}
        >
            <div
                className={classnames(`${twoColumnProfileListItemBlock}__text`, {
                    [`${twoColumnProfileListItemBlock}__text--paragraph`]:
                        Layout === TwoColumnLayoutEnum.paragraph,
                })}
            >
                {Label?.value && (
                    <div
                        className={classnames(`${twoColumnProfileListItemBlock}__label`, {
                            [`${twoColumnProfileListItemBlock}__label--paragraph`]:
                                Layout === TwoColumnLayoutEnum.paragraph,
                        })}
                    >
                        {Layout === TwoColumnLayoutEnum.bulletPoint}
                        <RichTextJss field={Label} />
                    </div>
                )}
                {Description?.value && (
                    <div
                        className={classnames(`${twoColumnProfileListItemBlock}__description`, {
                            [`${twoColumnProfileListItemBlock}__description--paragraph`]:
                                Layout === TwoColumnLayoutEnum.paragraph,
                        })}
                    >
                        <RichTextJss field={Description} />
                    </div>
                )}
            </div>
            {Layout === TwoColumnLayoutEnum.paragraph && (
                <div className={classnames(`${twoColumnProfileListItemBlock}__accent`)}></div>
            )}
        </li>
    );
};

export const TwoColumnProfileListItemStorybook = forStorybookV2(TwoColumnProfileListItem);

export default TwoColumnProfileListItem;
