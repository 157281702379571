/* eslint-disable no-unused-vars */
import gql from 'graphql-tag';
import GraphQLClientFactory from '../lib/GraphQLClientFactory';
// eslint-disable-next-line import/no-unresolved
import config from '../temp/config';
import {
    rootItem,
    isSearchable,
    notBioPageOnlyCondition,
    rootSiteItem,
    templateNameCondition,
} from '../utils/constants';
import {
    CHANGE_ENTERED_SEARCH_TEXT,
    PERFORM_SEARCH,
    REQUEST_RESULTS,
    RECEIVE_RESULTS,
    ADD_FACET_SELECTION,
    REMOVE_FACET_SELECTION,
    CHANGE_QUERY_TEXT,
    CHANGE_PAGE_OFFSET,
    SET_FACET_STATE,
    REGISTER_FACET,
} from '../constants';

export const changeEnteredSearchText = (text) => ({
    type: CHANGE_ENTERED_SEARCH_TEXT,
    text: text,
});

export const updatePageOffset = (offset) => ({
    type: CHANGE_PAGE_OFFSET,
    offset: offset,
});

export const setFacetState = (payload) => ({
    type: SET_FACET_STATE,
    payload: payload,
});

export const changeQueryText = (text) => ({
    type: CHANGE_QUERY_TEXT,
    text: text,
});

export const addFacetSelection = (facetId, facetValueId) => ({
    type: ADD_FACET_SELECTION,
    facetId,
    facetValueId,
});

export const registerFacet = (facetId) => ({
    type: REGISTER_FACET,
    facetId,
});

export const removeFacetSelection = (facetId, facetValueId) => ({
    type: REMOVE_FACET_SELECTION,
    facetId,
    facetValueId,
});

// Format results from queries with information to be used by components
const receiveResults = (json) => {
    return {
        results: json.data.customSearch?.results?.items?.map((child) => child),
        facets: json.data.customSearch?.facets,
        totalCount: json.data.customSearch?.results?.totalCount,
        pageInfo: json.data.customSearch?.results?.pageInfo,
    };
};

const requestResults = (keyword) => {
    return {
        type: REQUEST_RESULTS,
        keyword,
    };
};

// GraphQL fragment for facetting on data
export const facetFragment = gql`
    fragment FacetFragment on CustomSearchResults {
        facets {
            name
            values {
                count
                value
                item {
                    identifier: field(name: "identifier") {
                        value
                    }
                    name
                }
            }
        }
    }
`;

/*  
    GraphQL fragment for fetching fields of query results
    
    - Fetch all teaser content to display on results
    - Fetch additional fields to be used on search results for Bio Pages
    - Fetch 'Not Navigatable' field to be used to alter design of results on components
*/
export const representativeResultsFragment = gql`
    fragment ResultsFragment on CustomSearchResults {
        results {
            totalCount
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            items {
                id
                name
                templateName
                score
                item {
                    displayName
                    url
                    teaserTitle: field(name: "teasertitle") {
                        value
                    }
                    teaserDescription: field(name: "teaserdescription") {
                        value
                    }
                    featuredTeaserTitle: field(name: "featuredteasertitle") {
                        value
                    }
                    featuredTeaserDescription: field(name: "featuredteaserdescription") {
                        value
                    }
                    narrative: field(name: "narrative") {
                        value
                    }
                    teaserDate: field(name: "teaserdate") {
                        value
                    }
                    teaserDisplayDate: field(name: "teaserdisplaydate") {
                        value
                    }
                    teaserImage: field(name: "teaserimage") {
                        ... on ImageField {
                            src
                            alt
                            width
                            height
                        }
                    }
                    notNavigatable: field(name: "notnavigatable") {
                        value
                    }
                    source: field(name: "Source") {
                        value
                    }
                }
            }
        }
    }
`;

/*  
    GraphQL fragment for fetching fields of query results
    
    - Fetch all teaser content to display on results
    - Fetch additional fields to be used on search results for Bio Pages
    - Fetch 'Not Navigatable' field to be used to alter design of results on components
*/
export const resultsFragment = gql`
    fragment ResultsFragment on CustomSearchResults {
        results {
            totalCount
            items {
                id
                name
                templateName
                score
                teaserDisplayDate: field(name: "teaserdisplaydate")
                item {
                    displayName
                    url
                    teaserTitle: field(name: "teasertitle") {
                        value
                    }
                    title: field(name: "title") {
                        value
                    }
                    teaserDescription: field(name: "teaserdescription") {
                        value
                    }
                    featuredTeaserTitle: field(name: "featuredteasertitle") {
                        value
                    }
                    featuredTeaserDescription: field(name: "featuredteaserdescription") {
                        value
                    }
                    narrative: field(name: "narrative") {
                        value
                    }
                    teaserDate: field(name: "teaserdate") {
                        value
                    }
                    hideCardTeaserDate: field(name: "hidecardteaserdate") {
                        value
                    }
                    teaserImage: field(name: "teaserimage") {
                        ... on ImageField {
                            src
                            alt
                            width
                            height
                        }
                    }
                    teaserTemplateTypeOverride: field(name: "teasertemplatetypeoverride") {
                        value
                    }
                    notNavigatable: field(name: "notnavigatable") {
                        value
                    }
                    firstName: field(name: "FirstName") {
                        value
                    }
                    middleName: field(name: "MiddleName") {
                        value
                    }
                    lastName: field(name: "LastName") {
                        value
                    }
                    businessEmail: field(name: "BusinessEmail") {
                        value
                    }
                    businessPhone: field(name: "BusinessPhone") {
                        value
                    }
                    externalHost: field(name: "externalhost") {
                        value
                    }
                    role: field(name: "Role") {
                        value
                    }
                    leadParagraph: field(name: "LeadParagraph") {
                        value
                    }
                    profileImage: field(name: "HighResolutionImage") {
                        ... on ImageField {
                            src
                            alt
                        }
                    }
                    suffix: field(name: "Suffix") {
                        value
                    }
                    prefix: field(name: "Prefix") {
                        value
                    }
                    source: field(name: "Source") {
                        value
                    }
                }
            }
        }
    }
`;

// GraphQL fragment for fetching person name and search tags
export const personInfoFragment = gql`
    fragment ResultsFragment on CustomSearchResults {
        results {
            items {
                item {
                    firstName: field(name: "FirstName") {
                        value
                    }
                    middleName: field(name: "MiddleName") {
                        value
                    }
                    lastName: field(name: "LastName") {
                        value
                    }
                    searchTags: field(name: "searchtags") {
                        ... on MultilistField {
                            targetItems {
                                identifier: field(name: "identifier") {
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

// GraphQL fragment for fetching id and name of items
export const resultsItemsFragment = gql`
    fragment ResultsFragment on CustomSearchResults {
        results {
            items {
                id
                name
                title: field(name: "title")
                identifier: field(name: "identifier")
                item {
                    children(includeTemplateIDs: "{E9058ADB-BD30-4699-868D-7221F106460A}") {
                        children {
                            ... on SubTag {
                                id
                                title {
                                    value
                                }
                                identifier {
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

// GraphQL mapFragment data
export const mapFragment = gql`
    fragment MapFragment on CustomSearchResults {
        facets {
            name
            values {
                value
            }
        }
        results {
            items {
                name
                id
                path
                markertitle: field(name: "markertitle")
                teasertitle: field(name: "teasertitle")
                notNavigatable: field(name: "notnavigatable")
                disableMapCTA: field(name: "disablemapcta")
                lat: field(name: "latitude")
                lng: field(name: "longitude")
                industries: field(name: "industries")
            }
        }
    }
`;

// GraphQL fragment for facetting on data
export const mapTagFragment = gql`
    fragment MapTagFragment on CustomSearchResults {
        results {
            items {
                id
                title: field(name: "title")
                svgpath: field(name: "svgpath")
            }
        }
    }
`;

// GraphQL fragment for facetting on data
export const mapPropertiesFacetFragment = gql`
    fragment MapPropertiesFacetFragment on CustomSearchResults {
        facets {
            name
            values {
                value
            }
        }
    }
`;
/*  
    GraphQL query to fetch search results

    * conditions -  fields equal relations to be applied to search query
    * facetOn - if array passed not empty, facets will be applied on passed fields and returned from the search query
    * first - the number of results to fetch
    * after - the page to fetch results
    * sortBy - the field value to sort results by
    * sortDesc - boolean to sort values in descending order
    * excludeIds - the item ids to exclude from search results
    * keyword - keyword to apply to search results
    * excludeBioOnlyItems - boolean to include the condition to exclude Bio Page Only flagged pages in search
 */
export const fetchResults = (
    conditions,
    facetOn,
    first,
    after,
    sortBy,
    sortDesc,
    excludeIds,
    keyword,
    excludeBioOnlyItems
) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);
    conditions.push(isSearchable);
    excludeBioOnlyItems && conditions.push(notBioPageOnlyCondition);
    const excludeIdsClause = excludeIds[0] !== '';
    const includeFacet = facetOn.length !== 0;
    let onlyFacetQuery = false;
    let conditionFragments = includeFacet
        ? `...FacetFragment
...ResultsFragment`
        : `...ResultsFragment`;
    if (first === 0) {
        onlyFacetQuery = true;
        conditionFragments = `...FacetFragment`;
    }

    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]
        $facetOn: [String!]
        $first: Int
        $after: String
        $sortBy: String
        $sortDesc: Boolean
        $rootItem: String
        $keyword: String`;

    let variablesString = `conditions: $conditions
    facetOn: $facetOn
    first: $first
    after: $after
    sortBy: $sortBy
    sortDesc: $sortDesc
    keyword: $keyword
    rootItem: $rootItem`;

    if (excludeIdsClause) {
        conditionsString += `
        $excludeIds: [String]`;
        variablesString += `
        excludeIds: $excludeIds`;
    }

    return client
        .query({
            variables: {
                conditions,
                facetOn,
                rootItem,
                first,
                after,
                sortBy,
                sortDesc,
                keyword,
                excludeIds,
            },
            query: gql`
                query CustomSearch(
                    ${conditionsString}
                ) {
                    customSearch(
                        ${variablesString}
                    ) {
                        ${conditionFragments}
                    }
                }
                ${includeFacet ? facetFragment : ``}
                ${!onlyFacetQuery ? resultsFragment : ``}
            `,
        })
        .then((json) => receiveResults(json));
};
/*  
    GraphQL query to fetch representative search results

    * conditions -  fields equal relations to be applied to search query
    * facetOn - if array passed not empty, facets will be applied on passed fields and returned from the search query
    * first - the number of results to fetch
    * after - the page to fetch results
    * sortBy - the field value to sort results by
    * sortDesc - boolean to sort values in descending order
    * excludeIds - the item ids to exclude from search results
    * keyword - keyword to apply to search results
    * excludeBioOnlyItems - boolean to include the condition to exclude Bio Page Only flagged pages in search
 */
export const fetchRepresentativeResults = (
    conditions,
    facetOn,
    first,
    after,
    sortBy,
    sortDesc,
    excludeIds,
    keyword,
    excludeBioOnlyItems
) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);
    conditions.push(isSearchable);
    excludeBioOnlyItems && conditions.push(notBioPageOnlyCondition);
    const excludeIdsClause = excludeIds[0] !== '';
    const includeFacet = facetOn.length !== 0;
    let onlyFacetQuery = false;
    let conditionFragments = includeFacet
        ? `...FacetFragment
    ...ResultsFragment`
        : `...ResultsFragment`;
    if (first === 0) {
        onlyFacetQuery = true;
        conditionFragments = `...FacetFragment`;
    }

    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]
            $facetOn: [String!]
            $first: Int
            $after: String
            $sortBy: String
            $sortDesc: Boolean
            $rootItem: String
            $keyword: String`;

    let variablesString = `conditions: $conditions
        facetOn: $facetOn
        first: $first
        after: $after
        sortBy: $sortBy
        sortDesc: $sortDesc
        keyword: $keyword
        rootItem: $rootItem`;

    if (excludeIdsClause) {
        conditionsString += `
            $excludeIds: [String]`;
        variablesString += `
            excludeIds: $excludeIds`;
    }

    return client
        .query({
            variables: {
                conditions,
                facetOn,
                rootItem,
                first,
                after,
                sortBy,
                sortDesc,
                keyword,
                excludeIds,
            },
            query: gql`
                    query CustomSearch(
                        ${conditionsString}
                    ) {
                        customSearch(
                            ${variablesString}
                        ) {
                            ${conditionFragments}
                        }
                    }
                    ${includeFacet ? facetFragment : ``}
                    ${!onlyFacetQuery ? representativeResultsFragment : ``}
                `,
        })
        .then((json) => receiveResults(json));
};

/*  
    GraphQL query to fetch tag items by name

    * conditions -  item names to query in search results
    * facetOn - if array passed not empty, facets will be applied on passed fields and returned from the search query
    * first - the number of results to fetch
    * after - the page to fetch results
    * sortBy - the field value to sort results by
    * sortDesc - boolean to sort values in descending order
    * excludeIds - the item ids to exclude from search results
    * keyword - keyword to apply to search results
 */
export const fetchTagIds = (
    conditions,
    facetOn,
    first,
    after,
    sortBy,
    sortDesc,
    excludeIds,
    keyword
) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);
    conditions.push({ name: templateNameCondition, value: 'Tag' }); //query global Tag data folder to get Tag ids
    const excludeIdsClause = false;
    const includeFacet = false;
    let rootItem = rootSiteItem;
    let conditionFragments = includeFacet
        ? `...FacetFragment
...ResultsFragment`
        : `...ResultsFragment`;

    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]
        $facetOn: [String!]
        $first: Int
        $after: String
        $sortBy: String
        $sortDesc: Boolean
        $rootItem: String
        $keyword: String`;

    let variablesString = `conditions: $conditions
    facetOn: $facetOn
    first: $first
    after: $after
    sortBy: $sortBy
    sortDesc: $sortDesc
    keyword: $keyword
    rootItem: $rootItem`;

    if (excludeIdsClause) {
        conditionsString += `
        $excludeIds: [String]`;
        variablesString += `
        excludeIds: $excludeIds`;
    }

    return client
        .query({
            variables: {
                conditions,
                facetOn,
                rootItem,
                first,
                after,
                sortBy,
                sortDesc,
                keyword,
                excludeIds,
            },
            query: gql`
                query CustomSearch(
                    ${conditionsString}
                ) {
                    customSearch(
                        ${variablesString}
                    ) {
                        ${conditionFragments}
                    }
                }
                ${resultsItemsFragment}
            `,
        })
        .then((json) => receiveResults(json));
};

/*  
    GraphQL query to fetch person info by id

    * conditions -  item names to query in search results
    * facetOn - if array passed not empty, facets will be applied on passed fields and returned from the search query
    * first - the number of results to fetch
    * after - the page to fetch results
    * sortBy - the field value to sort results by
    * sortDesc - boolean to sort values in descending order
    * excludeIds - the item ids to exclude from search results
    * keyword - keyword to apply to search results
 */
export const fetchPersonInfo = (
    conditions,
    facetOn,
    first,
    after,
    sortBy,
    sortDesc,
    excludeIds,
    keyword
) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);

    let conditionFragments = `...ResultsFragment`;

    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]
            $facetOn: [String!]
            $first: Int
            $after: String
            $sortBy: String
            $sortDesc: Boolean
            $rootItem: String
            $keyword: String`;

    let variablesString = `conditions: $conditions
        facetOn: $facetOn
        first: $first
        after: $after
        sortBy: $sortBy
        sortDesc: $sortDesc
        keyword: $keyword
        rootItem: $rootItem`;

    return client
        .query({
            variables: {
                conditions,
                facetOn,
                rootItem,
                first,
                after,
                sortBy,
                sortDesc,
                keyword,
                excludeIds,
            },
            query: gql`
                    query CustomSearch(
                        ${conditionsString}
                    ) {
                        customSearch(
                            ${variablesString}
                        ) {
                            ${conditionFragments}
                        }
                    }
                    ${personInfoFragment}
                `,
        })
        .then((json) => receiveResults(json));
};

export const fetchPeopleFilters = (conditions, facetOn) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);
    let keyword = '';
    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]
        $facetOn: [String!]
        $rootItem: String
        $keyword: String`;

    let variablesString = `conditions: $conditions
    facetOn: $facetOn
    keyword: $keyword
    rootItem: $rootItem`;

    return client
        .query({
            variables: {
                conditions,
                facetOn,
                rootItem,
                keyword,
            },
            query: gql`
                query CustomSearch(
                    ${conditionsString}
                ) {
                    customSearch(
                        ${variablesString}
                    ) {
                        ...FacetFragment
                    }
                }
                ${facetFragment}
            `,
        })
        .then((json) => receiveResults(json));
};

export const fetchResultsTypeahead = (
    conditions,
    facetOn,
    first,
    after,
    sortBy,
    sortDesc,
    excludeIds,
    keyword,
    typeAhead,
    excludeBioOnlyItems
) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);
    conditions.push(isSearchable);
    excludeBioOnlyItems && conditions.push(notBioPageOnlyCondition);
    const excludeIdsClause = excludeIds[0] !== '';
    const includeFacet = facetOn.length !== 0;
    let onlyFacetQuery = false;
    let conditionFragments = includeFacet
        ? `...FacetFragment
    ...ResultsFragment`
        : `...ResultsFragment`;
    if (first === 0) {
        onlyFacetQuery = true;
        conditionFragments = `...FacetFragment`;
    }

    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]
            $facetOn: [String!]
            $first: Int
            $after: String
            $sortBy: String
            $sortDesc: Boolean
            $rootItem: String
            $keyword: String
            $typeAhead: String`;

    let variablesString = `conditions: $conditions
        facetOn: $facetOn
        first: $first
        after: $after
        sortBy: $sortBy
        sortDesc: $sortDesc
        keyword: $keyword
        rootItem: $rootItem
        typeAhead: $typeAhead`;

    if (excludeIdsClause) {
        conditionsString += `
            $excludeIds: [String]`;
        variablesString += `
            excludeIds: $excludeIds`;
    }

    return client
        .query({
            variables: {
                conditions,
                facetOn,
                rootItem,
                first,
                after,
                sortBy,
                sortDesc,
                keyword,
                excludeIds,
                typeAhead,
            },
            query: gql`
                    query CustomSearch(
                        ${conditionsString}
                    ) {
                        customSearch(
                            ${variablesString}
                        ) {
                            ${conditionFragments}
                        }
                    }
                    ${includeFacet ? facetFragment : ``}
                    ${!onlyFacetQuery ? resultsFragment : ``}
                `,
        })
        .then((json) => receiveResults(json));
};

export const fetchMapPoints = (conditions, withinRadius, facetOn, first, keyword) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);
    let sortBy = keyword ? 'score' : 'teaserdate';
    let sortDesc = true;
    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]
        $facetOn: [String!]
        $sortBy: String
        $sortDesc: Boolean
        $keyword: String
        $rootItem: String
        `;

    let variablesString = `conditions: $conditions
    facetOn: $facetOn
    sortBy: $sortBy
    sortDesc: $sortDesc
    keyword: $keyword
    rootItem: $rootItem`;

    if (first) {
        conditionsString += `
            $first: Int`;
        variablesString += `
            first: $first`;
    }

    if (withinRadius) {
        conditionsString += `
        $withinRadius: WithinRadiusFieldQuery`;
        variablesString += `
        withinRadius: $withinRadius`;
    }

    return client
        .query({
            variables: {
                conditions,
                sortBy,
                sortDesc,
                keyword,
                withinRadius,
                rootItem,
                first,
                facetOn,
            },
            query: gql`
                query CustomSearch(
                    ${conditionsString}
                ) {
                    customSearch(
                        ${variablesString}
                    ) {
                        ...MapFragment
                    }
                }
                ${mapFragment}
            `,
        })
        .then((json) => receiveResults(json));
};

export const fetchMapTags = (conditions) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);
    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]`;
    let variablesString = `conditions: $conditions`;

    return client
        .query({
            variables: {
                conditions,
            },
            query: gql`
                query CustomSearch(
                    ${conditionsString}
                ) {
                    customSearch(
                        ${variablesString}
                    ) {
                        ...MapTagFragment
                    }
                }
                ${mapTagFragment}
            `,
        })
        .then((json) => receiveResults(json));
};

export const fetchMapPropertiesFragment = (conditions, facetOn) => {
    const client = GraphQLClientFactory(config.graphQLEndpoint, false, null);
    let conditionsString = `$conditions: [CustomItemSearchFieldQuery]
    $facetOn: [String!]`;
    let variablesString = `conditions: $conditions
    facetOn: $facetOn`;

    return client
        .query({
            variables: {
                conditions,
                facetOn,
            },
            query: gql`
                query CustomSearch(
                    ${conditionsString}
                ) {
                    customSearch(
                        ${variablesString}
                    ) {
                        ...MapPropertiesFacetFragment
                    }
                }
                ${mapPropertiesFacetFragment}
            `,
        })
        .then((json) => receiveResults(json));
};
