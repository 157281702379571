export default {
    SearchPrompt: 'Search',
    AddDateFilters: true,
    DefaultFilters: [],
    Filters: [
        {
            label: 'Industries',
            dropdown: true,
            primary: true,
            options: [
                {
                    label: 'Corporate restructuring and advisory',
                    href: 'google.com',
                    defaultChecked: true,
                },
                {
                    label: 'Corporate restructur',
                    href: 'google.com',
                },
                {
                    label: 'Emerging companies and VC',
                    href: 'google.com',
                    defaultChecked: true,
                },
                {
                    label: 'Environmental',
                    href: 'google.com',
                },
                {
                    label: 'Managed assets',
                    href: 'google.com',
                },
                {
                    label: 'M&A',
                    href: 'google.com',
                    defaultChecked: true,
                },
                {
                    label: 'Pensions and employment',
                    href: 'google.com',
                },
                {
                    label: 'Private equity',
                    href: 'google.com',
                },
                {
                    label: 'Real estate',
                    href: 'google.com',
                    defaultChecked: true,
                },
                {
                    label: 'Pensions and employment',
                    href: 'google.com',
                },
                {
                    label: 'Private equity',
                    href: 'google.com',
                },
                {
                    label: 'Real estate',
                    href: 'google.com',
                    defaultChecked: true,
                },
            ],
        },
        {
            label: 'Regions',
            dropdown: true,
            primary: true,
            options: [
                { label: 'Corporate restructuring and advisory', href: 'google.com' },
                { label: 'Emerging companies and VC', href: 'google.com' },
                { label: 'Environmental', href: 'google.com' },
                { label: 'Managed assets', href: 'google.com' },
                { label: 'M&A', href: 'google.com' },
                { label: 'Pensions and employment', href: 'google.com' },
                { label: 'Private equity', href: 'google.com' },
                { label: 'Real estate', href: 'google.com', defaultChecked: true },
            ],
        },
        {
            label: 'Services',
            dropdown: true,
            primary: true,
            options: [
                { label: 'Corporate restructuring and advisory', href: 'google.com' },
                { label: 'Emerging companies and VC', href: 'google.com' },
                { label: 'Environmental', href: 'google.com' },
                { label: 'Managed assets', href: 'google.com' },
                { label: 'M&A', href: 'google.com' },
                { label: 'Pensions and employment', href: 'google.com' },
                { label: 'Private equity', href: 'google.com' },
                { label: 'Real estate', href: 'google.com', defaultChecked: true },
                { label: 'Corporate restructuring and advisory', href: 'google.com' },
                { label: 'Emerging companies and VC', href: 'google.com' },
                { label: 'Environmental', href: 'google.com' },
                { label: 'Managed assets', href: 'google.com' },
                { label: 'M&A', href: 'google.com' },
                { label: 'Pensions and employment', href: 'google.com' },
                { label: 'Private equity', href: 'google.com' },
                { label: 'Real estate', href: 'google.com', defaultChecked: true },
                { label: 'Corporate restructuring and advisory', href: 'google.com' },
                { label: 'Emerging companies and VC', href: 'google.com' },
                { label: 'Environmental', href: 'google.com' },
                { label: 'Managed assets', href: 'google.com' },
                { label: 'M&A', href: 'google.com' },
                { label: 'Pensions and employment', href: 'google.com' },
                { label: 'Private equity', href: 'google.com' },
                { label: 'Real estate', href: 'google.com', defaultChecked: true },
            ],
        },
    ],
};
