import React from 'react';
import classnames from 'classnames';
import './side-navigation-item.scss';
import { SideNavigationTypeEnum } from '../../utils/enums';
import Text from '../Text/Text.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export const SideNavigationItem = ({
    changeTitle,
    changeDropdown,
    fields,
    NavigationType,
    pageTitle,
    sectionIds,
    sitecoreContext,
}) => {
    const sideNavigationItemBlock = 'zn-side-navigation-item';
    const { Label, SectionID, LinkURL } = fields;

    const toggle = () => {
        changeTitle(Label?.value);
        changeDropdown();
    };

    const target =
        NavigationType?.value === SideNavigationTypeEnum.betweenPage ? { target: '_blank' } : {};

    return (
        <li
            id={`nav-${SectionID?.value}`}
            className={classnames(`${sideNavigationItemBlock}`, {
                [`${sideNavigationItemBlock}--current-page`]:
                    pageTitle === Label?.value &&
                    NavigationType?.value === SideNavigationTypeEnum.betweenPage,
            })}
            style={
                (sectionIds || [])?.find(
                    (x) => x.id === SectionID?.value && x.display !== 'none'
                ) || sitecoreContext?.pageEditing
                    ? { display: 'block' }
                    : { display: 'none' }
            }
        >
            <a
                className={classnames(`${sideNavigationItemBlock}__link`)}
                href={
                    NavigationType?.value === SideNavigationTypeEnum.inPage
                        ? `#${SectionID?.value}`
                        : LinkURL?.value?.href
                }
                onClick={toggle}
                {...target}
            >
                <Text field={Label}></Text>
            </a>
        </li>
    );
};

export default withSitecoreContext()(SideNavigationItem);
