import React from 'react';
import { LinkTargetValueEnum } from '../../utils/enums';
import { withTranslation } from 'react-i18next';
import './FeaturedWorksPdfList.scss';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const FeaturedWorksPdfList = ({ featuredResults, featuredItemsCount, t }) => {
    const classBase = 'zn-featured-list';
    return (
        <div>
            {(featuredResults || []).map((result, index) => {
                if (result && index < featuredItemsCount) {
                    const {
                        featuredTeaserTitle,
                        teaserTitle,
                        displayName,
                        url,
                        featuredTeaserDescription,
                        teaserDescription,
                        notNavigatable,
                    } = result.item ?? {};
                    const Title = featuredTeaserTitle ?? teaserTitle ?? displayName;

                    const ParagraphText = featuredTeaserDescription ??
                        teaserDescription ?? { value: '' };

                    const LinkURL =
                        notNavigatable?.value === '1'
                            ? { value: null }
                            : {
                                  value: {
                                      href: url,
                                      text: t('ReadMore'),
                                      title: result?.name,
                                      target: LinkTargetValueEnum.self,
                                  },
                              };
                    return (
                        <div className={`${classBase}__item`}>
                            <div className={`${classBase}__item__title`}>
                                <RichText field={Title} tag="h4" />
                            </div>
                            <div className={`${classBase}__item__description`}>
                                <RichText field={ParagraphText} />
                            </div>

                            {LinkURL?.value && (
                                <a
                                    className="readmore-button"
                                    alt={LinkURL?.value?.title}
                                    href={LinkURL?.value?.href}
                                    target={LinkURL?.value?.target}
                                    rel="noppener noreferrer"
                                >
                                    {LinkURL.value.text}
                                </a>
                            )}
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default withTranslation()(FeaturedWorksPdfList);
