import React, { useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import { SearchBarCalendarEnum } from '../../utils/enums.js';
import { CloseIcon, SearchBarIcon } from '../../assets/common.js';
import { ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';
import { Button } from '../Button/Button.js';
import { withTranslation } from 'react-i18next';
import SearchBarFilter from '../SearchBarFilter/SearchBarFilter.jsx';
import EventAutofillSearchResult from './EventAutofillSearchResult/EventAutofillSearchResult.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import 'url-search-params-polyfill';
import './event-search-bar.scss';
import '../SearchBarFilter/search-bar-filter.scss';
import { scoreSortBy } from '../../utils/constants';
import { fetchTagIds, fetchResults } from '../../actions';
import { sortFilterValues, decodeURIComponentSafe } from '../../utils/searchHelpers.jsx';
import DatePicker from '../../ui/RangeDataPicker/RangeDataPicker.jsx';
import {
    formatFilter,
    flattenFilterOptions,
    setTotalSelected,
    updateClearOptions,
    updatePeopleOptions,
    updateHasResults,
    formatFilterTitle,
    hideMobileFilters,
    getSearchConditions,
    tagIdConditions,
    showMobileFilters,
} from './helpers.js';

const searchBarBlock = 'zn-event-search-bar';

const staticFilterIds = ['EventFormat', 'Organizer', 'City'];

export const templateTypeConditions = [
    {
        group: [
            { name: '_templatename', value: 'Event', useor: true },
            { name: '_templatename', value: 'Webinar', useor: true },
            {
                group: [
                    { name: '_templatename', value: 'Publication Page', useor: true },
                    { name: 'IsEvent', value: 'true', useor: false },
                ],
                useor: true,
            },
        ],
    },
];

const EventSearchBar = ({ t, fields, sitecoreContext }) => {
    // Authored Fields
    const { data } = fields;
    const Filters = data?.datasource?.filters?.targetItems || [];
    const DefaultFilters =
        data?.datasource?.defaultFilters?.targetItems?.map((option) => option.identifier.value) ||
        [];
    const SearchPrompt = data?.datasource?.searchPrompt || '';
    const flattenedFilters = Filters.map((filter) =>
        formatFilter(flattenFilterOptions(filter), DefaultFilters)
    );

    // Query Parameters
    const queryParams = new URLSearchParams(useLocation()?.search);
    const SearchParam = queryParams.get('q');
    const itemID = queryParams.get('sc_itemid'); //Used to allow for editing/preview mode toggle of a Search Results with query params updating
    const language = queryParams.get('sc_lang'); //Include language in query params
    const queryParamsString = useLocation()?.search;
    const pathname = useLocation()?.pathname;

    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [searchParams, setSearchParams] = useState(
        SearchParam ? decodeURIComponentSafe(SearchParam) : ''
    );
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const [pageLoadingState, setPageLoadingState] = useState(0);
    const [firstFacetLoad, setFirstFacetLoad] = useState(true);
    const [disabledState, setDisabledState] = useState(false);
    const [allFilters, setAllFilters] = useState([]);

    const stateItemID = itemID ? itemID : sitecoreContext?.route?.itemId;

    // UI state elements
    const [contextFilterParams, setContextFilterParams] = useState({});
    const [setActiveFilterGroup, setActiveFilterGroupState] = useState(null);
    const [setMobileActive, setMobileActiveState] = useState(false);
    const [setMobilePublishMenuActive, setMobilePublishMenuActiveState] = useState(false);

    const autoloadSuggestionsRef = useRef(null);
    const prevActiveGroup = useRef();
    const filterList = useRef(null);
    const [pdfView, setPdfView] = useState(false);

    //Autofill results
    const [autofillResults, _setAutofillResults] = useState([]);
    const autofillResultsRef = useRef(autofillResults);
    const searchInputRef = useRef(null);

    //Parse incoming query parameters from url to populate filters on the search page
    let paramObj = decodeURI(useLocation().search)
        .replace('?', '')
        .split('&')
        .map((param) => {
            if (!param?.split('=')) return;
            else return param.split('=');
        })
        .reduce((values, [key, value]) => {
            values[key] = value?.split(',').map((item) => decodeURIComponentSafe(item));
            return values;
        }, {});

    const fromDate = dateRange.from
        ? `${
              dateRange.from.getMonth() + 1
          }/${dateRange.from.getDate()}/${dateRange.from.getFullYear()}`
        : '';
    const toDate = dateRange.to
        ? `${dateRange.to.getMonth() + 1}/${dateRange.to.getDate()}/${dateRange.to.getFullYear()}`
        : '';

    // Format chosen filters for UI filter lists
    const standardFilters =
        flattenedFilters?.map((filter) => ({
            label: filter.label,
            parentId: filter.label,
            totalSelected: setTotalSelected(filter, paramObj),
            peopleOnly: false,
            facetValue: staticFilterIds.includes(filter.identifier)
                ? 'static'
                : filter.identifier === 'People'
                ? 'relatedpeople'
                : 'tags',
            identifier: filter.identifier,
            options: filter.options
                ? filter.options
                      .map((option, index) => ({
                          id: index,
                          label: option.label,
                          identifier: option.identifier,
                          hasResults: true,
                          checked:
                              option.defaultChecked ||
                              paramObj['tags']?.includes(option.identifier) ||
                              paramObj['static']?.includes(option.identifier) ||
                              paramObj['people']?.includes(option.value) ||
                              paramObj['eventformat']?.includes(option.identifier) ||
                              paramObj[filter.label.toLowerCase()]?.includes(option.identifier)
                                  ? true
                                  : false,
                      }))
                      .sort(filter.identifier !== 'Position-Category' ? sortFilterValues : () => {})
                : [],
        })) || [];

    useEffect(() => {
        if (paramObj['daterange']?.length) {
            const [fromDateStr, toDateStr] = paramObj['daterange'][0].split('|');
            setDateRange({
                from: fromDateStr ? new Date(fromDateStr) : null,
                to: toDateStr ? new Date(toDateStr) : null,
            });
        }
    }, []);

    const onApplyFilters = () => {
        setActiveFilterGroupState(null);
        setMobilePublishMenuActiveState(false);
        setMobileActiveState(false);
        setAutofillResults([]);
    };

    const clearSingularFilter = useCallback((pId) => {
        setContextFilterParams({
            ...contextFilterParams,
            [formatFilterTitle(pId)]: null,
        });
        setAllFilters((current) =>
            current.map((item) => {
                if (item.parentId === pId) {
                    let updatedOptions = updateClearOptions({ oldOptions: item.options });

                    return { ...item, totalSelected: 0, options: updatedOptions };
                } else return item;
            })
        );
        setIsFilterApplied(true);
        setDisabledState(true);
    }, []);

    const clearAllFilters = () => {
        if (!disabledState) {
            setAllFilters((current) =>
                current.map((item) => {
                    let updatedOptions = updateClearOptions({ oldOptions: item.options });
                    return { ...item, totalSelected: 0, options: updatedOptions };
                })
            );

            setActiveFilterGroupState(null);
            setDateRange({ from: null, to: null });
            setIsFilterApplied(true);
            setDisabledState(true);
        }
    };

    const updateFacets = (facettedResults) => {
        setAllFilters((current) =>
            current.map((item) => {
                let mappedFacetKey = item?.facetValue;
                let options;
                if (mappedFacetKey === 'relatedpeople') {
                    options = updatePeopleOptions(item, facettedResults, paramObj);
                } else {
                    options = item.options;
                }
                let updatedOptions = updateHasResults(options, facettedResults, mappedFacetKey);

                return {
                    ...item,
                    totalSelected:
                        !firstFacetLoad || mappedFacetKey === 'relatedpeople'
                            ? changeTotalSelectedForFacets(updatedOptions)
                            : item.totalSelected,
                    options: updatedOptions,
                };
            })
        );

        if (!firstFacetLoad) {
            let params = '';
            if (searchParams) params += searchParams ? `q=${encodeURIComponent(searchParams)}` : '';

            let filteredValues = Object.assign(
                ...allFilters.map((filter) => ({
                    [formatFilterTitle(filter.identifier)]: filterParams(
                        formatFilterTitle(filter.identifier)
                    ).flat(),
                }))
            );

            if (dateRange.from || dateRange.to) {
                filteredValues['daterange'] = [encodeURIComponent(`${fromDate}|${toDate}`)];
            }

            for (const [key, value] of Object.entries(filteredValues)) {
                if (!value?.length) continue;

                let mappedFacetKey = mapFilterFacetValue(key);

                let filtersWithResults = value.filter((item) => {
                    if (['eventformat', 'organizer', 'city', 'daterange'].includes(key)) {
                        return true;
                    } else if (key === 'people') {
                        return facettedResults[mappedFacetKey]?.some(
                            (el) => el.value == decodeURIComponent(item)
                        );
                    } else {
                        return facettedResults[mappedFacetKey]?.includes(decodeURIComponent(item));
                    }
                });

                if (filtersWithResults.length) {
                    params += `&${key}=${filtersWithResults}`;
                }
            }

            if (sitecoreContext.pageState == 'edit' || sitecoreContext.pageState === 'preview') {
                params += `&sc_itemid=${stateItemID}`;
            }
            if (language) params += `&sc_lang=${language}`;
            //update the query parameters of the url according to selected filters and selected keywords

            window.history.replaceState(
                {
                    state: `${queryParamsString.replace(
                        queryParamsString,
                        `?${params.toString()}`
                    )}`,
                },
                '',
                `${pathname}${queryParamsString.replace(
                    queryParamsString,
                    `?${params.toString()}`
                )}`
            );
        }

        setFirstFacetLoad(false);
        setDisabledState(false);
    };

    const updateCheckedOption = (args) => {
        const { oldOptions, newValue, currentId } = args;

        return (
            oldOptions?.map((option) =>
                option.id === currentId ? { ...option, checked: newValue } : option
            ) || []
        );
    };

    const changeTotalSelected = (item, updatedId) => {
        let newCount = item.totalSelected;
        if (item.totalSelected === 0 && !updatedId) {
            return 0;
        } else {
            if (updatedId) {
                newCount++;
            } else {
                newCount--;
            }
            return newCount;
        }
    };

    const changeTotalSelectedForFacets = (updatedOptions) => {
        return updatedOptions.filter((item) => item.checked && item.hasResults).length;
    };

    const checkSingularBox = useCallback((id, pId) => {
        setAllFilters((current) =>
            current.map((item) => {
                if (item.parentId === pId) {
                    let updatedOptions = [];
                    let updatedId;
                    item.options.map((option) => {
                        if (option.id === id) {
                            updatedId = !option.checked;
                            updatedOptions = updateCheckedOption({
                                oldOptions: item.options,
                                newValue: updatedId,
                                currentId: id,
                            });
                        }
                    });
                    return {
                        ...item,
                        totalSelected: changeTotalSelected(item, updatedId, item.hasResults),
                        options: updatedOptions,
                    };
                } else return item;
            })
        );
        setIsFilterApplied(true);
    }, []);

    const toggleActiveFilterGroup = (index) => {
        if (!disabledState) {
            if (prevActiveGroup.current === index) {
                setActiveFilterGroupState(null);
            } else {
                setActiveFilterGroupState(index);
            }
        }
    };

    const setAutofillResults = (results) => {
        autofillResultsRef.current = results;
        _setAutofillResults(results);
    };

    const searchConditionsHandler = (searchConditions, mappedObject) => {
        let titleCondition = [
            {
                group: [
                    { name: '_name', value: `${searchParams}`, useor: true },
                    { name: '_displayname', value: `${searchParams}`, useor: true },
                ],
            },
        ];

        let queryConditions = [];

        if (!mappedObject['eventformat']?.length) {
            queryConditions = searchConditions.concat(templateTypeConditions, titleCondition);
        } else {
            queryConditions = searchConditions.concat(titleCondition);
        }

        let facets = []; //no facetting
        let page = '0'; // update the page value or reset if new search performed
        let orderBy = 'score'; //order by the selected option
        let sortDescending = true; //sort results in descending order
        let excludeIds = ['']; //no exluding ids
        let keyword = ''; //not matching on any keywords
        let includeBioPageOnlyExclusion = true; //include condition to exclude items that are listed as Bio Page Only

        fetchResults(
            queryConditions,
            facets,
            4, //get max 4 items
            page,
            orderBy,
            sortDescending,
            excludeIds,
            keyword,
            includeBioPageOnlyExclusion
        )
            .then((x) => {
                setAutofillResults(x.results);
            })
            .catch(() => {
                setAutofillResults([]);
            });
        setFirstFacetLoad(false);
    };

    const facetSearchHandler = (searchConditions, mappedObject, facetValues) => {
        let queryConditions = [];

        if (!mappedObject['eventformat']?.length) {
            queryConditions = searchConditions.concat(templateTypeConditions);
        } else {
            queryConditions = searchConditions;
        }

        let facets = facetValues; //no facetting
        let page = '0'; // update the page value or reset if new search performed
        let orderBy = 'score'; //order by the selected option
        let sortDescending = true; //sort results in descending order
        let excludeIds = ['']; //no exluding ids
        let keyword = ''; //not matching on any keywords
        let includeBioPageOnlyExclusion = true; //include condition to exclude items that are listed as Bio Page Only

        if (!queryConditions.length) return;

        fetchResults(
            queryConditions,
            facets,
            0, //no results needed, only facet values
            page,
            orderBy,
            sortDescending,
            excludeIds,
            keyword,
            includeBioPageOnlyExclusion
        )
            .then((x) => {
                let mappedFacettedResults = Object.assign(
                    ...(x.facets.length
                        ? x.facets.map((facet) => ({
                              [facet?.name]: facet?.values?.map((option) => {
                                  if (facet?.name === 'tags')
                                      return option?.item?.identifier?.value;
                                  else if (facet?.name === 'relatedpeople')
                                      return { name: option?.item?.name, value: option?.value };
                                  else return option?.value;
                              }),
                          }))
                        : [{}])
                );
                updateFacets(mappedFacettedResults);
            })
            .catch(() => {
                //setFacettedFilters({});
            });
    };

    const handleSearchParams = (e) => {
        setSearchParams(e.target.value);
        if (autoloadSuggestionsRef.current) autoloadSuggestionsRef.current.style.display = 'block';
    };

    // Function to map filter dropdown to associated facet value
    const mapFilterFacetValue = (label) => {
        if (label === 'eventformat') {
            return '_templatename';
        } else if (label === 'people') {
            return 'relatedpeople';
        }

        return 'tags';
    };

    const filterParams = (identifier) => {
        const filtered = allFilters.reduce(
            (array, filter) => (
                formatFilterTitle(filter.identifier) === identifier &&
                    array.push(
                        filter.options
                            .filter(function (option) {
                                return option.checked;
                            })
                            .map(function (option) {
                                return encodeURIComponent(option.identifier);
                            })
                    ),
                array
            ),
            []
        );
        return filtered.flat();
    };

    const submitForm = (event) => {
        event?.preventDefault();

        onApplyFilters();
        if (setMobileActive) hideMobileFilters();
        if (allFilters.length > 0) {
            setContextFilterParams(
                Object.assign(
                    ...allFilters.map((filter) => ({
                        [formatFilterTitle(filter.identifier)]: filterParams(
                            formatFilterTitle(filter.identifier)
                        ).flat(),
                    }))
                )
            );
        }
        setIsFilterApplied(true);
    };

    useEffect(() => {
        prevActiveGroup.current = setActiveFilterGroup;
    });

    function searchKeyBoard(event) {
        if (event.keyCode == 13) {
            event.target.blur();
        }
    }

    const setScrollY = () => {
        document.documentElement.style.setProperty('--scroll-y', `${window?.scrollY}px`);
    };

    useEffect(() => {
        function unsetActiveFilterGroup(event) {
            if (filterList.current && !filterList.current.contains(event.target)) {
                toggleActiveFilterGroup(null);
            }
        }
        document.addEventListener('mousedown', unsetActiveFilterGroup);
        return () => {
            document.removeEventListener('mousedown', unsetActiveFilterGroup);
            const body = document.body;
            body.style.position = '';
            body.style.top = '';
        };
    }, []);

    useEffect(() => {
        setAllFilters(standardFilters);

        document.addEventListener('click', (e) => {
            const suggestedResults = document.getElementsByClassName(
                'zn-event-search-bar__suggested-results'
            )[0];
            const searchInputBar = document.getElementsByClassName(
                'zn-event-search-bar__form-input'
            )[0];
            const searchBarFilters = Array.from(
                document.getElementsByClassName('zn-search-bar-filter')
            );
            const datePickerFilter = document.getElementsByClassName(
                'zn-search-bar-filter-range-datepicker-wrapper'
            )?.[0];

            let targetElement = e.target;
            do {
                if (targetElement == suggestedResults || targetElement == searchInputBar) {
                    if (autofillResultsRef?.current.length > 0) setActiveFilterGroupState(null);
                    return;
                }
                if (
                    searchBarFilters.find((e) => e === targetElement) &&
                    !datePickerFilter?.contains(targetElement)
                ) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            if (autoloadSuggestionsRef.current)
                autoloadSuggestionsRef.current.style.display = 'none';
            setActiveFilterGroupState(null);
        });
    }, []);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    useEffect(() => {
        if (pageLoadingState === 0) {
            setPageLoadingState(1); //on initial load all Filters have not been set yet
        } else {
            if (allFilters.length > 0) {
                //on all subsequent loads we want the context filter params (that go into the url updated based on allFilters)
                if (pageLoadingState === 1) {
                    submitForm();
                    setPageLoadingState(2);
                }
            }
        }
    }, [allFilters]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchParams !== '') {
                let mappedValues = allFilters.map((item) => ({
                    [formatFilterTitle(item.identifier)]: item.options
                        .filter((item) => item.checked)
                        .map((item) => item.identifier),
                }));
                let mappedObject = Object.assign({}, ...mappedValues);
                let tagIdConditionsArray = tagIdConditions(mappedObject); //Returns array with the tag names formatted correctly for graphql search query to retrieve associated tag ids

                if (dateRange.from || dateRange.to || paramObj['daterange']?.length) {
                    mappedObject['daterange'] =
                        !dateRange.from && !dateRange.to
                            ? paramObj['daterange']
                            : [`${fromDate}|${toDate}`];
                }

                if (['city', 'services', 'industries'].every((key) => !mappedObject[key]?.length)) {
                    searchConditionsHandler(
                        getSearchConditions({ results: [] }, mappedObject), //Returns array with the selected tags formatted correctly for graphql search query
                        mappedObject
                    );
                    return;
                }

                fetchTagIds(
                    tagIdConditionsArray?.flat(),
                    [], //no faceting
                    500, //The total amount of items to retrieve TODO UPDATE THIS IN THE OTHER SECTIONS
                    '0', //retreive the first page
                    scoreSortBy, //sort by score
                    true, //sort descending order
                    [''], //no ids to exclude
                    '' //no keyword
                )
                    .then((x) => {
                        searchConditionsHandler(
                            getSearchConditions(x, mappedObject), //Returns array with the selected tags formatted correctly for graphql search query
                            mappedObject
                        );
                    })
                    .catch(() => {
                        setAutofillResults([]);
                    });
            } else {
                setAutofillResults([]);
            }
        }, 400);
        return () => clearTimeout(timer);
    }, [searchParams]);

    useEffect(() => {
        searchInputRef?.current?.addEventListener('keypress', searchKeyBoard);
        window.addEventListener('scroll', setScrollY);
        return function cleanup() {
            searchInputRef?.current?.removeEventListener('keypress', searchKeyBoard);
            window.removeEventListener('scroll', setScrollY);
        };
    }, [fields?.data?.contextItem?.id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pageLoadingState !== 0 && isFilterApplied) {
                // on every search update perform facet search to update displayed filters
                let mappedValues = allFilters.map((item) => ({
                    [formatFilterTitle(item.identifier)]: item.options
                        .filter((item) => item.checked)
                        .map((item) => item.identifier),
                }));
                let mappedObject = Object.assign({}, ...mappedValues);

                if (
                    allFilters.find((el) => el.facetValue === 'relatedpeople')?.options?.length ===
                    0
                ) {
                    mappedObject['people'] = paramObj['people'] || [];
                }

                if (dateRange.from || dateRange.to || paramObj['daterange']?.length) {
                    mappedObject['daterange'] =
                        !dateRange.from && !dateRange.to
                            ? paramObj['daterange']
                            : [`${fromDate}|${toDate}`];
                }

                let facetValues = ['tags', '_templatename', 'relatedpeople'];

                if (
                    ['city', 'services', 'industries'].every(
                        (key) => mappedObject[key] && mappedObject[key].length === 0
                    )
                ) {
                    facetSearchHandler(
                        getSearchConditions({ results: [] }, mappedObject),
                        mappedObject,
                        facetValues
                    );
                    setIsFilterApplied(false);
                    return;
                }

                let tagIdConditionsArray = tagIdConditions(mappedObject); //Returns array with the tag names formatted correctly for graphql search query to retrieve associated tag ids

                fetchTagIds(
                    tagIdConditionsArray?.flat(),
                    [], //facet on tags, template names, people values
                    500, //No search results needed, only facets
                    '0', //retreive the first page
                    scoreSortBy, //sort by score
                    true, //sort descending order
                    [''], //no ids to exclude
                    '' //no keyword
                )
                    .then((x) => {
                        facetSearchHandler(
                            getSearchConditions(x, mappedObject), //Returns array with the selected tags formatted correctly for graphql search query
                            mappedObject,
                            facetValues
                        );
                    })
                    .catch(() => {});
                setIsFilterApplied(false);
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [isFilterApplied]); //update every time a filter is applied/search keyword is set and run

    return (
        !pdfView && (
            <div className={classnames(`${searchBarBlock}`)}>
                <div className={classnames(`${searchBarBlock}__input`)}>
                    <form className={classnames(`${searchBarBlock}__form`)} onSubmit={submitForm}>
                        <input
                            ref={searchInputRef}
                            className={classnames(`${searchBarBlock}__form-input`)}
                            type="text"
                            placeholder={SearchPrompt?.value}
                            value={searchParams}
                            name="q"
                            onChange={handleSearchParams}
                            autoComplete="off"
                            onFocus={() => {
                                if (autoloadSuggestionsRef.current)
                                    autoloadSuggestionsRef.current.style.display = 'block';
                            }}
                        ></input>
                        <Button
                            className={classnames(`${searchBarBlock}__search-button`)}
                            aria-label={t('SubmitSearch')}
                            type="submit"
                            variant="icon"
                        >
                            <SearchBarIcon />
                        </Button>
                    </form>
                    <div
                        className={`${searchBarBlock}__suggested-results-container`}
                        ref={autoloadSuggestionsRef}
                    >
                        {autofillResults?.length > 0 && (
                            <ul className={`${searchBarBlock}__suggested-results`}>
                                {autofillResults.map((result, index) => {
                                    if (result?.item) {
                                        result.fields = {
                                            Title:
                                                result?.templateName === 'Publication Page'
                                                    ? { value: result.item.title?.value }
                                                    : { value: result.item.teaserTitle?.value },
                                            Image: {},
                                            ResultType: { value: result.templateName },
                                            LinkUrl:
                                                result?.templateName === 'Publication Page'
                                                    ? {}
                                                    : { value: result.item.url },
                                        };
                                        return (
                                            <li
                                                key={index}
                                                className={`${searchBarBlock}__suggested-results-item`}
                                            >
                                                <EventAutofillSearchResult
                                                    fields={result.fields}
                                                    searchValue={searchParams}
                                                />
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        )}
                    </div>
                </div>
                <div className={classnames(`${searchBarBlock}__mobile-filter-buttons`)}>
                    <button
                        className={classnames(`${searchBarBlock}__mobile-filter-button`, {
                            [`${searchBarBlock}__mobile-filter-button--active`]: setMobileActive,
                        })}
                        onClick={() => {
                            setMobileActiveState(!setMobileActive);
                            showMobileFilters();
                        }}
                    >
                        <div className={classnames(`${searchBarBlock}__mobile-filter-pill`)}>
                            {t('Filters')}
                        </div>
                        <div className={classnames(`${searchBarBlock}__chevron`)}>
                            {setMobileActive ? <ChevronUp /> : <ChevronDown />}
                        </div>
                    </button>
                </div>
                <div
                    className={classnames(`${searchBarBlock}__list-wrapper`, {
                        [`${searchBarBlock}__list-wrapper--active`]: setMobileActive,
                    })}
                >
                    {setMobileActive && (
                        <div className={classnames(`${searchBarBlock}__mobile-header`)}>
                            <div
                                className={classnames(`${searchBarBlock}__mobile-header-content`, {
                                    [`${searchBarBlock}__mobile-header-content--menu-open`]:
                                        setActiveFilterGroup !== null || setMobilePublishMenuActive,
                                })}
                            >
                                <div
                                    className={classnames(`${searchBarBlock}__mobile-header-title`)}
                                >
                                    {setActiveFilterGroup === SearchBarCalendarEnum.start ||
                                    setActiveFilterGroup === SearchBarCalendarEnum.end
                                        ? t('SelectADate')
                                        : t('Filter')}
                                </div>
                                <span>
                                    <Button
                                        aria-label={t('CloseNavigation')}
                                        type="button"
                                        variant="icon"
                                        onClick={() => {
                                            setMobileActiveState(false);
                                            hideMobileFilters();
                                        }}
                                    >
                                        <CloseIcon />
                                    </Button>
                                </span>
                            </div>
                            <div
                                className={classnames(`${searchBarBlock}__mobile-header-divider`, {
                                    [`${searchBarBlock}__mobile-header-divider--menu-open`]:
                                        setActiveFilterGroup !== null || setMobilePublishMenuActive,
                                })}
                            ></div>
                        </div>
                    )}
                    <div
                        className={classnames(`${searchBarBlock}__filters`, {
                            [`${searchBarBlock}__filters--active`]: setMobileActive,
                        })}
                    >
                        <ul
                            className={classnames(`${searchBarBlock}__filter-list`, {
                                [`${searchBarBlock}__filter-list--active`]:
                                    setActiveFilterGroup !== null,
                            })}
                            ref={filterList}
                        >
                            <DatePicker
                                date={dateRange}
                                onChange={(val) => {
                                    setDateRange(val);
                                    setDisabledState(true);
                                    setIsFilterApplied(true);
                                }}
                                disabled={disabledState}
                            />
                            {allFilters.map(
                                (filter, index) =>
                                    !filter.peopleOnly &&
                                    filter.options && (
                                        <SearchBarFilter
                                            key={index}
                                            fields={filter}
                                            activeFilter={setActiveFilterGroup}
                                            index={index}
                                            checkBox={checkSingularBox}
                                            uncheckAllBoxes={clearSingularFilter}
                                            toggleFilter={toggleActiveFilterGroup}
                                            translation={t}
                                            onApplyFilters={submitForm}
                                            disabledState={disabledState}
                                            setDisabledState={setDisabledState}
                                        />
                                    )
                            )}

                            {allFilters?.length > 0 && (
                                <button
                                    className={classnames(
                                        `${searchBarBlock}__desktop-clear-button`,
                                        {
                                            [`${searchBarBlock}__desktop-clear-button--disabled`]: disabledState,
                                        }
                                    )}
                                    onClick={() => {
                                        clearAllFilters();
                                    }}
                                >
                                    {t('ClearAll')}
                                </button>
                            )}
                        </ul>
                    </div>
                    {setActiveFilterGroup === null && setMobileActive && (
                        <div className={classnames(`${searchBarBlock}__mobile-footer`)}>
                            {allFilters?.length > 0 && (
                                <button
                                    className={classnames(`${searchBarBlock}__mobile-clear-button`)}
                                    onClick={() => {
                                        clearAllFilters();
                                    }}
                                >
                                    {t('ClearAll')}
                                </button>
                            )}
                            <div className={classnames(`${searchBarBlock}__mobile-apply-button`)}>
                                <Button
                                    className="zn-main-nav-bar--hide-mobile"
                                    type="submit"
                                    onClick={submitForm}
                                >
                                    {t('ShowResults')}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

const SearchBarWithTranslation = withTranslation()(EventSearchBar);

export const SearchBarStorybook = forStorybookV2(SearchBarWithTranslation);

export default withSitecoreContext()(SearchBarWithTranslation);
