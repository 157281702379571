// Threshold is the amount of the component to be on the screen. 1 is all of it, 0 nothing
const defaultOptions = {
    threshold: 0.2,
};

const createIntersectionObserver = (callback, options, observables) => {
    const observer = new IntersectionObserver(callback, options);
    Array.from(observables).forEach((observable) => {
        observer.observe(observable);
    });
};

export const setAnimationInterObserver = (selector, newClass, options) => {
    const callback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add(newClass);
            }
        });
    };
    let content = document.querySelectorAll(selector);

    createIntersectionObserver(callback, options || defaultOptions, content);
};

export default createIntersectionObserver;
