/* eslint-disable no-unused-vars */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text as TextJss, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { SizeEnum, ArticleHeadingColorEnum, ThemeEnum, HeadingLevelEnum } from '../../utils/enums';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import './article-heading.scss';
import paramToBool from '../../utils/paramToBool';

const ArticleHeading = ({ fields, params, sitecoreContext }) => {
    const { Text } = fields;
    const {
        Theme,
        Size,
        HeadingLevel,
        Color,
        MarginTopSize,
        MarginBottomSize,
        isPeripheralContent,
    } = params;
    const isEditing = sitecoreContext?.pageEditing;

    const sectionHeadingBlock = 'zn-article-heading';
    let theme = Theme ? Theme.toLowerCase() : ThemeEnum.light.toLowerCase();
    let TagName = HeadingLevel ? HeadingLevel.toLowerCase() : 'span';
    let color = Color ? Color.toLowerCase() : '';
    const marginTopSize = MarginTopSize?.toLowerCase() || null;
    const marginBottomSize = MarginBottomSize?.toLowerCase() || null;
    return (
        (Text?.value || isEditing) && (
            <TagName
                className={classnames(`${sectionHeadingBlock}`, {
                    [`${sectionHeadingBlock}--medium`]: Size === SizeEnum.medium,
                    [`${sectionHeadingBlock}--small`]: Size === SizeEnum.small,
                    [`${sectionHeadingBlock}--${color}`]:
                        color && color != ArticleHeadingColorEnum.default.toLowerCase(),
                    [`${sectionHeadingBlock}--${theme}`]:
                        color === ArticleHeadingColorEnum.default.toLowerCase() || !color,
                    [`${sectionHeadingBlock}--padding-top-${marginTopSize}`]: marginTopSize,
                    [`${sectionHeadingBlock}--padding-bottom-${marginBottomSize}`]: marginBottomSize,
                })}
                data-is-peripheral={paramToBool(isPeripheralContent) ? 'true' : undefined}
            >
                <TextJss field={Text} />
            </TagName>
        )
    );
};

ArticleHeading.propTypes = {
    fields: PropTypes.exact({
        Text: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
    params: PropTypes.exact({
        Theme: PropTypes.oneOf(Object.values(ThemeEnum)),
        Size: PropTypes.oneOf(Object.values(SizeEnum)),
        HeadingLevel: PropTypes.oneOf(Object.values(HeadingLevelEnum)),
        Color: PropTypes.oneOf(Object.values(ArticleHeadingColorEnum)),
    }),
};

const ArticleHeadingSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
        ArticleHeading
    )
);

export const ArticleHeadingStorybook = forStorybookV2(ArticleHeadingSitecore);

export default ArticleHeadingSitecore;
