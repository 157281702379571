import React from 'react';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import { Image as Img, Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import forStorybookV2 from '../../utils/forStorybook.jsx';
import classnames from 'classnames';

import './office-card.scss';
import { ButtonVariantEnum, ThemeEnum } from '../../utils/enums';

const officeCard = 'zn-office-card';

const OfficeCard = ({ fields, params }) => {
    const { Title, Description, LinkURL, LocationInfo, Image } = fields;
    const { ColorTheme, AccentColor } = params;
    return (
        <div
            className={classnames(
                `${officeCard}`,
                `${officeCard}--${ColorTheme?.toLowerCase().replace(/\s+/g, '')}`
            )}
        >
            <div className={classnames(`${officeCard}__content`)}>
                <div className={classnames(`${officeCard}__title`)}>
                    <Text field={Title} />
                </div>
                {
                    <div
                        className={classnames(
                            `${officeCard}__accent`,
                            `${officeCard}__accent--${AccentColor?.toLowerCase()}`
                        )}
                    />
                }
                {Description && (
                    <div className={classnames(`${officeCard}__description`)}>
                        <Text field={Description} />
                    </div>
                )}
                {LinkURL?.value?.text && LinkURL?.value?.href && (
                    <div>
                        <ButtonLink
                            fields={{
                                LinkURL: {
                                    value: {
                                        text: LinkURL?.value?.text,
                                        href: LinkURL?.value?.href,
                                        title: LinkURL?.value?.title,
                                        target: LinkURL?.value?.target,
                                    },
                                },
                            }}
                            params={{
                                Theme: ThemeEnum.light,
                                Variant: ButtonVariantEnum.tertiary,
                            }}
                        />
                    </div>
                )}
                <div className={classnames(`${officeCard}__location-info`)}>
                    <RichText field={LocationInfo} />
                </div>
            </div>
            <div className={classnames(`${officeCard}__image`)}>
                <Img field={Image?.value} />
            </div>
        </div>
    );
};

const OfficeCardSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.params])(OfficeCard)
);

export const OfficeCardStorybook = forStorybookV2(OfficeCardSitecore);
export default OfficeCardSitecore;
