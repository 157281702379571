import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './sticky-side-banner.scss';
import PropTypes from 'prop-types';
import forStorybook from '../../utils/forStorybook.jsx';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const stickySideBannerBlock = 'zn-sticky-side-banner';

const StickySideBanner = ({
    stickySideBanner,
    stickySideBannerContent,
    sitecoreContext,
    fields,
}) => {
    const { BackgroundCustomColor } = fields;
    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    return (
        <div className={classnames(stickySideBannerBlock)}>
            <div className={classnames(`${stickySideBannerBlock}__content`)}>
                {stickySideBannerContent}
            </div>
            {!pdfView && (
                <div
                    className={classnames(`${stickySideBannerBlock}__banner-container`, {
                        [`${stickySideBannerBlock}__banner-container--sc-editing`]: sitecoreContext.pageEditing,
                    })}
                >
                    <div
                        className={classnames(`${stickySideBannerBlock}__banner`, {
                            [`${stickySideBannerBlock}__banner--sc-editing`]: sitecoreContext.pageEditing,
                        })}
                    >
                        <div
                            className={classnames(`${stickySideBannerBlock}__banner-content`)}
                            style={{ backgroundColor: BackgroundCustomColor?.value }}
                        >
                            {stickySideBanner}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

StickySideBanner.propTypes = {
    fields: PropTypes.shape({
        BackgroundCustomColor: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

const StickySideBannerWithContext = withSitecoreContext()(StickySideBanner);

export const StickySideBannerStorybook = forStorybook(StickySideBannerWithContext);

export default withPlaceholder(['stickySideBanner', 'stickySideBannerContent'])(
    StickySideBannerWithContext
);
