import React from 'react';
import { ThemeEnum, SizeEnum } from '../utils/enums.js';

export const SearchIcon = ({ theme, customColor }) => {
    let color = theme === ThemeEnum.dark.toLowerCase() ? 'white' : '#424D54';
    color = customColor || color;
    return (
        <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.5 16.5932L13.3809 11.4741C14.2868 10.2746 14.824 8.78103 14.824 7.162C14.824 3.20654 11.6175 0 7.662 0C3.70654 0 0.5 3.20654 0.5 7.162C0.5 11.1175 3.70654 14.324 7.662 14.324C9.28103 14.324 10.7746 13.7868 11.9741 12.8809L17.0932 18L18.5 16.5932ZM12.8346 7.162C12.8346 10.0187 10.5187 12.3346 7.662 12.3346C4.80527 12.3346 2.48944 10.0187 2.48944 7.162C2.48944 4.30527 4.80527 1.98944 7.662 1.98944C10.5187 1.98944 12.8346 4.30527 12.8346 7.162Z"
                fill={color}
            />
        </svg>
    );
};

export const HamburguerMenu = ({ theme }) => {
    const color = theme === ThemeEnum.dark.toLowerCase() ? 'white' : 'black';
    return (
        <svg
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="M5.875 0.45459H20.8125H5.875Z" stroke={color} />
                <path d="M5.875 6.81787H14.7067H5.875Z" stroke={color} />
                <path d="M5.875 13.1816H18H5.875Z" stroke={color} />
                <path d="M5.875 19.5454H23.5385H5.875Z" stroke={color} />
                <path d="M1 0.45459H2H1Z" stroke={color} />
                <path d="M1 6.81787H2H1Z" stroke={color} />
                <path d="M1 13.1816H2H1Z" stroke={color} />
                <path d="M1 19.5454H2H1Z" stroke={color} />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ShareIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.8 9.6C11.84 9.6 10.9872 10.0328 10.4 10.7048L6.3264 8.6688C6.3736 8.4536 6.4 8.2304 6.4 8C6.4 7.7696 6.3736 7.5472 6.3264 7.3304L10.4 5.2944C10.9872 5.9664 11.84 6.4 12.8 6.4C14.564 6.4 16 4.964 16 3.2C16 1.4352 14.564 0 12.8 0C11.036 0 9.6 1.4352 9.6 3.2C9.6 3.4296 9.6264 3.6528 9.6736 3.8688L5.6 5.9048C5.0128 5.2336 4.16 4.8 3.2 4.8C1.436 4.8 0 6.2352 0 8C0 9.764 1.436 11.2 3.2 11.2C4.16 11.2 5.0128 10.7656 5.6 10.0936L9.6736 12.1304C9.6264 12.3472 9.6 12.5704 9.6 12.8C9.6 14.564 11.036 16 12.8 16C14.564 16 16 14.564 16 12.8C16 11.0352 14.564 9.6 12.8 9.6ZM12.8 1.6C13.6832 1.6 14.4 2.316 14.4 3.2C14.4 4.0832 13.6832 4.8 12.8 4.8C11.9168 4.8 11.2 4.0832 11.2 3.2C11.2 2.316 11.9168 1.6 12.8 1.6ZM3.2 9.6C2.3168 9.6 1.6 8.8832 1.6 8C1.6 7.116 2.3168 6.4 3.2 6.4C4.0832 6.4 4.8 7.116 4.8 8C4.8 8.8832 4.0832 9.6 3.2 9.6ZM12.8 14.4C11.9168 14.4 11.2 13.6832 11.2 12.8C11.2 11.916 11.9168 11.2 12.8 11.2C13.6832 11.2 14.4 11.916 14.4 12.8C14.4 13.6832 13.6832 14.4 12.8 14.4Z"
                fill="#006EAA"
            />
        </svg>
    );
};

export const CloseIcon = ({ customColor, size }) => {
    const color = customColor || '#006EAA';

    if (size === SizeEnum.small) {
        return (
            <svg
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.92753 5.5L11.3333 9.9058L10.4058 10.8333L5.99999 6.42754L1.5942 10.8333L0.666661 9.9058L5.07246 5.5L0.666661 1.0942L1.5942 0.166667L5.99999 4.57246L10.4058 0.166668L11.3333 1.0942L6.92753 5.5Z"
                    fill={color}
                />
            </svg>
        );
    } else {
        return (
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1 1L17 17L1 1Z" stroke={color} />
                <path d="M17 1L1 17L17 1Z" stroke={color} />
            </svg>
        );
    }
};

export const Globe = ({ theme }) => {
    const color = theme === ThemeEnum.dark.toLowerCase() ? 'white' : 'black';
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.5 11.957C23.5 18.332 18.337 23.501 11.968 23.501C5.599 23.5 0.5 18.125 0.5 11.75C0.5 5.542 5.37 0.758 11.505 0.511C11.671 0.504 11.837 0.5 12.005 0.5C18.374 0.5 23.5 5.582 23.5 11.957Z"
                stroke={color}
            />
            <path
                d="M11.5039 0.510986C5.50391 7.01099 5.50391 15.491 11.5039 23.491"
                stroke={color}
            />
            <path
                d="M12.5039 0.510986C18.5039 7.01099 18.5039 15.488 12.5039 23.488"
                stroke={color}
            />
            <path d="M2.38599 5.5H21.521" stroke={color} />
            <path d="M0.502991 11.5H23.5" stroke={color} />
            <path d="M1.98499 17.5H22.085" stroke={color} />
        </svg>
    );
};

export const MailIcon = () => {
    return (
        <svg
            width="22"
            height="17"
            viewBox="0 0 22 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M19.6804 15.9548H2.50032C1.69795 15.9548 1.04761 15.3379 1.04761 14.5771V2.87847C1.04761 2.11766 1.69795 1.50073 2.50032 1.50073H19.6804C20.4828 1.50073 21.1328 2.11766 21.1328 2.87847V14.5771C21.1328 15.3379 20.4828 15.9548 19.6804 15.9548Z"
                    stroke="#006EAA"
                />
                <path
                    d="M12.3617 9.2465L20.6908 1.89035C20.4294 1.64955 20.0737 1.49976 19.6803 1.49976H2.50024C2.10685 1.49976 1.75155 1.64955 1.48975 1.89035L9.81886 9.2465C10.5354 9.87919 11.6452 9.87919 12.3617 9.2465Z"
                    stroke="#006EAA"
                />
                <path
                    d="M12.6374 9.00269L12.3618 9.24611C11.6453 9.8788 10.5354 9.8788 9.81892 9.24611L9.54327 9.00269L1.50781 15.577C1.76788 15.8089 2.11522 15.9535 2.5003 15.9535H19.6804C20.0655 15.9535 20.4128 15.8089 20.6729 15.577L12.6374 9.00269Z"
                    stroke="#006EAA"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="22" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const PhoneIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M23.3236 18.9163C22.48 17.3216 19.3685 15.4981 19.3685 15.4981C18.6645 15.0967 17.8151 15.0523 17.4133 15.7674C17.4133 15.7674 16.4027 16.9798 16.1453 17.1541C14.9702 17.9501 13.8827 17.9194 12.784 16.8207L7.67912 11.7158C6.58045 10.6172 6.54978 9.52963 7.34578 8.35451C7.52001 8.09718 8.73245 7.08651 8.73245 7.08651C9.44756 6.68474 9.40312 5.8354 9.00178 5.1314C9.00178 5.1314 7.17823 2.01985 5.58401 1.17629C4.90578 0.817181 4.07245 0.942514 3.53023 1.48518L2.40267 2.61274C-1.17333 6.18829 0.587117 10.2252 4.16312 13.8012L10.6987 20.3367C14.2742 23.9127 18.3116 25.6732 21.8871 22.0972L23.0147 20.9696C23.5573 20.4274 23.6827 19.5941 23.3236 18.9163Z"
                    stroke="#006EAA"
                />
                <path
                    d="M12.8889 16.9449C12.8889 16.9449 14.8334 18.8613 17.7778 19.1671"
                    stroke="#006EAA"
                />
                <path
                    d="M8.00004 12.056C8.00004 12.056 5.63915 10.1116 5.33337 7.16711"
                    stroke="#006EAA"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const MovieCameraIcon = () => {
    return (
        <svg
            width="23"
            height="20"
            viewBox="0 0 23 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.4566 12.9122H1.93327C1.08832 12.9122 0.403564 12.2275 0.403564 11.3825V1.99488C0.403564 1.11967 1.11979 0.403442 1.995 0.403442H14.549C15.4278 0.403442 16.1404 1.11604 16.1404 1.99488V11.2284C16.1404 12.1544 15.3826 12.9122 14.4566 12.9122Z"
                stroke="currentColor"
            />
            <path
                d="M21.3941 12.0763L16.3466 7.25273C16.0658 6.96906 16.0726 6.50745 16.3619 6.23266L21.4094 1.57374C21.8589 1.14683 22.5966 1.46843 22.5966 2.09145V11.5735C22.5966 12.207 21.838 12.5238 21.3941 12.0763Z"
                stroke="currentColor"
            />
            <path d="M8.52703 12.9125L5.53259 19.3686" stroke="currentColor" />
            <path d="M8.52698 12.9125L11.5214 19.3686" stroke="currentColor" />
            <path
                d="M7.26318 5.04352C7.26318 6.26897 6.26974 7.26282 5.04388 7.26282C3.81802 7.26282 2.82458 6.26897 2.82458 5.04352C2.82458 3.81766 3.81802 2.82422 5.04388 2.82422C6.26974 2.82422 7.26318 3.81766 7.26318 5.04352Z"
                stroke="currentColor"
            />
            <path d="M5.64917 5.24597L7.66671 7.26352" stroke="currentColor" />
        </svg>
    );
};

export const HeadPhonesIcon = () => {
    return (
        <svg
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.5865 19.0568C19.2807 17.9375 20.5998 13.0631 20.6037 11.4925C20.6085 9.62704 20.3925 7.71208 19.606 6.02019C17.1566 0.749986 12.5774 0.373967 11.0226 0.398533C10.6414 0.404476 10.2602 0.391004 9.87946 0.398533C8.29614 0.430231 3.84493 0.749986 1.4228 6.02019C0.641838 7.7204 0.42629 9.62189 0.43263 11.4925C0.437781 13.0596 1.2544 17.9419 1.94542 19.0568M6.09867 22.5143L5.56812 22.6149C5.03282 22.7164 4.51178 22.3618 4.40995 21.8265L2.89874 13.8643C2.79691 13.329 3.15193 12.8079 3.68723 12.7065L4.21778 12.6055C4.75308 12.504 5.27412 12.8587 5.37595 13.394L6.88716 21.3561C6.98899 21.8914 6.63397 22.4125 6.09867 22.5143ZM14.4376 22.5143L14.9686 22.6149C15.5035 22.7164 16.0245 22.3618 16.1264 21.8265L17.638 13.8643C17.7394 13.329 17.3848 12.8079 16.8495 12.7065L16.3185 12.6055C15.7832 12.504 15.2622 12.8587 15.1608 13.394L13.6492 21.3561C13.5477 21.8914 13.9023 22.4125 14.4376 22.5143ZM3.25059 15.7174L1.57931 16.4738L2.39197 20.9389L4.26493 21.0605L3.25059 15.7174ZM17.2857 15.7194L16.2718 21.0605L18.14 20.9389L18.9526 16.4738L17.2857 15.7194Z"
                stroke="currentColor"
            />
            <path
                d="M11.0285 0.398193C10.8419 0.400966 10.6557 0.399381 10.4691 0.397796C10.2757 0.396212 10.0812 0.39423 9.887 0.398193C7.67329 0.442174 5.78685 1.12685 4.28516 2.39953C6.13989 2.8231 8.34885 3.07034 10.723 3.07034C12.9538 3.07034 15.0399 2.85242 16.8226 2.47482C14.6817 0.607797 12.241 0.375608 11.0285 0.398193Z"
                stroke="currentColor"
            />
        </svg>
    );
};

export const DocumentIcon = () => {
    return (
        <svg
            width="16"
            height="21"
            viewBox="0 0 16 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.4427 4.47978H12C11.6196 4.47978 11.3115 4.17167 11.3115 3.79126V0.348633L15.4427 4.47978Z"
                stroke="currentColor"
            />
            <path
                d="M15.4427 4.48027H12C11.6196 4.48027 11.3115 4.17215 11.3115 3.79174V0.349121H1.67217C1.29176 0.349121 0.983643 0.657236 0.983643 1.03765V19.6278C0.983643 20.0082 1.29176 20.3163 1.67217 20.3163H14.7541C15.1345 20.3163 15.4427 20.0082 15.4427 19.6278V4.48027Z"
                stroke="currentColor"
            />
            <path d="M3.04919 4.47992H9.24592" stroke="currentColor" />
            <path d="M3.04919 6.54504H13.3771" stroke="currentColor" />
            <path d="M3.04919 8.61121H13.3771" stroke="currentColor" />
            <path d="M3.04919 10.6774H13.3771" stroke="currentColor" />
            <path d="M3.04919 12.7426H13.3771" stroke="currentColor" />
            <path d="M3.04919 14.8077H13.3771" stroke="currentColor" />
            <path d="M3.04919 16.8738H13.3771" stroke="currentColor" />
        </svg>
    );
};

export const NewsPaperIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.48254 23.5998C1.34461 23.5998 0.413574 22.6998 0.413574 21.5998V12.3998H4.55151V21.5998C4.55151 22.6998 3.62047 23.5998 2.48254 23.5998ZM4.55151 0.39978V21.5998C4.55151 22.6998 3.62047 23.5998 2.48254 23.5998H21.517C22.6574 23.5998 23.5818 22.7062 23.5818 21.6038V0.39978H4.55151ZM7.03426 6.79978H11.9998V11.5998H7.03426V6.79978ZM14.4825 6.79978H21.1032H14.4825ZM14.4825 9.19978H21.1032H14.4825ZM14.4825 11.5998H21.1032H14.4825ZM7.44806 13.9998H21.1032H7.44806ZM7.44806 16.3998H13.655H7.44806ZM7.44806 18.7998H13.655H7.44806ZM7.44806 21.1998H13.655H7.44806ZM16.1377 21.1998H21.1032V16.3998H16.1377V21.1998ZM4.55151 5.19978H23.586H4.55151ZM9.51702 1.99978H18.6205V3.59978H9.51702V1.99978Z"
                stroke="currentColor"
            />
        </svg>
    );
};

export const QuarterlyIcon = () => {
    return (
        <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="M12.214 21.6439V5.21533" stroke="#006EAA" />
                <path
                    d="M12.149 21.6234L3.28543 18.787V2.3584L12.214 5.21554V21.5759C12.214 21.6098 12.1811 21.6338 12.149 21.6234Z"
                    stroke="#006EAA"
                />
                <path d="M5.07742 6.80811L7.57135 7.80918" stroke="#006EAA" />
                <path d="M5.07742 8.78711L10.4217 10.7593" stroke="#006EAA" />
                <path d="M5.07742 10.9297L10.4217 12.9018" stroke="#006EAA" />
                <path d="M5.07742 13.0728L10.4217 15.0449" stroke="#006EAA" />
                <path d="M5.07742 15.2153L10.4217 17.1875" stroke="#006EAA" />
                <path
                    d="M12.279 21.6234L21.1426 18.787V2.3584L12.214 5.21554V21.5759C12.214 21.6098 12.2469 21.6338 12.279 21.6234Z"
                    stroke="#006EAA"
                />
                <path
                    d="M21.1426 5.21533V18.7868L12.279 21.6232C12.2469 21.6335 12.214 21.6093 12.214 21.5757C12.214 21.6093 12.1812 21.6335 12.149 21.6232L3.28544 18.7868V5.21533H2.02652C1.93294 5.21533 1.85687 5.2914 1.85687 5.38498V21.4743C1.85687 21.5678 1.93294 21.6439 2.02652 21.6439H22.4015C22.4951 21.6439 22.5712 21.5678 22.5712 21.4743V5.38498C22.5712 5.2914 22.4951 5.21533 22.4015 5.21533H21.1426Z"
                    stroke="#006EAA"
                />
                <path d="M19.3566 6.64404L14.0123 8.61619" stroke="#006EAA" />
                <path d="M19.3566 8.78711L14.0123 10.7593" stroke="#006EAA" />
                <path d="M19.3566 10.9297L14.0123 12.9018" stroke="#006EAA" />
                <path d="M19.3566 13.0728L14.0123 15.0449" stroke="#006EAA" />
                <path d="M19.3566 15.2153L14.0123 17.1875" stroke="#006EAA" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="21.4286" height="20" fill="white" transform="translate(1.5 2)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const NotebookIcon = () => {
    return (
        <svg
            width="19"
            height="24"
            viewBox="0 0 19 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0.400024 0.400024H18V23.6H0.400024V0.400024Z" stroke="currentColor" />
            <path d="M0.400024 0.400024H3.60002V23.6H0.400024V0.400024Z" stroke="currentColor" />
            <path d="M6.40002 4.39966H15.2V9.19966H6.40002V4.39966Z" stroke="currentColor" />
            <path d="M8 5.99963H13.6H8Z" stroke="currentColor" />
            <path d="M8 7.59961H13.6H8Z" stroke="currentColor" />
        </svg>
    );
};

export const CalendarIcon = () => {
    return (
        <svg
            width="19"
            height="21"
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.05352 0.3396H5.42852V3.05389H3.05352V0.3396ZM13.5714 0.3396H15.9464V3.05389H13.5714V0.3396ZM15.9464 1.69674V3.05389H13.5714V1.69674H5.42852V3.05389H3.05352V1.69674H0.339233V5.42889H18.6607V1.69674H15.9464ZM15.9464 1.69674V3.05389H13.5714V1.69674H5.42852V3.05389H3.05352V1.69674H0.339233V20.0182H18.6607V1.69674H15.9464Z"
                stroke="currentColor"
            />
            <path
                d="M3.39282 8.14227H6.44639V11.1958H3.39282V8.14227ZM3.39282 11.1958H6.44639V14.2494H3.39282V11.1958ZM3.39282 14.2494H6.44639V17.303H3.39282V14.2494ZM6.44639 8.14227H9.49997V11.1958H6.44639V8.14227ZM6.44639 11.1958H9.49997V14.2494H6.44639V11.1958ZM6.44639 14.2494H9.49997V17.303H6.44639V14.2494ZM9.49997 8.14227H12.5535V11.1958H9.49997V8.14227ZM9.49997 11.1958H12.5535V14.2494H9.49997V11.1958ZM9.49997 14.2494H12.5535V17.303H9.49997V14.2494ZM12.5535 8.14227H15.6071V11.1958H12.5535V8.14227ZM12.5535 11.1958H15.6071V14.2494H12.5535V11.1958ZM12.5535 14.2494H15.6071V17.303H12.5535V14.2494Z"
                stroke="currentColor"
            />
        </svg>
    );
};

export const InPersonEventIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="17.5" viewBox="0 0 23.5 17.5">
            <path
                d="M3.1,4.6c.2,5.5,8.1,5.5,8.3,0-.2-5.5-8.1-5.5-8.3,0Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M.5,17c.3-8.9,13.2-8.9,13.5,0"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.1,6.8c.1,4.5,6.6,4.5,6.8,0-.1-4.5-6.6-4.5-6.8,0Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.6,11.8c3.5-1.4,7.5,1.4,7.4,5.2"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const StaticEventIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.5" viewBox="0 0 23.5 23.5">
            <path
                d="M2,3.5h19.5s1.5,0,1.5,1.5v16.5s0,1.5-1.5,1.5H2s-1.5,0-1.5-1.5V5s0-1.5,1.5-1.5"
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
            />
            <path d="M.5,9.5h22.5" fill="none" stroke="currentColor" strokeLinejoin="round" />
            <path
                d="M6.5,5.8V.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17,5.8V.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.2,15.6c0,1.7-1.4,3.1-3.1,3.1-4.1-.1-4.1-6.1,0-6.2,1.7,0,3.1,1.4,3.1,3.1Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3,17.8l2.2,2.2"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const SearchBarIcon = () => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.6418 13.2965C25.6418 20.1151 20.1146 25.6422 13.2961 25.6422C6.4776 25.6422 0.950439 20.1151 0.950439 13.2965C0.950439 6.47803 6.4776 0.950867 13.2961 0.950867C20.1146 0.950867 25.6418 6.47803 25.6418 13.2965Z"
                stroke="currentColor"
                strokeWidth="1.32716"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1079 22.8537L30.8178 32.5636L32.5635 30.8179L22.8536 21.1081C22.3314 21.7463 21.7462 22.3315 21.1079 22.8537Z"
                stroke="currentColor"
                strokeWidth="1.32716"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const SelectedCheckbox = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="24" height="24" rx="2" fill="currentColor" />
            <path
                d="M6 11.5806L10.5073 16.0879L18.728 7.86719"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
        </svg>
    );
};

export const UnselectedCheckbox = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.75"
                y="0.75"
                width="22.5"
                height="22.5"
                rx="1.25"
                stroke="currentColor"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export const Checkmark = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.99927 11.5171L9.95702 17L18.9993 7"
                stroke="currentColor"
                strokeWidth="1.68247"
                strokeLinecap="square"
            />
        </svg>
    );
};

export const CalendarSearchBarIcon = () => {
    return (
        <svg
            width="19"
            height="21"
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.05413 0.339844H5.42913V3.05413H3.05413V0.339844ZM13.572 0.339844H15.947V3.05413H13.572V0.339844ZM15.947 1.69699V3.05413H13.572V1.69699H5.42913V3.05413H3.05413V1.69699H0.339844V5.42913H18.6613V1.69699H15.947ZM15.947 1.69699V3.05413H13.572V1.69699H5.42913V3.05413H3.05413V1.69699H0.339844V20.0184H18.6613V1.69699H15.947Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.39258 8.14258H6.44615V11.1961H3.39258V8.14258ZM3.39258 11.1961H6.44615V14.2497H3.39258V11.1961ZM3.39258 14.2497H6.44615V17.3033H3.39258V14.2497ZM6.44615 8.14258H9.49972V11.1961H6.44615V8.14258ZM6.44615 11.1961H9.49972V14.2497H6.44615V11.1961ZM6.44615 14.2497H9.49972V17.3033H6.44615V14.2497ZM9.49972 8.14258H12.5533V11.1961H9.49972V8.14258ZM9.49972 11.1961H12.5533V14.2497H9.49972V11.1961ZM9.49972 14.2497H12.5533V17.3033H9.49972V14.2497ZM12.5533 8.14258H15.6069V11.1961H12.5533V8.14258ZM12.5533 11.1961H15.6069V14.2497H12.5533V11.1961ZM12.5533 14.2497H15.6069V17.3033H12.5533V14.2497Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const PersonIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15C14.9391 15 8 15 8 15C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                stroke="#006EAA"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                stroke="#006EAA"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const CloseBubbleIcon = () => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.599976 0.5L16.6 16.5L0.599976 0.5Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6 0.5L0.599976 16.5L16.6 0.5Z"
                stroke="currentColor"
            />
        </svg>
    );
};

export const BookmarkIcon = () => {
    return (
        <svg
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.36196 0.387695C1.27237 0.387695 0.388672 1.27139 0.388672 2.36098V21.3265L7.36826 16.6734L14.3479 21.3265V2.71423C14.3479 1.43463 15.3948 0.387695 16.6744 0.387695H2.36196Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6393 0.389648C15.3756 0.408648 14.3477 1.44628 14.3477 2.71424V9.30608H18.613V2.37922C18.613 1.28536 17.7308 0.399342 16.6393 0.389648Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const BriefcaseIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3762 20H3.62379C3.27931 20 3 19.7061 3 19.3437V7.5951C3 7.23265 3.27931 6.93878 3.62379 6.93878H20.3762C20.7207 6.93878 21 7.23265 21 7.5951V19.3437C21 19.7061 20.7207 20 20.3762 20ZM15.1034 6.93878H8.89655V4.72033C8.89655 4.32229 9.20317 4 9.58117 4H14.4188C14.7968 4 15.1034 4.32229 15.1034 4.72033V6.93878ZM5.48276 6.93878V20V6.93878ZM18.5172 6.93878V20V6.93878ZM4.55172 5.95918H7.03448V6.93878H4.55172V5.95918ZM16.9655 5.95918H19.4483V6.93878H16.9655V5.95918Z"
                stroke="currentColor"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const MapExport = () => {
    return (
        <svg
            width="12"
            height="15"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.53029 8.78025L8.46979 7.71975L6.75004 9.4395V0H5.25004V9.4395L3.53029 7.71975L2.46979 8.78025L6.00004 12.3105L9.53029 8.78025Z"
                fill="#D54414"
            />
            <path
                d="M10.5 4.5H9.75V6H10.5V13.5H1.5V6H2.25V4.5H1.5C0.67275 4.5 0 5.17275 0 6V13.5C0 14.3265 0.67275 15 1.5 15H10.5C11.328 15 12 14.3265 12 13.5V6C12 5.17275 11.328 4.5 10.5 4.5Z"
                fill="#D54414"
            />
        </svg>
    );
};

export const ListView = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8301_354)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.896 2.44312H17.3439H4.896Z"
                    stroke="white"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.896 7.48071H12.2558H4.896Z"
                    stroke="white"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.896 12.5186H15.0002H4.896Z"
                    stroke="white"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.896 17.5566H19.6156H4.896Z"
                    stroke="white"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.833496 2.44312H1.66683H0.833496Z"
                    stroke="white"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.833496 7.48071H1.66683H0.833496Z"
                    stroke="white"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.833496 12.5186H1.66683H0.833496Z"
                    stroke="white"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.833496 17.5566H1.66683H0.833496Z"
                    stroke="white"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8301_354">
                    <rect
                        width="20"
                        height="15.8333"
                        fill="white"
                        transform="translate(0 2.08325)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const MapView = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.90922 17.4545L0.36377 18.909V3.27266L6.90922 1.81812V17.4545Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6363 15.9999L13.0908 18.5454V3.27264L19.6363 1.09082V15.9999Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.091 18.5454L6.90918 17.4545V1.81812L13.091 3.27266V18.5454Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const MapFilters = () => {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8675_1580)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.81494 3.00003C4.81494 4.18361 3.82013 5.14289 2.59272 5.14289C1.36531 5.14289 0.370497 4.18361 0.370497 3.00003C0.370497 1.81646 1.36531 0.857178 2.59272 0.857178C3.82013 0.857178 4.81494 1.81646 4.81494 3.00003Z"
                    stroke="#006EAA"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.375 10.426C12.375 11.6503 11.3457 12.6428 10.0761 12.6428C8.80648 12.6428 7.77722 11.6503 7.77722 10.426C7.77722 9.20173 8.80648 8.20923 10.0761 8.20923C11.3457 8.20923 12.375 9.20173 12.375 10.426Z"
                    stroke="#006EAA"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.6294 3L4.81458 3L19.6294 3Z"
                    stroke="#006EAA"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.1849 18C15.1849 19.1836 16.1798 20.1429 17.4072 20.1429C18.6346 20.1429 19.6294 19.1836 19.6294 18C19.6294 16.8165 18.6346 15.8572 17.4072 15.8572C16.1798 15.8572 15.1849 16.8165 15.1849 18Z"
                    stroke="#006EAA"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.369755 18L15.1846 18L0.369755 18Z"
                    stroke="#006EAA"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.6294 10.5L12.5924 10.5L19.6294 10.5Z"
                    stroke="#006EAA"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.77783 10.5L0.370425 10.5L7.77783 10.5Z"
                    stroke="#006EAA"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8675_1580">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(20 0.5) rotate(90)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const MapAdvancedFilters = () => {
    return (
        <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="1" width="39" height="39" rx="19.5" fill="white" />
            <g clipPath="url(#clip0_8956_5240)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8148 13C14.8148 14.1836 13.82 15.1429 12.5926 15.1429C11.3652 15.1429 10.3704 14.1836 10.3704 13C10.3704 11.8165 11.3652 10.8572 12.5926 10.8572C13.82 10.8572 14.8148 11.8165 14.8148 13Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.375 20.426C22.375 21.6503 21.3457 22.6428 20.0761 22.6428C18.8065 22.6428 17.7772 21.6503 17.7772 20.426C17.7772 19.2017 18.8065 18.2092 20.0761 18.2092C21.3457 18.2092 22.375 19.2017 22.375 20.426Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.6295 13L14.8147 13L29.6295 13Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.1851 28C25.1851 29.1836 26.1799 30.1429 27.4073 30.1429C28.6347 30.1429 29.6295 29.1836 29.6295 28C29.6295 26.8165 28.6347 25.8572 27.4073 25.8572C26.1799 25.8572 25.1851 26.8165 25.1851 28Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.3699 28L25.1847 28L10.3699 28Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.6295 20.5L22.5925 20.5L29.6295 20.5Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.7776 20.5L10.3702 20.5L17.7776 20.5Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <rect x="0.5" y="1" width="39" height="39" rx="19.5" stroke="currentColor" />
            <defs>
                <clipPath id="clip0_8956_5240">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(30 10.5) rotate(90)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
