import React from 'react';
import classnames from 'classnames';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import paramToBool from '../../utils/paramToBool.js';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import './image.scss';

const Image = ({ fields, params, sitecoreContext }) => {
    const { Image, Caption, ShowInPdf } = fields;
    const { FillSpace, FieldNameImage, FieldNameCaption } = params;
    const contextFields = sitecoreContext?.route?.fields;

    const caption = FieldNameCaption ? contextFields?.[FieldNameCaption] : Caption;
    const image = FieldNameImage ? contextFields?.[FieldNameImage] : Image;
    const imageBlock = 'zn-image';
    const fillSpaceBool = paramToBool(FillSpace);
    const showInPDF = paramToBool(ShowInPdf?.value);

    return image?.value?.src || sitecoreContext?.pageEditing ? (
        <figure
            className={classnames(imageBlock, {
                [`${imageBlock}--hide-on-print`]: !showInPDF,
                [`${imageBlock}--fill-space`]: fillSpaceBool,
            })}
        >
            {sitecoreContext?.pageEditing && <h6>Image Editing Mode</h6>}
            <div
                className={`${imageBlock}__media`}
                style={{
                    maxWidth:
                        image?.value?.width && !fillSpaceBool ? `${image.value.width}px` : `100%`,
                    'background-image': fillSpaceBool ? `url(${image?.value?.src})` : null,
                }}
            >
                <img src={image?.value?.src} alt={image?.value?.alt} />
            </div>
            {(caption?.value || sitecoreContext?.pageEditing) && (
                <figcaption className={`${imageBlock}__caption`}>
                    <RichText field={caption} />
                </figcaption>
            )}
        </figure>
    ) : (
        <></>
    );
};

export const ImageSitecore = withSitecoreContext()(Image);
export const ImageSitecoreStorybook = forStorybookV2(ImageSitecore);
export default withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
    ImageSitecore
);
