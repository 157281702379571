import React from 'react';
import ItemWithImage from '../ItemWithImage/ItemWithImage.jsx';
import { SearchResultTypeEnum } from '../../utils/enums';
import forStorybook from '../../utils/forStorybook.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './student-profile.scss';

const StudentProfile = ({ fields, sitecoreContext }) => {
    const {
        Image,
        Name,
        School,
        EducationInformation,
        PhoneNumber,
        EmailAddress,
        Description,
    } = fields;
    return (
        <div className="zn-student-profile">
            <ItemWithImage
                isPageEditing={sitecoreContext.pageEditing}
                fields={{
                    Image,
                    Name,
                    Title: Name,
                    Eyebrow: School,
                    EducationInformation,
                    Description,
                    Email: EmailAddress,
                    Phone: PhoneNumber,
                    ResultType: { value: SearchResultTypeEnum.biopage },
                    PeopleOnly: { value: true },
                }}
            />
        </div>
    );
};

const StudentProfileWithContext = withSitecoreContext()(StudentProfile);
export const StudentProfileStorybook = forStorybook(StudentProfileWithContext);
export default StudentProfileWithContext;
