import React from 'react';
import ContactCard from '../ContactCard/ContactCard.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';

const ContactCardManual = ({ fields, params }) => {
    const { ContactNameOverride, ImageOverride } = fields;
    return (
        <ContactCard
            params={params}
            fields={{
                data: {
                    datasource: {
                        contactNameOverride: {
                            jss: ContactNameOverride,
                        },
                        imageOverride: {
                            jss: ImageOverride,
                        },
                    },
                },
            }}
        />
    );
};

export default withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
    ContactCardManual
);
