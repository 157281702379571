import { sortFilterValues, mapFacetTypeToDisplay } from '../../utils/searchHelpers.jsx';

export const getSearchConditions = (tagIdResults, filterValues) => {
    let searchConditions = [];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(filterValues)) {
        if (
            !value?.length ||
            value[0] === '' ||
            key.substring(0, 3) == 'sc_' ||
            ['q', 'start', 'end', 'contenttype', 'school', 'yearofcall', 'person'].includes(key)
        )
            continue;

        // For query parameters that are to apply conditions
        let condition;
        if (key === 'eventformat') {
            condition = {
                group: value
                    //For every item selected in the filter, find the associated tagId from the tag search results where the tag identifier matches the value in the search params url
                    ?.map((item) => {
                        if (item === 'Event_Virtual') {
                            return [
                                { name: '_templatename', value: 'Webinar', useor: true },
                                {
                                    group: [
                                        { name: '_templatename', value: 'Event', useor: true },
                                        { name: 'HybridEvent', value: 'true', useor: false },
                                    ],
                                    useor: true,
                                },
                            ];
                        } else {
                            return { name: '_templatename', value: 'Event', useor: true };
                        }
                    })
                    .flat(),
            };
        } else if (key === 'organizer') {
            condition = {
                group: value?.map((item) => ({
                    name: 'IsExternal',
                    value: item === 'Organizer_Torys' ? 'false' : 'true',
                    useor: true,
                })),
            };
        } else if (key === 'city') {
            condition = {
                group: value
                    ?.filter((item) =>
                        tagIdResults?.results.find(
                            (x) => x?.identifier === decodeURIComponent(item)
                        )
                    )
                    .map((item) => ({
                        name: 'EventCity',
                        value: decodeURIComponent(item),
                        useor: true,
                    })),
            };
        } else if (key === 'people') {
            condition = {
                group: value
                    ?.filter((el) => !!el)
                    .map((item) => ({ name: 'relatedpeople', value: item, useor: true })),
            };
        } else if (key === 'daterange') {
            searchConditions.push({
                operation: 'DateBetween',
                name: 'TeaserDate',
                value: value[0],
            });
        } else {
            condition = {
                group: value
                    //For every item selected in the filter, find the associated tagId from the tag search results where the tag identifier matches the value in the search params url
                    ?.filter((item) =>
                        tagIdResults?.results.find(
                            (x) => x?.identifier === decodeURIComponent(item)
                        )
                    )
                    // From the found ids, construct a condition so search for items that have tags equal to the ids
                    .map((item) => ({
                        name: 'tags',
                        value: tagIdResults?.results
                            .find((x) => x?.identifier === decodeURIComponent(item))
                            ?.id.toLowerCase(),
                        useor: true,
                    })),
            };
        }

        //Add the filter group condition to overall search condition applying an AND relationship between filters
        condition?.group?.length > 0 && searchConditions.push(condition);
    }

    return searchConditions;
};

export const tagIdConditions = (filterValues) => {
    let tagIds = [];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(filterValues)) {
        value?.length > 0 &&
            !['q', 'start', 'end', 'people', 'eventformat', 'organizer'].includes(key) &&
            key.substring(0, 2) != 'sc_' &&
            tagIds.push(
                filterValues[key]
                    ?.filter((item) => item !== '')
                    .map((item) => ({ name: 'identifier', value: item, useor: true }))
            );
    }
    return tagIds;
};

export const updatePeopleOptions = (filter, facettedResults, paramObj) => {
    const checkedOptions = filter.options?.filter((option) => option.checked);

    const newOptions = facettedResults['relatedpeople']?.reduce(
        (acc, curr) => {
            if (!checkedOptions.some((el) => el.identifier === curr.value)) {
                acc.push({
                    id: curr.value,
                    label: curr.name,
                    identifier: curr.value,
                    hasResults: true,
                    checked: paramObj['people']?.includes(curr.value),
                });
            }

            return acc;
        },
        [...checkedOptions]
    );

    newOptions?.sort(filter.identifier !== 'Position-Category' ? sortFilterValues : () => {});

    return newOptions || [];
};

export const flattenFilterOptions = (filter) => {
    let array = [];
    array.push({ title: filter.title?.value, identifier: filter.identifier?.value });
    if (Array.isArray(filter.children)) {
        filter.children.forEach(function (a) {
            array = array.concat(flattenFilterOptions(a));
        });
    }
    return array;
};

export const formatFilter = (filter, defaultFilters) => {
    return {
        label: filter[0]?.title,
        identifier: filter[0]?.identifier,
        options: filter.slice(1).map((option) => ({
            label: option?.title,
            identifier: option?.identifier,
            defaultChecked: defaultFilters.includes(option.identifier),
        })),
    };
};

export const setTotalSelected = (filter, paramObj) => {
    return filter.options?.filter(
        (option) =>
            option.defaultChecked ||
            paramObj['tags']?.includes(option.identifier) ||
            paramObj[filter.label.toLowerCase()]?.includes(option.identifier) ||
            paramObj['eventformat']?.includes(option.identifier)
    )?.length;
};

export const updateClearOptions = (args) => {
    const { oldOptions } = args;

    return oldOptions?.map((option) => ({ ...option, checked: false })) || [];
};

export const updateHasResults = (options, facettedResults, mappedFacetKey) => {
    let mappedFacetArray =
        mappedFacetKey === '_templatename'
            ? mapFacetTypeToDisplay(facettedResults[mappedFacetKey] || [])
            : facettedResults[mappedFacetKey] || [];

    if (mappedFacetKey === 'relatedpeople') {
        mappedFacetArray = mappedFacetArray.map((el) => el.value);
    }

    return (
        options?.map((option) => ({
            ...option,
            hasResults:
                mappedFacetArray?.includes(option.identifier) || mappedFacetKey === 'static',
        })) || []
    );
};

export const showMobileFilters = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
};

export const hideMobileFilters = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

export const formatFilterTitle = (filter) => {
    return filter.toLowerCase().replace(/\s/g, '');
};
