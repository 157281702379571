import React from 'react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import './expertise-page-links-container.scss';

const expPageLinks = 'zn-expertise-page-links-container';

const LinksRow = ({ left, right }) => {
    return (
        <div className={classnames(`${expPageLinks}__row`)}>
            {left}
            {right}
        </div>
    );
};

const ExpertisePageLinksContainer = ({ expertisePageLinksComponents, sitecoreContext }) => {
    const isEditing = sitecoreContext?.pageEditing;
    const mobileElements = [];
    const desktopElements = [];

    if (expertisePageLinksComponents) {
        for (let index in expertisePageLinksComponents) {
            const bgColor = index % 2 === 0 ? '__white' : '__gray';
            mobileElements.push(
                <div className={classnames(expPageLinks + bgColor)}>
                    {expertisePageLinksComponents[index]}
                </div>
            );
        }

        for (let index = 1; index < expertisePageLinksComponents.length; index += 2) {
            desktopElements.push(
                <LinksRow
                    left={expertisePageLinksComponents[index - 1]}
                    right={expertisePageLinksComponents[index]}
                />
            );
            desktopElements.push(
                <div className={classnames(`${expPageLinks}__bottom-line-container`)}>
                    <div className={classnames(`${expPageLinks}__bottom-line`)} />
                    <div className={classnames(`${expPageLinks}__bottom-line`)} />
                </div>
            );
        }

        if (expertisePageLinksComponents.length % 2 === 1) {
            desktopElements.push(
                <LinksRow
                    left={expertisePageLinksComponents[expertisePageLinksComponents.length - 1]}
                    right={<div />}
                />
            );
            desktopElements.push(
                <div className={classnames(`${expPageLinks}__bottom-line-container`)}>
                    <div className={classnames(`${expPageLinks}__bottom-line`)} />
                    <div
                        style={{ border: 'none' }}
                        className={classnames(`${expPageLinks}__bottom-line`)}
                    />
                </div>
            );
        }
    }

    return (
        <div className={classnames(`${expPageLinks}`)}>
            {/* Only one of __desktop and __mobile will render, determined by CSS */}
            {!isEditing && (
                <div className={classnames(`${expPageLinks}__desktop`)}>{desktopElements}</div>
            )}

            {!isEditing && (
                <div className={classnames(`${expPageLinks}__mobile`)}>{mobileElements}</div>
            )}

            {isEditing && (
                <ol className={classnames(`${expPageLinks}__editor`)}>
                    {expertisePageLinksComponents}
                </ol>
            )}
        </div>
    );
};

const ExpertisePageLinksContainerSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields])(ExpertisePageLinksContainer)
);
export const ExpertisePageLinksContainerStorybook = forStorybookV2(
    ExpertisePageLinksContainerSitecore
);
export default withPlaceholder('expertisePageLinksComponents')(ExpertisePageLinksContainerSitecore);
