import React from 'react';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { BasicFeaturedList as FeaturedList } from '../FeaturedList/FeaturedList.jsx';
import FeatureListItem from '../FeaturedListItem/FeaturedListItem.jsx';
import { ContextFeatureListEnum, ContextFeatureListItemEnum } from '../../utils/enums';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';

const ContextFeatureList = ({ fields, sitecoreContext, params, sectionTitle }) => {
    const { TemplateFieldName, FieldTitle, FieldParagraph, LinkText } = fields;

    const contextFields = sitecoreContext?.route?.fields;
    const hasFieldTitle = FieldTitle?.value !== ContextFeatureListItemEnum.none;
    const hasFieldParagraph = FieldParagraph?.value !== ContextFeatureListItemEnum.none;
    const dataField = TemplateFieldName?.value || ContextFeatureListEnum.relatedServices;

    // Context field to use Item as datasource
    const titleFieldName = FieldTitle?.value || ContextFeatureListItemEnum.name;

    // Item datasource fields
    const paragraphFieldName = FieldParagraph?.value || ContextFeatureListItemEnum.description;
    const FeatureListItems = Array.isArray(contextFields[dataField])
        ? contextFields[dataField]
        : [];

    return (
        <>
            {sitecoreContext?.pageEditing && (
                <>
                    <h6 className="zn-page-editing-title">
                        **This component is using page field {dataField} as datasource**
                    </h6>
                    <h6 className="zn-page-editing-title">**Section Title placeholder**</h6>
                </>
            )}
            {(sitecoreContext?.pageEditing || FeatureListItems.length > 0) && (
                <>
                    <section>{sectionTitle}</section>
                    <FeaturedList fields={fields} params={params}>
                        {FeatureListItems.map((item, index) => {
                            const name = hasFieldTitle
                                ? item?.fields[titleFieldName] || {
                                      value: item?.name,
                                  }
                                : null;
                            const paragraphText = hasFieldParagraph
                                ? item?.fields[paragraphFieldName]
                                : null;
                            if (name && item?.url && item?.fields) {
                                return (
                                    <FeatureListItem
                                        params={params}
                                        key={`item-${index}`}
                                        fields={{
                                            ...fields,
                                            Title: name,
                                            ParagraphText: paragraphText,
                                            LinkURL: {
                                                value: {
                                                    title: name,
                                                    text: LinkText?.value || '',
                                                    href: item?.url,
                                                },
                                            },
                                        }}
                                    />
                                );
                            }
                        })}
                    </FeaturedList>
                </>
            )}
        </>
    );
};

export const ContextFeatureListSitecore = withSitecoreContext()(
    withPlaceholder('sectionTitle')(
        withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
            ContextFeatureList
        )
    )
);
export const ContextFeatureListStorybook = forStorybook(ContextFeatureListSitecore);
export default ContextFeatureListSitecore;
