import React, { useEffect } from 'react';
import classnames from 'classnames';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './section-heading.scss';
import { OverrideAlignmentEnum, SectionHeadingSizeEnum, ThemeEnum } from '../../utils/enums';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import HeadingAccent from '../HeadingAccent';
import { setAnimationInterObserver } from '../../utils/observers.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import paramToBool from '../../utils/paramToBool';
import usePdfView from '../../utils/hooks/usePdfView.jsx';

const SectionHeading = ({ fields, params, sitecoreContext }) => {
    const pdfView = usePdfView();

    const {
        Theme,
        SectionHeadingSize,
        HeadingLevel,
        AccentColor,
        OverrideAlignment,
        PaddingBottom,
        PaddingTop,
        EnableAnimation,
        isPeripheralContent,
    } = params;

    const { TextInput } = fields;
    const sectionHeadingBlock = 'zn-section-heading';

    let TagName = HeadingLevel ? HeadingLevel?.toLowerCase() : 'span';

    const isEditing = sitecoreContext?.pageEditing;
    const isAnimated = !pdfView && EnableAnimation && !isEditing;

    useEffect(() => {
        if (isAnimated) {
            const selector = `.${sectionHeadingBlock}`;
            const newClass = `${sectionHeadingBlock}__animation-start`;
            // Adds the class that activates the animation whenever the components are in the view
            setAnimationInterObserver(selector, newClass);
        }
    }, []);

    return (
        (TextInput?.value || isEditing) && (
            <div
                data-is-peripheral={paramToBool(isPeripheralContent) ? 'true' : undefined}
                className={classnames(`${sectionHeadingBlock}`, {
                    [`${sectionHeadingBlock}--dark`]: Theme === ThemeEnum.dark,
                    [`${sectionHeadingBlock}--${OverrideAlignment?.toLowerCase()}`]:
                        OverrideAlignment !== OverrideAlignmentEnum.none && OverrideAlignment,
                    [`${sectionHeadingBlock}--pd-${PaddingBottom?.toLowerCase()}`]: PaddingBottom,
                    [`${sectionHeadingBlock}--pt-${PaddingTop?.toLowerCase()}`]: PaddingTop,
                })}
            >
                <TagName
                    className={classnames(`${sectionHeadingBlock}__text`, {
                        [`${sectionHeadingBlock}__text--medium`]:
                            SectionHeadingSize === SectionHeadingSizeEnum.medium,
                        [`${sectionHeadingBlock}__text--eyebrow`]:
                            SectionHeadingSize === SectionHeadingSizeEnum.eyebrow,
                        [`${sectionHeadingBlock}__text--eyebrow--light`]:
                            SectionHeadingSize === SectionHeadingSizeEnum.eyebrow &&
                            Theme === ThemeEnum.light,
                    })}
                >
                    <RichText
                        field={TextInput}
                        className={classnames({
                            [`${sectionHeadingBlock}__text-animation`]: isAnimated,
                        })}
                    />
                </TagName>
                {SectionHeadingSize !== SectionHeadingSizeEnum.eyebrow && (
                    <HeadingAccent
                        params={{
                            SectionHeadingSize,
                            AccentColor,
                            EnableAnimation: isAnimated,
                        }}
                    />
                )}
            </div>
        )
    );
};

const SectionHeadingSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
        SectionHeading
    )
);

export const SectionHeadingStorybook = forStorybook(SectionHeadingSitecore);

export default SectionHeadingSitecore;
