/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import { relatedFeaturedPagesCondition, workItemTemplate } from '../../utils/constants.js';
import { fetchResults } from '../../actions';
import FeaturedList from '../FeaturedList/FeaturedList.jsx';
import FeaturedListItem from '../FeaturedListItem/FeaturedListItem.jsx';
import { withTranslation } from 'react-i18next';
import {
    FeaturedListLayoutEnum,
    LinkTargetValueEnum,
    HighlightColorItemEnum,
} from '../../utils/enums';
import './expertise-featured-work-list.scss';
import FeaturedWorksPdfList from './FeaturedWorksPdfList.jsx';
import usePdfView from '../../utils/hooks/usePdfView.jsx';
const expertiseFeaturedWorkListBlock = 'zn-expertise-featured-work-list';

/**
 * Expertise Featured Work List with GraphQL CustomSearch Query
 *
 * Once this component is authored on a Expertise page the featured work items are displayed
 *
 * The following steps are taken to display featured work items associated to the Expertise page.
 *
 * 1. Retrieve itemId from the page context
 * 2. Construct graphql custom search query conditions for featured work results:
      (_templatename = 'Work Page' AND RelatedFeaturedExpertise = 'Page Item Id')
 * 3. Fetch and set FeaturedItemsCount number of featured work results
 *
 */
const ExpertiseFeaturedWorkList = ({ sitecoreContext, fields, t }) => {
    const { ButtonText, SearchResultsPage, FeaturedItemsCount } = fields;
    // 1. Page Id
    const pageId = sitecoreContext?.route?.itemId;
    const serviceName = sitecoreContext?.route?.fields?.ServiceName?.value || '';
    const encodedServiceName = encodeURIComponent(serviceName);
    const isEditing = sitecoreContext?.pageEditing;
    const ButtonTag = isEditing ? 'div' : 'a';
    const [featuredResults, setFeaturedResults] = useState([]);
    const pdfView = usePdfView();
    // 2. GraphQL condition construction
    const conditions = [
        {
            name: relatedFeaturedPagesCondition,
            value: pageId.replace(/-/g, ''),
        },
        workItemTemplate,
    ];
    const featuredItemsCount = FeaturedItemsCount?.value
        ? parseInt(FeaturedItemsCount?.value, 10)
        : 4;

    const performSearch = (event) => {
        event.preventDefault();
        window.location.href = `${
            SearchResultsPage?.url.split('?')[0]
        }?contenttype=Work Page&tags=${encodedServiceName}`;
    };

    useEffect(() => {
        //3. Fetch results
        fetchResults(conditions, [], featuredItemsCount, '0', 'teaserdate', true, [''], '', true)
            .then((x) => {
                setFeaturedResults(x.results);
            })
            .catch(() => {});
    }, []);

    if (pdfView)
        return (
            <FeaturedWorksPdfList
                featuredItemsCount={featuredItemsCount}
                featuredResults={featuredResults}
            />
        );

    return (
        <div className={`${expertiseFeaturedWorkListBlock}`}>
            {(featuredResults.length > 0 || isEditing) && (
                <FeaturedList
                    params={{ Layout: FeaturedListLayoutEnum.twoColumn, EnableAnimation: '0' }}
                >
                    {(featuredResults || []).map((result, index) => {
                        if (result && index < featuredItemsCount) {
                            let featuredTeaserTitle = result?.item?.featuredTeaserTitle?.value;
                            let teaserTitle = result?.item?.teaserTitle?.value;
                            let displayName = result?.item?.displayName;
                            let featuredTeaserDescription =
                                result?.item?.featuredTeaserDescription?.value;
                            let teaserDescription = result?.item?.teaserDescription?.value;
                            return (
                                <>
                                    <FeaturedListItem
                                        key={`degree-${index}`}
                                        params={{
                                            Highlights: HighlightColorItemEnum.teal,
                                            DividerLines: '0',
                                            AddTitleArrows: '0',
                                            EnableAnimation: true,
                                        }}
                                        fields={{
                                            Title: featuredTeaserTitle
                                                ? {
                                                      value: featuredTeaserTitle,
                                                  }
                                                : teaserTitle
                                                ? {
                                                      value: teaserTitle,
                                                  }
                                                : {
                                                      value: displayName,
                                                  },
                                            ParagraphText: featuredTeaserDescription
                                                ? {
                                                      value: featuredTeaserDescription,
                                                  }
                                                : teaserDescription
                                                ? {
                                                      value: teaserDescription,
                                                  }
                                                : {},
                                            LinkURL:
                                                result?.item?.notNavigatable?.value === '1'
                                                    ? {
                                                          value: {},
                                                      }
                                                    : {
                                                          value: {
                                                              href: result?.item.url,
                                                              text: t('ReadMore'),
                                                              title: result?.name,
                                                              target: LinkTargetValueEnum.self,
                                                          },
                                                      },
                                        }}
                                    ></FeaturedListItem>
                                </>
                            );
                        }
                    })}
                </FeaturedList>
            )}
            {((SearchResultsPage?.url && ButtonText?.value && featuredResults.length > 0) ||
                isEditing) && (
                <div className={`${expertiseFeaturedWorkListBlock}__button-container`}>
                    <ButtonTag
                        onClick={isEditing ? undefined : performSearch}
                        target={LinkTargetValueEnum.self}
                        className={classnames(
                            `zn-button`,
                            `zn-button--secondary`,
                            `zn-button--light`,
                            `zn-button--secondary--light`
                        )}
                        alt={ButtonText?.value}
                    >
                        <Text field={ButtonText} />
                    </ButtonTag>
                </div>
            )}
        </div>
    );
};

const ExpertiseFeaturedWorkListSitecore = withSitecoreContext()(ExpertiseFeaturedWorkList);
export default withTranslation()(ExpertiseFeaturedWorkListSitecore);
export const ExpertiseFeaturedWorkListStorybook = forStorybook(ExpertiseFeaturedWorkListSitecore);
