import React, { useState, useEffect } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import classnames from 'classnames';
import { ThemeEnum } from '../../utils/enums';
import './video.scss';

const Video = ({ fields, params }) => {
    const { VideoURL, Caption, AltText } = fields;
    const { Theme } = params;
    const [playerURL, setPlayerURL] = useState('');
    const oEmbedURL = 'https://vimeo.com/api/oembed.json?url=';
    const videoBlock = 'zn-video';

    useEffect(() => {
        fetch(oEmbedURL + VideoURL?.value)
            .then((data) => data.json())
            .then((res) => {
                setPlayerURL('https://player.vimeo.com/video/' + res?.video_id);
            })
            .catch(() => setPlayerURL(''));
    }, [VideoURL]);

    return (
        <figure
            className={classnames(`${videoBlock}`, {
                [`${videoBlock}--dark`]: Theme === ThemeEnum.dark,
            })}
        >
            <div className="zn-video__media">
                <iframe
                    title={AltText?.value}
                    src={playerURL}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
            <figcaption className="zn-video__caption">
                <RichText field={Caption} />
            </figcaption>
        </figure>
    );
};

export default Video;
