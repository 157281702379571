import React, { useState, useRef, useEffect } from 'react';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import { SearchBarIcon } from '../../assets/common.js';
import { withTranslation } from 'react-i18next';
import { Button } from '../Button/Button.js';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { parsePageTitle } from '../../utils/pageTitleGenerator.js';
import AutofillSearchResult from '../AutofillSearchResult/AutofillSearchResult.jsx';
import { scoreSortBy } from '../../utils/constants.js';
import { fetchResults, fetchTagIds } from '../../actions';
import { SearchResultTypeEnum } from '../../utils/enums.js';
import '../SearchBar/search-bar.scss';
import { formatSearchParams, mapDisplayTypeToTemplate } from '../../utils/searchHelpers.jsx';
import {
    templateNameCondition,
    tagNameCondition,
    tagIdentifierCondition,
} from '../../utils/constants';
import classNames from 'classnames';

const generalLandingSeachBarBlock = 'zn-search-bar';

const GeneralLandingSearchBar = ({ t, sitecoreContext, fields }) => {
    const { SearchResultsPage, ContentTypes, Tags, SearchPromptOverride } = fields;
    const pageName = sitecoreContext?.route?.displayName || '';
    const serviceName = sitecoreContext?.route?.fields?.ServiceName?.value || '';
    const encodedServiceName = encodeURIComponent(serviceName);
    const isEditing = sitecoreContext?.pageEditing;
    const [searchParams, setSearchParams] = useState('');
    const formattedPageName = parsePageTitle(pageName);
    const [autofillResults, setAutofillResults] = useState([]);
    const [tagConditions, setTagConditions] = useState([]);
    const [templateConditions, setTemplateConditions] = useState([]);

    const autoloadSuggestionsRef = useRef(null);
    const searchInputRef = useRef(null);

    let templates = ContentTypes ? ContentTypes?.map((item) => item.name).join() : [];
    let templatesArray = ContentTypes ? ContentTypes?.map((item) => item.name) : [];

    let tagsNames = Tags
        ? Tags?.map((tag) =>
              encodeURIComponent(
                  tag?.fields?.Identifier?.value ? tag?.fields?.Identifier?.value : ''
              )
          )
        : [];
    let tagsNamesNonEncoded = Tags
        ? Tags?.map((tag) => (tag?.fields?.Identifier?.value ? tag?.fields?.Identifier?.value : ''))
        : [];
    const handleSearchParams = (e) => {
        setSearchParams(e.target.value);
        autoloadSuggestionsRef.current.style.display = 'block';
    };

    const submitForm = (event) => {
        event.preventDefault();
        let searchCondition = `q=${encodeURIComponent(searchParams)}`;
        if (encodedServiceName && tagsNames?.length > 0)
            searchCondition += `&tags=${encodedServiceName},${tagsNames.join()}`;
        else if (encodedServiceName) searchCondition += `&tags=${encodedServiceName}`;
        else if (tagsNames?.length > 0) searchCondition += `&tags=${tagsNames?.join()}`;

        if (templates) searchCondition += `&contenttype=${templates}`;

        window.location.href = `${SearchResultsPage?.url.split('?')[0]}?${searchCondition}`;
    };

    const performSearch = () => {
        let titleCondition = [
            {
                group: [
                    { name: '_name', value: `${searchParams}`, useor: true },
                    { name: '_displayname', value: `${searchParams}`, useor: true },
                ],
            },
        ];
        let queryConditions = tagConditions.concat(templateConditions, titleCondition);
        let facets = []; //no facetting
        let page = '0';
        let orderBy = 'score'; //order by relevancy
        let sortDescending = true; //sort results in descending order
        let excludeIds = ['']; //no exluding ids
        let keyword = ''; //not matching on any keywords
        let includeBioPageOnlyExclusion = true; //include condition to exclude items that are listed as Bio Page Only
        fetchResults(
            queryConditions,
            facets,
            4, //get max 4 items
            page,
            orderBy,
            sortDescending,
            excludeIds,
            keyword,
            includeBioPageOnlyExclusion
        )
            .then((x) => {
                setAutofillResults(x.results);
            })
            .catch(() => {
                setAutofillResults([]);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchParams !== '') {
                performSearch();
            } else {
                setAutofillResults([]);
            }
        }, 400);
        return () => clearTimeout(timer);
    }, [searchParams]);

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const suggestedResults = document.getElementsByClassName(
                'zn-search-bar__suggested-results'
            )[0];
            const searchInputBar = document.getElementsByClassName('zn-search-bar__form-input')[0];

            let targetElement = e.target;
            do {
                if (targetElement == suggestedResults || targetElement == searchInputBar) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            if (autoloadSuggestionsRef.current)
                autoloadSuggestionsRef.current.style.display = 'none';
        });
    }, []);

    function searchKeyBoard(event) {
        if (event.keyCode == 13) {
            event.target.blur();
        }
    }

    useEffect(() => {
        searchInputRef?.current?.addEventListener('keypress', searchKeyBoard);
        return function cleanup() {
            searchInputRef?.current?.removeEventListener('keypress', searchKeyBoard);
        };
    }, []);

    useEffect(() => {
        const tagIdConditionsArray = tagsNamesNonEncoded
            .concat(serviceName)
            .map((item) => formatSearchParams(tagIdentifierCondition, item));
        fetchTagIds(tagIdConditionsArray?.flat(), [], 100, '0', scoreSortBy, true, [''], '')
            .then((x) => {
                //set the tag and template search conditions for the autofill search results to use
                const tagSearchConditions =
                    x.results.length > 0
                        ? [
                              {
                                  group: x.results.map((item) =>
                                      formatSearchParams(tagNameCondition, item?.id?.toLowerCase())
                                  ),
                              },
                          ]
                        : [];
                setTagConditions(tagSearchConditions);
                const templateTypesConditions =
                    templatesArray?.length > 0
                        ? [
                              {
                                  group: mapDisplayTypeToTemplate(templatesArray).map((item) =>
                                      formatSearchParams(templateNameCondition, item)
                                  ),
                              },
                          ]
                        : [];
                setTemplateConditions(templateTypesConditions);
            })
            .catch(() => {});
    }, []);

    const placeholderText = SearchPromptOverride?.value
        ? SearchPromptOverride?.value
        : `${t('Search')} ${formattedPageName}`;

    return (
        <div className={`${generalLandingSeachBarBlock}`}>
            {(isEditing || SearchResultsPage?.url) && (
                <div className={`${generalLandingSeachBarBlock}__input`}>
                    <form className={`${generalLandingSeachBarBlock}__form`} onSubmit={submitForm}>
                        <input
                            ref={searchInputRef}
                            className={`${generalLandingSeachBarBlock}__form-input`}
                            type="text"
                            placeholder={placeholderText}
                            value={searchParams}
                            onChange={handleSearchParams}
                            autoComplete="off"
                            onFocus={() => {
                                if (autoloadSuggestionsRef.current)
                                    autoloadSuggestionsRef.current.style.display = 'block';
                            }}
                        ></input>
                        <Button
                            className={`${generalLandingSeachBarBlock}__search-button`}
                            aria-label="Submit Search"
                            type="submit"
                            variant="icon"
                        >
                            <SearchBarIcon />
                        </Button>
                    </form>
                    <div
                        className={`${generalLandingSeachBarBlock}__suggested-results-container`}
                        ref={autoloadSuggestionsRef}
                    >
                        {autofillResults?.length > 0 && (
                            <ul
                                className={classNames(
                                    `${generalLandingSeachBarBlock}__suggested-results`,
                                    `${generalLandingSeachBarBlock}__suggested-results--general-landing`
                                )}
                            >
                                {autofillResults.map((result, index) => {
                                    if (result?.item) {
                                        result.fields = {
                                            Title:
                                                result?.templateName ===
                                                SearchResultTypeEnum.biopage
                                                    ? {
                                                          value: `${result?.item?.firstName?.value} ${result?.item?.middleName?.value} ${result?.item?.lastName?.value}`,
                                                      }
                                                    : { value: result?.item?.teaserTitle?.value },
                                            Image:
                                                result?.templateName ===
                                                SearchResultTypeEnum.biopage
                                                    ? { value: result?.item?.profileImage }
                                                    : {},
                                            ResultType: { value: result?.templateName },
                                            LinkUrl:
                                                result?.item?.notNavigatable?.value === '1'
                                                    ? {}
                                                    : { value: result?.item?.url },
                                        };
                                        return (
                                            <li
                                                key={index}
                                                className={`${generalLandingSeachBarBlock}__suggested-results-item`}
                                            >
                                                <AutofillSearchResult
                                                    fields={result.fields}
                                                    searchValue={searchParams}
                                                />
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        )}
                    </div>
                </div>
            )}
            {isEditing && !SearchResultsPage?.url && (
                <div className={`${generalLandingSeachBarBlock}__sc-editing`}>
                    No Search Results page chosen, please select search page to redirect to.
                </div>
            )}
        </div>
    );
};

const GeneralLandingSearchBarWithTranslation = withTranslation()(GeneralLandingSearchBar);

const GeneralLandingSearchBarSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields])(GeneralLandingSearchBarWithTranslation)
);

export const GeneralLandingSearchBarStorybook = forStorybookV2(GeneralLandingSearchBarSitecore);

export default GeneralLandingSearchBarSitecore;
