/* eslint-disable prettier/prettier */
import React from 'react';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';

import classnames from 'classnames';

import './expertise-page-links-item.scss';
import { ButtonVariantEnum, ThemeEnum } from '../../utils/enums.js';

const ExpertisePageLinksItem = ({ fields }) => {

    const expPageLinksItem = 'zn-expertise-page-links-item';

    const {
        LinkURL,
    } = fields;

    return (
        <div className={classnames(
            `${expPageLinksItem}`,
        )}>
            <ButtonLink
                fields={{
                    LinkURL: {
                        value: {
                            text: LinkURL?.value.text,
                            href: LinkURL?.value.href,
                            title: LinkURL?.value.title,
                            target: LinkURL?.value.target,
                        },
                    },
                }}
                params={{
                    Theme: ThemeEnum.light,
                    Variant: ButtonVariantEnum.tertiary,
                }}
            />
        </div>
    );
};

export default ExpertisePageLinksItem;
