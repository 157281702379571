import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import forStorybook from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import { FeaturedListLayoutEnum } from '../../utils/enums.js';

import './featured-list.scss';

const classBase = 'zn-featured-list';
const FeaturedList = ({ featuredList, params, children }) => {
    const { Layout, EnableAnimation } = params;
    return (
        <ul
            className={classnames(classBase, {
                [`${classBase}--two-columns`]: Layout === FeaturedListLayoutEnum.twoColumn,
                [`${classBase}__animate`]: EnableAnimation,
            })}
        >
            {featuredList}
            {children}
        </ul>
    );
};

export const BasicFeaturedList = FeaturedList;
export default withPlaceholder('featuredList')(FeaturedList);
export const FeaturedListStorybook = forStorybook(FeaturedList);
