import React from 'react';
import classnames from 'classnames';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import paramToBool from '../../utils/paramToBool.js';
import './grid.scss';

const Grid = ({ grid, params, style, sitecoreContext }) => {
    const { ReverseColumns } = params;
    //check all column grid children of grid - if their placeholders are empty do NOT show the grid
    let emptyColumns = grid.filter(
        (item) => item?.props?.rendering?.placeholders?.column?.length == 0
    );

    let emptyGrid = emptyColumns?.length == grid?.length; //if the grid only contains empty columns, do not show the component

    return (
        (sitecoreContext?.pageEditing || !emptyGrid) && (
            <section
                style={style}
                className={classnames('zn-grid', {
                    'zn-grid--reverse-columns': paramToBool(ReverseColumns),
                })}
            >
                {grid}
            </section>
        )
    );
};

export const GridPlaceholder = withSitecoreContext()(withPlaceholder('grid')(Grid));
export const GridStorybook = forStorybook(Grid);
export default GridPlaceholder;
