import React from 'react';
import { Link, RichText } from '@sitecore-jss/sitecore-jss-react';

const FooterLinkList = ({ columnTitle, links }) => {
    return (
        <div className="zn-footer__nav-list">
            <RichText className="zn-footer__column-title" field={columnTitle?.jss} />
            <ul>
                {Array.isArray(links?.targetItems) &&
                    links?.targetItems.map((link, index) => (
                        <li className="zn-footer__column-list-item" key={index}>
                            <Link className="zn-footer__column-list-link" field={link?.link?.jss} />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default FooterLinkList;
