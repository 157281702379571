import React, { useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import { SearchBarCalendarEnum, SearchResultTypeEnum } from '../../utils/enums.js';
import { CloseIcon, SearchBarIcon } from '../../assets/common.js';
import { ChevronDown, ChevronUp, ChevronRight, ChevronLeft } from '../../assets/LinkIcons.js';
import { Button } from '../Button/Button.js';
import { withTranslation } from 'react-i18next';
import SearchBarFilter from '../SearchBarFilter/SearchBarFilter.jsx';
import AutofillSearchResult from '../AutofillSearchResult/AutofillSearchResult.jsx';
import MonthPicker from '../MonthPicker/MonthPicker.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import 'url-search-params-polyfill';
import './search-bar.scss';
import '../SearchBarFilter/search-bar-filter.scss';
import {
    bioPageTemplateCondition,
    displayedContentTypes,
    scoreSortBy,
} from '../../utils/constants';
import {
    fetchPeopleFilters,
    fetchTagIds,
    fetchPersonInfo,
    fetchResults,
    fetchResultsTypeahead,
} from '../../actions';
import {
    PeopleFiltersValues,
    mapTemplateTypeToDisplay,
    sortFilterValues,
    mapFacetTypeToDisplay,
} from '../../utils/searchHelpers.jsx';
import {
    tagIdConditions,
    templateTypesConditions,
    tagSearchConditions,
    relatedPeopleConditions,
    decodeURIComponentSafe,
} from '../../utils/searchHelpers.jsx';

const searchBarBlock = 'zn-search-bar';

const flattenFilterOptions = (filter) => {
    let array = [];
    array.push({ title: filter.title?.value, identifier: filter.identifier?.value });
    if (Array.isArray(filter.children)) {
        filter.children.forEach(function (a) {
            array = array.concat(flattenFilterOptions(a));
        });
    }
    return array;
};

const formatFilter = (filter, defaultFilters) => {
    return {
        label: filter[0]?.title,
        identifier: filter[0]?.identifier,
        options: filter.slice(1).map((option) => ({
            label: option?.title,
            identifier: option?.identifier,
            defaultChecked: defaultFilters.includes(option.identifier),
        })),
    };
};

const setTotalSelected = (filter, paramObj) => {
    return filter.options?.filter(
        (option) =>
            option.defaultChecked ||
            paramObj['tags']?.includes(option.identifier) ||
            paramObj[filter.label.toLowerCase()]?.includes(option.identifier)
    )?.length;
};

const updateClearOptions = (args) => {
    let newArray = [];
    const { oldOptions } = args;

    for (let i = 0; i < oldOptions?.length; i++) {
        newArray[i] = { ...oldOptions[i], checked: false };
    }
    return newArray;
};

const updateHasResults = (
    options,
    facettedResults,
    mappedFacetKey,
    personSearchFilters,
    personId
) => {
    let newArray = [];

    let mappedFacetArray =
        mappedFacetKey === '_templatename'
            ? mapFacetTypeToDisplay(facettedResults[mappedFacetKey])
            : facettedResults[mappedFacetKey];
    for (let i = 0; i < options?.length; i++) {
        if (personId && mappedFacetKey === 'tags' && personSearchFilters.length > 0) {
            newArray[i] = {
                ...options[i],
                hasResults:
                    mappedFacetArray?.includes(options[i].identifier) &&
                    personSearchFilters?.includes(options[i].identifier),
            };
        } else {
            newArray[i] = {
                ...options[i],
                hasResults: mappedFacetArray?.includes(options[i].identifier),
            };
        }
    }
    return newArray;
};

const showMobileFilters = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
};

const hideMobileFilters = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

const formatFilterTitle = (filter) => {
    return filter.toLowerCase().replace(/\s/g, '');
};

const SearchBar = ({ t, fields, params, sitecoreContext }) => {
    // Authored Fields
    const { data } = fields;
    const Filters = data?.datasource?.filters?.targetItems || [];
    const DefaultFilters =
        data?.datasource?.defaultFilters?.targetItems?.map((option) => option.identifier.value) ||
        [];
    const SearchPrompt = data?.datasource?.searchPrompt || '';
    const includeContentTypes = data?.datasource?.includeContentTypes?.value || false;
    const defaultContentTypes =
        data?.datasource?.defaultContentTypes?.targetItems?.map((value) => value.name) || [];
    const { PeopleOnlySearch, AddDateFilters } = params;
    const flattenedFilters = Filters.map((filter) =>
        formatFilter(flattenFilterOptions(filter), DefaultFilters)
    );

    // Query Parameters
    const queryParams = new URLSearchParams(useLocation()?.search);
    const SearchParam = queryParams.get('q');
    const personId = queryParams.get('person'); //for people filtering from bio related article list
    const itemID = queryParams.get('sc_itemid'); //Used to allow for editing/preview mode toggle of a Search Results with query params updating
    const language = queryParams.get('sc_lang'); //Include language in query params
    const queryParamsString = useLocation()?.search;
    const pathname = useLocation()?.pathname;
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [searchParams, setSearchParams] = useState(
        SearchParam ? decodeURIComponentSafe(SearchParam) : ''
    );
    const [pageLoadingState, setPageLoadingState] = useState(0);
    const [firstFacetLoad, setFirstFacetLoad] = useState(true);
    const [disabledState, setDisabledState] = useState(false);
    const [searchObject, setSearchObject] = useState({
        personSearchFilters: [],
        allFilters: [],
    });

    const stateItemID = itemID ? itemID : sitecoreContext?.route?.itemId;

    // UI state elements
    const [contextFilterParams, setContextFilterParams] = useState({});
    const [setActiveFilterGroup, setActiveFilterGroupState] = useState(null);
    const [setMobileActive, setMobileActiveState] = useState(false);
    const [setMobilePublishMenuActive, setMobilePublishMenuActiveState] = useState(false);
    const [startMonthYear, setStartMonthYear] = useState({ month: null, year: null });
    const [endMonthYear, setEndMonthYear] = useState({ month: null, year: null });
    const autoloadSuggestionsRef = useRef(null);
    const prevActiveGroup = useRef();
    const prevStartDate = useRef();
    const prevEndDate = useRef();
    const filterList = useRef(null);
    const [pdfView, setPdfView] = useState(false);

    //Autofill results
    const [autofillResults, _setAutofillResults] = useState([]);
    const autofillResultsRef = useRef(autofillResults);
    const searchInputRef = useRef(null);

    const [personFilterName, setPersonFilterName] = useState('');

    //Parse incoming query parameters from url to populate filters on the search page
    let paramObj = decodeURI(useLocation().search)
        .replace('?', '')
        .split('&')
        .map((param) => {
            if (!param?.split('=')) return;
            else return param.split('=');
        })
        .reduce((values, [key, value]) => {
            values[key] = value?.split(',').map((item) => decodeURIComponentSafe(item));
            return values;
        }, {});
    // Format chosen filters for UI filter lists
    const standardFilters = flattenedFilters
        ? flattenedFilters.map((filter) => ({
              label: filter.label,
              parentId: filter.label,
              totalSelected: setTotalSelected(filter, paramObj),
              peopleOnly: false,
              facetValue: 'tags',
              identifier: filter.identifier,
              options: filter.options
                  ? filter.options
                        .map((option, index) => ({
                            id: index,
                            label: option.label,
                            identifier: option.identifier,
                            hasResults: true,
                            checked:
                                option.defaultChecked ||
                                paramObj['tags']?.includes(option.identifier) ||
                                paramObj[filter.label.toLowerCase()]?.includes(option.identifier)
                                    ? true
                                    : false,
                        }))
                        .sort(
                            filter.identifier !== 'Position-Category' ? sortFilterValues : () => {}
                        )
                  : [],
          }))
        : [];
    const contentFilter =
        includeContentTypes && PeopleOnlySearch !== '1'
            ? [
                  {
                      label: t('ContentType'),
                      parentId: t('ContentType'),
                      totalSelected: defaultContentTypes
                          .concat(
                              paramObj['contenttype']?.length
                                  ? mapTemplateTypeToDisplay(paramObj['contenttype'])
                                  : []
                          )
                          .filter((v, i, a) => a.indexOf(v) === i).length,

                      peopleOnly: false,
                      facetValue: '_templatename',
                      options: displayedContentTypes.map((option, index) => ({
                          id: index,
                          label: t(`Filter${option}`),
                          identifier: option,
                          hasResults: true,
                          checked:
                              defaultContentTypes.includes(option) ||
                              paramObj['contenttype']?.includes(option) ||
                              (paramObj['contenttype']?.length
                                  ? mapTemplateTypeToDisplay(paramObj['contenttype'])?.includes(
                                        option
                                    )
                                  : false),
                      })),
                  },
              ]
            : [];

    const onApplyFilters = () => {
        setActiveFilterGroupState(null);
        setMobilePublishMenuActiveState(false);
        setMobileActiveState(false);
        setAutofillResults([]);
    };

    const clearSingularFilter = useCallback((pId) => {
        setContextFilterParams({
            ...contextFilterParams,
            [formatFilterTitle(pId)]: null,
        });
        setSearchObject((current) => ({
            personSearchFilters: current.personSearchFilters,
            allFilters: current.allFilters.map((item) => {
                if (item.parentId === pId) {
                    let updatedOptions = updateClearOptions({ oldOptions: item.options });

                    return { ...item, totalSelected: 0, options: updatedOptions };
                } else return item;
            }),
        }));
        setIsFilterApplied(true);
        setDisabledState(true);
    }, []);

    const clearAllFilters = () => {
        if (!disabledState) {
            setSearchObject((current) => ({
                personSearchFilters: current.personSearchFilters,
                allFilters: current.allFilters.map((item) => {
                    let updatedOptions = updateClearOptions({ oldOptions: item.options });
                    return { ...item, totalSelected: 0, options: updatedOptions };
                }),
            }));

            toggleStartMonthYear(null, null);
            toggleEndMonthYear(null, null);
            setActiveFilterGroupState(null);
            setIsFilterApplied(true);
            setDisabledState(true);
        }
    };

    const updateFacets = (facettedResults) => {
        // Need to cross reference all facet values with the options of all values in the filter dropdowns
        // The faccetted values for the contentType filter are lower case - this will be an if case (checks value)
        // For people filter - check if the label is equal to any of the three values in the dictionary (checks value)
        // All other will be tags - these will be crossreferencing the identifier of the tag

        setSearchObject((current) => ({
            personSearchFilters: current.personSearchFilters,
            allFilters: current.allFilters.map((item) => {
                let mappedFacetKey = item?.facetValue;
                let updatedOptions = updateHasResults(
                    item.options,
                    facettedResults,
                    mappedFacetKey,
                    searchObject.personSearchFilters,
                    personId
                );

                return {
                    ...item,
                    totalSelected: !firstFacetLoad
                        ? changeTotalSelectedForFacets(updatedOptions)
                        : item.totalSelected,
                    options: updatedOptions,
                };
            }),
        }));

        if (!firstFacetLoad) {
            let params = '';
            if (searchParams) params += searchParams ? `q=${encodeURIComponent(searchParams)}` : '';
            if (personId) params += `&person=${personId}`;
            let filteredValues = Object.assign(
                ...searchObject.allFilters.map((filter) => ({
                    [formatFilterTitle(filter.label)]: filterParams(
                        formatFilterTitle(filter.label)
                    ).flat(),
                }))
            );

            for (const [key, value] of Object.entries(filteredValues)) {
                if (filteredValues[key]?.length > 0 && key != formatFilterTitle(t('ContentType'))) {
                    let mappedFacetKey = mapFilterFacetValue(key);
                    let filtersWithResults = value.filter((item) =>
                        facettedResults[mappedFacetKey].includes(decodeURIComponent(item))
                    );
                    params += `&${key}=${filtersWithResults}`;
                } else if (
                    filteredValues[key]?.length > 0 &&
                    key === formatFilterTitle(t('ContentType'))
                ) {
                    params += `&contenttype=${value}`;
                }
            }

            if (sitecoreContext.pageState == 'edit' || sitecoreContext.pageState === 'preview') {
                params += `&sc_itemid=${stateItemID}`;
            }
            if (language) params += `&sc_lang=${language}`;
            //update the query parameters of the url according to selected filters and selected keywords
            window.history.replaceState(
                {
                    state: `${queryParamsString.replace(
                        queryParamsString,
                        `?${params.toString()}`
                    )}`,
                },
                '',
                `${pathname}${queryParamsString.replace(
                    queryParamsString,
                    `?${params.toString()}`
                )}`
            );
        }
        setFirstFacetLoad(false);
        setDisabledState(false);
    };

    const updateCheckedOption = (args) => {
        let newArray = [];
        const { oldOptions, newValue, currentId } = args;

        for (let i = 0; i < oldOptions?.length; i++) {
            if (oldOptions[i].id === currentId) {
                newArray[i] = { ...oldOptions[i], checked: newValue };
            } else {
                newArray[i] = oldOptions[i];
            }
        }
        return newArray;
    };

    const changeTotalSelected = (item, updatedId) => {
        let newCount = item.totalSelected;
        if (item.totalSelected === 0 && !updatedId) {
            return 0;
        } else {
            if (updatedId) {
                newCount++;
            } else {
                newCount--;
            }
            return newCount;
        }
    };

    const changeTotalSelectedForFacets = (updatedOptions) => {
        return updatedOptions.filter((item) => item.hasResults && item.checked).length;
    };

    const checkSingularBox = useCallback((id, pId) => {
        setSearchObject((current) => ({
            personSearchFilters: current.personSearchFilters,
            allFilters: current.allFilters.map((item) => {
                if (item.parentId === pId) {
                    let updatedOptions = [];
                    let updatedId;
                    item.options.map((option) => {
                        if (option.id === id) {
                            updatedId = !option.checked;
                            updatedOptions = updateCheckedOption({
                                oldOptions: item.options,
                                newValue: updatedId,
                                currentId: id,
                            });
                        }
                    });
                    return {
                        ...item,
                        totalSelected: changeTotalSelected(item, updatedId, item.hasResults),
                        options: updatedOptions,
                    };
                } else return item;
            }),
        }));
        setIsFilterApplied(true);
    }, []);

    const toggleActiveFilterGroup = (index) => {
        if (!disabledState) {
            if (prevActiveGroup.current === index) {
                setActiveFilterGroupState(null);
            } else {
                setActiveFilterGroupState(index);
            }
        }
    };

    const setAutofillResults = (results) => {
        autofillResultsRef.current = results;
        _setAutofillResults(results);
    };

    const toggleMobilePublishMenuOpen = (value) => {
        setMobilePublishMenuActiveState(value);
    };

    const toggleStartMonthYear = (startMonth, startYear) => {
        if (
            prevStartDate.current.month === startMonth &&
            prevStartDate.current.year === startYear
        ) {
            setStartMonthYear({ month: null, year: null });
        } else {
            setStartMonthYear({ month: startMonth, year: startYear });
        }
    };

    const toggleEndMonthYear = (endMonth, endYear) => {
        if (prevEndDate.current.month === endMonth && prevEndDate.current.year === endYear) {
            setEndMonthYear({ month: null, year: null });
        } else {
            setEndMonthYear({ month: endMonth, year: endYear });
        }
    };

    const searchConditionsHandler = (
        searchConditions,
        templateTypes,
        peopleConditions,
        targetValue
    ) => {
        let titleCondition = [
            {
                group: [
                    { name: '_name', value: `${searchParams}`, useor: true },
                    { name: '_displayname', value: `${searchParams}`, useor: true },
                ],
            },
        ];
        //if only people search use the typeahead not title condition
        let queryConditions =
            PeopleOnlySearch === '1'
                ? searchConditions.concat(bioPageTemplateCondition)
                : searchConditions.concat(templateTypes, peopleConditions, titleCondition);
        let facets = []; //no facetting
        let page = '0'; // update the page value or reset if new search performed
        let orderBy = 'score'; //order by the selected option
        let sortDescending = true; //sort results in descending order
        let excludeIds = ['']; //no exluding ids
        let keyword = ''; //not matching on any keywords
        let includeBioPageOnlyExclusion = personId ? false : true; //include condition to exclude items that are listed as Bio Page Only
        let typeAhead = `${targetValue}`;
        if (PeopleOnlySearch !== '1')
            fetchResults(
                queryConditions,
                facets,
                4, //get max 4 items
                page,
                orderBy,
                sortDescending,
                excludeIds,
                keyword,
                includeBioPageOnlyExclusion
            )
                .then((x) => {
                    setAutofillResults(x.results);
                })
                .catch(() => {
                    setAutofillResults([]);
                });
        else
            fetchResultsTypeahead(
                queryConditions,
                facets,
                4, //get max 4 items
                page,
                orderBy,
                sortDescending,
                excludeIds,
                keyword,
                typeAhead,
                includeBioPageOnlyExclusion
            )
                .then((x) => {
                    setAutofillResults(x.results);
                })
                .catch(() => {
                    setAutofillResults([]);
                });
    };
    useEffect(() => {
        function unsetActiveFilterGroup(event) {
            if (filterList.current && !filterList.current.contains(event.target)) {
                toggleActiveFilterGroup(null);
            }
        }
        document.addEventListener('mousedown', unsetActiveFilterGroup);
        return () => {
            document.removeEventListener('mousedown', unsetActiveFilterGroup);
            const body = document.body;
            body.style.position = '';
            body.style.top = '';
        };
    }, []);

    const facetSearchHandler = (searchConditions, templateTypes, peopleConditions, facetValues) => {
        let queryConditions =
            PeopleOnlySearch === '1'
                ? searchConditions.concat(bioPageTemplateCondition)
                : searchConditions.concat(templateTypes, peopleConditions);
        let facets = facetValues; //no facetting
        let page = '0'; // update the page value or reset if new search performed
        let orderBy = 'score'; //order by the selected option
        let sortDescending = PeopleOnlySearch === '1' ? false : true; //sort results in descending order
        let excludeIds = ['']; //no exluding ids
        let keyword = ''; //not matching on any keywords
        let includeBioPageOnlyExclusion = personId ? false : true; //include condition to exclude items that are listed as Bio Page Only
        fetchResults(
            queryConditions,
            facets,
            0, //no results needed, only facet values
            page,
            orderBy,
            sortDescending,
            excludeIds,
            keyword,
            includeBioPageOnlyExclusion
        )
            .then((x) => {
                let mappedFacettedResults = Object.assign(
                    ...x.facets.map((facet) => ({
                        [facet?.name]: facet?.values?.map((option) => {
                            if (facet?.name === 'tags') return option?.item?.identifier?.value;
                            else return option?.value;
                        }),
                    }))
                );
                updateFacets(mappedFacettedResults);
            })
            .catch(() => {
                //setFacettedFilters({});
            });
    };

    const handleSearchParams = (e) => {
        setSearchParams(e.target.value);
        if (autoloadSuggestionsRef.current) autoloadSuggestionsRef.current.style.display = 'block';
    };

    // Function to map filter dropdown to associated facet value
    const mapFilterFacetValue = (label) => {
        let mappedFacetValue = '';
        if (label === t('ContentType')) {
            mappedFacetValue = '_templatename';
        } else if (label.toLowerCase() === t('School').toLowerCase().replace(/\s/g, '')) {
            mappedFacetValue = 'education';
        } else if (label.toLowerCase() === t('YearOfCall').toLowerCase().replace(/\s/g, '')) {
            mappedFacetValue = 'yearofcall';
        } else {
            mappedFacetValue = 'tags';
        }
        return mappedFacetValue;
    };

    const filterParams = (label) => {
        const filtered = searchObject.allFilters.reduce(
            (array, filter) => (
                formatFilterTitle(filter.label) === label &&
                    array.push(
                        filter.options
                            .filter(function (option) {
                                return option.checked;
                            })
                            .map(function (option) {
                                return encodeURIComponent(option.identifier);
                            })
                    ),
                array
            ),
            []
        );
        return filtered.flat();
    };

    const submitForm = (event) => {
        event?.preventDefault();
        //TODO: People Landing template
        if (
            sitecoreContext?.route?.templateName === 'Search Results' ||
            sitecoreContext?.route?.templateName === 'People Landing'
        ) {
            //if on Search Results or People Landing page on search submit update query parameters, do not refresh page
            onApplyFilters();
            if (setMobileActive) hideMobileFilters();
            if (searchObject.allFilters.length > 0) {
                setContextFilterParams(
                    Object.assign(
                        ...searchObject.allFilters.map((filter) => ({
                            [formatFilterTitle(filter.label)]: filterParams(
                                formatFilterTitle(filter.label)
                            ).flat(),
                        }))
                    )
                );
            }
            setIsFilterApplied(true);
        }
    };

    const peopleFacetHandler = (filter) => {
        //Map the field name for the facet to the display name to show up on the search bar filter dropdown
        let mappedObject = PeopleFiltersValues.find((item) => item.fieldValue === filter.name);
        //Get the dictionary value for the mapped filter title
        let translatedFilterLabel = t(`${mappedObject.label.replace(/\s/g, '')}`);
        //Get the url formatted title for the mapped filter title
        let urlFormattedFilterTitle = formatFilterTitle(mappedObject.label);

        //return the filter mapped to the filter structure
        return {
            label: translatedFilterLabel,
            parentId: translatedFilterLabel,
            totalSelected: filter.values?.filter((option) =>
                paramObj[urlFormattedFilterTitle]?.includes(option.value)
            )?.length,
            identifier: translatedFilterLabel,
            peopleOnly: true,
            facetValue: mappedObject.fieldValue,
            options: filter.values
                ? filter.values
                      .map((option, index) => ({
                          id: index,
                          label: option.value,
                          identifier: option.value,
                          hasResults: true,
                          checked: paramObj[urlFormattedFilterTitle]?.includes(option.value),
                      }))
                      .sort(sortFilterValues)
                : [],
        };
    };

    useEffect(() => {
        let peopleFilters = [];
        if (PeopleOnlySearch === '1') {
            fetchPeopleFilters([bioPageTemplateCondition], ['education', 'yearofcall'])
                .then((x) => {
                    peopleFilters = x.facets
                        ? x.facets.map((filter) => peopleFacetHandler(filter))
                        : [];
                    //Set the filters state to all tag filters, people specific filters, and content type filter
                    setSearchObject({
                        personSearchFilters: [],
                        allFilters: standardFilters.concat(peopleFilters).concat(contentFilter),
                    });
                })
                .catch(() => {
                    //Set the filters state to all tag filters and content type filter
                    setSearchObject({
                        personSearchFilters: [],
                        allFilters: standardFilters.concat(contentFilter),
                    });
                });
        } else {
            //Set the filters state to all tag filters, content type filter
            if (personId) {
                fetchPersonInfo(
                    [{ name: '_group', value: `${personId}` }], //condition to fetch person
                    [], // no facetting
                    1, // one result
                    '0', //first page
                    'name', //sort
                    true, //sort descending
                    [''], //no excluded ids
                    '', //no keywords
                    false // no exclude bios
                )
                    .then((x) => {
                        let personInfo = x?.results[0];
                        if (personInfo) {
                            setPersonFilterName(
                                `${personInfo?.item?.firstName?.value} ${personInfo?.item?.middleName?.value} ${personInfo?.item?.lastName?.value}`
                            );
                        }
                        setSearchObject({
                            personSearchFilters: personInfo?.item?.searchTags?.targetItems?.map(
                                (item) => item?.identifier?.value
                            ),
                            allFilters: standardFilters.concat(contentFilter),
                        });
                    })
                    .catch(() => {});
            } else {
                setSearchObject({
                    personSearchFilters: [],
                    allFilters: standardFilters.concat(contentFilter),
                });
            }
        }
        document.addEventListener('click', (e) => {
            const suggestedResults = document.getElementsByClassName(
                'zn-search-bar__suggested-results'
            )[0];
            const searchInputBar = document.getElementsByClassName('zn-search-bar__form-input')[0];
            const searchBarFilters = Array.from(
                document.getElementsByClassName('zn-search-bar-filter')
            );

            let targetElement = e.target;
            do {
                if (targetElement == suggestedResults || targetElement == searchInputBar) {
                    if (autofillResultsRef?.current.length > 0) setActiveFilterGroupState(null);
                    return;
                }
                if (searchBarFilters.find((e) => e === targetElement)) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            if (autoloadSuggestionsRef.current)
                autoloadSuggestionsRef.current.style.display = 'none';
            setActiveFilterGroupState(null);
        });
    }, []);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    useEffect(() => {
        if (pageLoadingState === 0) {
            setPageLoadingState(1); //on initial load all Filters have not been set yet
        } else {
            if (searchObject.allFilters.length > 0) {
                //on all subsequent loads we want the context filter params (that go into the url updated based on allFilters)
                if (pageLoadingState === 1) {
                    submitForm();
                    setPageLoadingState(2);
                }
            }
        }
    }, [searchObject]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchParams !== '') {
                let mappedValues = searchObject.allFilters.map((item) => ({
                    [formatFilterTitle(item.label)]: item.options
                        .filter((item) => item.checked)
                        .map((item) => item.identifier),
                }));
                let mappedObject = Object.assign({}, ...mappedValues);
                let tagIdConditionsArray = tagIdConditions(mappedObject); //Returns array with the tag names formatted correctly for graphql search query to retrieve associated tag ids
                let peopleFilterKeys = [
                    t('YearOfCall').toLowerCase().replace(/\s/g, ''),
                    t('School').toLowerCase().replace(/\s/g, ''),
                ];
                fetchTagIds(
                    tagIdConditionsArray?.flat(),
                    [], //no faceting
                    500, //The total amount of items to retrieve TODO UPDATE THIS IN THE OTHER SECTIONS
                    '0', //retreive the first page
                    scoreSortBy, //sort by score
                    true, //sort descending order
                    [''], //no ids to exclude
                    '' //no keyword
                )
                    .then((x) => {
                        searchConditionsHandler(
                            tagSearchConditions(x, mappedObject, peopleFilterKeys, t), //Returns array with the selected tags formatted correctly for graphql search query
                            templateTypesConditions(mappedObject), //Returns array with the selected content types formatted correctly for graphql search query
                            relatedPeopleConditions(personId), //Returns array with the four people relations with the person id defined in the search params
                            searchParams
                        );
                    })
                    .catch(() => {
                        setAutofillResults([]);
                    });
            } else {
                setAutofillResults([]);
            }
        }, 400);
        return () => clearTimeout(timer);
    }, [searchParams]);

    useEffect(() => {
        prevStartDate.current = startMonthYear;
        prevEndDate.current = endMonthYear;
        prevActiveGroup.current = setActiveFilterGroup;
    });

    function searchKeyBoard(event) {
        if (event.keyCode == 13) {
            event.target.blur();
        }
    }

    const setScrollY = () => {
        document.documentElement.style.setProperty('--scroll-y', `${window?.scrollY}px`);
    };

    useEffect(() => {
        searchInputRef?.current?.addEventListener('keypress', searchKeyBoard);
        window.addEventListener('scroll', setScrollY);
        return function cleanup() {
            searchInputRef?.current?.removeEventListener('keypress', searchKeyBoard);
            window.removeEventListener('scroll', setScrollY);
        };
    }, [fields?.data?.contextItem?.id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pageLoadingState !== 0 && isFilterApplied) {
                // on every search update perform facet search to update displayed filters
                let mappedValues = searchObject.allFilters.map((item) => ({
                    [formatFilterTitle(item.label)]: item.options
                        .filter((item) => item.checked)
                        .map((item) => item.identifier),
                }));
                let mappedObject = Object.assign({}, ...mappedValues);
                let tagIdConditionsArray = tagIdConditions(mappedObject); //Returns array with the tag names formatted correctly for graphql search query to retrieve associated tag ids
                let facetValues = ['tags', '_templatename'];
                PeopleOnlySearch === '1' && facetValues.push('education', 'yearofcall');
                let peopleFilterKeys = [
                    t('YearOfCall').toLowerCase().replace(/\s/g, ''),
                    t('School').toLowerCase().replace(/\s/g, ''),
                ];
                fetchTagIds(
                    tagIdConditionsArray?.flat(),
                    [], //facet on tags, template names, people values
                    500, //No search results needed, only facets
                    '0', //retreive the first page
                    scoreSortBy, //sort by score
                    true, //sort descending order
                    [''], //no ids to exclude
                    '' //no keyword
                )
                    .then((x) => {
                        facetSearchHandler(
                            tagSearchConditions(x, mappedObject, peopleFilterKeys, t), //Returns array with the selected tags formatted correctly for graphql search query
                            templateTypesConditions(mappedObject), //Returns array with the selected content types formatted correctly for graphql search query
                            relatedPeopleConditions(personId), //Returns array with the four people relations with the person id defined in the search params
                            facetValues
                        );
                    })
                    .catch(() => {});
                setIsFilterApplied(false);
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [isFilterApplied]); //update every time a filter is applied/search keyword is set and run

    return (
        !pdfView && (
            <div className={classnames(`${searchBarBlock}`)}>
                <div className={classnames(`${searchBarBlock}__input`)}>
                    <form className={classnames(`${searchBarBlock}__form`)} onSubmit={submitForm}>
                        <input
                            ref={searchInputRef}
                            className={classnames(`${searchBarBlock}__form-input`)}
                            type="text"
                            placeholder={SearchPrompt?.value}
                            value={searchParams}
                            name="q"
                            onChange={handleSearchParams}
                            autoComplete="off"
                            onFocus={() => {
                                if (autoloadSuggestionsRef.current)
                                    autoloadSuggestionsRef.current.style.display = 'block';
                            }}
                        ></input>
                        <Button
                            className={classnames(`${searchBarBlock}__search-button`)}
                            aria-label={t('SubmitSearch')}
                            type="submit"
                            variant="icon"
                        >
                            <SearchBarIcon />
                        </Button>
                    </form>
                    <div
                        className={`${searchBarBlock}__suggested-results-container`}
                        ref={autoloadSuggestionsRef}
                    >
                        {autofillResults?.length > 0 && (
                            <ul className={`${searchBarBlock}__suggested-results`}>
                                {autofillResults.map((result, index) => {
                                    if (result?.item) {
                                        let bioTitle = `${result?.item?.prefix?.value} ${result?.item?.firstName?.value} ${result?.item?.middleName?.value} ${result?.item?.lastName?.value}`;
                                        result.fields = {
                                            Title:
                                                result?.templateName ===
                                                SearchResultTypeEnum.biopage
                                                    ? {
                                                          value: `${bioTitle}${
                                                              result?.item?.suffix?.value
                                                                  ? `, ${result?.item?.suffix?.value}`
                                                                  : ''
                                                          }`,
                                                      }
                                                    : { value: result?.item?.teaserTitle?.value },
                                            Image:
                                                result?.templateName ===
                                                SearchResultTypeEnum.biopage
                                                    ? { value: result?.item?.profileImage }
                                                    : {},
                                            ResultType: { value: result?.templateName },
                                            LinkUrl:
                                                result?.item?.notNavigatable?.value === '1'
                                                    ? {}
                                                    : { value: result?.item?.url },
                                        };
                                        return (
                                            <li
                                                key={index}
                                                className={`${searchBarBlock}__suggested-results-item`}
                                            >
                                                <AutofillSearchResult
                                                    fields={result.fields}
                                                    searchValue={searchParams}
                                                />
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        )}
                    </div>
                </div>
                <div className={classnames(`${searchBarBlock}__mobile-filter-buttons`)}>
                    <button
                        className={classnames(`${searchBarBlock}__mobile-filter-button`, {
                            [`${searchBarBlock}__mobile-filter-button--active`]: setMobileActive,
                        })}
                        onClick={() => {
                            setMobileActiveState(!setMobileActive);
                            showMobileFilters();
                        }}
                    >
                        <div className={classnames(`${searchBarBlock}__mobile-filter-pill`)}>
                            {t('Filters')}
                        </div>
                        <div className={classnames(`${searchBarBlock}__chevron`)}>
                            {setMobileActive ? <ChevronUp /> : <ChevronDown />}
                        </div>
                    </button>
                    {personFilterName && (
                        <div
                            className={classnames(
                                `${searchBarBlock}__mobile-filter-button`,
                                `zn-search-bar-filter--people-filter-mb`
                            )}
                        >
                            <div className={classnames(`${searchBarBlock}__mobile-filter-pill`)}>
                                {personFilterName}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={classnames(`${searchBarBlock}__list-wrapper`, {
                        [`${searchBarBlock}__list-wrapper--active`]: setMobileActive,
                    })}
                >
                    {setMobileActive && (
                        <div className={classnames(`${searchBarBlock}__mobile-header`)}>
                            <div
                                className={classnames(`${searchBarBlock}__mobile-header-content`, {
                                    [`${searchBarBlock}__mobile-header-content--menu-open`]:
                                        setActiveFilterGroup !== null || setMobilePublishMenuActive,
                                })}
                            >
                                <div
                                    className={classnames(`${searchBarBlock}__mobile-header-title`)}
                                >
                                    {setActiveFilterGroup === SearchBarCalendarEnum.start ||
                                    setActiveFilterGroup === SearchBarCalendarEnum.end
                                        ? t('SelectADate')
                                        : t('Filter')}
                                </div>
                                <span>
                                    <Button
                                        aria-label={t('CloseNavigation')}
                                        type="button"
                                        variant="icon"
                                        onClick={() => {
                                            setMobileActiveState(false);
                                            hideMobileFilters();
                                        }}
                                    >
                                        <CloseIcon />
                                    </Button>
                                </span>
                            </div>
                            <div
                                className={classnames(`${searchBarBlock}__mobile-header-divider`, {
                                    [`${searchBarBlock}__mobile-header-divider--menu-open`]:
                                        setActiveFilterGroup !== null || setMobilePublishMenuActive,
                                })}
                            ></div>
                        </div>
                    )}
                    <div
                        className={classnames(`${searchBarBlock}__filters`, {
                            [`${searchBarBlock}__filters--active`]: setMobileActive,
                        })}
                    >
                        <ul
                            className={classnames(`${searchBarBlock}__filter-list`, {
                                [`${searchBarBlock}__filter-list--active`]:
                                    setActiveFilterGroup !== null,
                            })}
                            ref={filterList}
                        >
                            {searchObject.allFilters.map(
                                (filter, index) =>
                                    ((PeopleOnlySearch === '1' && filter.peopleOnly) ||
                                        !filter.peopleOnly) &&
                                    filter.options?.length > 0 && (
                                        <SearchBarFilter
                                            key={index}
                                            fields={filter}
                                            activeFilter={setActiveFilterGroup}
                                            index={index}
                                            checkBox={checkSingularBox}
                                            uncheckAllBoxes={clearSingularFilter}
                                            toggleFilter={toggleActiveFilterGroup}
                                            translation={t}
                                            onApplyFilters={submitForm}
                                            disabledState={disabledState}
                                            setDisabledState={setDisabledState}
                                        />
                                    )
                            )}
                            {personFilterName && (
                                <div
                                    className={classnames(
                                        `zn-search-bar-filter`,
                                        `zn-search-bar-filter--people-filter-dt`
                                    )}
                                >
                                    <div
                                        className={classnames(
                                            `zn-search-bar-filter__button`,
                                            `zn-search-bar-filter__button--primary`,
                                            {
                                                [`zn-search-bar-filter__button--disabled`]: disabledState,
                                            }
                                        )}
                                    >
                                        <div
                                            className={classnames(
                                                `zn-search-bar-filter__title`,
                                                `zn-search-bar-filter__title--primary`
                                            )}
                                        >
                                            {personFilterName}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {setMobileActive && AddDateFilters === '1' && (
                                <div
                                    className={classnames(
                                        `${searchBarBlock}__mobile-published-menu`
                                    )}
                                >
                                    <button
                                        className={classnames(
                                            `${searchBarBlock}__mobile-published-menu-button`,
                                            {
                                                [`${searchBarBlock}__mobile-published-menu--active`]: setMobilePublishMenuActive,
                                            }
                                        )}
                                        onClick={() => {
                                            setMobilePublishMenuActiveState(true);
                                            toggleActiveFilterGroup(SearchBarCalendarEnum.publish);
                                        }}
                                    >
                                        <div
                                            className={classnames(
                                                `${searchBarBlock}__mobile-published-menu-title`
                                            )}
                                        >
                                            {t('Published')}
                                        </div>
                                        <div
                                            className={classnames(
                                                `${searchBarBlock}__mobile-published-menu-chevron`
                                            )}
                                        >
                                            <ChevronRight />
                                        </div>
                                    </button>
                                    <div
                                        className={classnames(
                                            `${searchBarBlock}__mobile-published-menu-selected-filters`
                                        )}
                                    >
                                        {startMonthYear.month !== null &&
                                        startMonthYear.year !== null
                                            ? ('0' + (startMonthYear.month + 1)).slice(-2) +
                                              '/' +
                                              startMonthYear.year +
                                              ` - `
                                            : 'MM/YYYY - '}
                                        {endMonthYear.month !== null && endMonthYear.year !== null
                                            ? ('0' + (endMonthYear.month + 1)).slice(-2) +
                                              '/' +
                                              endMonthYear.year
                                            : 'MM/YYYY'}
                                    </div>
                                </div>
                            )}
                            {AddDateFilters === '1' && (
                                <div
                                    className={classnames(
                                        `${searchBarBlock}__published-menu-wrapper`,
                                        {
                                            [`${searchBarBlock}__published-menu-wrapper--active`]: setMobilePublishMenuActive,
                                        }
                                    )}
                                >
                                    <a
                                        className={classnames(
                                            `${searchBarBlock}__published-menu-back-button`
                                        )}
                                        onClick={() => {
                                            toggleActiveFilterGroup(null);
                                            setMobilePublishMenuActiveState(false);
                                        }}
                                    >
                                        <div
                                            className={classnames(
                                                `${searchBarBlock}__published-menu-chevron`
                                            )}
                                        >
                                            <ChevronLeft />
                                        </div>

                                        <div
                                            className={classnames(
                                                `${searchBarBlock}__published-menu-title`
                                            )}
                                        >
                                            {t('PublishDate')}
                                        </div>
                                    </a>
                                    <div
                                        className={classnames(
                                            `${searchBarBlock}__published-menu-months`
                                        )}
                                    >
                                        <div
                                            className={classnames(
                                                `${searchBarBlock}__published-menu-month-title`
                                            )}
                                        >
                                            {t('StartDate')}
                                        </div>
                                        <MonthPicker
                                            monthYear={startMonthYear}
                                            setMonthYear={toggleStartMonthYear}
                                            toggleFilter={toggleActiveFilterGroup}
                                            activeFilter={setActiveFilterGroup}
                                            toggleMobilePublishMenu={toggleMobilePublishMenuOpen}
                                            type={SearchBarCalendarEnum.start}
                                            translation={t}
                                            onApplyFilters={submitForm}
                                        />
                                        <div
                                            className={classnames(
                                                `${searchBarBlock}__published-menu-month-title`
                                            )}
                                        >
                                            {t('EndDate')}
                                        </div>

                                        <MonthPicker
                                            monthYear={endMonthYear}
                                            setMonthYear={toggleEndMonthYear}
                                            toggleFilter={toggleActiveFilterGroup}
                                            activeFilter={setActiveFilterGroup}
                                            toggleMobilePublishMenu={toggleMobilePublishMenuOpen}
                                            type={SearchBarCalendarEnum.end}
                                            translation={t}
                                            onApplyFilters={submitForm}
                                        />
                                    </div>
                                    <div
                                        className={classnames(
                                            `${searchBarBlock}__month-bottom-buttons`
                                        )}
                                    >
                                        <button
                                            className={classnames(
                                                `${searchBarBlock}__clear-button`
                                            )}
                                            onClick={() => {
                                                toggleStartMonthYear(null, null);
                                                toggleEndMonthYear(null, null);
                                            }}
                                        >
                                            {t('ClearFilter')}
                                        </button>
                                        <div
                                            className={classnames(
                                                `${searchBarBlock}__apply-button`
                                            )}
                                        >
                                            <Button onClick={submitForm}>{t('ShowResults')}</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {searchObject.allFilters?.length > 0 && (
                                <button
                                    className={classnames(
                                        `${searchBarBlock}__desktop-clear-button`,
                                        {
                                            [`${searchBarBlock}__desktop-clear-button--disabled`]: disabledState,
                                        }
                                    )}
                                    onClick={() => {
                                        clearAllFilters();
                                    }}
                                >
                                    {t('ClearAll')}
                                </button>
                            )}
                        </ul>
                    </div>
                    {setActiveFilterGroup === null && setMobileActive && (
                        <div className={classnames(`${searchBarBlock}__mobile-footer`)}>
                            {searchObject.allFilters?.length > 0 && (
                                <button
                                    className={classnames(`${searchBarBlock}__mobile-clear-button`)}
                                    onClick={() => {
                                        clearAllFilters();
                                    }}
                                >
                                    {t('ClearAll')}
                                </button>
                            )}
                            <div className={classnames(`${searchBarBlock}__mobile-apply-button`)}>
                                <Button
                                    className="zn-main-nav-bar--hide-mobile"
                                    type="submit"
                                    onClick={submitForm}
                                >
                                    {t('ShowResults')}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

const SearchBarWithTranslation = withTranslation()(SearchBar);

export const SearchBarStorybook = forStorybookV2(SearchBarWithTranslation);

export default withSitecoreContext()(SearchBarWithTranslation);
