import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text';
import { HighlightColorEnum } from '../../utils/enums';
import forStorybook from '../../utils/forStorybook.jsx';
import { ArrowRight } from '../../assets/LinkIcons.js';
import './quarterly-introduction-list-item.scss';

const qlIntroItemBlock = 'zn-quarterly-introduction-list-item';

const QuarterlyIntroductionListItem = ({ fields }) => {
    const { SectionTitle, SectionHighlightColour, SectionID } = fields;
    const highlightColour = SectionHighlightColour?.value
        ? SectionHighlightColour.value.toLowerCase()
        : HighlightColorEnum.teal.toLowerCase();

    return (
        <li className={classnames(qlIntroItemBlock)}>
            <a
                className={classnames(
                    `${qlIntroItemBlock}__link`,
                    `${qlIntroItemBlock}__link--${highlightColour}`
                )}
                href={`#${SectionID?.value}`}
            >
                <Text
                    className={classnames(`${qlIntroItemBlock}__link-text`)}
                    type="h4"
                    useDefaultMargin={false}
                    field={SectionTitle}
                />
                <div className={classnames(`${qlIntroItemBlock}__link-icon`)}>
                    <ArrowRight />
                </div>
            </a>
            <div className={classnames(`${qlIntroItemBlock}__list-accent`)}></div>
        </li>
    );
};

export const QuarterlyIntroductionListItemStorybook = forStorybook(QuarterlyIntroductionListItem);

export default QuarterlyIntroductionListItem;

QuarterlyIntroductionListItem.prototype = {
    fields: PropTypes.shape({
        SectionTitle: PropTypes.shape({
            value: PropTypes.string,
        }).isRequired,
        SectionHighlightColour: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(HighlightColorEnum)),
        }),
        SectionID: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};
