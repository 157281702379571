import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import forStorybook from '../../utils/forStorybook.jsx';
import GeneralHero from '../GeneralHero/GeneralHero.jsx';

const ExpertiseHero = ({ sitecoreContext, fields }) => {
    const { Name, Overview } = sitecoreContext?.route?.fields;
    return (
        <section>
            <GeneralHero
                fields={{
                    ...fields,
                    Title: Name,
                    Description: Overview,
                }}
            />
        </section>
    );
};

const ExpertiseHeroSitecore = withSitecoreContext()(ExpertiseHero);
export default ExpertiseHeroSitecore;
export const ExpertiseHeroStorybook = forStorybook(ExpertiseHeroSitecore);
