/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';
import './quarterly-animation-section.scss';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext, Image as Img } from '@sitecore-jss/sitecore-jss-react';
import getScrollbarWidth from '../../utils/getScrollbarWidth.js';

const qLAnimationSectionBlock = 'zn-quarterly-animation-section';

export const QuarterlyAnimationSection = ({
    fields,
    quarterlyHero,
    quarterlyIntro,
    sitecoreContext,
}) => {
    const { Image } = fields;
    const [qLIntro, setqLIntro] = useState(null);
    const [scrollWidth, setScrollWidth] = useState(0);
    const [touchStart, setStartTouch] = useState(null);

    const quarterlyAnimationClass = 'quarterly-animation';
    const mainContent = document?.querySelector('body');
    const htmlElement = document?.querySelector('html');
    const mainNavBar = document?.querySelector('.zn-main-nav');

    const isEditing = sitecoreContext?.pageEditing;

    const scrollAmountThreshold = 5;
    const downArrowKeyCode = '40';
    const upArrowKeyCode = '38';
    const scrollDelay = 1140;
    const bp_s = 768; //mobile breakpoint

    const scrollBarHandler = () => {
        htmlElement.style.overflow =
            htmlElement?.style && getWindowSize().innerWidth < bp_s ? 'hidden' : '';
    };

    const applyDownwardsScrollAnimation = (qLHero, qLIntro, mainNavBar) => {
        let isNavOpen = document?.querySelector('body').classList.contains('nav-open');
        if (qLHero?.style && qLIntro?.style && !isNavOpen) {
            qLHero?.classList?.remove('scroll-up-animation');
            qLHero?.classList?.add('scroll-down-animation');
            qLIntro?.classList?.remove('scroll-up-animation');
            qLIntro?.classList?.add('scroll-down-animation');
            //remove non scrolling class when animation is completed, delay calculated as
            // hero fade out duration + intro scroll up delay + intro scroll up duration
            setTimeout(function () {
                mainContent?.classList?.remove(quarterlyAnimationClass);
                if (htmlElement?.style) {
                    htmlElement.style.overflow = '';
                }
                if (mainNavBar?.style) {
                    mainNavBar.style.paddingRight = '';
                }
            }, scrollDelay);
        }
    };

    const applyUpwardsScrollAnimation = (qLHero, qLIntro, mainNavBar, scrollWidth) => {
        let isNavOpen = document?.querySelector('body').classList.contains('nav-open');
        if (qLHero?.style && qLIntro?.style && !isNavOpen) {
            mainContent?.classList?.add(quarterlyAnimationClass);
            setTimeout(function () {
                scrollBarHandler();
            }, scrollDelay);
            if (mainNavBar?.style) {
                mainNavBar.style.paddingRight = `${scrollWidth}px`;
            }
            qLHero?.classList?.add('scroll-up-animation');
            qLIntro?.classList?.add('scroll-up-animation');
        }
    };

    const handleScroll = useCallback((event, scrollWidth) => {
        let deltaY = event.deltaY;
        let mainNavBar = document?.querySelector('.zn-main-nav');
        let qLAnimationSection = document?.querySelector('.zn-quarterly-animation-section');
        let qLIntro = document?.querySelector('.zn-quarterly-introduction-animation');
        let qLHero = document?.querySelector('.zn-quarterly-landing-hero-animation');
        let sectionTop = qLAnimationSection?.getBoundingClientRect()?.top;

        if (sectionTop > 0) {
            if (deltaY > scrollAmountThreshold) {
                applyDownwardsScrollAnimation(qLHero, qLIntro, mainNavBar);
            } else if (deltaY < -scrollAmountThreshold) {
                applyUpwardsScrollAnimation(qLHero, qLIntro, mainNavBar, scrollWidth);
            }
        }
    }, []);

    const handleScrollBar = useCallback((event, scrollWidth) => {
        var lastScroll = window?.pageYOffset || document?.documentElement.scrollTop;
        let mainNavBar = document?.querySelector('.zn-main-nav');
        let qLAnimationSection = document?.querySelector('.zn-quarterly-animation-section');
        let qLIntro = document?.querySelector('.zn-quarterly-introduction-animation');
        let qLHero = document?.querySelector('.zn-quarterly-landing-hero-animation');
        let sectionTop = qLAnimationSection?.getBoundingClientRect()?.top;
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

        if (sectionTop > 0) {
            if (currentScroll > 0 && lastScroll <= currentScroll) {
                lastScroll = currentScroll;
                applyDownwardsScrollAnimation(qLHero, qLIntro, mainNavBar);
            } else {
                lastScroll = currentScroll;
                applyUpwardsScrollAnimation(qLHero, qLIntro, mainNavBar, scrollWidth);
            }
        }
    }, []);

    const handleKeyDown = useCallback((event, scrollWidth) => {
        let keyCode = event?.keyCode;
        let mainNavBar = document?.querySelector('.zn-main-nav');
        let qLAnimationSection = document?.querySelector('.zn-quarterly-animation-section');
        let qLIntro = document?.querySelector('.zn-quarterly-introduction-animation');
        let qLHero = document?.querySelector('.zn-quarterly-landing-hero-animation');
        let sectionTop = qLAnimationSection?.getBoundingClientRect()?.top;

        if (sectionTop > 0) {
            if (keyCode == downArrowKeyCode) {
                applyDownwardsScrollAnimation(qLHero, qLIntro, mainNavBar);
            } else if (keyCode == upArrowKeyCode) {
                applyUpwardsScrollAnimation(qLHero, qLIntro, mainNavBar, scrollWidth);
            }
        }
    }, []);

    const handleTouchEnd = useCallback((event, touchStart, scrollWidth) => {
        let end = event.changedTouches[0];
        let mainNavBar = document?.querySelector('.zn-main-nav');
        let qLAnimationSection = document?.querySelector('.zn-quarterly-animation-section');
        let qLIntro = document?.querySelector('.zn-quarterly-introduction-animation');
        let qLHero = document?.querySelector('.zn-quarterly-landing-hero-animation');
        let sectionTop = qLAnimationSection?.getBoundingClientRect()?.top;

        if (sectionTop > 0) {
            if (end?.screenY - touchStart?.screenY < -scrollAmountThreshold) {
                applyDownwardsScrollAnimation(qLHero, qLIntro, mainNavBar);
            } else if (end?.screenY - touchStart?.screenY > scrollAmountThreshold) {
                applyUpwardsScrollAnimation(qLHero, qLIntro, mainNavBar, scrollWidth);
            }
        }
    }, []);

    useEffect(() => {
        setqLIntro(document?.querySelector('.zn-quarterly-introduction-animation'));
        setScrollWidth(getScrollbarWidth());

        function scrollCallback(e) {
            handleScroll(e, scrollWidth);
        }

        function keyDownCallback(e) {
            handleKeyDown(e, scrollWidth);
        }

        function touchStartCallback(e) {
            setStartTouch(e?.changedTouches[0]);
        }

        function touchEndCallback(e) {
            handleTouchEnd(e, touchStart, scrollWidth);
        }
        function scrollBarCallback(e) {
            handleScrollBar(e, scrollWidth);
        }

        if (!isEditing) {
            window?.addEventListener('wheel', scrollCallback);
            window?.addEventListener('keydown', keyDownCallback);
            window.addEventListener('touchstart', touchStartCallback);
            window.addEventListener('touchmove', touchEndCallback);
            window.addEventListener('scroll', scrollBarCallback);
        }

        return () => {
            window?.removeEventListener('wheel', scrollCallback);
            window?.removeEventListener('keydown', keyDownCallback);
            window.removeEventListener('touchstart', touchStartCallback);
            window.removeEventListener('touchmove', touchEndCallback);
            window.removeEventListener('scroll', scrollBarCallback);
        };
    }, [handleScroll, touchStart, scrollWidth]);

    useEffect(() => {
        setScrollWidth(getScrollbarWidth());
        if (!isEditing) {
            mainContent?.classList?.add(quarterlyAnimationClass);
            scrollBarHandler();

            if (mainNavBar?.style) {
                mainNavBar.style.paddingRight = `${getScrollbarWidth()}px`;
            }
        }
        return () => {
            mainContent?.classList?.remove(quarterlyAnimationClass);
            if (htmlElement?.style) {
                htmlElement.style.overflow = '';
            }
            if (mainNavBar?.style) {
                mainNavBar.style.paddingRight = ``;
            }
        };
    }, [fields?.data?.contextItem?.id]);

    useEffect(() => {
        function handleWindowResize() {
            scrollBarHandler();
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return (
        <div
            className={classnames(qLAnimationSectionBlock, {
                [`${qLAnimationSectionBlock}--editing`]: isEditing,
            })}
        >
            <div
                className={classnames(`${qLAnimationSectionBlock}__background-image`, {
                    [`${qLAnimationSectionBlock}__background-image--editing`]: isEditing,
                })}
                style={
                    isEditing
                        ? {
                              height: `${Image?.value?.height}px`,
                          }
                        : { backgroundImage: `url(${Image?.value?.src})` }
                }
            >
                {isEditing && <Img field={Image} />}
            </div>
            {quarterlyHero}
            {quarterlyIntro}
        </div>
    );
};

export default withSitecoreContext()(
    withPlaceholder(['quarterlyHero', 'quarterlyIntro'])(QuarterlyAnimationSection)
);
