import React, { useState } from 'react';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Button } from '../Button/Button.js';
import { ChevronLeftCirlcle, ChevronRightCirlcle, CirlcleSmall } from '../../assets/LinkIcons.js';
import classnames from 'classnames';

import './carousel.scss';

const MAX_PAGES = 5;
const MIDDLE_PAGINATION = 3;

export const Carousel = ({ sitecoreContext, carousel }) => {
    const [activeSlide, setActiveSlide] = useState(1);

    const createPagination = (pages, activePage) => {
        let pagination = [];
        for (let index = 1; index < pages + 1; index++) {
            const isActive = activePage === index;
            pagination.push(<CirlcleSmall inactive={!isActive} />);
        }
        return pagination;
    };

    const getPagination = () => {
        const isLarge = carousel.length > MAX_PAGES;
        const pages = isLarge ? MAX_PAGES : carousel.length;
        if (isLarge) {
            let active = 1;
            if (activeSlide < MIDDLE_PAGINATION) {
                active = activeSlide;
            } else if (activeSlide <= carousel.length - MIDDLE_PAGINATION) {
                active = MIDDLE_PAGINATION;
            } else {
                active = MAX_PAGES - (carousel.length - activeSlide);
            }
            return createPagination(pages, active);
        } else {
            return createPagination(pages, activeSlide);
        }
    };

    return (
        <React.Fragment>
            {!sitecoreContext.pageEditing ? (
                <section className="zn-carousel" aria-label="carousel of images">
                    <Button
                        aria-label="carousel previous button"
                        disabled={activeSlide === 1}
                        type="button"
                        as="button"
                        variant="icon"
                        className="zn-carousel__chevron zn-carousel__chevron--left"
                        onClick={() => {
                            const prev = activeSlide - 1;
                            if (activeSlide > 1) {
                                setActiveSlide(prev);
                            }
                        }}
                    >
                        <ChevronLeftCirlcle inactive={activeSlide === 1} />
                    </Button>
                    {activeSlide && (
                        <React.Fragment>
                            <ul className="zn-carousel__content">
                                {carousel.map((component, index) => {
                                    const active = activeSlide === index + 1;
                                    // this if is important, as it prevents breakage when using Sitecore Experience Editor
                                    if (component.props && component.props.type === 'text/sitecore')
                                        return component;
                                    return (
                                        <li
                                            aria-label="carousel slide"
                                            className={classnames(
                                                'zn-carousel__item',
                                                `${active && 'zn-carousel__item--active'}`
                                            )}
                                            data-slide={index}
                                            key={index}
                                        >
                                            {component}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div
                                arial-label="carousel pagination"
                                className="zn-carousel__pagination"
                            >
                                {getPagination()}
                            </div>
                        </React.Fragment>
                    )}

                    <Button
                        aria-label="carousel-next-button"
                        disabled={activeSlide === carousel.length}
                        type="button"
                        as="button"
                        variant="icon"
                        className="zn-carousel__chevron zn-carousel__chevron--right"
                        onClick={() => {
                            const next = activeSlide + 1;
                            if (next < carousel.length + 1) {
                                setActiveSlide(next);
                            }
                        }}
                    >
                        <ChevronRightCirlcle inactive={activeSlide === carousel.length} />
                    </Button>
                </section>
            ) : (
                <section>
                    <h3>This is the Carousel Editor View</h3>
                    <section role="carousel editor" className="zn-carousel-editor">
                        {carousel}
                    </section>
                </section>
            )}
        </React.Fragment>
    );
};

export default withSitecoreContext()(withPlaceholder('carousel')(Carousel));
