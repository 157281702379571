import React, { useEffect, useState } from 'react';
import { Link, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import FooterLinkList from '../FooterLinkList/FooterLinkList.jsx';
import FooterContactBar from '../FooterContactBar/FooterContactBar.jsx';
import FooterTeaser from '../FooterTeaser/FooterTeaser.jsx';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import withErrorBoundary from '../../utils/withErrorBoundary.jsx';
import { ThemeEnum } from '../../utils/enums';
import './footer.scss';

const Footer = ({ sitecoreContext, fields, params }) => {
    const columns = fields?.data?.datasource?.columns?.targetItems;
    const footerContactBar = fields?.data?.datasource?.footerContactBar?.targetItem;
    const footerTeaser = fields?.data?.datasource?.footerTeaser?.targetItem;
    const pageEditing = sitecoreContext?.pageEditing;
    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    return (
        !pdfView && (
            <footer className="zn-footer">
                <div className="zn-footer__container">
                    <div className="zn-footer__column-wrapper">
                        <div className="zn-footer__logo">
                            <Link field={fields?.data?.datasource?.logoLink?.jss}>
                                <Image field={fields?.data?.datasource?.logo?.jss} />
                            </Link>
                        </div>
                        {Array.isArray(columns) &&
                            columns.map((column, index) => (
                                <FooterLinkList key={index} {...column} />
                            ))}
                        <FooterTeaser
                            pageEditing={pageEditing}
                            title={footerTeaser?.title?.jss}
                            description={footerTeaser?.description?.jss}
                            buttonLink={footerTeaser?.buttonLink?.jss}
                        />
                    </div>
                    <FooterContactBar
                        params={{ ...params, Theme: ThemeEnum.dark }}
                        legalText={footerContactBar?.legalText?.jss}
                        legalLinks={footerContactBar?.legalLinks}
                        socialMediaLinks={footerContactBar?.socialMediaLinks?.targetItem}
                    />
                </div>
            </footer>
        )
    );
};

const FooterSitecore = withSitecoreContext()(withErrorBoundary()(Footer));
export const FooterStorybook = forStorybookV2(FooterSitecore);
export default FooterSitecore;
