import React from 'react';
import { BasicLinkList as LinkList } from '../LinkList/LinkList.jsx';
import LinkListItem from '../LinkListItem/LinkListItem.jsx';
import { withTranslation } from 'react-i18next';
import ArticleHeading from '../ArticleHeading/ArticleHeading.jsx';
import { SizeEnum } from '../../utils/enums';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './bio-education.scss';

const EducationTypeEnum = {
    education: 'Education',
    barJurisdictions: 'BarJurisdictions',
};

const bioEducationBlock = 'zn-bio-education';

const Education = ({ fields, t, params, sitecoreContext, sectionTitle }) => {
    const educationList = Array.isArray(fields?.data?.contextItem?.children)
        ? fields?.data?.contextItem?.children.find(
              (child) => child.name === EducationTypeEnum.education
          )
        : [];
    const barAdmissions = Array.isArray(fields?.data?.contextItem?.children)
        ? fields?.data?.contextItem?.children.find(
              (child) => child.name === EducationTypeEnum.barJurisdictions
          )
        : [];

    const hasEducation =
        Array.isArray(educationList?.children) && educationList?.children?.length > 0;
    const hasBarAdmissions =
        Array.isArray(barAdmissions?.children) && barAdmissions?.children?.length > 0;

    return (
        <section className={`${bioEducationBlock}`}>
            {sitecoreContext?.pageEditing && (
                <>
                    <h6 className="zn-page-editing-title">**Section Title placeholder**</h6>
                </>
            )}
            {(sitecoreContext?.pageEditing || hasEducation || hasBarAdmissions) && (
                <>
                    <section>{sectionTitle}</section>
                </>
            )}
            <section>
                {(hasEducation || sitecoreContext?.pageEditing) && (
                    <>
                        <ArticleHeading
                            params={{ Size: SizeEnum.medium }}
                            fields={{ Text: { value: t('Education') } }}
                        />
                        <LinkList params={params}>
                            {educationList?.children
                                ?.sort((a, b) => {
                                    if (a?.yearOfDegree?.value && b?.yearOfDegree?.value) {
                                        return (
                                            parseInt(b?.yearOfDegree?.value) -
                                            parseInt(a?.yearOfDegree?.value)
                                        );
                                    } else {
                                        if (a?.yearOfDegree?.value) {
                                            return -1;
                                        } else {
                                            return 1;
                                        }
                                    }
                                })
                                .map((education, index) => {
                                    if (education?.degree?.value) {
                                        return (
                                            <>
                                                <LinkListItem
                                                    key={`degree-${index}`}
                                                    params={params}
                                                    fields={{
                                                        LinkName: {
                                                            value: `${education?.degree?.value}${
                                                                education?.yearOfDegree?.value
                                                                    ? `, ${education?.yearOfDegree?.value}`
                                                                    : ''
                                                            }`,
                                                        },
                                                    }}
                                                ></LinkListItem>
                                                <LinkListItem
                                                    key={`school-${index}`}
                                                    params={params}
                                                    fields={{
                                                        LinkName: {
                                                            value: education?.school?.value,
                                                        },
                                                    }}
                                                ></LinkListItem>
                                            </>
                                        );
                                    }
                                })}
                        </LinkList>
                    </>
                )}
                {(hasBarAdmissions || sitecoreContext?.pageEditing) && (
                    <>
                        <ArticleHeading
                            params={{ Size: SizeEnum.medium }}
                            fields={{ Text: { value: t('BarAdmissions') } }}
                        />
                        <LinkList params={params}>
                            {barAdmissions?.children
                                ?.sort((a, b) => {
                                    if (a?.year?.value && b?.year?.value) {
                                        return -(
                                            parseInt(b?.year?.value) - parseInt(a?.year?.value)
                                        );
                                    } else {
                                        if (a?.year?.value) {
                                            return -1;
                                        } else {
                                            return 1;
                                        }
                                    }
                                })
                                .map((barAdmission, index) => {
                                    if (barAdmission?.state?.value) {
                                        return (
                                            <LinkListItem
                                                key={`bar-admission-${index}`}
                                                params={params}
                                                fields={{
                                                    LinkName: {
                                                        value: `${barAdmission?.state?.value}${
                                                            barAdmission?.year?.value
                                                                ? `, ${barAdmission?.year?.value}`
                                                                : ''
                                                        }`,
                                                    },
                                                }}
                                            ></LinkListItem>
                                        );
                                    }
                                })}
                        </LinkList>
                    </>
                )}
            </section>
        </section>
    );
};

export default withTranslation()(
    withSitecoreContext()(
        withPlaceholder('sectionTitle')(
            withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
                Education
            )
        )
    )
);
export const EducationStorybook = forStorybookV2(Education);
