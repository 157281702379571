/* eslint-disable no-unused-vars */
import React from 'react';
import classnames from 'classnames';
import './search-bar-filter.scss';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from '../../assets/LinkIcons.js';
import { Button } from '../Button/Button.js';
import CheckboxWrapper from '../Checkbox/CheckboxWrapper.jsx';
import { PeopleFiltersValues } from '../../utils/searchHelpers.jsx';

const SearchBarFilter = ({
    translation,
    fields,
    index,
    activeFilter,
    toggleFilter,
    checkBox,
    uncheckAllBoxes,
    onApplyFilters,
    disabledState,
    setDisabledState,
}) => {
    const searchBarFilterBlock = 'zn-search-bar-filter';
    const { label, options, totalSelected, parentId } = fields;

    const selectableFilters = options.filter((item) => item.hasResults || item.checked).length;

    return (
        <div
            className={classnames(`${searchBarFilterBlock}`, {
                [`${searchBarFilterBlock}--active`]: index === activeFilter,
            })}
        >
            <button
                className={classnames(`${searchBarFilterBlock}__button`, {
                    [`${searchBarFilterBlock}__button--active`]:
                        index === activeFilter && !disabledState,
                    [`${searchBarFilterBlock}__button--disabled`]: disabledState,
                })}
                onClick={() => {
                    if (!disabledState) toggleFilter(index);
                }}
            >
                <div
                    className={classnames(
                        `${searchBarFilterBlock}__title`,
                        `${searchBarFilterBlock}__title--desktop`
                    )}
                >
                    {totalSelected > 0 ? `${label} (${totalSelected})` : label}
                </div>
                <div
                    className={classnames(
                        `${searchBarFilterBlock}__title`,
                        `${searchBarFilterBlock}__title--mobile`
                    )}
                >
                    {label}
                </div>
                <div className={classnames(`${searchBarFilterBlock}__desktop-chevron`)}>
                    {index === activeFilter ? <ChevronUp /> : <ChevronDown />}
                </div>
                <div className={classnames(`${searchBarFilterBlock}__mobile-chevron`)}>
                    <ChevronRight />
                </div>
            </button>
            <div className={classnames(`${searchBarFilterBlock}__mobile-selected-filters`)}>
                {totalSelected > 0
                    ? totalSelected + ' ' + translation('FiltersSelected')
                    : translation('Any') + ' ' + label}
            </div>
            <div
                className={classnames(`${searchBarFilterBlock}__dropdown`, {
                    [`${searchBarFilterBlock}__dropdown--active`]: index === activeFilter,
                })}
            >
                <div
                    className={classnames(`${searchBarFilterBlock}__desktop-dropdown-title`, {
                        [`${searchBarFilterBlock}__desktop-dropdown-title--disabled`]: disabledState,
                    })}
                >
                    {label}
                </div>

                <a
                    className={classnames(`${searchBarFilterBlock}__mobile-dropdown-back-button`)}
                    onClick={() => {
                        toggleFilter(null);
                    }}
                >
                    <div className={classnames(`${searchBarFilterBlock}__mobile-chevron`)}>
                        <ChevronLeft />
                    </div>

                    <div className={classnames(`${searchBarFilterBlock}__mobile-dropdown-title`)}>
                        {label}
                    </div>
                </a>
                <ul className={classnames(`${searchBarFilterBlock}__dropdown-list`)}>
                    {selectableFilters ? (
                        options.map((option, index) => {
                            if (!option.hasResults && !option.checked) return;
                            return (
                                <CheckboxWrapper
                                    checked={option.checked}
                                    change={checkBox}
                                    key={index}
                                    id={option.id}
                                    pId={parentId}
                                    label={option.label}
                                    disabledState={disabledState}
                                    setDisabledState={setDisabledState}
                                />
                            );
                        })
                    ) : (
                        <li className={`${searchBarFilterBlock}__no-filters`}>
                            <div
                                className={classnames(`${searchBarFilterBlock}__no-filters-title`, {
                                    [`${searchBarFilterBlock}__no-filters-title--disabled`]: disabledState,
                                })}
                            >
                                {translation('NoMatchingFiltersTitle')}
                            </div>
                            <div
                                className={classnames(
                                    `${searchBarFilterBlock}__no-filters-description`,
                                    {
                                        [`${searchBarFilterBlock}__no-filters-description--disabled`]: disabledState,
                                    }
                                )}
                            >
                                {translation('NoMatchingFiltersDescription')}
                            </div>
                        </li>
                    )}
                </ul>
                <div className={classnames(`${searchBarFilterBlock}__dropdown-buttons`)}>
                    <button
                        className={classnames(`${searchBarFilterBlock}__clear-button`, {
                            [`${searchBarFilterBlock}__clear-button--disabled`]: disabledState,
                        })}
                        onClick={() => {
                            uncheckAllBoxes(parentId);
                        }}
                    >
                        {translation('ClearFilter')}
                    </button>
                    <div className={classnames(`${searchBarFilterBlock}__apply-button`)}>
                        <Button className="zn-main-nav-bar--hide-mobile" onClick={onApplyFilters}>
                            {translation('ShowResults')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

SearchBarFilter.propTypes = {};

SearchBarFilter.defaultProps = {};

export default SearchBarFilter;
