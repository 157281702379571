import { createEvent } from 'ics';

export const getCalendarEventURL = (eventData) => {
    /**
     * Returns a calendar download url from event data
     * @param {Object} eventData               Data about the event
     * @param {String} eventData.title         Title of the event
     * @param {String} eventData.startDate     Date and time the event starts
     * @param {String} [eventData.endDate]     Date and time the event ends
     * @param {Object} [eventData.duration]    Event Duration in form {hours: X, minutes: X}
     * @param {String} [eventData.description] Description of the event
     * @param {String} [eventData.location]    Location of the event
     * @param {String} [eventData.url]         URL related to the event
     */

    const { startDate, endDate, duration, title, description, location, url } = eventData;
    let start, end;

    if (startDate) {
        const startDateObject = new Date(Date.parse(startDate));
        const startYear = startDateObject.getFullYear();
        const startMonth = startDateObject.getMonth() + 1;
        const startDay = startDateObject.getDate();
        const startHours = startDateObject.getHours();
        const startMinutes = startDateObject.getMinutes();
        start = [startYear, startMonth, startDay, startHours, startMinutes];
    }

    if (endDate) {
        const endDateObject = new Date(Date.parse(endDate));
        const endYear = endDateObject.getFullYear();
        const endMonth = endDateObject.getMonth() + 1;
        const endDay = endDateObject.getDate();
        const endHours = endDateObject.getHours();
        const endMinutes = endDateObject.getMinutes();
        end = [endYear, endMonth, endDay, endHours, endMinutes];
    }

    const eventJSON = Object.assign(
        {},
        start && { start },
        end && { end },
        duration && { duration },
        title && { title },
        description && { description },
        location && { location },
        url && { url }
    );

    return 'data:text/calendar;charset=utf-8,' + encodeURIComponent(createEvent(eventJSON).value);
};
