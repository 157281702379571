import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import Lottie from 'react-lottie';
import './interactive-map.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import classnames from 'classnames';
import { debounce } from 'throttle-debounce';
import animationData from '../../assets/bubbleLoading';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { fetchMapPoints } from '../../actions';
import { MAP_DEBOUNCE_CONST } from '../../utils/constants';
import { MapExport } from '../../assets/common.js';
import { withTranslation } from 'react-i18next';
import {
    loadMapSource,
    loadMapLayers,
    loadMapLanguage,
    loadMapAccessibilityLayer,
    getSearchRadius,
    easeToMapPoints,
    formatGeoJsonData,
    renderMapPopup,
    exportCanvasBlob,
    loadClusterClickFunctionality,
    filterInBoundMapPoints,
} from './mapUtil.js';

const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const InteractiveMap = ({
    mapStyle,
    fields,
    lng,
    lat,
    zoom,
    mapMarkerData,
    mapTags,
    filterFacets,
    isLoading,
    searchConditions,
    searchInput,
    clearAll,
    setClearAll,
    spriteData,
    maxNumListResults,
    setLng,
    setLat,
    setZoom,
    setMapMarkerData,
    setListData,
    setIsListLoading,
    setIsLoading,
    updateFilterFacets,
    t,
    sitecoreContext,
}) => {
    const {
        DefaultZoomLevel,
        MinZoomLevel,
        MaxZoomLevel,
        MaxClusterZoom,
        MapBoxAPIKey,
        CTAText,
        MapImageExportName,
        Latitude,
        Longitude,
        MapLegend,
    } = fields;

    const mapContainer = useRef(null);
    const map = useRef(null);
    mapboxgl.accessToken = MapBoxAPIKey?.value;

    const [firstPageLoad, setFirstPageLoad] = useState(true);
    const [isClustersOn, setIsClustersOn] = useState(true);
    const [sourceMapLoaded, setSourceMapLoaded] = useState(false);
    const [searchConditionInitial, setSearchConditionInitial] = useState(false);

    const updateMapPoints = useRef(
        debounce(MAP_DEBOUNCE_CONST, (searchConditions, searchInput, searchCondition) => {
            fetchMapPoints(searchConditions, null, filterFacets, null, searchInput)
                .then((x) => {
                    updateFilterFacets(x.facets);
                    setMapMarkerData(() => ({
                        data: x.results,
                        searchCondition: searchCondition,
                    }));
                    setIsLoading(false);
                })
                .catch(() => {});
        })
    );

    const updateListPoints = useRef(
        debounce(MAP_DEBOUNCE_CONST, (lat, lng, searchConditions, searchInput) => {
            const searchRadius = getSearchRadius(map);
            let withinRadiusCondition = {
                lat: lat,
                lng: lng,
                radius: searchRadius,
            };
            fetchMapPoints(
                searchConditions,
                withinRadiusCondition,
                [],
                maxNumListResults,
                searchInput
            )
                .then((x) => {
                    let withinRadiusPoints = x?.results;
                    let mapBounds = map.current.getBounds();
                    let inBoundsPoints = filterInBoundMapPoints(withinRadiusPoints, mapBounds);
                    setListData(inBoundsPoints);
                    setIsListLoading(false);
                })
                .catch(() => {});
        })
    );

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyle, //get this from the project container
            center: sitecoreContext?.pageEditing
                ? [
                      Longitude?.value ? parseFloat(Longitude?.value) : 0,
                      Latitude?.value ? parseFloat(Latitude?.value) : 0,
                  ]
                : [lng ? lng : 0, lat ? lat : 0],
            zoom: sitecoreContext?.pageEditing ? DefaultZoomLevel?.value : zoom,
            minZoom: MinZoomLevel?.value ? MinZoomLevel?.value : 2,
            maxZoom: MaxZoomLevel?.value ? MaxZoomLevel?.value : 20,
            preserveDrawingBuffer: true,
            locale: {
                'NavigationControl.ZoomIn': `${t('ZoomIn')}`,
                'NavigationControl.ZoomOut': `${t('ZoomOut')}`,
            },
        });

        map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
        map.current.scrollZoom.disable();
    });

    //After the search is complete this function will run to frame the markers on the map.
    //If clear all is selected then we want to frame it to the default map box location.
    useEffect(() => {
        if (!map.current) return;
        if (!sitecoreContext?.pageEditing) {
            let geojson = formatGeoJsonData(mapMarkerData, mapTags);
            map.current.getSource('places')?.setData(geojson); //update data points on source layer
            if (clearAll) {
                map.current.easeTo({
                    center: [
                        Longitude?.value ? parseFloat(Longitude?.value) : 0,
                        Latitude?.value ? parseFloat(Latitude?.value) : 0,
                    ],
                    zoom: DefaultZoomLevel.value,
                });
                setClearAll(false);
            } else if (mapMarkerData?.searchCondition) {
                easeToMapPoints(geojson, map);
            }
            setIsLoading(false);
        }
    }, [mapMarkerData]);

    useEffect(() => {
        if (!sitecoreContext?.pageEditing) {
            if (searchConditions.length) setSearchConditionInitial(true);
            if (searchConditions.length && !firstPageLoad) {
                updateMapPoints.current(searchConditions, searchInput, !firstPageLoad);
                updateListPoints.current(lat, lng, searchConditions, searchInput);
            }
            if (firstPageLoad && searchConditions.length) setFirstPageLoad(false);
        }
    }, [searchConditions]);

    useEffect(() => {
        //initial population of list and map points when map is fully loaded, and initial search conditions set
        if (sourceMapLoaded && searchConditionInitial) {
            updateMapPoints.current(searchConditions, searchInput, false);
            updateListPoints.current(lat, lng, searchConditions, searchInput);
            map.current.scrollZoom.enable();
        }
    }, [sourceMapLoaded, searchConditionInitial]);

    useEffect(() => {
        map.current.on('moveend', () => {
            updateListPoints.current(lat, lng, searchConditions, searchInput, true);
        });
    }, [searchConditions, lat, lng, zoom]);

    useEffect(() => {
        if (!sitecoreContext?.pageEditing) {
            if (!Object.keys(spriteData).length) return;
            map.current.on('click', 'place', (marker) => {
                let mapPopup = renderMapPopup(marker, spriteData, CTAText);

                new mapboxgl.Popup({ closeOnMove: true })
                    .setLngLat(mapPopup.coordinates)
                    .setDOMContent(mapPopup.mapCardNode)
                    .addTo(map.current);
            });
        }
    }, [spriteData]);

    useEffect(() => {
        if (sourceMapLoaded) {
            updateMapPoints.current(searchConditions, searchInput, false);
            updateListPoints.current(lat, lng, searchConditions, searchInput);
        }
    }, [sourceMapLoaded]);

    useEffect(() => {
        if (!sitecoreContext?.pageEditing) {
            if (!map.current) return; // wait for map to initialize

            map.current.on('load', () => {
                loadMapSource(map, isClustersOn, MaxClusterZoom);
                loadMapLayers(map);
                loadMapAccessibilityLayer(map);

                map.current.on('mouseenter', 'place', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'place', () => {
                    map.current.getCanvas().style.cursor = '';
                });
                map.current.on('mouseenter', 'clusters', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'clusters', () => {
                    map.current.getCanvas().style.cursor = '';
                });
            });

            map.current.on('move', () => {
                setLng(map.current.getCenter().lng.toFixed(6));
                setLat(map.current.getCenter().lat.toFixed(6));
                setZoom(map.current.getZoom().toFixed(2));
                setIsListLoading(true);
            });

            map.current.on('click', 'clusters', (e) => {
                loadClusterClickFunctionality(e, map);
            });

            const sourceDataEventHandler = (e) => {
                if (e.dataType === 'source' && e.sourceId === 'places' && e.isSourceLoaded) {
                    setSourceMapLoaded(true); //now safe to add data to map
                }
            };
            map.current.on('sourcedata', sourceDataEventHandler);

            const styleDataEventHandler = (e) => {
                if (e?.dataType === 'style' && map.current.isStyleLoaded()) {
                    loadMapLanguage(sitecoreContext?.language, map);
                }
            };
            map.current.on('styledata', styleDataEventHandler);
        }
    }, []);

    const toggleClusters = () => {
        const style = map.current.getStyle();
        if (style?.sources?.places) style.sources.places.cluster = !isClustersOn;
        map.current.setStyle(style);
        setIsClustersOn(!isClustersOn);
    };

    const exportMap = () => {
        map.current.getCanvas().toBlob((blob) => {
            exportCanvasBlob(blob, MapImageExportName);
        }, 'image/png');
    };

    const openLegend = () => {
        if (MapLegend?.value?.src) window.open(MapLegend?.value?.src);
    };

    return (
        <>
            {isLoading && (
                <>
                    <div className="zn-interactive-map__loading-overlay"></div>
                    <div className={'zn-interactive-map__loading-animation'}>
                        <Lottie options={defaultAnimationOptions} height={120} width={120} />
                    </div>
                </>
            )}
            <div className="zn-interactive-map__map-buttons">
                {zoom < MaxClusterZoom?.value && (
                    <button
                        tabIndex={0}
                        className={classnames(
                            'zn-interactive-map__map-button',
                            'zn-interactive-map__map-button--with-padding'
                        )}
                        onClick={toggleClusters}
                    >
                        <div
                            className={classnames(
                                'zn-interactive-map__button-text',
                                'zn-interactive-map__button-text--toggle'
                            )}
                        >
                            {isClustersOn ? t('ShowPlotPoints') : t('ShowClusters')}
                        </div>
                    </button>
                )}
                <button
                    tabIndex={0}
                    className={classnames('zn-interactive-map__map-button', {
                        'zn-interactive-map__map-button--with-padding': MapLegend?.value?.src,
                    })}
                    onClick={exportMap}
                >
                    <div className="zn-interactive-map__button-text">{t('Download')}</div>
                    <MapExport />
                </button>
                {MapLegend?.value?.src && (
                    <a
                        tabIndex={0}
                        className={'zn-interactive-map__map-button'}
                        onClick={openLegend}
                        href={MapLegend?.value?.src}
                        download={t('MapLegend')}
                    >
                        <div className="zn-interactive-map__button-text">{t('MapLegend')}</div>
                        <MapExport />
                    </a>
                )}
            </div>

            {sitecoreContext?.pageEditing && <div>Project Map Editing View</div>}
            <div ref={mapContainer} className="map-container" />
        </>
    );
};

InteractiveMap.propTypes = {
    fields: PropTypes.shape({}),
};

export const BasicInteractiveMap = InteractiveMap;
const InteractiveMapWithContext = withSitecoreContext()(InteractiveMap);
const InteractiveMapWithTranslation = withTranslation()(InteractiveMapWithContext);
export default withPlaceholder([])(
    withErrorBoundary([ErrorBoundaryConditions.fields])(InteractiveMapWithTranslation)
);
