import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const projectWorkTableRowBlock = 'zn-project-map-work-article-table-row';

const ProjectMapWorkArticleTableDivider = ({ sitecoreContext }) => {
    return !sitecoreContext?.pageEditing ? (
        <>
            <tr className={`${projectWorkTableRowBlock}__divider`}>
                <td className={`${projectWorkTableRowBlock}__divider-border`} colSpan="100%">
                    <div className={`${projectWorkTableRowBlock}__divider-padding-top`}></div>
                </td>
            </tr>
            <tr className={`${projectWorkTableRowBlock}__divider`}>
                <td className={`${projectWorkTableRowBlock}__divider-span`} colSpan="100%">
                    <div className={`${projectWorkTableRowBlock}__divider-padding-bottom`}></div>
                </td>
            </tr>
        </>
    ) : (
        <div>Divider Placeholder</div>
    );
};

export default withSitecoreContext()(ProjectMapWorkArticleTableDivider);
