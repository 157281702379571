import React, { useEffect } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import classnames from 'classnames';
import forStorybook from '../../utils/forStorybook.jsx';
import { ArrowRight } from '../../assets/LinkIcons.js';
import { ThemeEnum, HighlightColorItemEnum, ButtonVariantEnum } from '../../utils/enums.js';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { setAnimationInterObserver } from '../../utils/observers.jsx';
import { Link as RouterLink } from 'react-router-dom';

import './featured-list-item.scss';

const classBase = 'zn-featured-list__item';

const getHighlightValue = (Highlights) => (Highlights ? Highlights.toLowerCase() : '');

const FeaturedListItem = ({ fields, params }) => {
    const { Title, ParagraphText, LinkURL } = fields;

    const { Highlights, DividerLines, AddTitleArrows, EnableAnimation } = params;

    const animationEnabled = EnableAnimation;

    useEffect(() => {
        if (animationEnabled) {
            // Scroll fade up reveal
            const selector = `.${classBase} .fadeIn`;
            const newClass = 'fadeInActive';
            // Adds the class that activates the animation whenever the components are in the view
            // Hightlight gets activated more easily, so threshold was lowered to activate text more easily
            setAnimationInterObserver(selector, newClass, { threshold: 0.15 });

            // Highlight reveal
            const highlightSelector = `.${classBase} .expandDown`;
            const highlightNewClass = 'expandDownActive';
            // Adds the class that activates the animation whenever the components are in the view
            setAnimationInterObserver(highlightSelector, highlightNewClass);
        }
    }, []);

    return (
        <li
            className={classnames(classBase, {
                [`${classBase}--divider`]: DividerLines === '1',
            })}
        >
            <div className={`${classBase}__container`}>
                <div
                    className={classnames({
                        [`${classBase}__highlight ${classBase}__highlight--${getHighlightValue(
                            Highlights
                        )}`]: Highlights && Highlights !== HighlightColorItemEnum.none,
                        expandDown: animationEnabled,
                    })}
                />
                <div className={classnames(`${classBase}__content`, { fadeIn: animationEnabled })}>
                    <div className={classnames(`${classBase}__title-section`)}>
                        {(Title?.value || Title?.editable) &&
                            (AddTitleArrows === '1' ? (
                                LinkURL?.value?.href?.startsWith('/') ? (
                                    <RouterLink
                                        alt={LinkURL?.value?.title}
                                        className={classnames(`${classBase}__title-link`)}
                                        to={LinkURL?.value?.href}
                                        target={LinkURL?.value?.target}
                                        rel="noppener noreferrer"
                                    >
                                        <ArrowRight />
                                        <RichText
                                            className={classnames(
                                                `${classBase}__title`,
                                                `${classBase}__title--icon`
                                            )}
                                            type="h4"
                                            useDefaultMargin={false}
                                            field={Title}
                                        />
                                    </RouterLink>
                                ) : (
                                    <a
                                        alt={LinkURL?.value?.title}
                                        className={classnames(`${classBase}__title-link`)}
                                        href={LinkURL?.value?.href}
                                        target={LinkURL?.value?.target}
                                        rel="noppener noreferrer"
                                    >
                                        <ArrowRight />
                                        <RichText
                                            className={classnames(
                                                `${classBase}__title`,
                                                `${classBase}__title--icon`
                                            )}
                                            type="h4"
                                            useDefaultMargin={false}
                                            field={Title}
                                        />
                                    </a>
                                )
                            ) : (
                                <RichText
                                    type="h4"
                                    useDefaultMargin={false}
                                    className={classnames(`${classBase}__title`)}
                                    field={Title}
                                />
                            ))}
                    </div>
                    {(ParagraphText?.value || ParagraphText?.editable) && (
                        <RichText className={`${classBase}__description`} field={ParagraphText} />
                    )}
                    {LinkURL?.value?.text && LinkURL?.value?.href && (
                        <ButtonLink
                            fields={{
                                LinkURL: {
                                    value: {
                                        text: LinkURL?.value?.text,
                                        href: LinkURL?.value?.href,
                                        title: LinkURL?.value?.title,
                                        target: LinkURL?.value?.target,
                                    },
                                },
                            }}
                            params={{
                                Theme: ThemeEnum.light,
                                Variant: ButtonVariantEnum.tertiary,
                                showInPDF: '0',
                            }}
                        />
                    )}
                </div>
            </div>
        </li>
    );
};

export default withErrorBoundary([ErrorBoundaryConditions.fields])(FeaturedListItem);
export const FeaturedListItemStorybook = forStorybook(FeaturedListItem);
