import React from 'react';
import classnames from 'classnames';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import Text from '../Text/Text.jsx';
import { withTranslation } from 'react-i18next';

const projectWorkTableRowBlock = 'zn-project-map-work-article-table-row';

const ProjectMapWorkArticleTableRow = ({ fields, sitecoreContext, t }) => {
    const { Label, Value, ContextValueField } = fields;

    const contextValue = ContextValueField?.value
        ? ContextValueField?.value.replace(/\s/g, '')
        : '';

    let tableLabel = Label?.value
        ? Label?.value
        : contextValue
        ? sitecoreContext?.route?.fields?.[contextValue]?.value ||
          (Array.isArray(sitecoreContext?.route?.fields?.[contextValue]) &&
              sitecoreContext?.route?.fields?.[contextValue].length)
            ? t(`${contextValue}`)
            : ''
        : '';
    let tableValue = Value?.value
        ? Value?.value
        : sitecoreContext?.route?.fields?.[contextValue]
        ? Array.isArray(sitecoreContext?.route?.fields?.[contextValue]) &&
          sitecoreContext?.route?.fields?.[contextValue].length
            ? sitecoreContext?.route?.fields?.[contextValue]
                  .map((item) =>
                      item?.fields?.Title?.value ? item?.fields?.Title?.value : item?.displayName
                  )
                  .join(', ')
            : sitecoreContext?.route?.fields?.[contextValue]?.value
        : '';

    if (ContextValueField?.value === 'Value') {
        let projectValue = sitecoreContext?.route?.fields['Value']?.value
            ? parseInt(sitecoreContext?.route?.fields?.['Value']?.value.replace(/,/g, ''))
            : '';
        let projectCurrency = sitecoreContext?.route?.fields?.['ValueCurrency']?.value
            ? sitecoreContext?.route?.fields?.['ValueCurrency']?.value
            : '';
        let language = sitecoreContext?.language;
        let localizedLanguage = language == 'en' ? 'en' : language == 'fr-CA' ? 'fr' : 'en'; //english fallback
        let langProjectValue = '';

        if (projectValue && projectCurrency)
            langProjectValue = new Intl.NumberFormat(localizedLanguage, {
                style: 'currency',
                currency: projectCurrency,
                currencyDisplay: 'narrowSymbol',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }).format(projectValue);

        if (langProjectValue) tableValue = langProjectValue + ' ' + projectCurrency;
        else tableValue = '';
    }
    return sitecoreContext?.pageEditing ? (
        <div
            className={classnames(
                `${projectWorkTableRowBlock}__container`,
                `${projectWorkTableRowBlock}__container--editing`
            )}
        >
            <div className={`${projectWorkTableRowBlock}__label`}>
                <Text className={`${projectWorkTableRowBlock}__label`} field={Label} />
            </div>
            <div className={`${projectWorkTableRowBlock}__value`}>
                <Text className={`${projectWorkTableRowBlock}__value`} field={Value} />
            </div>
            <div className={`${projectWorkTableRowBlock}__value`}>
                <Text className={`${projectWorkTableRowBlock}__value`} field={ContextValueField} />
            </div>
        </div>
    ) : tableValue && tableLabel ? (
        <tr>
            <td className={`${projectWorkTableRowBlock}__label`}>{tableLabel}</td>
            <td className={`${projectWorkTableRowBlock}__value`}>{tableValue}</td>
        </tr>
    ) : null;
};

export default withTranslation()(
    withSitecoreContext()(
        withErrorBoundary([ErrorBoundaryConditions.fields])(ProjectMapWorkArticleTableRow)
    )
);
