/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './section.scss';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import {
    BackgroundColorEnum,
    HighlightColorEnum,
    HorizontalAlignmentEnum,
    SizeEnum,
} from '../../utils/enums';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import paramToBool from '../../utils/paramToBool';

const sectionBlock = 'zn-section';

export const Section = ({ params, section, sitecoreContext }) => {
    const {
        BackgroundCustomColor,
        BackgroundColor,
        FullWidth,
        FullHeight,
        Highlight,
        HighlightColor,
        HighlightCustomColor,
        HighlightIndent,
        ID,
        HorizontalAlignment,
        PaddingTop,
        PaddingBottom,
        HideEmpty,
        HideInPdf,
    } = params;

    const sectionStyles = {
        backgroundColor: BackgroundCustomColor,
    };

    const [isHideSection, setIsHideSection] = useState(true);

    const hideSection = !sitecoreContext?.pageEditing && paramToBool(HideEmpty);

    if (hideSection) {
        sectionStyles.display = 'none';
    }
    useEffect(() => {
        setIsHideSection(hideSection);
    }, [sitecoreContext?.itemId]);

    const checkSectionContent = () => {
        const hasMedia = sectionRef?.current?.querySelector('video,img,object');
        const contentSection = sectionRef?.current?.getElementsByClassName('zn-section__content');
        let childList = Array.from(contentSection[0].children); //all children items of the section content
        //get all non peripheral items in the section - if the section contains any, then apply normal hiding logic
        // if section content only contains peripheral components then do not show the section even with text entered
        const nonPeripheralItems = childList.filter((item) => !item.dataset.isPeripheral);

        const hasInnerText = nonPeripheralItems.some((p) => p.innerText !== '');

        const showSection =
            (sitecoreContext?.pageEditing || hasInnerText || hasMedia) &&
            (nonPeripheralItems.length > 0 || sitecoreContext?.pageEditing);

        setIsHideSection(!showSection);

        return showSection;
    };

    const displaySection = () => {
        const navItem = document.querySelector(`#nav-${ID}`);
        if (sectionRef?.current?.style?.removeProperty) {
            navItem?.style?.removeProperty('display');
            sectionRef?.current?.style?.removeProperty('display');
        } else {
            navItem?.style?.removeAttribute('display');
            sectionRef?.current?.style?.removeAttribute('display');
        }
    };

    const sectionRef = useRef(null);

    useEffect(() => {
        if (paramToBool(HideEmpty)) {
            if (checkSectionContent()) {
                displaySection();
            }
            const observer = new MutationObserver(function checkChildren() {
                if (checkSectionContent()) {
                    displaySection();
                    observer.disconnect();
                } else {
                    if (sectionRef?.current?.style) {
                        sectionRef.current.style.display = 'none';
                    }
                }
            });

            observer.observe(sectionRef.current, {
                attributes: true,
                childList: true,
                subtree: true,
            });
        }
    }, [sitecoreContext?.itemId]);

    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    return (
        !(pdfView && paramToBool(HideInPdf)) && (
            <section
                id={ID}
                ref={sectionRef}
                className={classnames(sectionBlock, {
                    [`${sectionBlock}--indent`]:
                        paramToBool(Highlight) && paramToBool(HighlightIndent),
                    [`${sectionBlock}--full-width`]: paramToBool(FullWidth),
                    [`${sectionBlock}--full-height`]: paramToBool(FullHeight),
                    [`${sectionBlock}--padding-top-${PaddingTop?.toLowerCase()}`]: PaddingTop,
                    [`${sectionBlock}--padding-bottom-${PaddingBottom?.toLowerCase()}`]: PaddingBottom,
                    [`${sectionBlock}--${BackgroundColor?.toLowerCase().replace(
                        /\s/g,
                        ''
                    )}`]: BackgroundColor,
                    'show-section': !isHideSection,
                    'hide-section': isHideSection,
                })}
                style={sectionStyles}
            >
                {paramToBool(Highlight) && (
                    <span
                        className={classnames(`${sectionBlock}__highlight`, {
                            [`${sectionBlock}__highlight--${HighlightColor?.toLowerCase().replace(
                                /\s/g,
                                ''
                            )}`]: HighlightColor,
                        })}
                        style={{ backgroundColor: HighlightCustomColor }}
                    />
                )}
                <div
                    className={classnames(`${sectionBlock}__content`, {
                        [`${sectionBlock}__content--full-width`]: paramToBool(FullWidth),
                        [`${sectionBlock}__content--${HorizontalAlignment?.toLowerCase()}`]: HorizontalAlignment,
                    })}
                >
                    {section}
                </div>
            </section>
        )
    );
};

Section.propTypes = {
    params: PropTypes.shape({
        BackgroundColor: PropTypes.oneOf(Object.values(BackgroundColorEnum)),
        BackgroundCustomColor: PropTypes.string,
        FullWidth: PropTypes.bool,
        FullHeight: PropTypes.bool,
        HighlightColor: PropTypes.oneOf(Object.values(HighlightColorEnum)),
        HighlightCustomColor: PropTypes.string,
        Highlight: PropTypes.bool,
        HighlightIndent: PropTypes.bool,
        ID: PropTypes.string,
        HorizontalAlignment: PropTypes.oneOf(Object.values(HorizontalAlignmentEnum)),
        PaddingTop: PropTypes.oneOf(Object.values(SizeEnum)),
        PaddingBottom: PropTypes.oneOf(Object.values(SizeEnum)),
    }),
};

const SectionWithPlaceholder = withSitecoreContext()(withPlaceholder('section')(Section));

export const SectionStorybook = forStorybookV2(Section);

export default SectionWithPlaceholder;
