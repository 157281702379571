import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import Lottie from 'react-lottie';
import animationData from '../../assets/bubbleLoading';
import { SearchBarIcon, CloseIcon, CloseBubbleIcon } from '../../assets/common.js';
import { Button } from '../Button/Button.js';
import repWorkData from '../../utils/mock/repWorkData';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import FeaturedListItem from '../FeaturedListItem/FeaturedListItem.jsx';
import Pagination from '../Pagination/Pagination.jsx';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HighlightColorItemEnum, LinkTargetValueEnum } from '../../utils/enums.js';
import { ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';
import RepresentativeWorkFilter from '../RepresentativeWorkFilter/RepresentativeWorkFilter.jsx';
import '../SearchInput/search-input.scss';
import './representative-work.scss';
import 'url-search-params-polyfill';
import { fetchResults, fetchTagIds } from '../../actions';
import {
    formatSearchParams,
    sortFilterValues,
    decodeURIComponentSafe,
} from '../../utils/searchHelpers.jsx';
import {
    tagNameCondition,
    teaserDateSortBy,
    workItemTemplate,
    subTagNameCondition,
} from '../../utils/constants.js';
import usePdfView from '../../utils/hooks/usePdfView.jsx';

const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const flattenFilterOptions = (filter) => {
    let array = [];
    array.push({ title: filter.title?.value, identifier: filter.identifier?.value, id: filter.id });
    if (Array.isArray(filter.children)) {
        filter.children.forEach(function (a) {
            array = array.concat(flattenFilterOptions(a));
        });
    }
    return array;
};

const formatFilter = (filter, defaultFilters, pageTags) => {
    return {
        label: filter[0]?.title,
        options: filter.slice(1).map((option) => ({
            label: option?.title,
            tagId: option.id,
            identifier: option?.identifier,
            defaultChecked:
                defaultFilters.includes(option.identifier) || pageTags.includes(option.id),
        })),
    };
};

const setTotalSelected = (filter) => {
    return filter.options?.filter((option) => option.defaultChecked).length;
};

const countUpdatedOptions = (options) => {
    return options.filter((option) => option.checked).length;
};

const showMobileFilters = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
};

const hideMobileFilters = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

const formatFilterTitle = (filter) => {
    return filter?.toLowerCase().replace(/\s/g, '');
};

const RepresentativeWork = ({ t, fields, sitecoreContext }) => {
    const representativeWorkBlock = 'zn-representative-work';
    const pageTags = sitecoreContext?.route?.fields?.Tags;
    const serviceName = sitecoreContext?.route?.fields?.ServiceName?.value || '';
    const { data } = fields;
    const Filters = data?.datasource?.filters?.targetItems || [];
    const DefaultFilters =
        data?.datasource?.defaultFilters?.targetItems?.map((option) => option.identifier.value) ||
        [];
    const SearchPrompt = data?.datasource?.searchPrompt?.value || t('Keywords');
    const CTAText = data?.datasource?.cTAText?.value || t('LearnMore');
    const applyDefaultPageTags = data?.datasource?.applyDefaultPageTags?.value || '0';
    const applyRecommendedFilters = data?.datasource?.applyRecommendedFilters?.value || '0';
    const serviceNameOverride = data?.datasource?.serviceNameOverride?.value || '';
    const finalServiceName = serviceNameOverride ? serviceNameOverride : serviceName;

    const pageTagIds =
        applyDefaultPageTags === '1'
            ? pageTags.map((item) => item.id.replace(/-/g, '').toUpperCase())
            : [];

    const flattenedFilters = Filters.length
        ? Filters.map((filter) =>
              formatFilter(flattenFilterOptions(filter), DefaultFilters, pageTagIds)
          )
        : [];

    let incomingSearchParams = decodeURI(useLocation().search)
        .replace('?', '')
        .split('&')
        .map((param) => {
            if (!param?.split('=')) return;
            else return param.split('=');
        })
        .reduce((values, [key, value]) => {
            values[key] = value?.split(',').map((item) => decodeURIComponentSafe(item));
            return values;
        }, {});

    //search variables
    const queryParamsString = useLocation()?.search;
    const pathname = useLocation()?.pathname;
    const incomingSearchParam = incomingSearchParams['q'] || [];
    const incomingPageParam = incomingSearchParams['page'] || [];
    const pdfView = usePdfView();

    const itemsPerPage = pdfView ? 10 : 5;
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [resultCount, setResultCount] = useState();
    const [searchParams, setSearchParams] = useState(
        incomingSearchParam[0] ? incomingSearchParam[0] : ''
    );
    const page = incomingPageParam[0] ? parseInt(incomingPageParam[0], 10) : 1;
    const [pageLoadingState, setPageLoadingState] = useState(0);
    const [pageTagCondition, setPageTagCondition] = useState({});
    const [filtersAppliedObject, setFiltersAppliedObject] = useState({
        page: page,
        isFilterApplied: false,
    });
    const [disabledState, setDisabledState] = useState(false);
    const [allFilters, setAllFilters] = useState([]);

    //UI state variables
    const [setActiveFilterGroup, setActiveFilterGroupState] = useState(null);
    const [setMobileActive, setMobileActiveState] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [firstFacetLoad, setFirstFacetLoad] = useState(true);
    const prevActiveGroup = useRef();
    const repWorkFilterRef = useRef(null);
    const searchInputRef = useRef(null);

    const onApplyFilters = () => {
        setActiveFilterGroupState(null);
        setMobileActiveState(false);
    };

    const updateClearOptions = (args) => {
        let newArray = [];
        const { oldOptions, defaultFilters } = args;

        for (let i = 0; i < oldOptions.length; i++) {
            newArray[i] = {
                ...oldOptions[i],
                checked:
                    pageTagIds.includes(oldOptions[i].tagId) ||
                    defaultFilters.includes(oldOptions[i].label)
                        ? true
                        : false,
            };
        }
        return newArray;
    };

    const clearSingularFilter = React.useCallback((pId) => {
        setAllFilters((current) =>
            current.map((item) => {
                if (item.parentId === pId) {
                    let updatedOptions = updateClearOptions({
                        oldOptions: item.options,
                        defaultFilters: DefaultFilters,
                    });

                    return {
                        ...item,
                        totalSelected: countUpdatedOptions(updatedOptions),
                        options: updatedOptions,
                    };
                } else return item;
            })
        );
        setFiltersAppliedObject(() => ({
            page: 1,
            isFilterApplied: true,
        }));
        setDisabledState(true);
    }, []);

    const clearAllFilters = () => {
        if (!disabledState) {
            setAllFilters((current) =>
                current.map((item) => {
                    let updatedOptions = updateClearOptions({
                        oldOptions: item.options,
                        defaultFilters: DefaultFilters,
                    });
                    return {
                        ...item,
                        totalSelected: countUpdatedOptions(updatedOptions),
                        options: updatedOptions,
                    };
                })
            );
            setActiveFilterGroupState(null);
            setSearchParams('');
            setFiltersAppliedObject(() => ({
                page: 1,
                isFilterApplied: true,
            }));
            setDisabledState(true);
        }
    };

    const updateCheckedOption = (args) => {
        let newArray = [];
        const { oldOptions, newValue, currentId } = args;

        for (let i = 0; i < oldOptions.length; i++) {
            if (oldOptions[i].id === currentId) {
                newArray[i] = { ...oldOptions[i], checked: newValue };
            } else {
                newArray[i] = oldOptions[i];
            }
        }
        return newArray;
    };

    const changeTotalSelected = (item, updatedId) => {
        let newCount = item.totalSelected;
        if (item.totalSelected === 0 && !updatedId) {
            return 0;
        } else {
            if (updatedId) {
                newCount++;
            } else {
                newCount--;
            }
            return newCount;
        }
    };

    const checkSingularBox = React.useCallback((id, pId) => {
        setAllFilters((current) =>
            current.map((item) => {
                if (item.parentId === pId) {
                    let updatedOptions = [];
                    let updatedId;
                    item.options.map((option) => {
                        if (option.id === id) {
                            updatedId = !option.checked;
                            updatedOptions = updateCheckedOption({
                                oldOptions: item.options,
                                newValue: updatedId,
                                currentId: id,
                            });
                        }
                    });
                    return {
                        ...item,
                        totalSelected: changeTotalSelected(item, updatedId),
                        options: updatedOptions,
                    };
                } else return item;
            })
        );
        setFiltersAppliedObject(() => ({
            page: 1,
            isFilterApplied: true,
        }));
        setDisabledState(true);
    }, []);

    const toggleActiveFilterGroup = (index) => {
        if (!disabledState) {
            if (prevActiveGroup.current === index) {
                setActiveFilterGroupState(null);
            } else {
                setActiveFilterGroupState(index);
            }
        }
    };

    useEffect(() => {
        prevActiveGroup.current = setActiveFilterGroup;

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    });

    const handleSearchParams = (e) => {
        setSearchParams(e.target.value);
    };

    const filterParams = (label, byIdentifier) => {
        const filtered = allFilters.reduce(
            (array, filter) => (
                formatFilterTitle(filter.label) === label &&
                    array.push(
                        filter.options
                            .filter(function (option) {
                                return option.checked;
                            })
                            .map(function (option) {
                                return byIdentifier ? option.identifier : option.tagId;
                            })
                    ),
                array
            ),
            []
        );
        return filtered.flat();
    };

    const formatAllFilters = (pageId) => {
        let allFilters = flattenedFilters.length
            ? applyRecommendedFilters === '1'
                ? flattenedFilters //if sub tag filter included do not include the filter dropdown that contains the tag of the expertise page we are on
                      .filter(
                          (filter) => !filter.options.map((option) => option.tagId).includes(pageId)
                      )
                      .map((filter) => ({
                          label: filter.label,
                          parentId: filter.label,
                          totalSelected: setTotalSelected(filter),
                          peopleOnly: false,
                          options: filter.options
                              ? filter.options
                                    .map((option, index) => ({
                                        id: index,
                                        tagId: option.tagId,
                                        label: option.label,
                                        identifier: option.identifier,
                                        hasResults: true,
                                        checked:
                                            option.defaultChecked ||
                                            incomingSearchParams['tags']?.includes(
                                                option.identifier
                                            ),
                                    }))
                                    .sort(sortFilterValues)
                              : [],
                      }))
                : flattenedFilters.map((filter) => ({
                      label: filter.label,
                      parentId: filter.label,
                      totalSelected: setTotalSelected(filter),
                      peopleOnly: false,
                      options: filter.options
                          ? filter.options
                                .map((option, index) => ({
                                    id: index,
                                    tagId: option.tagId,
                                    label: option.label,
                                    identifier: option.identifier,
                                    hasResults: true,
                                    checked: option.defaultChecked ? true : false,
                                }))
                                .sort(sortFilterValues)
                          : [],
                  }))
            : [];
        return allFilters;
    };

    const constructSearchConditions = () => {
        let searchConditions = [];
        if (applyRecommendedFilters === '1' && Object.keys(pageTagCondition).length !== 0)
            searchConditions.push(pageTagCondition); //always apply tag associated to page if sub tag variant selected
        let selectedFilterOptions = Object.assign(
            ...allFilters.map((filter) => ({
                [formatFilterTitle(filter.label)]: filterParams(
                    formatFilterTitle(filter.label)
                ).flat(),
            }))
        );
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(selectedFilterOptions)) {
            if (selectedFilterOptions[key]?.length > 0 && key !== formatFilterTitle(pageTitle)) {
                searchConditions.push({
                    group: selectedFilterOptions[key]?.map((item) =>
                        formatSearchParams(tagNameCondition, item.toLowerCase())
                    ),
                });
            } else if (
                selectedFilterOptions[key]?.length > 0 &&
                key === formatFilterTitle(pageTitle)
            ) {
                //apply the subfilter association and the parent association!
                searchConditions.push({
                    group: selectedFilterOptions[key]?.map((item) =>
                        formatSearchParams(subTagNameCondition, item.toLowerCase())
                    ),
                });
            }
        }
        return searchConditions;
    };

    const submitForm = (event) => {
        event?.preventDefault();
        setFiltersAppliedObject(() => ({
            page: 1,
            isFilterApplied: true,
        }));
    };

    const updateHasResults = (options, facettedResults, mappedFacetKey) => {
        let newArray = [];
        let mappedFacetArray = facettedResults[mappedFacetKey];

        for (let i = 0; i < options?.length; i++) {
            newArray[i] = {
                ...options[i],
                hasResults: mappedFacetArray?.includes(options[i].identifier),
            };
        }
        return newArray;
    };

    const changeTotalSelectedForFacets = (updatedOptions) => {
        return updatedOptions.filter((item) => item.hasResults && item.checked).length;
    };

    const updateFacets = (facettedResults, searchConditions) => {
        setAllFilters((current) =>
            current.map((item) => {
                let mappedFacetKey = item?.isSubfilterGroup ? 'subtags' : 'tags';
                let updatedOptions = updateHasResults(
                    item.options,
                    facettedResults,
                    mappedFacetKey
                );
                return {
                    ...item,
                    totalSelected: changeTotalSelectedForFacets(updatedOptions),
                    options: updatedOptions,
                };
            })
        );

        //Update search params of url with selected filters, page, and tags
        if (!firstFacetLoad) {
            let queryParam = '';
            let keywordParam = 'q=';
            let tagParam = 'tags=';
            let subTagParam = 'subtags=';
            let pageParam = 'page=';

            if (searchParams) keywordParam += `${encodeURIComponent(searchParams)}`;
            if (filtersAppliedObject?.page) pageParam += filtersAppliedObject?.page;
            let selectedFilterOptions = Object.assign(
                ...allFilters.map((filter) => ({
                    [formatFilterTitle(filter.label)]: filterParams(
                        formatFilterTitle(filter.label),
                        true
                    ).flat(),
                }))
            );

            for (const [key, value] of Object.entries(selectedFilterOptions)) {
                if (
                    selectedFilterOptions[key]?.length > 0 &&
                    key !== formatFilterTitle(pageTitle)
                ) {
                    let selectedTags = value.map((filter) => encodeURIComponent(filter));
                    tagParam += tagParam === 'tags=' ? selectedTags : `,${selectedTags}`;
                } else if (
                    selectedFilterOptions[key]?.length > 0 &&
                    key === formatFilterTitle(pageTitle)
                ) {
                    let selectedSubTags = value.map((filter) => encodeURIComponent(filter));
                    subTagParam += selectedSubTags;
                }
            }

            queryParam = [keywordParam, tagParam, subTagParam, pageParam].join('&');

            window.history.replaceState(
                {
                    state: `${queryParamsString.replace(
                        queryParamsString,
                        `?${queryParam.toString()}`
                    )}`,
                },
                '',
                `${pathname}${queryParamsString.replace(
                    queryParamsString,
                    `?${queryParam.toString()}`
                )}`
            );
        }
        setFirstFacetLoad(false);
        fetchResults(
            searchConditions,
            [],
            itemsPerPage,
            ((filtersAppliedObject.page - 1) * itemsPerPage).toString(),
            teaserDateSortBy,
            true,
            [''],
            searchParams,
            true
        )
            .then((x) => {
                setResults(x.results);
                setResultCount(x.totalCount);
                setIsLoading(false);
                setHasError(false);
                setDisabledState(false);
            })
            .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
    };

    useEffect(() => {
        if (pageLoadingState === 0) {
            setPageLoadingState(1); //on initial load all Filters have not been set yet
        } else {
            if (allFilters.length > 0) {
                if (pageLoadingState === 1) {
                    setFiltersAppliedObject((current) => ({
                        page: current.page,
                        isFilterApplied: true,
                    }));
                    setPageLoadingState(2);
                }
            }
        }
    }, [allFilters]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pageLoadingState !== 0 && filtersAppliedObject.isFilterApplied) {
                let searchConditions = constructSearchConditions();
                searchConditions.push(workItemTemplate);
                //run facet call on search conditions
                fetchResults(
                    searchConditions,
                    ['tags', 'subtags'],
                    0,
                    '0',
                    teaserDateSortBy,
                    true,
                    [''],
                    '',
                    true
                )
                    .then((x) => {
                        let mappedFacettedResults = Object.assign(
                            ...x.facets.map((facet) => ({
                                [facet?.name]: facet?.values?.map((option) => {
                                    return option?.item?.identifier?.value;
                                }),
                            }))
                        );
                        updateFacets(mappedFacettedResults, searchConditions);
                    })
                    .catch(() => {});
                setFiltersAppliedObject((current) => ({
                    page: current.page,
                    isFilterApplied: false,
                }));
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [filtersAppliedObject]);

    useEffect(() => {
        let standardFiltersWithoutSubtags = formatAllFilters();
        //perform search query to find the tag that has the identifier equal to the service name of this page
        if (applyRecommendedFilters === '1') {
            fetchTagIds(
                [{ name: 'identifier', value: `${finalServiceName}` }], //fetch the tag data source that has a tag identifier equal to the page service name
                [], //no faceting
                100, //fetch the first item and only item - to account for multiple same name tags
                '0', //fetch the first page
                'score', //fetch by score
                true, // descending order
                [''], //do not exclude any ids
                '' //no keywords
            )
                .then((x) => {
                    //Set the tag condition of the current expertise page to be used whenever a sub filter is applied, if the tag exists
                    let pageTag = x.results.find((item) => item?.identifier === finalServiceName);
                    pageTag?.id &&
                        setPageTagCondition({
                            group: [
                                formatSearchParams(tagNameCondition, pageTag?.id.toLowerCase()),
                            ],
                        });
                    pageTag?.title && setPageTitle(pageTag?.title);
                    //format the filter dropdown for the page's associated subfilters
                    let subFilters = pageTag?.item?.children[0]?.children || [];
                    let pageId = pageTag?.id;
                    let associatedSubFilter =
                        subFilters.length > 0
                            ? [
                                  {
                                      label: pageTag?.title,
                                      parentId: pageTag?.title,
                                      totalSelected: subFilters.filter((item) =>
                                          DefaultFilters.includes(item.identifier?.value)
                                      ).length,
                                      identifier: pageTag?.title,
                                      isSubfilterGroup: true,
                                      options: subFilters
                                          .map((option, index) => ({
                                              id: index,
                                              tagId: option?.id,
                                              label: option?.title?.value,
                                              identifier: option?.identifier?.value,
                                              hasResults: true,
                                              checked:
                                                  DefaultFilters.includes(
                                                      option.identifier?.value
                                                  ) ||
                                                  pageTags.includes(option?.id) ||
                                                  incomingSearchParams['subtags']?.includes(
                                                      option?.identifier?.value
                                                  ),
                                          }))
                                          .sort(sortFilterValues),
                                  },
                              ]
                            : [];
                    let standardFiltersWithSubTags = formatAllFilters(pageId);
                    associatedSubFilter.length > 0
                        ? setAllFilters(associatedSubFilter.concat(standardFiltersWithSubTags))
                        : setAllFilters(standardFiltersWithSubTags);
                })
                .catch(() => {
                    setAllFilters(standardFiltersWithoutSubtags);
                });
        } else {
            setAllFilters(standardFiltersWithoutSubtags);
        }
    }, []);

    useEffect(() => {
        const escFunction = (event) => {
            if (event?.key === 'Escape') {
                setMobileActiveState(false);
                hideMobileFilters();
                setActiveFilterGroupState(null);
            }
        };

        window.addEventListener('keydown', escFunction);

        return () => {
            window.removeEventListener('keydown', escFunction);
            const body = document.body;
            body.style.position = '';
            body.style.top = '';
        };
    }, []);

    function searchKeyBoard(event) {
        if (event.keyCode == 13) {
            event.target.blur();
        }
    }

    useEffect(() => {
        searchInputRef?.current?.addEventListener('keypress', searchKeyBoard);
        return function cleanup() {
            searchInputRef?.current?.removeEventListener('keypress', searchKeyBoard);
        };
    }, []);

    function closeBypass() {
        for (let el of document?.getElementsByClassName('zn-main-nav__bypass-button')) {
            if (el?.type === 'button') {
                el.click();
            }
        }
    }

    return (
        <div className={classnames(`${representativeWorkBlock}`)} ref={repWorkFilterRef}>
            <div className={classnames(`${representativeWorkBlock}__left-content`)}>
                {allFilters.length > 0 && (
                    <div className={classnames(`${representativeWorkBlock}__filters-container`)}>
                        <button
                            className={classnames(`${representativeWorkBlock}__mb-filter-opener`, {
                                [`${representativeWorkBlock}__mb-filter-opener--active`]: setMobileActive,
                            })}
                            onClick={() => {
                                setMobileActiveState(!setMobileActive);
                                showMobileFilters();
                                closeBypass();
                            }}
                        >
                            <div className={`${representativeWorkBlock}__mb-filter-opener-title`}>
                                {t('Filters')}
                            </div>
                            <div className={`${representativeWorkBlock}__mb-filter-opener-chevron`}>
                                {setMobileActive ? <ChevronUp /> : <ChevronDown />}
                            </div>
                        </button>
                        <div
                            className={classnames(`${representativeWorkBlock}__filters-wrapper`, {
                                [`${representativeWorkBlock}__filters-wrapper--active`]: setMobileActive,
                            })}
                        >
                            <div className={`${representativeWorkBlock}__filter-list-mb-header`}>
                                <div
                                    className={`${representativeWorkBlock}__filter-list-mb-header-content`}
                                >
                                    <div
                                        className={`${representativeWorkBlock}__filter-list-mb-header-title`}
                                    >
                                        {t('Filter')}
                                    </div>
                                    <span>
                                        <Button
                                            className="zn-button-simple--border"
                                            aria-label="Close filters"
                                            type="button"
                                            variant="icon"
                                            onClick={() => {
                                                setMobileActiveState(false);
                                                hideMobileFilters();
                                            }}
                                        >
                                            <CloseIcon />
                                        </Button>
                                    </span>
                                </div>
                                <div
                                    className={classnames(
                                        `${representativeWorkBlock}__filter-list-mb-header-divider`,
                                        {
                                            [`${representativeWorkBlock}__filter-list-mb-header-divider--menu-open`]:
                                                setActiveFilterGroup !== null,
                                        }
                                    )}
                                ></div>
                            </div>
                            <div className={`${representativeWorkBlock}__list-container`}>
                                <div
                                    className={classnames(
                                        `${representativeWorkBlock}__mb-wrapper`,
                                        {
                                            [`${representativeWorkBlock}__mb-wrapper--filter-open`]:
                                                setActiveFilterGroup !== null,
                                        }
                                    )}
                                >
                                    {allFilters.map((filter, index) => (
                                        <RepresentativeWorkFilter
                                            key={index}
                                            fields={filter}
                                            index={index}
                                            activeFilter={setActiveFilterGroup}
                                            checkBox={checkSingularBox}
                                            uncheckAllBoxes={clearSingularFilter}
                                            toggleFilter={toggleActiveFilterGroup}
                                            translation={t}
                                            onApplyFilters={() => {
                                                onApplyFilters();
                                                hideMobileFilters();
                                            }}
                                            disabledState={disabledState}
                                            setDisabledState={setDisabledState}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div
                                className={classnames(
                                    `${representativeWorkBlock}__mb-menu-buttons`,
                                    {
                                        [`${representativeWorkBlock}__mb-menu-buttons--mb-open`]: setMobileActive,
                                    },
                                    {
                                        [`${representativeWorkBlock}__mb-menu-buttons--filter-open`]:
                                            setActiveFilterGroup !== null,
                                    }
                                )}
                            >
                                <button
                                    className={`${representativeWorkBlock}__mb-menu-clear-button`}
                                    onClick={clearAllFilters}
                                >
                                    {t('Reset')}
                                </button>
                                <div className={`${representativeWorkBlock}__mb-menu-apply-button`}>
                                    <Button
                                        onClick={() => {
                                            onApplyFilters();
                                            hideMobileFilters();
                                        }}
                                    >
                                        {t('ShowResults')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={`${representativeWorkBlock}__input`}>
                    <form className={`${representativeWorkBlock}__form`} onSubmit={submitForm}>
                        <Button
                            className={classnames(`${representativeWorkBlock}__search-button`)}
                            aria-label="Submit Search"
                            type="submit"
                            variant="icon"
                        >
                            <SearchBarIcon />
                        </Button>
                        <input
                            ref={searchInputRef}
                            className={`${representativeWorkBlock}__form-input`}
                            type="text"
                            placeholder={SearchPrompt}
                            value={searchParams}
                            onChange={handleSearchParams}
                        ></input>
                    </form>
                </div>
            </div>
            <div className={`${representativeWorkBlock}__right-content`}>
                <div className={`${representativeWorkBlock}__sel-filters`}>
                    {applyRecommendedFilters === '1' && pageTitle !== '' && (
                        <div className={`${representativeWorkBlock}__sel-filter`}>
                            <div
                                className={classnames(
                                    `${representativeWorkBlock}__sel-filter-button`,
                                    `${representativeWorkBlock}__sel-filter-button--primary`
                                )}
                            >
                                <div
                                    className={classnames(
                                        `${representativeWorkBlock}__sel-filter-title`,
                                        `${representativeWorkBlock}__sel-filter-title--primary`
                                    )}
                                >
                                    {pageTitle}
                                </div>
                            </div>
                        </div>
                    )}
                    {allFilters.map((filter) =>
                        filter.options.map(
                            (option, index) =>
                                option.checked && (
                                    <div
                                        className={`${representativeWorkBlock}__sel-filter`}
                                        key={index}
                                    >
                                        <button
                                            className={`${representativeWorkBlock}__sel-filter-button`}
                                            onClick={() => {
                                                checkSingularBox(option.id, filter.parentId);
                                            }}
                                        >
                                            <div
                                                className={`${representativeWorkBlock}__sel-filter-title`}
                                            >
                                                {option.label}
                                            </div>
                                            <div
                                                className={`${representativeWorkBlock}__sel-filter-icon`}
                                            >
                                                <CloseBubbleIcon />
                                            </div>
                                        </button>
                                    </div>
                                )
                        )
                    )}
                    <button
                        className={classnames(
                            `${representativeWorkBlock}__desktop-clear-all-button`
                        )}
                        onClick={clearAllFilters}
                    >
                        {t('Reset')}
                    </button>
                </div>
                {!hasError ? (
                    !isLoading ? (
                        <div className={`${representativeWorkBlock}__search-results`}>
                            {resultCount === 0 && (
                                <div className={`${representativeWorkBlock}__search-results-title`}>
                                    {t('NoResults')}
                                </div>
                            )}
                            {resultCount > 0 && (
                                <ul className={`${representativeWorkBlock}__search-results-list`}>
                                    {results.map((result, index) => (
                                        <FeaturedListItem
                                            key={index}
                                            fields={{
                                                Title: { value: result?.item?.teaserTitle?.value },
                                                ParagraphText: {
                                                    value: result?.item?.teaserDescription?.value,
                                                },
                                                LinkURL:
                                                    result?.item?.notNavigatable?.value === '1'
                                                        ? {
                                                              value: {},
                                                          }
                                                        : {
                                                              value: {
                                                                  text: CTAText,
                                                                  title:
                                                                      result?.item?.teaserTitle
                                                                          ?.value,
                                                                  href: result?.item?.url,
                                                                  target: LinkTargetValueEnum.self,
                                                              },
                                                          },
                                            }}
                                            params={{
                                                Highlights: HighlightColorItemEnum.none,
                                                DividerLines: '0',
                                                AddTitleArrows: '0',
                                            }}
                                        />
                                    ))}
                                </ul>
                            )}
                            {resultCount > 0 && resultCount > itemsPerPage && (
                                <div className={`${representativeWorkBlock}__pagination`}>
                                    <Pagination
                                        Total={Math.ceil(resultCount / itemsPerPage)}
                                        Current={filtersAppliedObject.page}
                                        OnNext={() => {
                                            //setPage(page + 1);
                                            setFiltersAppliedObject((current) => ({
                                                page: current.page + 1,
                                                isFilterApplied: true,
                                            }));
                                            repWorkFilterRef.current.scrollIntoView();
                                        }}
                                        OnPrevious={() => {
                                            setFiltersAppliedObject((current) => ({
                                                page: current.page - 1,
                                                isFilterApplied: true,
                                            }));
                                            repWorkFilterRef.current.scrollIntoView();
                                        }}
                                        OnNumberClick={(number) => {
                                            //setPage(number);
                                            setFiltersAppliedObject(() => ({
                                                page: number,
                                                isFilterApplied: true,
                                            }));
                                            repWorkFilterRef.current.scrollIntoView();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={`${representativeWorkBlock}__loading`}>
                            <Lottie options={defaultAnimationOptions} height={300} width={300} />
                        </div>
                    )
                ) : (
                    <div className={`${representativeWorkBlock}__error`}>{t('Error')}</div>
                )}
            </div>
        </div>
    );
};

RepresentativeWork.defaultProps = repWorkData;

const RepresentativeWorkStorybookContext = withSitecoreContext()(RepresentativeWork);

export const RepresentativeWorkStorybook = forStorybookV2(RepresentativeWorkStorybookContext);

export default withTranslation()(RepresentativeWorkStorybookContext);
