import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './in-page-navigation.scss';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import forStorybook from '../../utils/forStorybook.jsx';
import InPageNavigationItem from '../InPageNavigationItem/InPageNavigationItem.jsx';
import { LinkTargetEnum, ThemeEnum } from '../../utils/enums';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { ChevronRightNav, ChevronLeftNav, ChevronDown, ChevronUp } from '../../assets/LinkIcons.js';

export const InPageNavigation = ({
    fields,
    sitecoreContext,
    dropdown,
    pageSections,
    rendering,
}) => {
    const inPageNavigationBlock = 'zn-in-page-navigation';
    const { PageTitle, LinkURL } = fields;
    const [scrolledToEnd, setScrolledToEnd] = useState(false);
    const [scrolledToStart, setScrolledToStart] = useState(true);
    const [setActive, setActiveState] = useState(false);
    const [clickedSection, setClickedSection] = useState('');
    const [setMobileTitle, setMobileTitleState] = useState('');
    const [sectionIds, setSectionIds] = useState([]);
    const observerRef = useRef();
    const sectionRef = useRef(clickedSection);
    const navRef = useRef(null);
    const navListRef = useRef(null);
    const contentRef = useRef(null);
    const desktopContentRef = useRef(null);

    const toggleDropdown = () => {
        setActiveState(!setActive ? true : false);
    };

    const toggleTitle = (mobileTitle) => {
        setMobileTitleState(mobileTitle);
    };

    const toggleCurrentPressed = (currentSection) => {
        setClickedSection(currentSection);
    };

    const getContentWidth = () => {
        const { scrollWidth: listTotalWidth } = navListRef.current;
        const { offsetWidth: contentWidth } = contentRef.current;
        const { offsetWidth: titleWidth } = desktopContentRef.current;

        return {
            width: contentWidth - titleWidth,
            isOverflow: contentWidth - titleWidth < listTotalWidth,
        };
    };

    const scrollLeft = () => {
        navListRef.current.scrollLeft = 0;
    };

    const scrollRight = () => {
        navListRef.current.scrollLeft = 10000000;
    };

    const useCurrentWidth = () => {
        const [contentWidth, setContentWidth] = useState({});

        useEffect(() => {
            setTimeout(() => setContentWidth(getContentWidth()), 150);
            let timeoutId = null;
            const resizeListener = () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => setContentWidth(getContentWidth()), 150);
            };
            window.addEventListener('resize', resizeListener);

            return () => {
                window.removeEventListener('resize', resizeListener);
            };
        }, [sitecoreContext?.itemId]);

        return contentWidth;
    };
    let widthArray = useCurrentWidth();

    const options = {
        rootMargin: '-30% 0px -70%',
        threshold: 0,
    };

    const callback = useCallback(
        (entries) => {
            let content = document.getElementsByClassName('zn-section');
            let viewportOffset = navRef.current?.getBoundingClientRect();

            if (!sitecoreContext.pageEditing) {
                entries.forEach((entry) => {
                    const selectedId = entry.target.getAttribute('id');
                    if (sectionRef.current === '' || selectedId === sectionRef.current) {
                        if (entry.isIntersecting) {
                            const title = document.querySelector(
                                `nav ul li a[href="#${selectedId}"]`
                            );

                            title?.parentElement.classList.add('current');

                            if (title?.innerText) {
                                setMobileTitleState(title?.innerText);
                            } else {
                                setMobileTitleState(PageTitle?.value);
                            }

                            Array.from(content).forEach((section) => {
                                const id = section.id;
                                if (id !== selectedId) {
                                    document
                                        .querySelector(`nav ul li a[href="#${id}"]`)
                                        ?.parentElement.classList.remove('current');
                                }
                            });
                            if (
                                navListRef.current.scrollLeft +
                                    navListRef.current.clientWidth -
                                    64 -
                                    (document.querySelector(`nav ul li a[href="#${selectedId}"]`)
                                        ?.parentElement.offsetLeft +
                                        document.querySelector(`nav ul li a[href="#${selectedId}"]`)
                                            ?.parentElement.offsetWidth) <
                                    0 ||
                                navListRef.current.scrollLeft +
                                    navListRef.current.clientWidth -
                                    document.querySelector(`nav ul li a[href="#${selectedId}"]`)
                                        ?.parentElement.offsetLeft >
                                    navListRef.current.clientWidth
                            )
                                setTimeout(function () {
                                    navListRef.current.scrollLeft =
                                        document.querySelector(`nav ul li a[href="#${selectedId}"]`)
                                            ?.parentElement.offsetLeft - 64;
                                }, 500);
                        } else {
                            if (viewportOffset?.top === 0) {
                                document
                                    .querySelector(`nav ul li a[href="#${selectedId}"]`)
                                    ?.parentElement.classList.remove('current');
                            }
                        }
                        setClickedSection('');
                    }
                });
            }
        },
        [sitecoreContext?.itemId]
    );

    const scrollSetup = () => {
        let scrollLeft = navListRef.current.scrollLeft;
        let scrollWidth = navListRef.current.scrollWidth;
        let offsetWidth = navListRef.current.offsetWidth;
        let contentWidth = scrollWidth - offsetWidth;
        if (contentWidth - 2 <= scrollLeft) {
            setScrolledToEnd(true);
        } else if (scrollLeft === 0) {
            setScrolledToStart(true);
        } else {
            setScrolledToEnd(false);
            setScrolledToStart(false);
        }
    };

    useEffect(() => {
        const html = document.querySelector('html');
        html.classList.add('zn-scroll-padding');
        let content = document.getElementsByClassName('zn-section');
        let inPageNavItems = document.getElementsByClassName(`zn-in-page-navigation-item`);
        Array.from(inPageNavItems).forEach((inPageNavItem) => {
            inPageNavItem.classList.remove('current');
        });
        inPageNavItems[0].classList.add('current');
        observerRef.current = new IntersectionObserver(callback, options);

        Array.from(content).forEach((section) => {
            observerRef.current.observe(section);
        });

        navListRef.current.addEventListener('scroll', scrollSetup);
        setSectionIds(
            Array.from(content).map((section) => ({
                id: section.id,
                display: section.style.display,
            }))
        );
        setMobileTitleState(PageTitle?.value);

        return () => {
            document.querySelector('html').classList.remove('zn-scroll-padding');
            navListRef.current.removeEventListener('scroll', scrollSetup);
            Array.from(content).forEach((section) => {
                observerRef.current.unobserve(section);
            });
        };
    }, [sitecoreContext?.itemId]);

    useEffect(() => {
        sectionRef.current = clickedSection;
    }, [clickedSection]);

    useEffect(() => {
        if (!sitecoreContext?.pageEditing) {
            for (let j = 0; j < rendering?.placeholders?.pageSections?.length; j++) {
                if (
                    sectionIds?.find(
                        (x) =>
                            x.id ===
                                rendering?.placeholders?.pageSections[j]?.fields?.SectionID
                                    ?.value && x.display !== 'none'
                    )
                ) {
                    document
                        .querySelector(
                            `nav ul li a[href="#${rendering?.placeholders?.pageSections[j]?.fields?.SectionID?.value}"]`
                        )
                        ?.parentElement.classList.add('current');
                    break;
                }
            }
        }
        setClickedSection(sectionIds[0]?.id);
    }, [sectionIds]);

    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, [sitecoreContext?.itemId]);

    return (
        !pdfView && (
            <div
                className={classnames(`${inPageNavigationBlock}`, {
                    [`${inPageNavigationBlock}--sc-editing`]: sitecoreContext.pageEditing,
                })}
                ref={navRef}
            >
                <div className={classnames(`${inPageNavigationBlock}__container`)}>
                    <div
                        className={classnames(`${inPageNavigationBlock}__content`)}
                        ref={contentRef}
                    >
                        <div
                            className={classnames(`${inPageNavigationBlock}__desktop-content`)}
                            ref={desktopContentRef}
                        >
                            <div className={classnames(`${inPageNavigationBlock}__title`)}>
                                {PageTitle?.value}
                            </div>
                            {widthArray.isOverflow && (
                                <div
                                    className={classnames(
                                        `${inPageNavigationBlock}__icon`,
                                        `${inPageNavigationBlock}__icon--left`
                                    )}
                                >
                                    <button
                                        className={classnames(
                                            `${inPageNavigationBlock}__button-icon`,
                                            `${inPageNavigationBlock}__button-icon--left`
                                        )}
                                        onClick={() => {
                                            scrollLeft();
                                        }}
                                        style={{
                                            display: scrolledToStart ? `none` : 'block',
                                        }}
                                    >
                                        <ChevronLeftNav inactive={false} />
                                    </button>
                                </div>
                            )}
                        </div>
                        <button
                            className={classnames(`${inPageNavigationBlock}__info`)}
                            onClick={toggleDropdown}
                        >
                            <div className={classnames(`${inPageNavigationBlock}__mobile-title`)}>
                                {setMobileTitle}
                            </div>

                            <div className={classnames(`${inPageNavigationBlock}__mobile-icon`)}>
                                {!setActive ? <ChevronDown /> : <ChevronUp />}
                            </div>
                        </button>
                        <div className={classnames(`${inPageNavigationBlock}__sections`)}>
                            <nav style={setActive ? { display: 'block' } : { display: 'none' }}>
                                <ul
                                    style={
                                        !sitecoreContext.pageEditing
                                            ? { width: `${widthArray.width}px` }
                                            : { width: `100%` }
                                    }
                                    className={classnames(`${inPageNavigationBlock}__list`, {
                                        [`${inPageNavigationBlock}__list--sc-editing`]: sitecoreContext.pageEditing,
                                    })}
                                    ref={navListRef}
                                >
                                    {sitecoreContext.pageEditing && <div>{pageSections}</div>}
                                    {!sitecoreContext.pageEditing &&
                                        (rendering.placeholders.pageSections || [])
                                            .filter(
                                                (item) =>
                                                    item &&
                                                    item?.fields &&
                                                    item?.fields?.SectionName &&
                                                    item?.fields?.SectionID
                                            )
                                            .map((item, index) => (
                                                <InPageNavigationItem
                                                    key={index}
                                                    changeTitle={toggleTitle}
                                                    changeDropdown={toggleDropdown}
                                                    changeCurrent={toggleCurrentPressed}
                                                    fields={item?.fields}
                                                    sectionIds={sectionIds}
                                                    sitecoreContext={sitecoreContext}
                                                />
                                            ))}
                                </ul>
                                {LinkURL?.value?.text && LinkURL?.value?.href && (
                                    <div
                                        className={classnames(
                                            `${inPageNavigationBlock}__button-mobile`
                                        )}
                                    >
                                        <ButtonLink
                                            fields={{
                                                LinkURL: {
                                                    value: {
                                                        text: LinkURL?.value?.text,
                                                        href: LinkURL?.value?.href,
                                                        title: LinkURL?.value?.title,
                                                        target: LinkURL?.value?.target,
                                                    },
                                                },
                                            }}
                                            params={{
                                                Theme: ThemeEnum.light,
                                                Variant: 'primary',
                                            }}
                                        />
                                    </div>
                                )}
                            </nav>
                        </div>
                    </div>
                    <div className={classnames(`${inPageNavigationBlock}__share-content`)}>
                        <div className={classnames(`${inPageNavigationBlock}__desktop-content`)}>
                            {widthArray.isOverflow && (
                                <div
                                    className={classnames(
                                        `${inPageNavigationBlock}__icon`,
                                        `${inPageNavigationBlock}__icon--right`
                                    )}
                                >
                                    <button
                                        className={classnames(
                                            `${inPageNavigationBlock}__button-icon`,
                                            `${inPageNavigationBlock}__button-icon--right`
                                        )}
                                        onClick={() => {
                                            scrollRight();
                                        }}
                                        style={{
                                            display: scrolledToEnd ? `none` : 'block',
                                        }}
                                    >
                                        <ChevronRightNav inactive={false} />
                                    </button>
                                </div>
                            )}
                        </div>
                        <div
                            className={classnames(`${inPageNavigationBlock}__dropdown`, {
                                [`${inPageNavigationBlock}__dropdown--no-cta`]: !(
                                    LinkURL?.value?.text && LinkURL?.value?.href
                                ),
                            })}
                        >
                            {dropdown}
                        </div>
                        {LinkURL?.value?.text && LinkURL?.value?.href && (
                            <div className={classnames(`${inPageNavigationBlock}__button-desktop`)}>
                                <ButtonLink
                                    fields={{
                                        LinkURL: {
                                            value: {
                                                text: LinkURL?.value?.text,
                                                href: LinkURL?.value?.href,
                                                title: LinkURL?.value?.title,
                                                target: LinkURL?.value?.target,
                                            },
                                        },
                                    }}
                                    params={{
                                        Theme: ThemeEnum.light,
                                        Variant: 'primary',
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

InPageNavigation.propTypes = {
    fields: PropTypes.shape({
        PageTitle: PropTypes.shape({
            value: PropTypes.string,
        }),
        PageSections: PropTypes.array,
        LinkText: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkURL: PropTypes.shape({
            value: PropTypes.string,
        }),
        LinkTarget: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(LinkTargetEnum)).isRequired,
        }),
        LinkAltText: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

const InPageNavigationWithContext = withSitecoreContext()(InPageNavigation);

export const InPageNavigationStorybook = forStorybook(InPageNavigationWithContext);

export default withPlaceholder(['pageSections', 'dropdown'])(
    withErrorBoundary([ErrorBoundaryConditions.fields])(InPageNavigationWithContext)
);
