import React, { useState, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { debounce } from 'throttle-debounce';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { BasicInteractiveMap as ProjectsMap } from '../InteractiveMap/InteractiveMap.jsx';
import { BasicProjectMapList as ProjectsList } from '../ProjectMapList/ProjectMapList.jsx';
import { BasicProjectMapSearchBar as ProjectsSearchBar } from '../ProjectMapSearchBar/ProjectMapSearchBar.jsx';
import { fetchMapTags, fetchMapPropertiesFragment } from '../../actions';
import {
    mapTagCondition,
    includeInMapCondition,
    mapMarkerInheritanceCondition,
    MAP_DEBOUNCE_CONST,
} from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import { ListView, MapView } from '../../assets/common.js';
import { useLocation } from 'react-router-dom';
import {
    updateLowLevelFilter,
    updateAdvancedFilter,
    updateHighLevelFilter,
    clearHighLevelFilter,
    updateCategory,
    updateAllCategories,
    updateFacets,
    formatProjectSearchBarFilters,
    constructSearchConditions,
    formatFilterParams,
    formatAdvancedFilters,
    getTextFacetValues,
    updateAdvancedFilterSection,
    updateAdvancedFilterHighLevelSection,
    clearAllAdvancedFilters,
} from './projectMapHelper.jsx';

import './project-map-container.scss';

const ProjectMapContainer = ({
    sitecoreContext,
    projectsMap,
    projectsList,
    projectsSearchBar,
    rendering,
    params,
    t,
}) => {
    const projectsMapContainerBlock = 'zn-projects-map-container';
    const { MapBoxAPIKey, MapBoxStyleURL } = params;

    // map, list, search components of project map container
    let mapComponent = rendering?.placeholders?.projectsMap[0];
    let listComponent = rendering?.placeholders?.projectsList[0];
    let searchBarComponent = rendering?.placeholders?.projectsSearchBar[0];

    // selected/default filter folders
    const projectSearchBarFilters =
        searchBarComponent?.fields?.data?.datasource?.mapFilters?.targetItems;
    const defaultSelectedFilters =
        searchBarComponent?.fields?.data?.datasource?.defaultFilters?.targetItems?.map(
            (option) => option?.identifier?.value
        ) || [];

    //advanced filters
    let advancedFilters =
        searchBarComponent?.fields?.data?.datasource?.advancedFilters?.targetItems;
    const pinnedAdvancedFilters =
        searchBarComponent?.fields?.data?.datasource?.pinnedAdvancedFilters?.targetItems?.map(
            (option) => option?.id
        ) || [];

    // incoming query params
    const queryParams = new URLSearchParams(useLocation()?.search);
    const latParam = queryParams.get('lat');
    const lngParam = queryParams.get('lng');
    const zoomParam = queryParams.get('zoom');
    const keywordParam = queryParams.get('q');
    const regionsParam = queryParams.get('regions') ? queryParams.get('regions')?.split(',') : [];
    const industriesParam = queryParams.get('industries')
        ? queryParams.get('industries')?.split(',')
        : [];
    const tagsParam = queryParams.get('tags') ? queryParams.get('tags')?.split(',') : [];
    let paramObj = {};
    for (var value of queryParams.keys()) {
        paramObj[value] = queryParams.get(value);
    }
    // project map component state variables
    const [mapTags, setMapTags] = useState([]);
    const [spriteData, setSpriteData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [lng, setLng] = useState(
        lngParam
            ? lngParam
            : mapComponent?.fields?.Longitude?.value
            ? parseFloat(mapComponent?.fields?.Longitude?.value)
            : 0
    );
    const [lat, setLat] = useState(
        latParam
            ? latParam
            : mapComponent?.fields?.Latitude?.value
            ? parseFloat(mapComponent?.fields?.Latitude?.value)
            : 0
    );
    const [clearAll, setClearAll] = useState(false);
    const [zoom, setZoom] = useState(
        zoomParam ? zoomParam : mapComponent?.fields?.DefaultZoomLevel?.value
    );
    const [mapMarkerData, setMapMarkerData] = useState({
        data: [],
        searchCondition: false,
    });

    //search view component state variables
    const [searchInput, setSearchInput] = useState(keywordParam ? keywordParam : '');
    const [isMobileFilterViewOpen, setIsMobileFilterViewOpen] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [activeFilterGroup, setActiveFilterGroup] = useState(null);
    const [isAdvancedFiltersLoaded, setIsAdvancedFiltersLoaded] = useState(false);
    const filterFacets = ['industries', 'regions', 'tags'].concat(
        getTextFacetValues(advancedFilters).map((facet) => facet.facetName)
    );
    const [filters, setFilters] = useState({
        mapTagFilters: formatProjectSearchBarFilters(
            projectSearchBarFilters,
            regionsParam?.concat(industriesParam)?.concat(defaultSelectedFilters)
        ),
        advancedFilters: [],
    });
    const [searchConditions, setSearchConditions] = useState([]);
    const prevActiveGroup = useRef();

    useEffect(() => {
        prevActiveGroup.current = activeFilterGroup;
    });

    // list view component state variables
    const [listData, setListData] = useState([]);
    const [isListLoading, setIsListLoading] = useState(true);
    const [isMobileListViewOpen, setIsMobileListViewOpen] = useState(false);

    // search view filter UI update methods
    const toggleMobileFilterView = () => setIsMobileFilterViewOpen((prevValue) => !prevValue);
    const toggleMobileListView = () => setIsMobileListViewOpen((prevValue) => !prevValue);

    const toggleActiveFilterGroup = (index) => {
        if (prevActiveGroup.current === index) {
            setActiveFilterGroup(null);
        } else {
            setActiveFilterGroup(index);
        }
    };

    // search view filter data update methods
    const checkLowLevelFilter = useCallback((lowLevelId, highLevelId, categoryId) => {
        setFilters((current) => updateLowLevelFilter(lowLevelId, highLevelId, categoryId, current));
        setFilterApplied(true);
    }, []);

    const checkAdvancedFilter = useCallback((lowLevelId, highLevelId) => {
        setFilters((current) => updateAdvancedFilter(lowLevelId, highLevelId, current));
        setFilterApplied(true);
    }, []);

    const toggleHighLevelFilter = useCallback((highLevelId, categoryId) => {
        setFilters((current) => updateHighLevelFilter(highLevelId, categoryId, current));
        setFilterApplied(true);
    }, []);

    const toggleAdvancedHighLevelFilter = useCallback((highLevelId, categoryId, isClear) => {
        setFilters((current) =>
            updateAdvancedFilterHighLevelSection(highLevelId, categoryId, current, isClear)
        );
        setFilterApplied(true);
    }, []);

    const checkHighLevelFilter = useCallback((highLevelId, categoryId, isClear) => {
        setFilters((current) => clearHighLevelFilter(highLevelId, categoryId, current, isClear));
        setFilterApplied(true);
    }, []);

    const toggleAdvancedFilter = useCallback((categoryId, isClear) => {
        setFilters((current) => updateAdvancedFilterSection(categoryId, current, isClear));
        setFilterApplied(true);
    }, []);

    const clearCategory = useCallback((categoryId) => {
        setFilters((current) => updateCategory(categoryId, current));
        setFilterApplied(true);
    }, []);

    const clearAllFilters = () => {
        setActiveFilterGroup(null);
        setSearchInput('');
        setClearAll(true);
        setFilters((current) => updateAllCategories(current));
        setFilterApplied(true);
    };

    const clearAllAdvancedFiltersFunc = () => {
        setActiveFilterGroup(null);
        setSearchInput('');
        setFilters((current) => clearAllAdvancedFilters(current));
        setFilterApplied(true);
    };

    const updateFilterFacets = (facets) => {
        let flattenedFacets = facets.map((facet) => ({
            name: facet?.name,
            values: facet?.values?.map((item) => item?.value),
        }));
        setFilters((current) => updateFacets(flattenedFacets, current));
    };

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value);
    };

    const submitForm = (event) => {
        event?.preventDefault();
        updateUrl.current(lat, lng, zoom, filters, searchInput);
        setFilterApplied(true);
    };

    useEffect(() => {
        if (filterApplied) {
            setIsLoading(true);
            setIsListLoading(true);
            setSearchConditions(constructSearchConditions(filters));
            setFilterApplied(false);
        }
    }, [filterApplied]);

    const updateUrl = useRef(
        debounce(MAP_DEBOUNCE_CONST, (lat, lng, zoom, filters, searchInput) => {
            const [
                mapFilterKeyValuePairs,
                adFilterKeyValuePairs,
                tagKeyValuePairs,
            ] = formatFilterParams(filters);
            const state = { lat, lng, zoom };
            const params = new URLSearchParams(state);
            params.append('q', searchInput);
            mapFilterKeyValuePairs.map((category) =>
                params.append(category?.name, category?.value)
            );
            adFilterKeyValuePairs.map((category) => params.append(category?.name, category?.value));
            params.append(tagKeyValuePairs?.name, tagKeyValuePairs?.value);
            const url = new URL(window.location);
            const newUrl = new URL(url.pathname + '?' + params.toString(), document.baseURI);
            history.replaceState(state, '', newUrl);
        })
    );

    useEffect(() => {
        if (!sitecoreContext?.pageEditing) updateUrl.current(lat, lng, zoom, filters, searchInput);
    }, [lat, lng, zoom, filters]);

    useEffect(() => {
        let textFacetValues = getTextFacetValues(advancedFilters).map((facet) => facet.facetName);
        let searchConditions = [includeInMapCondition, mapMarkerInheritanceCondition];
        if (textFacetValues.length) {
            fetchMapPropertiesFragment(searchConditions, textFacetValues)
                .then((x) => {
                    let textFacets = x?.facets;
                    textFacets.map((facet) => {
                        advancedFilters = advancedFilters.map((filter) => {
                            if (facet?.name === filter?.textValueProperty?.value) {
                                return {
                                    ...filter,
                                    isTextAdFilter: true,
                                    values: facet?.values,
                                };
                            } else return filter;
                        });
                    });
                    setFilters((current) => ({
                        mapTagFilters: current.mapTagFilters,
                        advancedFilters: formatAdvancedFilters(
                            advancedFilters,
                            pinnedAdvancedFilters,
                            tagsParam,
                            paramObj
                        ),
                    }));
                    setIsAdvancedFiltersLoaded(true);
                })
                .catch(() => {});
        } else {
            setFilters((current) => ({
                mapTagFilters: current.mapTagFilters,
                advancedFilters: formatAdvancedFilters(
                    advancedFilters,
                    pinnedAdvancedFilters,
                    tagsParam,
                    paramObj
                ),
            }));
            setIsAdvancedFiltersLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (isAdvancedFiltersLoaded) {
            setSearchConditions(constructSearchConditions(filters));
        }
    }, [isAdvancedFiltersLoaded]);

    // fetch all the map tags
    useEffect(() => {
        let searchCondition = [mapTagCondition];
        let styleURLkey = MapBoxStyleURL?.split('mapbox://styles/')?.[1];
        fetchMapTags(searchCondition)
            .then((x) => {
                const mapTagItems = x?.results;
                setMapTags(
                    mapTagItems.map((tag) => ({
                        id: tag?.id?.toLowerCase(),
                        title: tag?.title,
                        path: tag?.svgpath,
                    }))
                );
            })
            .catch(() => {});

        async function getData(url) {
            const response = await fetch(url);
            const data = await response.json();
            const filteredData = Object.keys(data).filter((key) => key.startsWith('circle_'));
            const arrOfPromises = filteredData.map((item) =>
                fetch(
                    `https://api.mapbox.com/styles/v1/${styleURLkey}/sprite/${[
                        item,
                    ]}?access_token=${MapBoxAPIKey}`
                )
                    .then((response) => response.json())
                    .then((data) => ({ [item]: data?.svg }))
            );
            return Promise.all(arrOfPromises);
        }

        //get circle icons
        if (!sitecoreContext?.pageEditing && MapBoxStyleURL && MapBoxAPIKey) {
            getData(
                `https://api.mapbox.com/styles/v1/${styleURLkey}/sprite?access_token=${MapBoxAPIKey}`
            ).then((data) => setSpriteData(Object.assign({}, ...data)));
        }
    }, []);

    return (
        <div className={classnames(`${projectsMapContainerBlock}`)}>
            <div
                className={classnames(`${projectsMapContainerBlock}__left-container`, {
                    [`${projectsMapContainerBlock}__left-container--mobile-view`]: isMobileListViewOpen,
                    [`${projectsMapContainerBlock}__left-container--no-map`]:
                        !sitecoreContext?.pageEditing && !mapComponent,
                    [`${projectsMapContainerBlock}__left-container--mobile-map-open`]:
                        !isMobileFilterViewOpen && !isMobileListViewOpen,
                })}
            >
                <div className={classnames(`${projectsMapContainerBlock}__projects-search-bar`)}>
                    {sitecoreContext.pageEditing && <div>{projectsSearchBar}</div>}
                    {!sitecoreContext.pageEditing && searchBarComponent && (
                        <ProjectsSearchBar
                            t={t}
                            fields={searchBarComponent?.fields}
                            filters={filters}
                            activeFilterGroup={activeFilterGroup}
                            isLoading={isLoading}
                            searchInput={searchInput}
                            isMobileFilterViewOpen={isMobileFilterViewOpen}
                            toggleActiveFilterGroup={toggleActiveFilterGroup}
                            checkLowLevelFilter={checkLowLevelFilter}
                            checkAdvancedFilter={checkAdvancedFilter}
                            toggleHighLevelFilter={toggleHighLevelFilter}
                            toggleAdvancedHighLevelFilter={toggleAdvancedHighLevelFilter}
                            checkHighLevelFilter={checkHighLevelFilter}
                            clearAllFilters={clearAllFilters}
                            clearAllAdvancedFiltersFunc={clearAllAdvancedFiltersFunc}
                            clearCategory={clearCategory}
                            toggleAdvancedFilter={toggleAdvancedFilter}
                            setIsLoading={setIsLoading}
                            handleSearchInput={handleSearchInput}
                            submitForm={submitForm}
                            toggleMobileFilterView={toggleMobileFilterView}
                            setIsMobileFilterViewOpen={setIsMobileFilterViewOpen}
                            includesProjectMap={mapComponent}
                        ></ProjectsSearchBar>
                    )}
                </div>
                <div
                    className={classnames(`${projectsMapContainerBlock}__projects-list`, {
                        [`${projectsMapContainerBlock}__projects-list--mobile-view`]: isMobileListViewOpen,
                        [`${projectsMapContainerBlock}__projects-list--no-map`]:
                            !sitecoreContext?.pageEditing && !mapComponent,
                    })}
                >
                    {sitecoreContext.pageEditing && <div>{projectsList}</div>}
                    {!sitecoreContext.pageEditing && listComponent && (
                        <ProjectsList
                            t={t}
                            fields={listComponent?.fields}
                            listData={listData}
                            mapTags={mapTags}
                            filterFacets={filterFacets}
                            isLoading={isLoading}
                            isListLoading={isListLoading}
                            spriteData={spriteData}
                            searchConditions={searchConditions}
                            searchInput={searchInput}
                            setIsLoading={setIsLoading}
                            setIsListLoading={setIsListLoading}
                            updateFilterFacets={updateFilterFacets}
                            setListData={setListData}
                            clearAllFilters={clearAllFilters}
                            setIsMobileListViewOpen={setIsMobileListViewOpen}
                            ctaText={mapComponent?.fields?.CTAText}
                            includesProjectMap={mapComponent}
                        ></ProjectsList>
                    )}
                </div>
            </div>
            <div
                className={classnames(`${projectsMapContainerBlock}__right-container`, {
                    [`${projectsMapContainerBlock}__right-container--hide-mobile`]: isMobileListViewOpen,
                    [`${projectsMapContainerBlock}__right-container--no-map`]:
                        !sitecoreContext?.pageEditing && !mapComponent,
                })}
            >
                <div className={classnames(`${projectsMapContainerBlock}__projects-map`)}>
                    {sitecoreContext.pageEditing && <div>{projectsMap}</div>}
                    {!sitecoreContext.pageEditing && mapComponent && (
                        <ProjectsMap
                            mapStyle={MapBoxStyleURL}
                            fields={mapComponent?.fields}
                            lng={lng}
                            lat={lat}
                            zoom={zoom}
                            mapMarkerData={mapMarkerData}
                            mapTags={mapTags}
                            filterFacets={filterFacets}
                            isLoading={isLoading}
                            searchConditions={searchConditions}
                            searchInput={searchInput}
                            clearAll={clearAll}
                            setClearAll={setClearAll}
                            spriteData={spriteData}
                            maxNumListResults={listComponent?.fields?.NumberOfResults?.value}
                            setLng={setLng}
                            setLat={setLat}
                            setZoom={setZoom}
                            setMapMarkerData={setMapMarkerData}
                            setListData={setListData}
                            setIsListLoading={setIsListLoading}
                            setIsLoading={setIsLoading}
                            updateFilterFacets={updateFilterFacets}
                            setSpriteData={setSpriteData}
                            t={t}
                            sitecoreContext={sitecoreContext}
                        ></ProjectsMap>
                    )}
                </div>
            </div>
            {mapComponent && (
                <div className={`${projectsMapContainerBlock}__toggle-button-container`}>
                    <button
                        className={classnames(`${projectsMapContainerBlock}__toggle-view-button`)}
                        onClick={toggleMobileListView}
                    >
                        {isMobileListViewOpen ? <MapView /> : <ListView />}
                        <div
                            className={classnames(
                                `${projectsMapContainerBlock}__toggle-view-button-text`
                            )}
                        >
                            {isMobileListViewOpen ? t('MapView') : t('ListView')}
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
};
const ProjectMapContainerContext = withSitecoreContext()(ProjectMapContainer);
const ProjectMapContainerWithTranslation = withTranslation()(ProjectMapContainerContext);
export default withPlaceholder(['projectsMap', 'projectsList', 'projectsSearchBar'])(
    ProjectMapContainerWithTranslation
);
