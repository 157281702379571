import React from 'react';
import { Tag } from '../Tag/Tag.jsx';
import Text from '../Text/Text.jsx';
import forStorybook from '../../utils/forStorybook.jsx';
import classnames from 'classnames';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import './tag-list.scss';

const tagListBlock = 'zn-tag-list';
const TagList = ({ fields, children, tagList, sitecoreContext }) => {
    const { Title, Tags, SearchResultsPage, ApplyTemplateToSearch } = fields;
    const isEditing = sitecoreContext?.pageEditing;
    const searchLinkedTags = Tags?.map((tag) => {
        if (tag.fields) {
            tag.fields.SearchResultsPage = SearchResultsPage;
            tag.fields.ApplyTemplateToSearch = ApplyTemplateToSearch;
            tag.fields.templateName = sitecoreContext?.route.templateName;
        }
        return tag;
    });
    const searchLinkedTagList = tagList?.map((tag) => {
        if (tag.fields) {
            tag.fields.SearchResultsPage = SearchResultsPage;
            tag.fields.ApplyTemplateToSearch = ApplyTemplateToSearch;
            tag.fields.templateName = sitecoreContext?.route.templateName;
        }
        return tag;
    });
    return (
        <div className={classnames(tagListBlock, {})}>
            <Text type="allCaps" className={`${tagListBlock}__title`} field={Title}></Text>
            <ul className={classnames(`${tagListBlock}__list`, {})}>
                {Array.isArray(searchLinkedTags) &&
                    searchLinkedTags.map(
                        (tag, index) =>
                            !tag?.fields?.HideInArticleTagList?.value && (
                                <li className={`${tagListBlock}__list-item`} key={index}>
                                    <Tag fields={tag.fields} />
                                </li>
                            )
                    )}
                {!isEditing
                    ? searchLinkedTagList?.map(
                          (tag, index) =>
                              !tag?.fields?.HideInArticleTagList?.value && (
                                  <li
                                      className={`${tagListBlock}__list-item`}
                                      key={`ChildTag${index}`}
                                  >
                                      {tag}
                                  </li>
                              )
                      )
                    : searchLinkedTagList}
                {children}
            </ul>
        </div>
    );
};

export const BasicTagList = TagList;

export default withSitecoreContext()(
    withPlaceholder('tagList')(withErrorBoundary([ErrorBoundaryConditions.fields])(TagList))
);
export const TagListStorybook = forStorybook(TagList);
