/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import './quarterly-landing-hero-animation.scss';
import '../QuarterlyLandingHero/quarterly-landing-hero.scss';
import Text from '../Text';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { setAnimationInterObserver } from '../../utils/observers.jsx';
import { QuarterlyOverlayColor } from '../../utils/enums';

const qLHeroAnimationBlock = 'zn-quarterly-landing-hero-animation';
const qLHeroBlock = 'zn-quarterly-landing-hero';

export const QuarterlyLandingHeroAnimation = ({ fields, t, sitecoreContext }) => {
    const { Title, Eyebrow, Description, AccentColor, OverlayColor } = fields;
    const overlayColor = OverlayColor?.value
        ? OverlayColor?.value?.toLowerCase()
        : QuarterlyOverlayColor.white.toLowerCase();
    const isEditing = sitecoreContext?.pageEditing;

    const scrollContainerRef = useRef(null);
    const contentContainerRef = useRef(null);
    const minSpaceAboveScroll = 24;

    useEffect(() => {
        const selector = `.${qLHeroAnimationBlock}`;
        const newClass = `${qLHeroAnimationBlock}__animation-start`;

        // Scroll fade up reveal
        const fadeUpSelector = `.${qLHeroAnimationBlock} .fadeIn`;
        const fadeUpClass = 'fadeInActive';
        // Scroll reveal
        const scrollSelector = `.${qLHeroAnimationBlock} .expandDown`;
        const scrollClass = 'expandDownActive';
        // Heading reveal
        const headingRevealSelector = `.${qLHeroAnimationBlock} .headingFadeIn`;
        const headingRevealClass = 'headingFadeInActive';
        // // Headding accent
        const headingAccentSelector = `.${qLHeroAnimationBlock} .expandRight`;
        const headingAccentClass = 'expandRightActive';
        // Adds the class that activates the animation whenever the components are in the view
        setAnimationInterObserver(selector, newClass);
        setAnimationInterObserver(fadeUpSelector, fadeUpClass);
        setAnimationInterObserver(scrollSelector, scrollClass);
        setAnimationInterObserver(headingRevealSelector, headingRevealClass);
        setAnimationInterObserver(headingAccentSelector, headingAccentClass);

        const scrollContainerTop = scrollContainerRef?.current?.getBoundingClientRect()?.top;
        const contentContainerBottom = contentContainerRef?.current?.getBoundingClientRect()
            ?.bottom;

        if (scrollContainerTop - contentContainerBottom < minSpaceAboveScroll)
            scrollContainerRef.current.style.display = 'none';

        return () => {
            scrollContainerRef.current.style.display = '';
        };
    }, []);

    return (
        <section
            className={classnames(qLHeroAnimationBlock, {
                [`${qLHeroAnimationBlock}--editing`]: isEditing,
            })}
        >
            <div
                className={classnames(
                    `${qLHeroAnimationBlock}__overlay`,
                    `${qLHeroAnimationBlock}__overlay--${overlayColor}`,
                    {
                        [`${qLHeroAnimationBlock}__overlay--editing`]: isEditing,
                    }
                )}
            ></div>
            <div
                className={classnames(
                    `${qLHeroAnimationBlock}__vector`,
                    `${qLHeroAnimationBlock}__vector--${overlayColor}`
                )}
            ></div>
            <div className={classnames(`${qLHeroBlock}__padding`)}>
                <div ref={contentContainerRef} className={classnames(`${qLHeroBlock}__content`)}>
                    <Text
                        className={classnames(
                            `${qLHeroBlock}__eyebrow`,
                            `${qLHeroBlock}__eyebrow--${overlayColor}`,
                            { fadeIn: true },
                            {
                                [`${qLHeroBlock}__eyebrow--editing`]: isEditing,
                            }
                        )}
                        as="span"
                        useDefaultMargin={false}
                        field={Eyebrow}
                    />
                    <div
                        className={classnames(
                            `${qLHeroBlock}__title`,
                            `${qLHeroBlock}__title--${overlayColor}`,
                            {
                                headingFadeIn: true,
                            },
                            {
                                [`${qLHeroBlock}__title--editing`]: isEditing,
                            }
                        )}
                    >
                        <RichText
                            className={classnames(`${qLHeroBlock}__title-content`)}
                            field={Title}
                        />
                    </div>
                    <div
                        className={classnames(
                            `${qLHeroAnimationBlock}__accent`,
                            `${qLHeroAnimationBlock}__accent--${AccentColor?.value?.toLowerCase()}`,
                            { expandRight: true }
                        )}
                    />
                    <RichText
                        className={classnames(
                            `${qLHeroBlock}__description`,
                            `${qLHeroBlock}__description--${overlayColor}`,
                            { fadeIn: true },
                            {
                                [`${qLHeroBlock}__description--editing`]: isEditing,
                            }
                        )}
                        tag="p"
                        field={Description}
                    />
                </div>
            </div>
            <div
                ref={scrollContainerRef}
                className={classnames(`${qLHeroAnimationBlock}__scroll-container`, {})}
            >
                <div
                    className={classnames(
                        `${qLHeroAnimationBlock}__scroll-container-line`,
                        `${qLHeroAnimationBlock}__scroll-container-line--${overlayColor}`,
                        {
                            expandDown: true,
                        }
                    )}
                ></div>
                <p
                    className={classnames(
                        `${qLHeroAnimationBlock}__scroll-container-text`,
                        `${qLHeroAnimationBlock}__scroll-container-text--${overlayColor}`,
                        {
                            fadeIn: true,
                        }
                    )}
                >
                    {t('Scroll')}
                </p>
            </div>
        </section>
    );
};

export default withSitecoreContext()(withTranslation()(QuarterlyLandingHeroAnimation));
