import React from 'react';
import Text from '../Text/Text.jsx';
import MegaNavHeader from '../MegaNavHeader/MegaNavHeader.jsx';
import { LinkTargetEnum, ButtonVariantEnum, LinkTargetValueEnum } from '../../utils/enums';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';

import './mega-nav-tertiary.scss';

const MegaNavTertiary = ({
    insightsItem,
    aboutUsContentTypeTitle,
    aboutUsContentTypeLinks,
    openMegaMenuHome,
}) => {
    return (
        <section className="zn-mega-menu-tertiary">
            <MegaNavHeader
                title={insightsItem?.title}
                description={insightsItem?.description}
                link={insightsItem?.descriptionLink?.url}
                linkTarget={LinkTargetEnum.sameTab}
                linkText={insightsItem?.descriptionLink?.text}
                onBack={openMegaMenuHome}
            />
            <section className="zn-mega-menu-tertiary__body">
                <div className="zn-mega-menu-tertiary__body__column zn-mega-menu-tertiary__body__main-column">
                    <Text type="h2" className="zn-mega-menu-tertiary__title">
                        {aboutUsContentTypeTitle?.value}
                    </Text>
                    <ul className="zn-mega-menu-tertiary__list">
                        {Array.isArray(aboutUsContentTypeLinks) &&
                            aboutUsContentTypeLinks.map((item, index) => (
                                <li key={index} className="zn-mega-menu-tertiary__list__item">
                                    <ButtonLink
                                        fields={{
                                            LinkURL: {
                                                value: {
                                                    href: item?.navigationLink?.url,
                                                    title: `nav link to ${item?.title?.value}`,
                                                    target: LinkTargetValueEnum.default,
                                                    text: item?.title?.value,
                                                },
                                            },
                                        }}
                                        params={{
                                            Variant: ButtonVariantEnum.tertiary.toLowerCase(),
                                        }}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
            </section>
        </section>
    );
};

export default MegaNavTertiary;
