import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../SocialMediaLinks/social-media-links.scss';
import {
    Image as Img,
    withSitecoreContext,
    withPlaceholder,
} from '@sitecore-jss/sitecore-jss-react';
import { DownloadFile } from '../../assets/LinkIcons';
import forStorybook from '../../utils/forStorybook.jsx';
import { getVCardUrl } from '../../utils/vcardUrl.jsx';
import './profile-hero.scss';

const ProfileHero = ({ socialmedia, dropdown, fields, sitecoreContext }) => {
    const {
        Image,
        Name,
        Position,
        Description,
        PrimaryPhoneLabel,
        PrimaryPhoneNumber,
        SecondaryPhoneLabel,
        SecondaryPhoneNumber,
        Email,
    } = fields;

    const profileHeroBlock = 'zn-profile-hero';
    let contactTitle = 'Contact';
    let withImage = !Image?.value?.class && Image?.value && Object.keys(Image?.value).length > 0;

    const contextFields = sitecoreContext?.route?.fields;

    let FirstName = contextFields?.FirstName;
    let MiddleName = contextFields?.MiddleName;
    let LastName = contextFields?.LastName;

    if (!(FirstName?.value || LastName?.value || MiddleName?.value)) {
        if (Name?.value) {
            const splitName = Name?.value.split(' ');
            FirstName = { value: splitName[0] };
            if (splitName?.length > 1) {
                LastName = { value: splitName[splitName.length - 1] };
            }
            if (splitName?.length > 2) {
                MiddleName = { value: splitName.slice(1, splitName?.length - 1).join(' ') };
            }
        }
    }

    const primaryNumber = PrimaryPhoneNumber?.value;
    const secondaryNumber = SecondaryPhoneNumber?.value;

    return (
        <div className={classnames(`${profileHeroBlock}`)}>
            <div className={classnames(`${profileHeroBlock}__columns`)}>
                {withImage && (
                    <div className={classnames(`${profileHeroBlock}__photo-col`)}>
                        <div className={classnames(`${profileHeroBlock}__image`)}>
                            <Img field={Image?.value} />
                        </div>
                    </div>
                )}
                <div
                    className={classnames(`${profileHeroBlock}__info-col`, {
                        [`${profileHeroBlock}__info-col--no-image`]: !withImage,
                    })}
                >
                    <div className={classnames(`${profileHeroBlock}__icon-bar`)}>
                        <div className={`${profileHeroBlock}__icon-element`}>{dropdown}</div>
                        <div className={`${profileHeroBlock}__download`}>
                            <a
                                className={`${profileHeroBlock}__download-link`}
                                download={Name?.value ? Name?.value + '.vcf' : 'contact-card.vcf'}
                                href={getVCardUrl(
                                    FirstName,
                                    MiddleName,
                                    LastName,
                                    Position,
                                    PrimaryPhoneNumber,
                                    SecondaryPhoneNumber,
                                    { value: 'work' },
                                    {},
                                    Email
                                )}
                            >
                                <DownloadFile />
                            </a>
                        </div>
                        <div className={`${profileHeroBlock}__icon-element`}>{socialmedia}</div>
                    </div>
                    <div className={classnames(`${profileHeroBlock}__title`)}>{Name?.value}</div>
                    <div className={classnames(`${profileHeroBlock}__position`)}>
                        {Position?.value}
                    </div>
                    <div className={classnames(`${profileHeroBlock}__accent`)}></div>
                    <div
                        className={classnames(`${profileHeroBlock}__description`, {
                            [`${profileHeroBlock}__description--no-image`]: !withImage,
                        })}
                    >
                        {Description?.value}
                    </div>
                    <div
                        className={classnames(`${profileHeroBlock}__contact-accent`, {
                            [`${profileHeroBlock}__contact-accent--no-image`]: !withImage,
                        })}
                    ></div>
                    {(PrimaryPhoneLabel?.value ||
                        primaryNumber ||
                        SecondaryPhoneLabel?.value ||
                        secondaryNumber ||
                        Email?.value) && (
                        <div
                            className={classnames(`${profileHeroBlock}__contact-bar`, {
                                [`${profileHeroBlock}__contact-accent--no-image`]: !withImage,
                            })}
                        >
                            <div className={classnames(`${profileHeroBlock}__contact-title`)}>
                                {contactTitle}
                            </div>
                            <div className={classnames(`${profileHeroBlock}__contact-info`)}>
                                <ul className={classnames(`${profileHeroBlock}__phone-numbers`)}>
                                    {((PrimaryPhoneLabel?.value && primaryNumber) ||
                                        primaryNumber) && (
                                        <li>
                                            {PrimaryPhoneLabel.value}
                                            <a
                                                className={classnames(
                                                    `${profileHeroBlock}__phone-number`
                                                )}
                                                href={`tel:${PrimaryPhoneNumber.value}`}
                                            >
                                                {PrimaryPhoneNumber.value}
                                            </a>
                                        </li>
                                    )}
                                    {((SecondaryPhoneLabel?.value && secondaryNumber) ||
                                        secondaryNumber) && (
                                        <li>
                                            {SecondaryPhoneLabel.value}
                                            <a
                                                className={classnames(
                                                    `${profileHeroBlock}__phone-number`
                                                )}
                                                href={`tel:${SecondaryPhoneNumber.value}`}
                                            >
                                                {SecondaryPhoneNumber.value}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                                {Email?.value && (
                                    <a
                                        className={classnames(`${profileHeroBlock}__email`)}
                                        href={`mailto:${Email.value}`}
                                    >
                                        {Email.value}
                                    </a>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ProfileHero.propTypes = {
    fields: PropTypes.exact({
        Image: PropTypes.shape({
            value: PropTypes.object,
        }),
        Name: PropTypes.shape({
            value: PropTypes.string,
        }),
        Position: PropTypes.shape({
            value: PropTypes.string,
        }),
        Description: PropTypes.shape({
            value: PropTypes.string,
        }),
        PrimaryPhoneLabel: PropTypes.shape({
            value: PropTypes.string,
        }),
        PrimaryPhoneNumber: PropTypes.shape({
            value: PropTypes.string,
        }),
        SecondaryPhoneLabel: PropTypes.shape({
            value: PropTypes.string,
        }),
        SecondaryPhoneNumber: PropTypes.shape({
            value: PropTypes.string,
        }),
        Email: PropTypes.shape({
            value: PropTypes.string,
        }),
        VCard: PropTypes.shape({
            value: PropTypes.object,
        }),
    }),
    socialmedia: PropTypes.object,
    dropdown: PropTypes.object,
};

const ProfileHeroWithContext = withSitecoreContext()(ProfileHero);

export const ProfileHeroStorybook = forStorybook(ProfileHeroWithContext);
export default withPlaceholder(['socialmedia', 'dropdown'])(ProfileHeroWithContext);
