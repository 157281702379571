/* eslint-disable no-unused-vars */
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import ButtonLink from '../../ButtonLink/ButtonLink.jsx';
import { ArrowRight } from '../../../assets/LinkIcons.js';
import { withTranslation } from 'react-i18next';
import { Image as Img, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../../utils/withErrorBoundary.jsx';
import classnames from 'classnames';
import { forStorybookV2 } from '../../../utils/forStorybook.jsx';
import { Link as RouterLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { IsEmptyDate } from '../../../utils/dateExtensions';
import './event-content-card.scss';
import {
    HighlightColorItemEnum,
    ContentCardColour,
    ContentCardType,
    ThemeEnum,
    ButtonVariantEnum,
    LinkTargetValueEnum,
    TimeZoneEnum,
} from '../../../utils/enums.js';
import { MovieCameraIcon, InPersonEventIcon, StaticEventIcon } from '../../../assets/common.js';

const TypeToIconEmum = {
    Virtual: MovieCameraIcon,
    Event: StaticEventIcon,
    'In-Person': InPersonEventIcon,
};

const contentCardBlock = 'zn-event-content-card';

const parseContentType = (template) => {
    let contentType;

    switch (template) {
        case 'Publication Page':
            contentType = 'Event';
            break;
        case 'Event':
            contentType = 'In-Person';
            break;
        case 'Webinar':
            contentType = 'Virtual';
            break;
    }

    return contentType;
};

const EventContentCard = ({ sitecoreContext, fields, params, t }) => {
    const { data } = fields;
    const { IncludeImage, ImageHighlightColor, DefaultCardColor, EnableAnimation } = params;
    const AltText = data?.datasource?.altText || '';
    const TitleOverride = data?.datasource?.titleOverride || '';
    const DescriptionOverride = data?.datasource?.descriptionOverride || '';
    const ImageOverride = data?.datasource?.imageOverride || '';
    const ExternalLink = data?.datasource?.externalLink || {};
    const ExternalLinkIcon = data?.datasource?.externalLinkIcon?.value || 'Insight';
    const PageLink = data?.datasource?.pageLink?.targetItem || '';
    const teaserTitle = PageLink?.teaserTitle;
    const teaserDescription = PageLink?.teaserDescription;
    const teaserTemplateTypeOverride = PageLink?.teaserTemplateTypeOverride;
    const teaserDate = PageLink?.teaserDate;
    const displayDate = PageLink?.displayDate;
    const hideDate = PageLink?.hideCardTeaserDate;
    const teaserImage = PageLink?.teaserImage;
    const notNavigatable = PageLink?.notNavigatable;
    const isEditing = sitecoreContext.pageEditing;

    const ContextTeaserDate =
        teaserDate?.value &&
        DateTime.fromISO(teaserDate?.value, {
            locale: sitecoreContext?.route?.itemLanguage,
        })
            .setZone(TimeZoneEnum.server)
            .toLocaleString(DateTime.DATE_MED);
    let useExternalLink =
        ExternalLink?.linkType === 'external' &&
        ExternalLink?.url &&
        (TitleOverride?.value || ExternalLink?.text);

    let externalLinkTitle = ExternalLink?.text
        ? { value: ExternalLink?.text }
        : TitleOverride?.value
        ? TitleOverride
        : '';

    //content card  values
    //if external link use the link text or the title override, if page link use override or teaser title
    const title = useExternalLink
        ? externalLinkTitle
        : TitleOverride?.value
        ? TitleOverride
        : teaserTitle;
    const description = DescriptionOverride?.value ? DescriptionOverride : teaserDescription;

    let FinalDate = '';

    if (!hideDate?.value || hideDate?.value === '0') {
        FinalDate = displayDate?.value
            ? displayDate.value
            : teaserDate?.value && !IsEmptyDate(ContextTeaserDate)
            ? ContextTeaserDate
            : '';
    } else {
        FinalDate = '';
    }

    const image = ImageOverride?.src ? ImageOverride : teaserImage;
    let link = useExternalLink ? ExternalLink?.url : PageLink?.url;
    // Encode any special characters in link. Add / and : back in
    link = link ? encodeURIComponent(link)?.replace(/%2F/g, '/')?.replace(/%3A/g, ':') : '';

    let withImage = PageLink?.teaserImage?.src || ImageOverride?.src;
    let ContentStyle = useExternalLink
        ? ExternalLinkIcon
            ? parseContentType(ExternalLinkIcon)
            : parseContentType('Insight')
        : PageLink?.template?.name
        ? parseContentType(PageLink?.template?.name)
        : '';
    const IconTag = ContentStyle ? TypeToIconEmum[ContentStyle.replace(/\s/g, '')] : '';
    let Type =
        ContentStyle === ContentCardType.contentSeries ||
        !ContentStyle ||
        notNavigatable?.value === '1'
            ? 'div'
            : 'a';
    return (
        (PageLink || isEditing || useExternalLink) &&
        (link?.startsWith('/') && notNavigatable?.value !== '1' ? (
            <RouterLink
                className={classnames(`${contentCardBlock}`, {
                    [`${contentCardBlock}--white`]:
                        DefaultCardColor === ContentCardColour.white &&
                        ContentStyle !== ContentCardType.contentSeries,
                    [`${contentCardBlock}--series`]: ContentStyle === ContentCardType.contentSeries,
                    [`${contentCardBlock}--sc-editing`]: isEditing,
                    [`${contentCardBlock}--non-navigatable`]: notNavigatable?.value === '1',
                    fadeIn: !isEditing && EnableAnimation,
                })}
                {...(!isEditing && ContentStyle !== ContentCardType.contentSeries
                    ? { to: link }
                    : {})}
                target="_self"
                alt={AltText?.value}
            >
                <div className={classnames(`${contentCardBlock}__container`)}>
                    {withImage &&
                        IncludeImage === '1' &&
                        ContentStyle !== ContentCardType.contentSeries && (
                            <div className={classnames(`${contentCardBlock}__image`)}>
                                {ImageHighlightColor &&
                                    ImageHighlightColor !== HighlightColorItemEnum.none && (
                                        <div
                                            className={classnames(
                                                `${contentCardBlock}__image-highlight`,
                                                `${contentCardBlock}__image-highlight--${ImageHighlightColor.toLowerCase()}`
                                            )}
                                        ></div>
                                    )}
                                <Img field={image} />
                            </div>
                        )}
                    <div className={`${contentCardBlock}__content`}>
                        {(ContentStyle || teaserTemplateTypeOverride?.value) && (
                            <div className={`${contentCardBlock}__eyebrow-content`}>
                                {IconTag && (
                                    <div
                                        className={classnames(`${contentCardBlock}__icon`, {
                                            [`${contentCardBlock}__icon--series`]:
                                                ContentStyle === ContentCardType.contentSeries,
                                        })}
                                    >
                                        <IconTag />
                                    </div>
                                )}
                                <div className={`${contentCardBlock}__content-type`}>
                                    {teaserTemplateTypeOverride?.value
                                        ? teaserTemplateTypeOverride?.value
                                        : ContentStyle
                                        ? t(ContentStyle?.replace(/\s/g, ''))
                                        : ''}
                                </div>
                            </div>
                        )}
                        <div
                            className={classnames(`${contentCardBlock}__title`, {
                                [`${contentCardBlock}__title--series`]:
                                    ContentStyle === ContentCardType.contentSeries,
                            })}
                        >
                            <RichText field={title} />
                        </div>
                        <div className={classnames(`${contentCardBlock}__description`)}>
                            <RichText field={description} />
                        </div>
                    </div>
                </div>
                {ContentStyle === ContentCardType.contentSeries && notNavigatable?.value !== '1' ? (
                    <div className={`${contentCardBlock}__cta-container`}>
                        <ButtonLink
                            fields={{
                                LinkURL: {
                                    value: {
                                        text: t('GoToSeries'),
                                        href: link,
                                        title: AltText?.value,
                                        target: LinkTargetValueEnum.self,
                                    },
                                },
                            }}
                            params={{
                                Variant: ButtonVariantEnum.secondary,
                                Theme: ThemeEnum.dark,
                            }}
                        ></ButtonLink>
                    </div>
                ) : (
                    <div className={`${contentCardBlock}__card-footer`}>
                        <div className={`${contentCardBlock}__date`}>{FinalDate && FinalDate}</div>
                        {notNavigatable?.value !== '1' && (
                            <div className={`${contentCardBlock}__arrow`}>
                                <ArrowRight />
                            </div>
                        )}
                    </div>
                )}
            </RouterLink>
        ) : (
            <Type
                className={classnames(`${contentCardBlock}`, {
                    [`${contentCardBlock}--white`]:
                        DefaultCardColor === ContentCardColour.white &&
                        ContentStyle !== ContentCardType.contentSeries,
                    [`${contentCardBlock}--series`]: ContentStyle === ContentCardType.contentSeries,
                    [`${contentCardBlock}--sc-editing`]: isEditing,
                    [`${contentCardBlock}--non-navigatable`]: notNavigatable?.value === '1',
                    fadeIn: !isEditing && EnableAnimation,
                })}
                {...(!isEditing && ContentStyle !== ContentCardType.contentSeries
                    ? { href: link }
                    : {})}
                target="_self"
                alt={AltText?.value}
            >
                <div className={classnames(`${contentCardBlock}__container`)}>
                    {withImage &&
                        IncludeImage === '1' &&
                        ContentStyle !== ContentCardType.contentSeries && (
                            <div className={classnames(`${contentCardBlock}__image`)}>
                                {ImageHighlightColor &&
                                    ImageHighlightColor !== HighlightColorItemEnum.none && (
                                        <div
                                            className={classnames(
                                                `${contentCardBlock}__image-highlight`,
                                                `${contentCardBlock}__image-highlight--${ImageHighlightColor.toLowerCase()}`
                                            )}
                                        ></div>
                                    )}
                                <Img field={image} />
                            </div>
                        )}
                    <div className={`${contentCardBlock}__content`}>
                        <div className={`${contentCardBlock}__eyebrow-content`}>
                            {IconTag && (
                                <div
                                    className={classnames(`${contentCardBlock}__icon`, {
                                        [`${contentCardBlock}__icon--series`]:
                                            ContentStyle === ContentCardType.contentSeries,
                                    })}
                                >
                                    <IconTag />
                                </div>
                            )}
                            {(isEditing || ContentStyle) && (
                                <div className={`${contentCardBlock}__content-type`}>
                                    {ContentStyle
                                        ? t(ContentStyle?.replace(/\s/g, ''))
                                        : t('NoPageLinkSet')}
                                </div>
                            )}
                        </div>
                        <div
                            className={classnames(`${contentCardBlock}__title`, {
                                [`${contentCardBlock}__title--series`]:
                                    ContentStyle === ContentCardType.contentSeries,
                            })}
                        >
                            <RichText field={title} />
                        </div>
                        <div className={classnames(`${contentCardBlock}__description`)}>
                            <RichText field={description} />
                        </div>
                    </div>
                </div>
                {ContentStyle === ContentCardType.contentSeries && notNavigatable?.value !== '1' ? (
                    <div className={`${contentCardBlock}__cta-container`}>
                        <ButtonLink
                            fields={{
                                LinkURL: {
                                    value: {
                                        text: t('GoToSeries'),
                                        href: link,
                                        title: AltText?.value,
                                        target: LinkTargetValueEnum.self,
                                    },
                                },
                            }}
                            params={{
                                Variant: ButtonVariantEnum.secondary,
                                Theme: ThemeEnum.dark,
                            }}
                        ></ButtonLink>
                    </div>
                ) : (
                    <div className={`${contentCardBlock}__card-footer`}>
                        <div className={`${contentCardBlock}__date`}>{FinalDate && FinalDate}</div>
                        {notNavigatable?.value !== '1' && (
                            <div className={`${contentCardBlock}__arrow`}>
                                <ArrowRight />
                            </div>
                        )}
                    </div>
                )}
            </Type>
        ))
    );
};

const ContentCardSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params])(EventContentCard)
);

export default withTranslation()(ContentCardSitecore);
export const ContentCardStorybook = forStorybookV2(ContentCardSitecore);
