export const templateNameCondition = '_templatename';
export const nameCondition = '_name';
export const tagIdentifierCondition = 'identifier';
export const tagNameCondition = 'tags';
export const subTagNameCondition = 'subtags';
export const teaserDateSortBy = 'teaserdate';
export const scoreSortBy = 'score';
export const includeInSearchCondition = 'includeinsearch';
export const rootItem = '/sitecore/content/zenith tenant/zenith site/home';
export const isSearchable = { name: includeInSearchCondition, value: 'True' };
export const relatedPeopleCondition = 'relatedpeople';
export const relatedFeaturedPeopleCondition = 'relatedfeaturedpeople';
export const workItemTemplate = { name: templateNameCondition, value: 'Work Page' };
export const relatedFeaturedPagesCondition = 'relatedfeaturedpages';
export const notBioPageOnlyCondition = { name: 'biopageonly', value: 'False' };
export const rootSiteItem = '/sitecore/content/zenith tenant/zenith site';
export const allTemplatesConditions = [
    'About Us',
    'Bio Page',
    'Event',
    'Expertise Landing',
    'General Landing',
    'Homepage',
    'Knowledge Hub',
    'News',
    'Office',
    'Podcast',
    'Premium Article',
    'Premium Expertise Landing',
    'Project Map Work',
    'Publication Page',
    'Quarterly Report Landing',
    'Service',
    'Video',
    'Webinar',
    'Work Page',
];
export const displayedContentTypes = ['Work', 'News', 'Events', 'Insights', 'Podcasts', 'Videos'];
export const peopleRelationsArray = [
    'relatedpeople',
    'dealteamlist',
    'relatedfeaturedpeople',
    'relatedprofessionals',
];
export const bioPageTemplateCondition = { name: '_templatename', value: 'Bio Page' };
export const mapTagCondition = { name: '_templatename', value: 'MapTag' };
export const mapMarkerInheritanceCondition = {
    name: '_templates',
    value: 'a48ae6a3415d5031b9f5792c3ed3ccc2',
};
export const includeInMapCondition = { name: 'includeinmap', value: 'True' };
export const MAP_DEBOUNCE_CONST = 1000;
export const MAP_EASE_PADDING_CONST = 70;
