import React from 'react';
import Link from '../Link/Link.jsx';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import Dropdown from '../Dropdown/Dropdown.jsx';
import MegaNavHomeFooter from './MegaNavHomeFooter.jsx';
import {
    LinkTargetEnum,
    ThemeEnum,
    LanguageNamesEnum,
    LinkSubVariantsEnum,
    ButtonVariantEnum,
    LinkVariantEnum,
} from '../../utils/enums';
import paramToBool from '../../utils/paramToBool.js';

import './mega-nav-home.scss';
import { useHistory } from 'react-router-dom';

const MegaNavHome = ({
    params,
    language,
    languages,
    pageUrl,
    theme,
    homeMainLinksPrimary,
    homeMainLinksSecondary,
    homeSecondaryLinks,
    homeSubscribeText,
    homeSubscribeButton,
    homeSocialMediaText,
    homeSocialMediaLinks,
    openMegaMenuPrimary,
    openMegaMenuSecondary,
    openMegaMenuTertiary,
}) => {
    const history = useHistory();
    return (
        <div className="zn-mega-menu-home">
            <div className="zn-mega-menu-home__body">
                <ul className="zn-mega-menu-home__list" as="nav">
                    {Array.isArray(homeMainLinksPrimary) &&
                        homeMainLinksPrimary.map((item, index) => {
                            const hasDropdown =
                                paramToBool(item?.isInsights?.value) ||
                                paramToBool(item?.isExpertise?.value) ||
                                paramToBool(item?.isAboutUs?.value);
                            return (
                                <li
                                    key={index}
                                    className="zn-mega-menu-home__list__item zn-mega-menu-home__list__item--large"
                                >
                                    <Link
                                        Theme={{ value: theme }}
                                        Variant={{
                                            value: `${LinkVariantEnum.nav.toLowerCase()}--mega`,
                                        }}
                                        SubVariant={{
                                            value: [
                                                ...(hasDropdown
                                                    ? [LinkSubVariantsEnum.chevronRight]
                                                    : []),
                                            ],
                                        }}
                                        LinkText={item?.title}
                                        LinkURL={{ value: item?.navigationLink?.url }}
                                        LinkAltText={{ value: item?.title?.value }}
                                        LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (hasDropdown) {
                                                if (paramToBool(item?.isExpertise?.value)) {
                                                    openMegaMenuPrimary();
                                                } else if (paramToBool(item?.isInsights?.value)) {
                                                    openMegaMenuSecondary();
                                                } else {
                                                    openMegaMenuTertiary();
                                                }
                                            } else {
                                                history.push(item?.navigationLink?.url);
                                            }
                                        }}
                                    />
                                </li>
                            );
                        })}
                </ul>
                <ul as="nav" className="zn-mega-menu-home__list">
                    {Array.isArray(homeMainLinksSecondary) &&
                        homeMainLinksSecondary.map((item, index) => {
                            const hasDropdown =
                                paramToBool(item?.isInsights?.value) ||
                                paramToBool(item?.isExpertise?.value) ||
                                paramToBool(item?.isAboutUs?.value);
                            return (
                                <li
                                    key={index}
                                    className="zn-mega-menu-home__list__item zn-mega-menu-home__list__item--large"
                                >
                                    <Link
                                        Theme={{ value: theme }}
                                        Variant={{
                                            value: `${LinkVariantEnum.nav.toLowerCase()}--mega`,
                                        }}
                                        SubVariant={{
                                            value: [
                                                ...(hasDropdown
                                                    ? [LinkSubVariantsEnum.chevronRight]
                                                    : []),
                                            ],
                                        }}
                                        LinkText={item?.title}
                                        LinkURL={{ value: item?.navigationLink?.url }}
                                        LinkAltText={{ value: item?.title?.value }}
                                        LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (hasDropdown) {
                                                if (paramToBool(item?.isExpertise?.value)) {
                                                    openMegaMenuPrimary();
                                                } else if (paramToBool(item?.isInsights?.value)) {
                                                    openMegaMenuSecondary();
                                                } else {
                                                    openMegaMenuTertiary();
                                                }
                                            } else {
                                                history.push(item?.navigationLink?.url);
                                            }
                                        }}
                                    />
                                </li>
                            );
                        })}
                </ul>
                <hr className="zn-divider" />
                <ul className="zn-mega-menu-home__list zn-mega-menu-home__list--small" as="nav">
                    {Array.isArray(homeSecondaryLinks) &&
                        homeSecondaryLinks.map((item, index) => (
                            <li key={index} className="zn-mega-menu-home__list__item">
                                <ButtonLink
                                    fields={{
                                        LinkURL: {
                                            value: {
                                                text: item?.title?.value,
                                                href: item?.navigationLink?.url,
                                                title: item?.title?.value,
                                                target: LinkTargetEnum.sameTab,
                                            },
                                        },
                                    }}
                                    params={{
                                        Theme: ThemeEnum.light.toLowerCase(),
                                        Variant: ButtonVariantEnum.tertiary.toLowerCase(),
                                    }}
                                />
                            </li>
                        ))}
                    {languages.length > 0 && (
                        <li className="zn-mega-menu-home__list__item zn-mega-menu-home__show-mobile">
                            <Dropdown
                                border
                                theme={ThemeEnum[theme]}
                                title={LanguageNamesEnum[language]}
                                content={languages.map((lan, index) => {
                                    if (lan === language) {
                                        return;
                                    }
                                    return (
                                        <li key={index} className="zn-dropdown__list-item">
                                            <Link
                                                className="zn-dropdown__list-item-content"
                                                Theme={{ value: ThemeEnum.light }}
                                                Variant={{
                                                    value: LinkVariantEnum.nav.toLowerCase(),
                                                }}
                                                SubVariant={{
                                                    value: [
                                                        LinkSubVariantsEnum.small,
                                                        LinkSubVariantsEnum.noMargin,
                                                    ],
                                                }}
                                                LinkText={{ value: LanguageNamesEnum[lan] }}
                                                LinkURL={{
                                                    value: `${pageUrl}${
                                                        pageUrl?.includes('?') ? '&' : '?'
                                                    }sc_lang=${lan}`,
                                                }}
                                                LinkAltText={{
                                                    value: LanguageNamesEnum[lan],
                                                }}
                                                LinkTarget={{ value: LinkTargetEnum.sameTab }}
                                            />
                                        </li>
                                    );
                                })}
                            />
                        </li>
                    )}
                </ul>
            </div>
            <hr className="zn-divider" />
            <MegaNavHomeFooter
                homeSocialMediaText={homeSocialMediaText}
                homeSocialMediaLinks={homeSocialMediaLinks}
                homeSubscribeButton={homeSubscribeButton}
                homeSubscribeText={homeSubscribeText}
                params={params}
            />
        </div>
    );
};

export default MegaNavHome;
