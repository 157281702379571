import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './quarterly-section-header.scss';
import forStorybookV2 from '../../utils/forStorybook.jsx';
import { AccentColorEnum } from '../../utils/enums';
import HeadingAccent from '../HeadingAccent';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const qLSectionHeader = 'zn-quarterly-section-header';

export const QuarterlySectionHeader = ({ fields }) => {
    const { Title, FeaturedImage, Description, AccentColor } = fields;

    return (
        <section className={classnames(qLSectionHeader, {})}>
            <Image
                className={classnames(`${qLSectionHeader}__background-image`)}
                field={FeaturedImage}
            />
            <Image
                className={classnames(`${qLSectionHeader}__mobile-image`)}
                field={FeaturedImage}
            />
            <div
                className={classnames(
                    `${qLSectionHeader}__accent-strip`,
                    `${qLSectionHeader}__accent-strip--${AccentColor.value.toLowerCase()}`
                )}
            />
            <div className={classnames(`${qLSectionHeader}__padding`)}>
                <div className={classnames(`${qLSectionHeader}__content`)}>
                    <RichText
                        className={classnames(`${qLSectionHeader}__title`)}
                        tag="h1"
                        field={Title}
                    />
                    <HeadingAccent params={{ AccentColor: AccentColor?.value }} />
                    <RichText
                        className={classnames(`${qLSectionHeader}__description`)}
                        tag="p"
                        field={Description}
                    />
                </div>
            </div>
        </section>
    );
};

QuarterlySectionHeader.propTypes = {
    fields: PropTypes.shape({
        Title: PropTypes.shape({
            value: PropTypes.string,
        }),
        FeaturedImage: PropTypes.shape({
            value: PropTypes.object,
        }),
        AccentColor: PropTypes.shape({
            value: PropTypes.oneOf(Object.values(AccentColorEnum)),
        }),
        Description: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

export const QuarterlySectionHeaderStorybook = forStorybookV2(QuarterlySectionHeader);

export default withTranslation()(QuarterlySectionHeader);
