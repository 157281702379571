import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import { IsEmptyDate } from '../../utils/dateExtensions';
import readingTime from 'reading-time';
import Text from '../Text/Text.jsx';
import { withTranslation } from 'react-i18next';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from '../ButtonLink/ButtonLink.jsx';
import { forStorybookV2 } from '../../utils/forStorybook.jsx';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { Image as Img } from '@sitecore-jss/sitecore-jss-react';
import {
    ArticleHeroThemeColorEnum,
    ThemeEnum,
    ButtonVariantEnum,
    LinkTargetEnum,
    TimeZoneEnum,
    ContentTypeEnum,
} from '../../utils/enums.js';
import { getCalendarEventURL } from '../../utils/calendarEvent.jsx';

import './article-hero.scss';

const timeZone = TimeZoneEnum.server;

const getChapterTitle = (TableOfContentsArticles) => {
    if (!Array.isArray(TableOfContentsArticles)) {
        return '';
    }

    const quarterlyItem = TableOfContentsArticles.find(
        (item) => !!item?.fields?.['Issue Display Date']
    );
    return quarterlyItem?.fields?.['Issue Display Date'] || '';
};

const ArticleHero = ({ fields, sitecoreContext, t, params }) => {
    const {
        Title,
        Image,
        PremiumArticle,
        QuarterlyWatermark,
        ReportEdition,
        PublishDate,
        ReadingTimeOverride,
        ButtonLink,
        ContextDateField,
        ContextTitleField,
    } = fields;
    const { ColorTheme, ColorAccent, TwoColumnLayout } = params;
    const contextFields = sitecoreContext?.route?.fields;
    const isPageEdit = sitecoreContext?.pageEditing;
    const isEvent = sitecoreContext?.route?.templateName === ContentTypeEnum.event;
    const isPremiumArticle =
        sitecoreContext?.route?.templateName === ContentTypeEnum.premiumArticle;
    const isPublication =
        sitecoreContext?.route?.templateName === ContentTypeEnum.publication || isPremiumArticle;
    const isProjectMapWork =
        contextFields?.DefaultPostingStyle?.value == 'Project' ||
        contextFields?.DefaultPostingStyle?.value == 'Hybrid';
    const isWork =
        sitecoreContext?.route?.templateName === ContentTypeEnum.work ||
        sitecoreContext?.route?.templateName === ContentTypeEnum.projectMapWork;
    const isNews = sitecoreContext?.route?.templateName === ContentTypeEnum.news;
    const isWebinar = sitecoreContext?.route?.templateName === ContentTypeEnum.webinar;
    const articleHeroBlock = 'zn-article-hero';
    const colourTheme = ColorTheme ? ColorTheme.toLowerCase() : '';
    const colourAccent = ColorAccent ? ColorAccent.toLowerCase() : '';
    const expertiseCategory =
        !isPageEdit && contextFields?.ExpertiseCategory
            ? contextFields?.ExpertiseCategory[0]?.fields?.Title
            : '';

    let titleValue = null;

    if (!Title?.value && contextFields?.[ContextTitleField?.value]?.value) {
        titleValue = contextFields[ContextTitleField.value];
    } else {
        titleValue = Title?.value ? Title : contextFields?.Title;
    }

    const description = isEvent ? contextFields?.Location : contextFields?.Subtitle;
    const projectMapIndustry =
        isProjectMapWork && !isPageEdit && contextFields?.Industries
            ? contextFields?.Industries[contextFields?.Industries?.length - 1]?.fields?.Title //TODO: subject to change based on how lowest level industry determined
            : '';

    const contextEyebrowPremium = isPremiumArticle
        ? getChapterTitle(contextFields?.['Table of Contents Articles'])
        : '';

    let publishDate = null;

    if (contextFields?.[ContextDateField?.value]?.value) {
        publishDate = DateTime.fromISO(contextFields?.[ContextDateField?.value]?.value, {
            locale: sitecoreContext?.route?.itemLanguage,
        }).setZone(timeZone, { keepLocalTime: true });
    } else if (PublishDate?.value) {
        publishDate = DateTime.fromISO(PublishDate.value, {
            locale: sitecoreContext?.route?.itemLanguage,
        }).setZone(timeZone, { keepLocalTime: true });
    }
    const contextDate =
        isEvent || isWebinar
            ? DateTime.fromISO(contextFields?.StartDateAndTime?.value, {
                  locale: sitecoreContext?.route?.itemLanguage,
              }).setZone(timeZone, { keepLocalTime: true })
            : DateTime.fromISO(contextFields?.Date?.value, {
                  locale: sitecoreContext?.route?.itemLanguage,
              }).setZone(timeZone, { keepLocalTime: true });

    const contextEndDate =
        isEvent || isWebinar
            ? DateTime.fromISO(contextFields?.EndDateAndTime?.value, {
                  locale: sitecoreContext?.route?.itemLanguage,
              }).setZone(timeZone, { keepLocalTime: true })
            : '';

    const endTimeValue = !IsEmptyDate(contextEndDate)
        ? ` - ${contextEndDate?.hour}:${contextEndDate?.minute.toLocaleString(
              sitecoreContext?.route?.itemLanguage,
              {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
              }
          )}`
        : '';

    const endDateValue =
        !IsEmptyDate(contextEndDate) &&
        contextEndDate.toLocaleString() !== contextDate.toLocaleString()
            ? ` - ${contextEndDate.toLocaleString(DateTime.DATE_FULL)}`
            : '';

    const publishDateValue = !IsEmptyDate(publishDate)
        ? publishDate
        : !IsEmptyDate(contextDate)
        ? contextDate
        : '';

    const publishDateLocale =
        isWebinar &&
        publishDateValue &&
        !IsEmptyDate(contextEndDate) &&
        contextEndDate.toLocaleString() !== contextDate.toLocaleString()
            ? `${publishDateValue.monthLong} ${publishDateValue.day}`
            : publishDateValue
            ? publishDateValue.toLocaleString(DateTime.DATE_FULL)
            : '';

    const publishDateString =
        (isEvent || isWebinar) && publishDateValue
            ? `${publishDateLocale}${endDateValue} • 
        ${publishDateValue?.hour}:${publishDateValue?.minute.toLocaleString(
                  sitecoreContext?.route?.itemLanguage,
                  {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                  }
              )}${endTimeValue} ${contextFields?.EventTimeZone?.fields?.DisplayName?.value || ''}`
            : publishDateValue
            ? publishDateLocale
            : '';

    let workEyebrowText = '';

    if (isWork) {
        if (isProjectMapWork) {
            if (expertiseCategory?.value && projectMapIndustry?.value) {
                workEyebrowText = (
                    <div>
                        {expertiseCategory?.value}
                        <span style={{ padding: '0 8px' }}>|</span>
                        {projectMapIndustry?.value}
                    </div>
                );
            } else if (expertiseCategory?.value) {
                workEyebrowText = expertiseCategory?.value;
            } else if (projectMapIndustry?.value) {
                workEyebrowText = projectMapIndustry?.value;
            }
        } else {
            workEyebrowText = expertiseCategory?.value ? (
                <div>
                    {expertiseCategory?.value}
                    <span style={{ padding: '0 8px' }}>|</span>
                    {publishDateString}
                </div>
            ) : (
                publishDateString
            );
        }
    }

    const Theme =
        colourTheme === ArticleHeroThemeColorEnum.white.toLocaleLowerCase() || colourTheme === ''
            ? ThemeEnum.light
            : ThemeEnum.dark;

    const calendarEventData = {
        title: contextFields?.Title?.value,
        startDate: contextFields?.StartDateAndTime?.value,
        endDate: contextFields?.EndDateAndTime?.value,
        location: contextFields?.Location?.value,
        description: '',
    };

    const calendarEventURL = getCalendarEventURL(calendarEventData);

    const [timeToRead, setTimeToRead] = useState(
        ReadingTimeOverride?.value ? ReadingTimeOverride : { value: `${t('Calculating')}...` }
    );
    useEffect(() => {
        if (isPublication && !ReadingTimeOverride?.value) {
            const readingTimeStats = readingTime(
                document?.querySelector('.zn-sticky-side-banner__content')?.innerText || ''
            );
            setTimeToRead({
                value: readingTimeStats?.minutes
                    ? `${Math.ceil(readingTimeStats?.minutes)} ${t('MinRead')}`
                    : '',
            });
        }
    }, []);

    return (
        <section
            style={{ backgroundImage: `url(${Image?.value?.src})` }}
            className={classnames(articleHeroBlock, {
                [`${articleHeroBlock}--image`]: Image?.value?.src,
            })}
        >
            {Image?.value?.src && <Img className={`${articleHeroBlock}__image`} field={Image} />}
            <div
                className={classnames(`${articleHeroBlock}__background`, {
                    [`${articleHeroBlock}__background--${colourTheme}`]: ColorTheme,
                })}
            >
                <div
                    className={classnames(`${articleHeroBlock}-content`, {
                        [`${articleHeroBlock}-content--two-col-width`]: TwoColumnLayout === '1',
                    })}
                >
                    {PremiumArticle?.value ? (
                        <div>
                            {QuarterlyWatermark.value ? (
                                <span>
                                    <Text
                                        className={`${articleHeroBlock}-content__eyebrow`}
                                        field={QuarterlyWatermark}
                                    />
                                </span>
                            ) : (
                                <span>
                                    <Text
                                        className={`${articleHeroBlock}-content__eyebrow`}
                                        field={contextEyebrowPremium}
                                    />
                                </span>
                            )}

                            {ReportEdition?.value && (
                                <>
                                    <span
                                        className={`${articleHeroBlock}-content__eyebrow-divider`}
                                    >
                                        •
                                    </span>
                                    <span>
                                        <Text
                                            field={ReportEdition}
                                            className={`${articleHeroBlock}-content__eyebrow`}
                                        />
                                    </span>
                                </>
                            )}
                        </div>
                    ) : (
                        <div>
                            {(isPublication || isEvent || isNews || isWebinar) && (
                                <span>
                                    <Text className={`${articleHeroBlock}-content__eyebrow`}>
                                        {publishDateString}
                                    </Text>
                                </span>
                            )}

                            {isWork && (
                                <span>
                                    <Text className={`${articleHeroBlock}-content__eyebrow`}>
                                        {workEyebrowText}
                                    </Text>
                                </span>
                            )}

                            {isPublication && (timeToRead?.value || isPageEdit) && (
                                <>
                                    <span
                                        className={`${articleHeroBlock}-content__eyebrow-divider`}
                                    >
                                        •
                                    </span>
                                    <span>
                                        <Text
                                            className={`${articleHeroBlock}-content__eyebrow`}
                                            field={timeToRead}
                                        />
                                    </span>
                                </>
                            )}
                        </div>
                    )}

                    <Text type="h1" className={classnames(`${articleHeroBlock}-content__title`)}>
                        <RichText field={titleValue} />
                    </Text>
                    <div
                        className={classnames(
                            `${articleHeroBlock}-content__accent`,
                            `${articleHeroBlock}-content__accent--${colourAccent}`
                        )}
                    ></div>

                    {(isPageEdit || description?.value) && (
                        <div className={`${articleHeroBlock}__description`}>
                            <RichText field={description} />
                        </div>
                    )}

                    {isEvent && (
                        <div className={`${articleHeroBlock}__description`}>
                            <Text field={contextFields?.Street} />
                            <br />
                            <Text field={contextFields?.City} />
                            {contextFields?.City?.value &&
                                (contextFields?.StateOrProvince?.fields?.StateAndProvinceName
                                    ?.value ||
                                    contextFields?.ZipOrPostalCode?.value) && <span>, </span>}
                            {contextFields?.StateOrProvince?.fields?.StateAndProvinceName
                                ?.value && (
                                <Text
                                    field={{
                                        value:
                                            contextFields?.StateOrProvince?.fields
                                                ?.StateAndProvinceName?.value,
                                    }}
                                />
                            )}
                            <span> </span>
                            <Text field={contextFields?.ZipOrPostalCode} />
                        </div>
                    )}

                    {isEvent && (
                        <Button
                            params={{
                                Variant: ButtonVariantEnum.primary,
                                Theme: Theme,
                            }}
                            fields={{
                                LinkURL: {
                                    value: {
                                        text: t('AddtoCalendar'),
                                        href: calendarEventURL,
                                        title: t('AddtoCalendar'),
                                        target: LinkTargetEnum.sameTab,
                                    },
                                },
                            }}
                        />
                    )}

                    {ButtonLink?.value?.href && ButtonLink?.value?.text && (
                        <Button
                            params={{
                                Variant: ButtonVariantEnum.primary,
                                Theme: Theme,
                            }}
                            fields={{
                                LinkURL: {
                                    value: {
                                        text: ButtonLink?.value?.text,
                                        href: ButtonLink?.value?.href,
                                        title: ButtonLink?.value?.title,
                                        target: ButtonLink?.value?.target,
                                    },
                                },
                            }}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

const ArticleHeroSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(ArticleHero)
);
export const ArticleHeroStorybook = forStorybookV2(ArticleHeroSitecore);
export default withTranslation()(ArticleHeroSitecore);
