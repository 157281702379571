import React, { useEffect, useState } from 'react';
import Text from '../Text/Text.jsx';
import { Button } from '../Button/Button.js';
import { ChevronDown } from '../../assets/LinkIcons.js';

import './dropdown.scss';
import classNames from 'classnames';

const Dropdown = ({ active, title, Icon, content, theme, border }) => {
    const [isActive, setActive] = useState(active);

    useEffect(() => {
        const escFunction = (event) => {
            if (event?.key === 'Escape') {
                setActive(false);
            }
        };

        window.addEventListener('keydown', escFunction);

        return () => {
            window.removeEventListener('keydown', escFunction);
        };
    }, []);

    return (
        <span className={classNames('zn-dropdown', { 'zn-dropdown--border': border })}>
            <Button
                as="button"
                variant="icon"
                className={classNames('zn-dropdown__title', {
                    [`zn-dropdown__title--${theme}`]: theme,
                })}
                onClick={() => {
                    setActive(!isActive);
                }}
            >
                {Icon && (
                    <span className="zn-dropdown__icon">
                        <Icon theme={theme} />
                    </span>
                )}
                <Text type="body" field={{ value: title }} useDefaultMargin={false} />
                <span className={classNames('zn-dropdown__chevron', { active: isActive })}>
                    <ChevronDown theme={theme} />
                </span>
            </Button>
            {isActive && <div className="zn-dropdown__content">{content}</div>}
        </span>
    );
};

export default Dropdown;
