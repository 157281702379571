import React from 'react';
import classnames from 'classnames';
import forStorybookV2 from '../../utils/forStorybook.jsx';
import './horizontalRule.scss';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const HorizontalRule = ({ params }) => {
    const { HorizontalRuleColor, PaddingTop, PaddingBottom } = params;

    const horizontalRule = 'zn-horizontal-rule';

    return (
        <section
            className={classnames(`${horizontalRule}`, {
                [`${horizontalRule}--pt-${PaddingTop?.toLowerCase()}`]: PaddingTop,
                [`${horizontalRule}--pb-${PaddingBottom?.toLowerCase()}`]: PaddingBottom,
            })}
        >
            <hr
                className={classnames(
                    `${horizontalRule}__line--${HorizontalRuleColor?.toLowerCase()}`
                )}
            />
        </section>
    );
};

const HorizontalRuleSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.params])(HorizontalRule)
);

export const HorizontalRuleStorybook = forStorybookV2(HorizontalRuleSitecore);

export default HorizontalRuleSitecore;
