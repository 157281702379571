import React, { useEffect, useState } from 'react';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { BasicLinkList as LinkList } from '../LinkList/LinkList.jsx';
import LinkListItem from '../LinkListItem/LinkListItem.jsx';
import { ContextLinkListEnum, ContextLinkListItemEnum, LinkTargetEnum } from '../../utils/enums';
import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { forStorybookV2 as forStorybook } from '../../utils/forStorybook.jsx';
import './context-link-list.scss';
import paramToBool from '../../utils/paramToBool';

const ContextLinkList = ({ fields, sitecoreContext, linkList, sectionTitle, params }) => {
    const { TemplateFieldName, FieldTitle, LinkTarget } = fields;
    const contextFields = sitecoreContext?.route?.fields;
    const titleFieldName = FieldTitle?.value || ContextLinkListItemEnum.description;
    const listFieldName = TemplateFieldName?.value || ContextLinkListEnum.relatedServices;
    const LinkListItems = Array.isArray(contextFields[listFieldName])
        ? contextFields[listFieldName]
        : [];
    const contextLinkListBlock = `zn-context-link-list`;
    const { HideInPdf } = params;

    const [pdfView, setPdfView] = useState(false);

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setPdfView(true);
        }
    }, []);

    return (
        !(pdfView && paramToBool(HideInPdf)) && (
            <>
                {sitecoreContext?.pageEditing && (
                    <>
                        <h6 className="zn-page-editing-title">
                            **This component is using page field {listFieldName} as datasource**
                        </h6>
                        <h6 className="zn-page-editing-title">**Section Title placeholder**</h6>
                    </>
                )}
                {(sitecoreContext?.pageEditing || LinkListItems.length > 0) && (
                    <div className={`${contextLinkListBlock}`}>
                        <section>{sectionTitle}</section>
                        <LinkList fields={fields} params={params} placeholders={{ linkList }}>
                            {LinkListItems.map((item, index) => {
                                const name = item?.fields[titleFieldName] || {
                                    value: item?.displayName,
                                };
                                const notNavigatable = item?.fields['NotNavigatable'];

                                //should be navigatable except if explicitly set to 1
                                const navigatable = notNavigatable?.value !== true;

                                if (name?.value && item?.url) {
                                    return (
                                        <LinkListItem
                                            params={params}
                                            key={`item-${index}`}
                                            fields={{
                                                ...fields,
                                                LinkName: name,
                                                LinkURL: navigatable
                                                    ? {
                                                          value: {
                                                              text: name?.value,
                                                              href: item?.url,
                                                              title: name?.value,
                                                              target:
                                                                  LinkTarget?.value ===
                                                                  LinkTargetEnum.newTab
                                                                      ? '_blank'
                                                                      : '_self',
                                                          },
                                                      }
                                                    : undefined,
                                            }}
                                        ></LinkListItem>
                                    );
                                }
                            })}
                        </LinkList>
                    </div>
                )}
            </>
        )
    );
};

export const ContextLinkListSitecore = withSitecoreContext()(
    withPlaceholder(['sectionTitle', 'linkList'])(
        withErrorBoundary([ErrorBoundaryConditions.params, ErrorBoundaryConditions.fields])(
            ContextLinkList
        )
    )
);
export const ContextLinkListStorybook = forStorybook(ContextLinkListSitecore);
export default ContextLinkListSitecore;
