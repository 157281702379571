import React from 'react';
import PropTypes from 'prop-types';
import './social-media-links.scss';
import Text from '../Text';
import classnames from 'classnames';
import { LinkIconComponent } from './SocialMediaIcons.jsx';
import { ThemeEnum, SizeEnum } from '../../utils/enums';
import SocialMediaLink from './SocialMediaLink.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const socialMediaLinkBlock = 'zn-social-media-link';

const copyToClipboard = (text) => navigator.clipboard.writeText(text);

const CopyToClipboardButton = ({
    contentToCopy,
    showLabel,
    label,
    theme,
    alt,
    labelsMarginSize,
    t,
}) => (
    <div
        role="button"
        tabIndex="0"
        onClick={() => copyToClipboard(contentToCopy)}
        onKeyDown={({ key }) => {
            if (['Enter', 'Space'].includes(key)) {
                copyToClipboard(contentToCopy);
            }
        }}
        className={classnames(socialMediaLinkBlock, {
            [`${socialMediaLinkBlock}--dark`]: theme === ThemeEnum.dark,
        })}
    >
        <LinkIconComponent alt={alt?.value} className={`${socialMediaLinkBlock}__icon`} />
        <Text
            field={label}
            useDefaultMargin={false}
            className={classnames(`${socialMediaLinkBlock}__label`, {
                [`${socialMediaLinkBlock}__label--dark`]: theme === ThemeEnum.dark,
                [`${socialMediaLinkBlock}__label--sm-margin`]: labelsMarginSize === SizeEnum.small,
                [`${socialMediaLinkBlock}__label--lg-margin`]: labelsMarginSize === SizeEnum.large,
                'sr-only': !showLabel,
            })}
        />
        <span className="sr-only">{t('OpensInANewWindow')}</span>
    </div>
);

CopyToClipboardButton.propTypes = {
    theme: SocialMediaLink.propTypes.theme,
    alt: SocialMediaLink.propTypes.alt,
    showLabel: SocialMediaLink.propTypes.showLabel,
    label: SocialMediaLink.propTypes.label,
    labelsMarginSize: SocialMediaLink.propTypes.labelsMarginSize,
    contentToCopy: PropTypes.string,
};

export default withTranslation()(withSitecoreContext()(CopyToClipboardButton));
