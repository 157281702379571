import React from 'react';
import classnames from 'classnames';
import { ArrowRight } from '../../assets/LinkIcons.js';
import '../ButtonLink/button-link.scss';
import './interactive-map-popup.scss';

const InteractiveMapPopup = ({
    icon,
    title,
    tags,
    ctaText,
    notNavigatable,
    disableMapCTA,
    url,
}) => {
    const interMapPopupBlock = 'zn-interactive-map-popup';
    const nonNavigable = notNavigatable === 'True' || disableMapCTA === 'True';
    return (
        <div className={`${interMapPopupBlock}`}>
            <div className={`${interMapPopupBlock}__icon-container`}>
                <div
                    className={`${interMapPopupBlock}__icon`}
                    dangerouslySetInnerHTML={{ __html: icon }}
                ></div>
            </div>
            <div className={`${interMapPopupBlock}__content`}>
                <div className={`${interMapPopupBlock}__title-container`}>
                    <span
                        className={classnames(`${interMapPopupBlock}__title`)}
                        dangerouslySetInnerHTML={{ __html: title }}
                    ></span>
                </div>
                <div className={`${interMapPopupBlock}__tag`}>{tags}</div>
                {ctaText?.value && !nonNavigable && (
                    <div className={`${interMapPopupBlock}__link`}>
                        <a
                            className={classnames(
                                `zn-button`,
                                `zn-button--tertiary`,
                                `zn-button--light`,
                                `zn-button--tertiary--light`
                            )}
                            href={url}
                            target="_self"
                        >
                            {ctaText?.value}
                            <div
                                className={classnames(
                                    `zn-button__icon`,
                                    `zn-button--tertiary--light__icon`
                                )}
                            >
                                <ArrowRight />
                            </div>
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InteractiveMapPopup;
